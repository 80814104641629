import Pill from '@shared/components/Pill'
import { MedicationOrder } from '@shared/types/medication_order'
import {
  isDiscontinued,
  isExternal,
  isNewMedication,
  isPending,
  isPendingDiscontinue,
  isPendingUpdate,
  isRejected,
  isWeakMatch,
} from '@shared/utils/medicationStatement'
import { twx } from '@shared/utils/tailwind'
import { mapMedOrderToFormData } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/helpers'
import { isCurrentlyOnHold } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderHoldForCard/helpers'

type Props = {
  order: MedicationOrder
  className?: string
}
export const OrderStatusPill = ({ order, className }: Props) => {
  const isRejectedMed = isRejected(order)
  const isPendingMed = isPending(order)
  const isPendingUpdateMed = isPendingUpdate(order)
  const isPendingDiscontinueMed = isPendingDiscontinue(order)
  const isExternalMed = isExternal(order)
  const isNewMed = isNewMedication(order)
  const isDiscontinuedMed = isPending(order)
    ? false
    : isDiscontinued(order.medicationStatement)
  const isOnHold = isCurrentlyOnHold(mapMedOrderToFormData(order), new Date())
  const isWeakMatchMed = isWeakMatch(order)

  if (isRejectedMed) {
    return (
      <Pill
        shape={'right-arrow'}
        size={'small'}
        className={twx(`bg-alert`, className)}
      >
        Rejected
      </Pill>
    )
  }

  if (isWeakMatchMed) {
    return (
      <Pill
        shape={'right-arrow'}
        size={'small'}
        className={twx(`bg-pill-warning`, className)}
      >
        Possible Duplicate
      </Pill>
    )
  }

  return (
    <>
      {isPendingMed &&
        !isPendingDiscontinueMed &&
        !isPendingUpdateMed &&
        !isWeakMatchMed && (
          <Pill
            shape={'right-arrow'}
            size={'small'}
            className={twx(`bg-pill-warning`, className)}
          >
            Pending
          </Pill>
        )}
      {isPendingUpdateMed && (
        <Pill
          shape={'right-arrow'}
          size={'small'}
          className={twx(`bg-pill-warning`, className)}
        >
          Pending Update
        </Pill>
      )}
      {isPendingDiscontinueMed && (
        <Pill
          shape={'right-arrow'}
          size={'small'}
          className={twx(`bg-pill-warning`, className)}
        >
          Pending Discontinue
        </Pill>
      )}
      {isExternalMed && isNewMed && !isDiscontinuedMed && (
        <Pill
          shape={'right-arrow'}
          size={'small'}
          className={twx(`bg-lime-500`, className)}
        >
          New
        </Pill>
      )}
      {isOnHold && (
        <Pill
          shape={'right-arrow'}
          size={'small'}
          className={twx(`bg-pill-warning`, className)}
        >
          On Hold
        </Pill>
      )}
      {isDiscontinuedMed && <DiscontinuedStatusMedPill className={className} />}
    </>
  )
}

export const DiscontinuedStatusMedPill = ({
  className,
}: {
  className?: string
}) => {
  return (
    <Pill
      shape={'right-arrow'}
      size={'small'}
      className={twx(`bg-alert`, className)}
    >
      Discontinued
    </Pill>
  )
}
