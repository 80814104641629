import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { MedicationOrder, OrderAndDiff } from '@shared/types/medication_order'
import { Loading } from '@shared/utils/loading'
import { usePharmacyMessageDiffs } from '@app/hooks/usePharmacyMessageDiffs'
import { getSinglePharmacyMessage } from '../api/medicationOrders'
import PersonContext from '../contexts/PersonContext'

export function usePharmacyMessage(
  messageId?: string,
  orderGroupId?: string
): {
  medicationOrder: Loading<MedicationOrder | null>
  reloadPharmacyMessage: () => Promise<void>
  messageDiffs: Loading<OrderAndDiff | undefined>
} {
  const { setError } = useContext(GlobalContext)
  const { person } = useContext(PersonContext)

  const [medicationOrder, setMedicationOrder] = useState<
    Loading<MedicationOrder | null>
  >({
    tag: 'Loading',
  })

  const { messageDiffs } = usePharmacyMessageDiffs(
    medicationOrder.tag === 'Complete' && medicationOrder.value && orderGroupId
      ? medicationOrder.value
      : undefined,
    orderGroupId
  )

  const reloadPharmacyMessage = async () => {
    if (person && messageId) {
      try {
        const medicationOrder = await getSinglePharmacyMessage(
          person,
          messageId
        )

        setMedicationOrder({
          tag: 'Complete',
          value: medicationOrder as MedicationOrder,
        })
      } catch (e) {
        setError(e)
        setMedicationOrder({
          tag: 'Complete',
          value: null,
        })
      }
    }
  }

  useEffect(() => {
    setMedicationOrder({ tag: 'Loading' })
    void reloadPharmacyMessage()
  }, [person?.id, messageId])

  return {
    medicationOrder: medicationOrder,
    reloadPharmacyMessage,
    messageDiffs,
  }
}
