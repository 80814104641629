import { parseISO, startOfToday, subDays } from 'date-fns'
import { BillingEvent, BillingEventType } from '@shared/types/billing'
import { formatDateToMonthYearLabel } from '@shared/utils/billing'
import { convertISODateStringToLabel } from '@shared/utils/date'
import { twx } from '@shared/utils/tailwind'
import { MenuItem } from '@app/components/DownloadMenu'
import { BadgeOption } from './TransactionBadgesFilter/types'

type BillingEventKey = keyof BillingEvent

const keysToSearch: BillingEventKey[] = [
  'serviceStartDate',
  'serviceEndDate',
  'description',
  'amountCents',
  'balanceCents',
]

// TO DO: Need to add tests
export function getSearchedBillingTransactions({
  rows,
  searchTerm,
}: {
  rows: BillingEvent[]
  searchTerm: string
}) {
  const searchText = searchTerm.toLowerCase()

  return rows.filter((transaction: BillingEvent) => {
    return Object.entries(transaction).some(([key, val]) => {
      if (keysToSearch.includes(key as BillingEventKey) && val) {
        if (key === 'serviceStartDate' || key === 'serviceEndDate') {
          return convertISODateStringToLabel(val as string).includes(searchText)
        } else {
          return val.toString().toLowerCase().includes(searchText)
        }
      }

      return false
    })
  })
}

export type BadgeFilterType =
  | 'PENDING'
  | BillingEventType.RECURRING
  | BillingEventType.STATEMENT

/**
 * Filter by Pending, Recurring or Statement + Time period
 */

export function filterTransactions({
  transactions,
  badgeFilter,
  monthIndex,
}: {
  transactions: BillingEvent[]
  badgeFilter?: BadgeFilterType
  monthIndex?: number
}) {
  const today = startOfToday()
  const thisYear = today.getFullYear()
  let filteredTransactions = transactions

  if (badgeFilter === 'PENDING') {
    filteredTransactions = transactions.filter((t) => t.pending)
  } else if (badgeFilter) {
    filteredTransactions = transactions.filter(
      (t) => t.transactionType === badgeFilter
    )
  }

  if (monthIndex === undefined) {
    filteredTransactions = filteredTransactions.filter(
      (t) => parseISO(t.serviceStartDate) >= subDays(today, 90)
    )
  } else {
    filteredTransactions = filteredTransactions.filter((t) => {
      const serviceDate = parseISO(t.serviceStartDate)
      return (
        serviceDate.getFullYear() === thisYear &&
        serviceDate.getMonth() === monthIndex
      )
    })
  }

  return filteredTransactions
}

export function getBadgeFilterOptions(
  transactions: BillingEvent[]
): BadgeOption[] {
  return [
    {
      label: 'PENDING',
      value: 'PENDING',
      counter: transactions.filter((t) => t.pending).length,
    },
    {
      label: BillingEventType.RECURRING,
      value: BillingEventType.RECURRING,
      counter: transactions.filter(
        (t) => t.transactionType === BillingEventType.RECURRING
      ).length,
    },
    {
      label: BillingEventType.STATEMENT,
      value: BillingEventType.STATEMENT,
      counter: transactions.filter(
        (t) => t.transactionType === BillingEventType.STATEMENT
      ).length,
    },
  ]
}

type PeriodItem = MenuItem & {
  mIndex?: number
}

export function getPeriodItems({
  selectedMonthIndex,
  today = startOfToday(),
  onClick,
}: {
  today?: Date
  selectedMonthIndex?: number
  onClick: (value: number | undefined) => void
}): PeriodItem[] {
  const monthIndex = today.getMonth()
  const year = today.getFullYear()

  return [
    {
      dataTestId: 'last-90',
      id: 'last-90-days-menu',
      isDisabled: false,
      isVisible: true,
      title: 'Last 90 days',
      onClick: () => onClick(undefined),
      iconName: twx('fa-fw', { 'fa-check': selectedMonthIndex === undefined }),
    },
    ...new Array(monthIndex + 1).fill(undefined).map((_, orderIndex) => {
      const currentIndex = monthIndex - orderIndex
      const currentDateObj = new Date(year, currentIndex, 1)

      return {
        dataTestId: `month-${currentIndex}`,
        id: `month-${currentIndex}-menu`,
        mIndex: currentIndex,
        isDisabled: false,
        isVisible: true,
        title: formatDateToMonthYearLabel(currentDateObj),
        onClick: () => onClick(currentIndex),
        iconName: twx('fa-fw', {
          'fa-check': selectedMonthIndex === currentIndex,
        }),
      }
    }),
  ]
}
