import { EncryptionMethod } from '@augusthealth/models/com/august/protos/encryption'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import {
  CreatePharmacyFacilityPayload,
  PharmacyFacility,
  UpdatePharmacyFacilityPayload,
} from '@shared/types/pharmacy_facility'
import { PharmacyPartner } from '@shared/types/pharmacy_partner'
import { getReadablePharmacyPartnerName } from '@shared/utils/pharmacyPartner'

const ENCRYPTED_PASSWORD_DISPLAY = '**********'
export const BLANK_INTEGRATIONS_FORM: IntegrationsForm = {
  partner: null,
  augustFacilityId: '',
  externalIdentifier: '',
  facilityPassword: undefined,
  pharmacyMailboxId: undefined,
  pharmacyId: undefined,
  facilityMailboxId: undefined,
  facilityId: undefined,
  authUserId: undefined,
}

export interface IntegrationsForm {
  // All Partners
  partner: PharmacyPartner | null
  augustFacilityId: string
  externalIdentifier: string
  // Omnicare, Framework LTC
  facilityPassword?: string
  // Omnicare
  pharmacyMailboxId?: string
  pharmacyId?: string
  facilityMailboxId?: string
  facilityId?: string
  // Framework LTC
  authUserId?: string
}

export const relevantPharmacyPartners = Object.values(PharmacyPartner).filter(
  (partner) =>
    partner !== PharmacyPartner.UNRECOGNIZED &&
    partner !== PharmacyPartner.PHARMACY_PARTNER_UNSPECIFIED
)

export const getPharmacyPartnerOptions =
  (): OptionTypeBase<PharmacyPartner>[] => {
    return relevantPharmacyPartners.map((partner) => {
      return {
        label: getReadablePharmacyPartnerName(partner),
        value: partner,
      }
    })
  }

export const getExistingPharmacyPartnerOptions = (
  integrations?: PharmacyFacility[]
): OptionTypeBase<PharmacyFacility>[] => {
  if (integrations) {
    return integrations.map((integration) => ({
      label: getReadablePharmacyPartnerName(integration.partner!),
      value: integration,
    }))
  }
  return []
}

export const mapPharmacyFacilityToIntegrationsForm = (
  pharmacyFacility: PharmacyFacility
): IntegrationsForm => {
  switch (pharmacyFacility.partner) {
    case PharmacyPartner.PHARMACY_PARTNER_OMNICARE: {
      return {
        ...BLANK_INTEGRATIONS_FORM,
        partner: PharmacyPartner.PHARMACY_PARTNER_OMNICARE,
        augustFacilityId: pharmacyFacility.facilityId,
        externalIdentifier: pharmacyFacility.externalIdentifier,
        facilityPassword: ENCRYPTED_PASSWORD_DISPLAY,
        pharmacyMailboxId:
          pharmacyFacility.partnerSpecificConfiguration?.omnicare
            ?.pharmacyMailboxId,
        pharmacyId:
          pharmacyFacility.partnerSpecificConfiguration?.omnicare?.pharmacyId,
        facilityMailboxId:
          pharmacyFacility.partnerSpecificConfiguration?.omnicare
            ?.facilityMailboxId,
        facilityId:
          pharmacyFacility.partnerSpecificConfiguration?.omnicare?.facilityId,
      }
    }
    case PharmacyPartner.PHARMACY_PARTNER_FRAMEWORK_LTC: {
      return {
        ...BLANK_INTEGRATIONS_FORM,
        partner: PharmacyPartner.PHARMACY_PARTNER_FRAMEWORK_LTC,
        augustFacilityId: pharmacyFacility.facilityId,
        externalIdentifier: pharmacyFacility.externalIdentifier,
        facilityPassword: ENCRYPTED_PASSWORD_DISPLAY,
        authUserId:
          pharmacyFacility.partnerSpecificConfiguration?.frameworkLtc
            ?.authUserId,
      }
    }
    case PharmacyPartner.PHARMACY_PARTNER_REDSAIL_QS1:
    case PharmacyPartner.PHARMACY_PARTNER_SUITE_RX:
    case PharmacyPartner.PHARMACY_PARTNER_DIGITAL_RX:
    case PharmacyPartner.PHARMACY_PARTNER_PHARMERICA: {
      return {
        ...BLANK_INTEGRATIONS_FORM,
        partner: pharmacyFacility.partner,
        augustFacilityId: pharmacyFacility.facilityId,
        externalIdentifier: pharmacyFacility.externalIdentifier,
      }
    }
    default: {
      return BLANK_INTEGRATIONS_FORM
    }
  }
}

export const getCreatePharmacyIntegrationPayload = (
  formData: IntegrationsForm
): CreatePharmacyFacilityPayload => {
  switch (formData.partner) {
    case PharmacyPartner.PHARMACY_PARTNER_OMNICARE: {
      return {
        partner: formData.partner,
        externalIdentifier: formData.externalIdentifier,
        facilityId: formData.augustFacilityId,
        facilityPassword: {
          method: EncryptionMethod.ENCRYPTION_METHOD_KMS,
          unencryptedPlainText: formData.facilityPassword!,
        },
        isActive: true,
        partnerSpecificConfiguration: {
          omnicare: {
            pharmacyMailboxId: formData.pharmacyMailboxId!,
            pharmacyId: formData.pharmacyId!,
            facilityMailboxId: formData.facilityMailboxId!,
            facilityId: formData.facilityId!,
          },
        },
      }
    }
    case PharmacyPartner.PHARMACY_PARTNER_FRAMEWORK_LTC: {
      return {
        partner: formData.partner,
        externalIdentifier: formData.externalIdentifier,
        facilityId: formData.augustFacilityId,
        facilityPassword: {
          method: EncryptionMethod.ENCRYPTION_METHOD_KMS,
          unencryptedPlainText: formData.facilityPassword!,
        },
        isActive: true,
        partnerSpecificConfiguration: {
          frameworkLtc: {
            authUserId: formData.authUserId!,
          },
        },
      }
    }
    case PharmacyPartner.PHARMACY_PARTNER_REDSAIL_QS1:
    case PharmacyPartner.PHARMACY_PARTNER_SUITE_RX:
    case PharmacyPartner.PHARMACY_PARTNER_DIGITAL_RX:
    case PharmacyPartner.PHARMACY_PARTNER_PHARMERICA: {
      return {
        partner: formData.partner,
        externalIdentifier: formData.externalIdentifier,
        facilityId: formData.augustFacilityId,
        isActive: true,
      }
    }
    default:
      throw new Error('unhandled pharmacy partner')
  }
}

export const getUpdatePharmacyIntegrationPayload = ({
  formData,
  currentIntegration,
}: {
  formData: IntegrationsForm
  currentIntegration: PharmacyFacility
}): UpdatePharmacyFacilityPayload => {
  switch (currentIntegration.partner) {
    case PharmacyPartner.PHARMACY_PARTNER_OMNICARE: {
      return {
        ...currentIntegration,
        facilityPassword:
          formData.facilityPassword !== ENCRYPTED_PASSWORD_DISPLAY
            ? {
                method: EncryptionMethod.ENCRYPTION_METHOD_KMS,
                unencryptedPlainText: formData.facilityPassword!,
              }
            : {
                encryptedValue: null,
              },
        partnerSpecificConfiguration: {
          omnicare: {
            pharmacyMailboxId: formData.pharmacyMailboxId!,
            pharmacyId: formData.pharmacyId!,
            facilityMailboxId: formData.facilityMailboxId!,
            facilityId: formData.facilityId!,
          },
        },
      }
    }
    case PharmacyPartner.PHARMACY_PARTNER_FRAMEWORK_LTC: {
      return {
        ...currentIntegration,
        facilityPassword:
          formData.facilityPassword !== ENCRYPTED_PASSWORD_DISPLAY
            ? {
                method: EncryptionMethod.ENCRYPTION_METHOD_KMS,
                unencryptedPlainText: formData.facilityPassword!,
              }
            : {
                encryptedValue: null,
              },
        partnerSpecificConfiguration: {
          frameworkLtc: {
            authUserId: formData.authUserId!,
          },
        },
      }
    }
    case PharmacyPartner.PHARMACY_PARTNER_REDSAIL_QS1:
    case PharmacyPartner.PHARMACY_PARTNER_SUITE_RX:
    case PharmacyPartner.PHARMACY_PARTNER_DIGITAL_RX:
    case PharmacyPartner.PHARMACY_PARTNER_PHARMERICA: {
      return {
        ...currentIntegration,
        facilityPassword: undefined,
      }
    }
    default:
      throw new Error('unhandled pharmacy partner')
  }
}
