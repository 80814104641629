import classNames from 'classnames/bind'
import { useContext } from 'react'
import { DropzoneOptions, useDropzone } from 'react-dropzone'
import GlobalContext from '@shared/contexts/GlobalContext'
import styles from './styles.module.css'

// https://convertlive.com/u/convert/megabytes/to/bytes#10
export const MAX_UPLOAD_SIZE = 31457280 // 30 mb

const cx = classNames.bind(styles)

export default function Uploader(props: {
  handleChange: (file: File) => void
  options?: DropzoneOptions
}) {
  const { handleChange, options } = props
  const { setError } = useContext(GlobalContext)

  const onDrop = (files: File[]) => {
    const file: File | undefined = files[0]
    if (file) {
      if (file.size > MAX_UPLOAD_SIZE) {
        setError({ status: 413 })
      } else {
        handleChange(file)
      }
    }
  }
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/pdf': ['.pdf', '.csv'],
      'image/*': ['.gif', '.jpeg', '.jpg', '.png'],
      'text/csv': ['.csv'],
    },
    onDrop,
    multiple: false,
    ...options,
  })

  const bodyClass = cx(styles.dropZoneBody, {
    'dropZoneBody--active': isDragActive,
  })

  return (
    <div className={styles.dropZoneContainer} {...getRootProps()}>
      <input
        {...getInputProps()}
        data-testid="uploaderInput"
        data-cy="dropzone"
      />
      <div className={bodyClass}>
        <span className={styles.dropZoneCta}>
          <i className="fa-solid fa-file-arrow-up mr-[8px]" />
          Drag and drop or select {options?.multiple ? 'files' : 'a file'}
        </span>
      </div>
    </div>
  )
}
