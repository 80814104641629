import classNames from 'classnames'
import { useContext } from 'react'
import { FieldPath } from 'react-hook-form'
import Skeleton from 'react-loading-skeleton'
import Card from '@shared/components/Card'
import { formatTimeAgo } from '@shared/utils/date'
import {
  VITALS_INPUT_PROPS_MAP,
  VitalsFormData,
  VitalsType,
} from '@shared/utils/vitals'
import VitalsContext from '@app/contexts/VitalsContext'
import styles from './vitals.module.css'

function getSectionClassNames(isActive: boolean | undefined) {
  return classNames('flex items-center', styles.section, {
    [styles.activeSection]: isActive,
  })
}

export default function LatestVitalsCard({
  showDateOfLastEdits,
}: {
  showDateOfLastEdits: boolean
}) {
  const { activeTab, setActiveTab, latestVitals } = useContext(VitalsContext)
  return (
    <Card className="print-hide mb-[16px] p-0">
      <div className={styles.vitalsGrid}>
        {Object.values(VitalsType).map((vitalsType: VitalsType) => {
          if (latestVitals.tag === 'Loading' || !latestVitals.value) {
            return (
              <EmptyVital
                loading={latestVitals.tag === 'Loading'}
                isActive={vitalsType === activeTab}
                handleOnClick={() => setActiveTab(vitalsType)}
                vitalType={vitalsType}
                key={`ev-${vitalsType}`}
                showDateOfLastEdits={showDateOfLastEdits}
              />
            )
          }

          const { label, unitsLabel, iconClassName } =
            VITALS_INPUT_PROPS_MAP(vitalsType)
          const vital = latestVitals.value.compositePanel![vitalsType]

          if (vital === undefined) {
            return (
              <EmptyVital
                isActive={vitalsType === activeTab}
                handleOnClick={() => setActiveTab(vitalsType)}
                vitalType={vitalsType}
                key={`ev-${vitalsType}`}
                showDateOfLastEdits={showDateOfLastEdits}
              />
            )
          }
          let displayValue = '--'
          if ('value' in vital.record!) {
            displayValue = `${vital.record.value!}`
          }
          if ('systolic' in vital.record!) {
            displayValue = vital.record.systolic
              ? `${vital.record.systolic.value}/${
                  vital.record.diastolic!.value
                }`
              : '--'
          }

          const sectionClassNames = getSectionClassNames(
            vitalsType === activeTab
          )
          const iconClassNames = classNames(
            `${iconClassName} text-primary-light`,
            {
              'mr-[8px]': !showDateOfLastEdits,
              'mr-[16px]': showDateOfLastEdits,
            }
          )

          return (
            <section
              data-testid={`${vitalsType}-vital`}
              className={sectionClassNames}
              key={`lv-${vitalsType}`}
              onClick={() => setActiveTab(vitalsType)}
            >
              <i className={iconClassNames} />
              <div className="leading-[16px]">
                <div className="text-[14px] font-semibold">
                  {label}
                  {': '}
                  <span>
                    {displayValue}
                    {unitsLabel === '%' ? unitsLabel : ` ${unitsLabel}`}
                  </span>
                </div>
                {showDateOfLastEdits && (
                  <div
                    data-testid="last-edit-date"
                    className="mt-[4px] text-[12px]"
                  >
                    Updated {timeAgo(vital)}
                  </div>
                )}
              </div>
            </section>
          )
        })}
      </div>
    </Card>
  )
}

const timeAgo = (vital: any) => formatTimeAgo(vital.recordedAt)

function EmptyVital({
  vitalType,
  loading = false,
  isActive,
  handleOnClick = () => null,
  showDateOfLastEdits,
}: {
  vitalType: VitalsType
  loading?: boolean
  isActive?: boolean
  handleOnClick?: (name: FieldPath<VitalsFormData>) => void
  showDateOfLastEdits: boolean
}) {
  const { name, label, iconClassName } = VITALS_INPUT_PROPS_MAP(vitalType)

  const sectionClassNames = getSectionClassNames(isActive)
  const iconClassNames = classNames(`${iconClassName} text-primary-light`, {
    'mr-[8px]': !showDateOfLastEdits,
    'mr-[16px]': showDateOfLastEdits,
  })

  return (
    <section
      data-testid="empty-vitals-cards"
      className={sectionClassNames}
      onClick={() => handleOnClick(name as FieldPath<VitalsFormData>)}
    >
      <i className={iconClassNames} />
      <div className="leading-[16px]">
        <div className="text-[14px] font-semibold">
          {label}
          {': '}
          <span className="font-medium">
            {loading ? <Skeleton height="14px" width="16px" inline /> : '--/--'}
          </span>
        </div>
        {showDateOfLastEdits && (
          <div data-testid="last-edit-date" className="mt-[4px] text-[12px]">
            {loading ? <Skeleton height="12px" inline /> : 'Never updated'}
          </div>
        )}
      </div>
    </section>
  )
}
