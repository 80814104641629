import ButtonLink from '@shared/components/ButtonLink'
import { RoutineAdministration } from '@shared/types/careapp'
import { Shift } from '@shared/types/shift'
import { tw, twx } from '@shared/utils/tailwind'
import { fromTimetoTimeString } from '@shared/utils/time'
import { generateTableChartData, getBarStyles } from './helpers'

const TD_CLASS_NAME = 'border-white p-[12px] text-center'

export default function TableChart({
  shift,
  routineAdministrations,
  selectedShiftId,
  setShiftId,
  facilityTimeZone,
}: {
  shift: Shift
  routineAdministrations: RoutineAdministration[]
  selectedShiftId?: string
  setShiftId: (shiftId: string) => void
  facilityTimeZone: string
}) {
  const {
    id,
    name,
    period: { startTime, endTime },
  } = shift
  const routinesNotFound = routineAdministrations.length === 0
  const title = `${name} (${routineAdministrations.length})`
  const subTitle = `${fromTimetoTimeString(startTime, true)} - ${fromTimetoTimeString(endTime, true)}`
  const tableChartData = generateTableChartData(
    routineAdministrations,
    facilityTimeZone
  )
  const isSelected = selectedShiftId === id

  return (
    <div className={tw`flex-grow`}>
      <div className={tw`border-bottom-separator-darker pb-[4px]`}>
        <table
          className={tw`mt-[32px] h-[66px] w-full text-[14px] font-semibold`}
        >
          <tbody>
            <tr>
              {routinesNotFound ? (
                <td
                  className={TD_CLASS_NAME}
                  style={getBarStyles({
                    routineType: 'Not started yet',
                    width: '100%',
                  })}
                >
                  0
                </td>
              ) : (
                tableChartData.map((data, index) => {
                  const { style, count, key } = data
                  if (count === 0) {
                    return null
                  }

                  return (
                    <td
                      key={`td-${key}`}
                      className={twx(TD_CLASS_NAME, {
                        'border-l-4': index > 0,
                      })}
                      style={style}
                    >
                      {count}
                    </td>
                  )
                })
              )}
            </tr>
          </tbody>
        </table>
      </div>
      <div className={tw`mb-[8px] mt-[16px] text-center text-[12px]`}>
        <ButtonLink
          className={twx('px-[12px] py-[2px]', {
            'shaodow-card rounded bg-black': isSelected,
          })}
          onClick={() => setShiftId(id)}
        >
          <span
            className={twx('mr-[12px] font-semibold', {
              'text-white': isSelected,
              'text-gray-04': !isSelected,
            })}
          >
            {title}
          </span>
          <span
            className={twx('font-medium', {
              'text-gray-10': isSelected,
              'text-gray-08': !isSelected,
            })}
          >
            ({subTitle})
          </span>
        </ButtonLink>
      </div>
    </div>
  )
}
