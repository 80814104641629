import { DateMessage } from '@augusthealth/models/com/august/protos/date'
import { UnitOfTime } from '@augusthealth/models/com/august/protos/timing'
import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { BadgeInput } from '@shared/components/BadgeInputs/BadgeInput'
import { Label } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import { ErrorDropdownStyleConfig } from '@shared/components/Selects/utils'
import { MedOrderFormData } from '@shared/types/medication_order'
import { validatePositiveNumber } from '@shared/utils/formValidationFunctions'
import {
  ALL_PERIOD_UNIT_OPTIONS,
  CustomPeriodUnits,
  TAPER_PERIOD_UNIT_OPTIONS,
} from '@shared/utils/medicationStatement'
import pluralize from '@shared/utils/pluralize'
import { DaysOfMonth } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/DynamicFrequencyInputs/DaysOfMonth'
import { DaysOfWeek } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/DynamicFrequencyInputs/DaysOfWeek'
import { EveryBlankPeriod } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/DynamicFrequencyInputs/EveryBlankPeriod'
import { OnThisDay } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/DynamicFrequencyInputs/OnThisDay'
import { TimesOfDay } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/DynamicFrequencyInputs/TimesOfDay'
import { isTaperDosageType } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/helpers'
import styles from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/styles.module.css'

export const DynamicFrequencyInputs = ({
  index,
  readOnly,
}: {
  index: number
  readOnly: boolean
}) => {
  const { register, formState, control, watch, getValues, setValue, trigger } =
    useFormContext<MedOrderFormData>()

  const [originalEndDate, setOriginalEndDate] = useState<
    DateMessage | undefined | null
  >()
  const doseType = watch(`doses.${index}.userSetDosageType`)

  const startDate = watch('effectivePeriod.startDate')

  const frequencyType = watch(`doses.${index}.periodUnit`)
  const variablePeriodUnit = watch(`doses.${index}.variablePeriodUnit`)
  const frequencyCount = watch(`doses.${index}.frequency`)

  useEffect(() => {
    if (frequencyType && originalEndDate === undefined) {
      // saves the original end date of the med or null to enable reverting later
      setOriginalEndDate(getValues('effectivePeriod.endDate') ?? null)
    }
  }, [frequencyType])

  useEffect(() => {
    if (formState.isSubmitted) {
      void trigger([`doses.${index}.dayOfMonth`, `doses.${index}.dayOfWeek`])
    }
  }, [frequencyCount])

  useEffect(() => {
    setValue(
      `doses.${index}.oneTimeOnly`,
      frequencyCount === 1 && frequencyType === CustomPeriodUnits.SPECIFIC_DAYS
    )
  }, [frequencyType, frequencyCount])

  const isFrequencyDaysOfWeekOrMonth =
    frequencyType === UnitOfTime.UNIT_OF_TIME_WEEK ||
    frequencyType === UnitOfTime.UNIT_OF_TIME_MONTH

  const isEveryXHoursFrequency =
    frequencyType === CustomPeriodUnits.EVERY &&
    variablePeriodUnit === UnitOfTime.UNIT_OF_TIME_HOUR

  const staticInputText = () => {
    if (isFrequencyDaysOfWeekOrMonth) {
      return pluralize('day', frequencyCount)
    } else return pluralize('time', frequencyCount)
  }

  const relevantOptions = isTaperDosageType(doseType)
    ? TAPER_PERIOD_UNIT_OPTIONS
    : ALL_PERIOD_UNIT_OPTIONS

  return (
    <>
      <div className={styles.scheduleInputRow}>
        <BadgeInput
          badgeLabel={'Frequency'}
          type={'number'}
          staticInputText={staticInputText()}
          {...register(`doses.${index}.frequency`, {
            valueAsNumber: true,
            validate: validatePositiveNumber,
            onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
              setValue(
                `doses.${index}.frequency`,
                Math.floor(+event.target.value)
              )
            },
          })}
          placeholder={'#'}
          min={0}
          max={99}
          step={1}
          showErrorBorder={!!formState.errors.doses?.[index]?.frequency}
          disabled={
            readOnly || isFrequencyDaysOfWeekOrMonth || isEveryXHoursFrequency
          }
          onKeyDown={(event) => {
            // ensure frequency is whole numbers only; no decimal places
            if (event.key === '.') {
              event.preventDefault()
            }
          }}
        />
        <Controller
          control={control}
          name={`doses.${index}.periodUnit`}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            return (
              <>
                <Label
                  visuallyHidden
                  htmlFor={`doses.${index}.periodUnit`}
                  id={`label-doses.${index}.periodUnit`}
                >
                  Period Unit
                </Label>
                <StyledSelect
                  aria-labelledby={`label-doses.${index}.periodUnit`}
                  id={`doses.${index}.periodUnit`}
                  instanceId={`doses.${index}.periodUnit`}
                  options={relevantOptions}
                  placeholder="Select..."
                  onChange={(
                    e: OptionTypeBase<UnitOfTime | CustomPeriodUnits>
                  ) => {
                    if (value === CustomPeriodUnits.SPECIFIC_DAYS) {
                      // moving away from specific day, reset the endDate to default value
                      setValue(
                        'effectivePeriod.endDate',
                        originalEndDate ?? undefined
                      )
                    }
                    onChange(e.value)

                    if (e.value === CustomPeriodUnits.SPECIFIC_DAYS) {
                      setValue(`doses.${index}.onThisDate`, startDate)
                      setValue('effectivePeriod.startDate', startDate)
                      setValue('effectivePeriod.endDate', startDate)
                      setValue(`doses.${index}.duration`, 1)
                      setValue(
                        `doses.${index}.durationUnit`,
                        UnitOfTime.UNIT_OF_TIME_DAY
                      )
                    }
                  }}
                  value={relevantOptions.find((opt) => opt.value === value)}
                  isDisabled={readOnly}
                  styles={ErrorDropdownStyleConfig({ error: !!error })}
                />
              </>
            )
          }}
        />
        {frequencyType === CustomPeriodUnits.EVERY && (
          <EveryBlankPeriod index={index} readOnly={readOnly} />
        )}
        {frequencyType === CustomPeriodUnits.SPECIFIC_DAYS && (
          <OnThisDay index={index} readOnly={readOnly} />
        )}
      </div>
      {frequencyType === UnitOfTime.UNIT_OF_TIME_WEEK && (
        <DaysOfWeek index={index} readOnly={readOnly} />
      )}
      {frequencyType === UnitOfTime.UNIT_OF_TIME_MONTH && (
        <DaysOfMonth index={index} readOnly={readOnly} />
      )}
      <TimesOfDay index={index} readOnly={readOnly} />
    </>
  )
}
