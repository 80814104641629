import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useEffect, useState } from 'react'
import { Redirect, Switch, useHistory } from 'react-router-dom'
import { CompatRoute } from 'react-router-dom-v5-compat'
import { fetchFacilities } from '@shared/api/facility'
import { fetchPerson, fetchProfileImageURL } from '@shared/api/person'
import { fetchTasks } from '@shared/api/task'
import GatedRoute from '@shared/components/Auth/GatedRoute'
import { RequirePermissions } from '@shared/components/Auth/RequirePermissions'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import {
  admissionTasksPathForPerson,
  documentsPathForPerson,
} from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { Task } from '@shared/types/task'
import AddMedication from '@app/components/Residents/Medications/AddMedication/AddMedication'
import { EditMedication } from '@app/components/Residents/Medications/EditMedication/EditMedication'
import ReviewMedicationOrder from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewMedicationOrder'
import FacilitiesContext from '@app/contexts/FacilitiesContext'
import PersonContext from '@app/contexts/PersonContext'
import ProspectsContext, { CongratsType } from '@app/contexts/ProspectsContext'
import Contacts from '@app/pages/Contacts'
import Documents from '@app/pages/Documents'
import ClosedProspectsList from '@app/pages/Facilities/Prospects/ClosedProspectsList'
import Notes from '@app/pages/Notes'
import EditIncident from '@app/pages/Notes/IncidentForm/EditIncident'
import { AddIncident } from '@app/pages/Notes/IncidentForm/IncidentForm'
import RoutinesWrapper from '@app/pages/Routines'
import ScheduleCustomRoutine from '@app/pages/Routines/CustomRoutines/ScheduleCustomRoutine'
import styles from '../Residents/list.module.css'
import Breadcrumbs from '../Breadcrumbs'
import DefaultCongratsModal from '../CongratsModal/CompletedTaskContinue'
import CompletedTaskCongratsModal from '../CongratsModal/CompletedTaskVisitDocuments'
import PersonNavbarLayout from '../Layouts/PersonNavbarLayout'
import { tabFromUrl } from '../Layouts/PersonNavbarLayout/Tabs'
import BillingDecider from '../Residents/BillingDecider'
import Medications from '../Residents/Medications'
import Details from './Details'
import Forms from './Forms'
import List from './List'
import Review from './Review'
import Sign, { onSignFinish } from './Sign'
import SignInPerson from './Sign/SignInPerson'
import Tasks from './Tasks'
import SignInPersonPopup, {
  SignInPersonData,
} from './Tasks/popups/SignInPersonPopup'

export const PROSPECTS_LIST_PATHS = [
  '/orgs/:orgId/facilities/:facilityId',
  '/orgs/:orgId/facilities/:facilityId/prospects',
]
export const PROSPECTS_CLOSED_LIST_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/prospects/closed',
]

export const PROSPECT_TASKS_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/prospects/:id',
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/tasks',
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/admission_tasks',
]

export const PROSPECT_DETAILS_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/details',
]

export const PROSPECT_EMPTY_TAB_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/:tab',
]

export const PROSPECT_CONTACTS_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/contacts',
]

export const PROSPECT_REVIEW_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/tasks/:taskId/review',
]

export const PROSPECT_DOCUMENTS_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/documents/:documentId',
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/documents',
]

export const PROSPECT_CARE_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/care',
]

export const PROSPECT_SCHEDULE_ROUTINE_PATH = [
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/routines/:routineId/schedule',
]

export const PROSPECT_MEDICATIONS_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/medications',
]

export const PROSPECT_ADD_MEDICATION_PATH = [
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/medications/new',
]

export const PROSPECT_EDIT_MEDICATION_PATH = [
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/medications/:medicationId/edit',
]

export const PROSPECT_REVIEW_PHARMACY_MESSAGE_PATH = [
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/pharmacy-messages/:messageId/review',
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/pharmacy-messages/:messageId/order-groups/:orderGroupId/review',
]

export const PROSPECT_BILLING_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/billing',
]

export const PROSPECT_NOTES_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/notes',
]

export const PROSPECT_ADD_INCIDENT_PATH = [
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/incidents/new',
]

export const PROSPECT_EDIT_INCIDENT_PATH = [
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/incidents/:incidentId/edit',
]

export const PROSPECT_SIGN_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/tasks/:taskId/sign',
]

export const PROSPECT_SIGN_IN_PERSON_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/tasks/:taskId/signInPerson',
]

export const PROSPECT_FORMS_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/tasks/:taskId/:page',
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/tasks/:taskId',
]

export const PROSPECT_ADD_ROUTINE_PATH = [
  '/orgs/:orgId/facilities/:facilityId/prospects/:id/routines/new',
]

export const PROSPECT_PATHS = [
  ...PROSPECTS_LIST_PATHS,
  ...PROSPECTS_CLOSED_LIST_PATHS,
  ...PROSPECT_TASKS_PATHS,
  ...PROSPECT_DETAILS_PATHS,
  ...PROSPECT_DOCUMENTS_PATHS,
  ...PROSPECT_CARE_PATHS,
  ...PROSPECT_CONTACTS_PATHS,
  ...PROSPECT_FORMS_PATHS,
  ...PROSPECT_EMPTY_TAB_PATHS,
  ...PROSPECT_NOTES_PATHS,
  ...PROSPECT_MEDICATIONS_PATHS,
  ...PROSPECT_ADD_MEDICATION_PATH,
  ...PROSPECT_EDIT_MEDICATION_PATH,
  ...PROSPECT_REVIEW_PHARMACY_MESSAGE_PATH,
  ...PROSPECT_BILLING_PATHS,
  ...PROSPECT_ADD_INCIDENT_PATH,
  ...PROSPECT_ADD_ROUTINE_PATH,
  ...PROSPECT_EDIT_INCIDENT_PATH,
  ...PROSPECT_SCHEDULE_ROUTINE_PATH,
]

type Props = {
  match: {
    params: {
      facilityId: string
      orgId: string
      id: string
    }
    url: string
    path: string
  }
}

function Prospects(props: Props) {
  const history = useHistory()
  const { facilities, setFacilities } = useContext(FacilitiesContext)
  const [openShareWithRPModal, setOpenShareWithRPModal] = useState(false)
  // If I make this { task: Task; onComplete: () => void }, I think I can solve the 'lightbox doesn't close' issue
  const [signInPersonPopupTask, setSignInPersonPopupTask] =
    useState<SignInPersonData>()
  const [openCongrats, setOpenCongrats] = useState<CongratsType>(false)
  const [isPersonLoading, setIsPersonLoading] = useState(false)
  const [tasks, setTasks] = useState<Task[] | undefined>()
  const [person, setPerson] = useState<Person>()
  const [profileImageURL, setProfileImageURL] = useState('')
  const { setError } = useContext(GlobalContext)
  const { user } = useUserContext()
  const { facilityId, id: pId, orgId } = props.match.params

  useEffect(() => {
    fetchFacilities({ orgId })
      .then((res) => setFacilities(res && res.data))
      .catch(setError)
  }, [orgId])

  useEffect(() => {
    if (pId !== undefined) {
      let localPerson: Person | undefined = undefined

      setIsPersonLoading(true)
      fetchPerson({ orgId, personId: pId, facilityId })
        .then((res) => {
          localPerson = res
          setPerson(res)
          fetchProfileImageURL(res)
            .then((url) => {
              setProfileImageURL(url)
              setIsPersonLoading(false)
            })
            .catch(setError)
        })
        .then(() => {
          const canRequestTasks =
            localPerson &&
            hasPermissionForPerson({
              user,
              person: localPerson,
              permissions: [GroupPermission.GROUP_PERMISSION_TASK_READ],
            })

          if (canRequestTasks) {
            fetchTasks({ orgId, pId })
              .then((res) => setTasks(res && res.data))
              .catch(setError)
          }
        })
        .catch(setError)
    } else {
      setPerson(undefined)
    }
  }, [facilityId, pId, orgId])

  if (facilities?.length === 0) {
    return null
  }

  const layoutProps = {
    activeTab: tabFromUrl(props.match.url),
  }

  const signInPersonPopup = signInPersonPopupTask && (
    <SignInPersonPopup
      facilityId={facilityId}
      taskToSign={signInPersonPopupTask}
      setTaskToSign={setSignInPersonPopupTask}
    />
  )

  let congratsModal: JSX.Element | null = null
  if (openCongrats) {
    if (openCongrats === 'COMPLETED_TASK_VISIT_DOCUMENTS') {
      // After Submit no pdf documents from Preview Lightbox
      congratsModal = (
        <CompletedTaskCongratsModal
          redirectToTasksPage={() => {
            setOpenCongrats(false)
            history.push(
              admissionTasksPathForPerson(person as Required<Person>)
            )
          }}
          redirectToDocumentsPage={() => {
            setOpenCongrats(false)
            history.push(documentsPathForPerson(person as Required<Person>))
          }}
        />
      )
    } else {
      // After sign a document
      // TO DO, after RP signs, should use 'COMPLETED_TASK' instead
      congratsModal = (
        <DefaultCongratsModal
          continueAndClose={() => {
            onSignFinish({ facilityId, pId, orgId, history })
            setOpenCongrats(false)
          }}
        />
      )
    }
  }

  const pageHasHeader = shouldRenderPerson(props.match.path)
  const pageHasBreadcrumbs = shouldRenderBreadcrumbs(props.match.path)

  return (
    <ProspectsContext.Provider
      value={{
        openShareWithRPModal,
        setOpenShareWithRPModal,
        openCongrats,
        setOpenCongrats,
      }}
    >
      <PersonContext.Provider
        value={{
          person,
          setPerson,
          tasks,
          setTasks,
          profileImageURL,
          setProfileImageURL,
          isPersonLoading,
          setIsPersonLoading,
          signInPersonPopupTask,
          setSignInPersonPopupTask,
        }}
      >
        <div className={styles.content}>
          {pageHasBreadcrumbs && <Breadcrumbs />}
          {pageHasHeader && <PersonNavbarLayout {...layoutProps} />}
          <Switch>
            <Redirect
              exact
              from={PROSPECTS_LIST_PATHS[0]}
              to={PROSPECTS_LIST_PATHS[1]}
            />
            <GatedRoute
              exact
              minimalPermissions={[
                GroupPermission.GROUP_PERMISSION_FACILITY_READ,
                GroupPermission.GROUP_PERMISSION_PERSON_READ,
              ]}
              path={PROSPECTS_CLOSED_LIST_PATHS}
              component={ClosedProspectsList}
              title={'Closed Move-ins'}
            />
            <GatedRoute
              exact
              minimalPermissions={[
                GroupPermission.GROUP_PERMISSION_FACILITY_READ,
                GroupPermission.GROUP_PERMISSION_PERSON_READ,
              ]}
              path={PROSPECTS_LIST_PATHS}
              component={List}
              title={'Move-ins'}
            />
            <Redirect
              exact
              from={PROSPECT_TASKS_PATHS[0]}
              to={PROSPECT_TASKS_PATHS[2]}
            />
            <Redirect
              exact
              from={PROSPECT_TASKS_PATHS[1]}
              to={PROSPECT_TASKS_PATHS[2]}
            />
            <GatedRoute
              exact
              minimalPermissions={[
                GroupPermission.GROUP_PERMISSION_PERSON_READ,
                GroupPermission.GROUP_PERMISSION_TASK_READ,
              ]}
              path={PROSPECT_TASKS_PATHS}
              component={Tasks}
            />
            <GatedRoute
              exact
              minimalPermissions={[
                GroupPermission.GROUP_PERMISSION_PERSON_READ,
                GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
                GroupPermission.GROUP_PERMISSION_PERSON_CONTACT_READ,
              ]}
              path={PROSPECT_CONTACTS_PATHS}
              component={Contacts}
            />
            <GatedRoute
              exact
              minimalPermissions={[
                GroupPermission.GROUP_PERMISSION_PERSON_READ,
                GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
                GroupPermission.GROUP_PERMISSION_SNAPSHOT_READ,
              ]}
              path={PROSPECT_DETAILS_PATHS}
              component={Details}
            />
            <GatedRoute
              exact
              path={PROSPECT_DOCUMENTS_PATHS}
              component={Documents}
              minimalPermissions={[
                GroupPermission.GROUP_PERMISSION_PERSON_READ,
                GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
                GroupPermission.GROUP_PERMISSION_SNAPSHOT_READ,
              ]}
            />
            <GatedRoute
              exact
              path={PROSPECT_CARE_PATHS}
              component={RoutinesWrapper}
              minimalPermissions={[
                GroupPermission.GROUP_PERMISSION_PERSON_READ,
                GroupPermission.GROUP_PERMISSION_ROUTINE_READ,
                GroupPermission.GROUP_PERMISSION_SNAPSHOT_READ,
              ]}
            />
            <GatedRoute
              exact
              path={PROSPECT_MEDICATIONS_PATHS}
              minimalPermissions={[
                GroupPermission.GROUP_PERMISSION_PERSON_READ,
                GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
                GroupPermission.GROUP_PERMISSION_MEDICATION_READ,
              ]}
              component={Medications}
            />
            <GatedRoute
              exact
              minimalPermissions={[
                GroupPermission.GROUP_PERMISSION_PERSON_READ,
                GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
                GroupPermission.GROUP_PERMISSION_MEDICATION_READ,
                GroupPermission.GROUP_PERMISSION_MEDICATION_CREATE,
              ]}
              path={PROSPECT_ADD_MEDICATION_PATH}
              component={AddMedication}
            />
            <GatedRoute
              exact
              minimalPermissions={[
                GroupPermission.GROUP_PERMISSION_PERSON_READ,
                GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
                GroupPermission.GROUP_PERMISSION_MEDICATION_READ,
                GroupPermission.GROUP_PERMISSION_MED_PASSES_READ,
              ]}
              path={PROSPECT_EDIT_MEDICATION_PATH}
              component={EditMedication}
            />
            <GatedRoute
              exact
              minimalPermissions={[
                GroupPermission.GROUP_PERMISSION_PERSON_READ,
                GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
                GroupPermission.GROUP_PERMISSION_MEDICATION_READ,
                GroupPermission.GROUP_PERMISSION_MED_PASSES_READ,
              ]}
              path={PROSPECT_REVIEW_PHARMACY_MESSAGE_PATH}
              component={ReviewMedicationOrder}
            />
            <CompatRoute
              path={PROSPECT_ADD_ROUTINE_PATH}
              render={() => (
                <RequirePermissions
                  minimalPermissions={[
                    GroupPermission.GROUP_PERMISSION_ROUTINE_UPDATE,
                  ]}
                >
                  <ScheduleCustomRoutine />
                </RequirePermissions>
              )}
            />
            <GatedRoute
              exact
              minimalPermissions={[
                GroupPermission.GROUP_PERMISSION_PERSON_READ,
                GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
                GroupPermission.GROUP_PERMISSION_INCIDENT_READ,
              ]}
              path={PROSPECT_NOTES_PATHS}
              component={Notes}
            />
            <GatedRoute
              exact
              minimalPermissions={[
                GroupPermission.GROUP_PERMISSION_PERSON_READ,
                GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
                GroupPermission.GROUP_PERMISSION_INCIDENT_CREATE,
              ]}
              path={PROSPECT_ADD_INCIDENT_PATH}
              component={AddIncident}
            />
            <GatedRoute
              exact
              minimalPermissions={[
                GroupPermission.GROUP_PERMISSION_PERSON_READ,
                GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
                GroupPermission.GROUP_PERMISSION_INCIDENT_UPDATE,
              ]}
              path={PROSPECT_EDIT_INCIDENT_PATH}
              component={EditIncident}
            />
            <GatedRoute
              exact
              minimalPermissions={[]}
              path={PROSPECT_BILLING_PATHS}
              component={BillingDecider}
              title="Billing"
            />
            <GatedRoute
              exact
              minimalPermissions={[
                GroupPermission.GROUP_PERMISSION_PERSON_READ,
                GroupPermission.GROUP_PERMISSION_TASK_READ,
                GroupPermission.GROUP_PERMISSION_PERSON_UPDATE,
                GroupPermission.GROUP_PERMISSION_TASK_UPDATE,
              ]}
              path={PROSPECT_REVIEW_PATHS}
              component={Review}
            />
            <GatedRoute
              exact
              minimalPermissions={[
                GroupPermission.GROUP_PERMISSION_PERSON_READ,
                GroupPermission.GROUP_PERMISSION_TASK_READ,
                GroupPermission.GROUP_PERMISSION_PERSON_UPDATE,
                GroupPermission.GROUP_PERMISSION_TASK_UPDATE,
              ]}
              path={PROSPECT_SIGN_PATHS}
              component={Sign}
            />
            <GatedRoute
              exact
              minimalPermissions={[
                GroupPermission.GROUP_PERMISSION_PERSON_READ,
                GroupPermission.GROUP_PERMISSION_TASK_READ,
                GroupPermission.GROUP_PERMISSION_PERSON_UPDATE,
                GroupPermission.GROUP_PERMISSION_TASK_UPDATE,
              ]}
              path={PROSPECT_SIGN_IN_PERSON_PATHS}
              component={SignInPerson}
            />
            <GatedRoute
              exact
              minimalPermissions={[
                GroupPermission.GROUP_PERMISSION_PERSON_READ,
                GroupPermission.GROUP_PERMISSION_TASK_READ,
                GroupPermission.GROUP_PERMISSION_PERSON_UPDATE,
                GroupPermission.GROUP_PERMISSION_TASK_UPDATE,
              ]}
              path={PROSPECT_FORMS_PATHS}
              component={Forms}
            />
          </Switch>
        </div>
        {congratsModal}
        {signInPersonPopup}
      </PersonContext.Provider>
    </ProspectsContext.Provider>
  )
}

function shouldRenderPerson(path: string) {
  switch (path) {
    case PROSPECT_FORMS_PATHS[1]:
    case PROSPECT_FORMS_PATHS[0]:
    case PROSPECT_ADD_MEDICATION_PATH[0]:
    case PROSPECT_EDIT_MEDICATION_PATH[0]:
    case PROSPECT_REVIEW_PHARMACY_MESSAGE_PATH[0]:
    case PROSPECT_REVIEW_PHARMACY_MESSAGE_PATH[1]:
    case PROSPECT_ADD_INCIDENT_PATH[0]:
    case PROSPECT_EDIT_INCIDENT_PATH[0]:
    case PROSPECT_SCHEDULE_ROUTINE_PATH[0]:
    case PROSPECT_ADD_ROUTINE_PATH[0]:
      return false
    default:
      return true
  }
}

function shouldRenderBreadcrumbs(path: string) {
  switch (path) {
    case PROSPECT_EDIT_MEDICATION_PATH[0]:
    case PROSPECT_REVIEW_PHARMACY_MESSAGE_PATH[0]:
    case PROSPECT_REVIEW_PHARMACY_MESSAGE_PATH[1]:
      return false
    default:
      return true
  }
}

export default Prospects
