import { useContext, useEffect, useState } from 'react'
import { hasPermissionForOrg } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { GroupPermission } from '@shared/types/permission'
import { loaded, LOADING, Loading } from '@shared/utils/loading'
import { DashboardRecord, fetchBillingDashboards } from '@app/api/organizations'

export default function useBillingDashboards(orgId: string) {
  const { setError } = useContext(GlobalContext)
  const [billingDashboards, setDashboardRecords] =
    useState<Loading<DashboardRecord[]>>(LOADING)
  const { user } = useUserContext()
  const canLoadDashboards = hasPermissionForOrg({
    user,
    orgId,
    permissions: [
      GroupPermission.GROUP_PERMISSION_BILLING_SUPERSET_DASHBOARD_READ,
    ],
  })

  useEffect(() => {
    if (canLoadDashboards) {
      fetchBillingDashboards(orgId)
        .then((v) => setDashboardRecords(loaded(v)))
        .catch(setError)
    } else {
      setDashboardRecords(loaded([]))
    }
  }, [orgId, canLoadDashboards])

  return { billingDashboards }
}
