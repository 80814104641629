/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { camelCase, capitalize, isEmpty, startCase } from 'lodash'

export enum Order {
  ASC = 1,
  DESC = -1,
}

function filterOutLeadingZero(str: string) {
  return str.replace(/^0+/, '')
}

function sortWithoutLeadingZero(str1: string, str2: string) {
  const s1 = filterOutLeadingZero(str1.toLowerCase())
  const s2 = filterOutLeadingZero(str2.toLowerCase())
  if (s1 > s2) {
    return 1
  } else if (s1 < s2) {
    return -1
  }

  return 0
}

export function sortNumberString(str1: string, str2: string) {
  const num1 = parseInt(str1, 10)
  const num2 = parseInt(str2, 10)
  // Both numbers
  if (!isNaN(num1) && !isNaN(num2)) {
    if (num1 > num2) {
      return 1
    } else if (num1 < num2) {
      return -1
    }
  } else if (isNaN(num1) && !isNaN(num2)) {
    return 1
  } else if (!isNaN(num1) && isNaN(num2)) {
    return -1
  }

  return sortWithoutLeadingZero(str1, str2)
}

// For no-reference type value
// Considered number 0 NOT empty, since user has entered something
function isEmptyPrimitiveInput(val: string | number | null | undefined) {
  return val === undefined || val === null || val === ''
}

export function isNotEmpty(val: any) {
  return !isEmpty(val)
}

/**
 * Returns true for
 * - empty array []
 * - empty hasg {}
 * - falsy value other than 0
 *
 * Returns false for anything else
 */

export function isEmptyObject(hash: any) {
  if (isEmptyPrimitiveInput(hash)) {
    return true
  }

  if (Array.isArray(hash)) {
    return hash.every(isEmptyObject)
  } else if (typeof hash === 'object') {
    return Object.keys(hash).every((k) => isEmptyObject(hash[k]))
  }

  return false
}

// Useful when printing a page only after when all images had been loaded
export function triggerEventAfterImageLoaded(triggerName = 'allImagesLoaded') {
  if (document.images.length) {
    // https://stackoverflow.com/questions/11071314/javascript-execute-after-all-images-have-loaded
    void Promise.all(
      Array.from(document.images).map((img) => {
        if (img.complete) {
          return Promise.resolve()
        }

        return new Promise((resolve, reject) => {
          img.addEventListener('load', resolve)
          img.addEventListener('error', () => reject(img))
        })
      })
    ).finally(() => {
      // Trigger event after images loaded or unable to load
      window.frameElement?.dispatchEvent(new Event(triggerName))
    })
  } else {
    // If no images, trigger event directly
    window.frameElement?.dispatchEvent(new Event(triggerName))
  }
}

export function convertEnumValueToLabel(
  emuValue: string,
  options: { capitalizeFirstWordOnly?: boolean } = {}
) {
  if (options.capitalizeFirstWordOnly) {
    return capitalize(startCase(emuValue))
  } else {
    return startCase(camelCase(emuValue))
  }
}
