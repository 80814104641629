export enum RequiredUserActionType {
  NO_ACTION = 'NO_ACTION',
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
  ACCOUNT_LOCKED_TRY_AGAIN_LATER = 'ACCOUNT_LOCKED_TRY_AGAIN_LATER',
}

export type CreateSessionResponse = {
  action: RequiredUserActionType
  username?: string
}
