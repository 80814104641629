import {
  OrgFacilitySettings,
  SettingsType,
} from '@augusthealth/models/com/august/protos/org_facility_settings'
import { upsertSettings } from '@shared/api/orgFacilitySettings'
import { CardLevel } from '@shared/types/org_facility_settings'
import { DataType } from '@shared/types/snapshot'
import {
  StateMachineType,
  TaskCreationEvent,
  TaskType,
} from '@shared/types/task'
import { covidDiseaseName } from '@shared/utils/immunizations'
import { SettingsLevel } from '@shared/utils/orgFacilitySettings'
import { getTitleFromDataType } from '@shared/utils/task'
import { eventName } from '@shared/utils/taskCreationEvent'

export interface TaskCreationFormData {
  settingsLevel: { label: string; value: SettingsLevel } | null
  taskType: { label: string; value: TaskType } | null
  events: TaskCreationEvent[]
  customTaskStateMachine: { label: string; value: StateMachineType } | null
  displayName: string | null
  shortName: string | null
  state: string | null
}

export function getSettingsLevel(
  orgFacilitySettings: OrgFacilitySettings
): SettingsLevel {
  if (orgFacilitySettings.facilityId) {
    return SettingsLevel.FACILITY_LEVEL
  } else if (orgFacilitySettings.orgId) {
    if (orgFacilitySettings.state) {
      return SettingsLevel.ORG_STATE_LEVEL
    } else {
      return SettingsLevel.ORG_LEVEL
    }
  } else if (orgFacilitySettings.state) {
    return SettingsLevel.STATE_LEVEL
  } else {
    return SettingsLevel.GLOBAL_LEVEL
  }
}

export function getDefaultValues({
  defaultSettingsLevel,
  initialDefinition,
}: {
  defaultSettingsLevel: SettingsLevel
  initialDefinition: OrgFacilitySettings | undefined
}): TaskCreationFormData {
  const taskDefinition = initialDefinition?.settings?.taskDefinition ?? null
  const taskType =
    taskTypes().find((tt) => tt.value === taskDefinition?.template!.taskType) ??
    null
  const events = taskDefinition?.events ?? []
  const customTaskStateMachine =
    (taskType?.value === TaskType.TASK_TYPE_CUSTOM_SIGNABLE_FORM
      ? STATE_MACHINE_OPTIONS.find(
          (so) => so.value === taskDefinition?.template?.stateMachineType
        )
      : null) ?? null
  const settingsLevel = initialDefinition
    ? getSettingsLevel(initialDefinition)
    : defaultSettingsLevel
  const displayName =
    initialDefinition?.settings?.taskDefinition?.template?.displayName ||
    taskType?.label ||
    ''
  const shortName =
    initialDefinition?.settings?.taskDefinition?.template?.shortName ?? null

  return {
    settingsLevel: { label: settingsLevel, value: settingsLevel },
    taskType,
    events,
    customTaskStateMachine,
    displayName,
    shortName,
    state: initialDefinition?.state ?? null,
  }
}

export function addTaskDefinition({
  cardLevel,
  formData,
  initialCustomType,
}: {
  cardLevel: CardLevel
  formData: TaskCreationFormData
  initialCustomType?: string
}): Promise<any> {
  const { tag, ...ids } = cardLevel
  const taskType = formData.taskType?.value
  const displayName = formData.displayName ?? undefined
  const isCustomSignableForm =
    taskType === TaskType.TASK_TYPE_CUSTOM_SIGNABLE_FORM
  const state = formData.state || ids.state || undefined

  // Don't change customType on update
  let customType: string | undefined
  if (isCustomSignableForm) {
    if (initialCustomType) {
      customType = initialCustomType
    } else {
      customType = displayName
    }
  }

  const isUpload = isCustomSignableForm
    ? !!formData.customTaskStateMachine?.value &&
      uploadableStateMachineTypes.includes(
        formData.customTaskStateMachine?.value
      )
    : undefined

  const isShareable = isCustomSignableForm
    ? !!formData.customTaskStateMachine?.value &&
      shareableStateMachineTypes.includes(
        formData.customTaskStateMachine?.value
      )
    : undefined

  const taskSettings = {
    ...ids,
    state,
    settingsType: SettingsType.SETTINGS_TYPE_TASK_DEFINITION,
    key: isCustomSignableForm ? `${taskType}_${customType}` : taskType,
    settings: {
      taskDefinition: {
        events: formData.events,
        template: {
          taskType: taskType,
          dataType:
            taskType === TaskType.TASK_TYPE_LEVEL_OF_CARE
              ? DataType.DATA_TYPE_LEVEL_OF_CARE_CIMINO
              : undefined,
          customType,
          stateMachineType: formData.customTaskStateMachine?.value,
          displayName,
          shortName: formData.shortName ?? undefined,
          isUpload,
          isShareable,
        },
      },
    },
  }

  return upsertSettings(taskSettings)
}

export function taskTypes() {
  const types = Object.values(TaskType).filter(
    (tt) =>
      ![
        TaskType.UNRECOGNIZED,
        TaskType.TASK_TYPE_UNSPECIFIED,
        TaskType.TASK_TYPE_DIRECTIVE,
        TaskType.TASK_TYPE_MEDICAL_RECORD,
        TaskType.TASK_TYPE_OTHER_UPLOAD,
      ].includes(tt)
  )

  return types.map((type) => {
    const isImmunizationRecord = type === TaskType.TASK_TYPE_IMMUNIZATION_RECORD
    const taskTypeTitle = getTitleFromDataType(dataTypeForTaskType(type))

    // COVID19 is the only Immunizaion Record which requires a task,
    // So re-label Immunization Record to COVID-19 but still keep Immunization Record as sub-label.
    return {
      label: isImmunizationRecord ? covidDiseaseName : taskTypeTitle,
      value: type,
      subLabel: isImmunizationRecord ? taskTypeTitle : undefined,
    }
  })
}

export function eventNames() {
  const events = Object.values(TaskCreationEvent).filter(
    (tce) =>
      ![
        TaskCreationEvent.TASK_CREATION_EVENT_UNSPECIFIED,
        TaskCreationEvent.UNRECOGNIZED,
      ].includes(tce)
  )

  return events.map((e) => ({ label: eventName(e), value: e }))
}

export const uploadableStateMachineTypes = [
  StateMachineType.STATE_MACHINE_TYPE_SHAREABLE_NOT_SIGNABLE,
  StateMachineType.STATE_MACHINE_TYPE_NOT_SHAREABLE_NOR_SIGNABLE,
]

export const shareableStateMachineTypes = [
  StateMachineType.STATE_MACHINE_TYPE_SHAREABLE_FILLABLE_PDF,
]

export const STATE_MACHINE_OPTIONS = [
  {
    label: 'Shareable',
    value: StateMachineType.STATE_MACHINE_TYPE_SHAREABLE_AND_SIGNABLE,
  },
  {
    label: 'Shareable & Signable (No Review)',
    value: StateMachineType.STATE_MACHINE_TYPE_SHAREABLE_AND_SIGNABLE_NO_REVIEW,
  },
  {
    label: 'Not Shareable',
    value: StateMachineType.STATE_MACHINE_TYPE_SIGNABLE_NOT_SHAREABLE,
  },
  {
    label: 'Uploadable & Shareable',
    value: StateMachineType.STATE_MACHINE_TYPE_SHAREABLE_NOT_SIGNABLE,
  },
  {
    label: 'Uploadable & Not Shareable',
    value: StateMachineType.STATE_MACHINE_TYPE_NOT_SHAREABLE_NOR_SIGNABLE,
  },
  {
    label: 'Fillable PDF (Not Shareable, Not Signable)',
    value: StateMachineType.STATE_MACHINE_TYPE_FILLABLE_PDF,
  },
  {
    label: 'Fillable PDF (Shareable, Not Signable)',
    value: StateMachineType.STATE_MACHINE_TYPE_SHAREABLE_FILLABLE_PDF,
  },
]

export function dataTypeForTaskType(taskType: TaskType): DataType {
  if (taskType === TaskType.TASK_TYPE_LEVEL_OF_CARE) {
    return DataType.DATA_TYPE_LEVEL_OF_CARE_CIMINO
  }

  return taskType.replace(/^TASK_TYPE_/, 'DATA_TYPE_') as DataType
}
