import Skeleton from 'react-loading-skeleton'
import { BasicSpinner } from '@shared/components/BasicSpinner'
import Card from '@shared/components/Card'
import 'react-loading-skeleton/dist/skeleton.css'

export function NoteCardSkeleton() {
  return (
    <Card className="mb-[16px] w-full p-[24px]" data-testid={'notes-skeleton'}>
      <div className="flex w-full items-start">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '40px',
            width: '40px',
            marginRight: '16px',
          }}
        >
          <BasicSpinner className="fa-2x fa-fw text-secondary" doNotAnimate />
        </div>
        <div className="flex w-full flex-col">
          <div>
            <h3 className="mb-[4px] text-[16px] font-semibold leading-[24px] text-gray-03">
              <Skeleton width={'200px'} />
            </h3>
            <h4 className="mb-0 text-[14px] font-medium text-gray-07">
              <Skeleton width={'400px'} />
            </h4>
          </div>
          <div className="mt-[32px] text-[14px] leading-[16px] text-gray-05">
            <Skeleton />
          </div>
        </div>
      </div>
    </Card>
  )
}
