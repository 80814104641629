import { FrontendDetail } from '@shared/types/assessment'
import { CustomDetailEnum } from '@shared/types/assessment_configuration'
import { AugustFieldType } from '@shared/types/custom_field'
import { ADDED } from '@app/pages/Tools/AssessmentConfiguration/helpers'

const AUGUST_FIELDS_SUPPORTED_LIST: CustomDetailEnum[] = [
  AugustFieldType.AUGUST_FIELD_TYPE_ALLERGIES,
  AugustFieldType.AUGUST_FIELD_TYPE_CONDITION,
  AugustFieldType.AUGUST_FIELD_TYPE_DIETARY_RESTRICTIONS,
  AugustFieldType.AUGUST_FIELD_TYPE_MARITAL_STATUS,
]

export function isSupportedAugustField(
  customDetailEnum: CustomDetailEnum | undefined
) {
  return (
    customDetailEnum && AUGUST_FIELDS_SUPPORTED_LIST.includes(customDetailEnum)
  )
}

export function constructAugustFieldDetail({
  description,
  augustField,
  detailGroup,
}: {
  augustField: AugustFieldType
  description: string
  detailGroup: string
}): Partial<FrontendDetail> {
  return {
    tag: 'DetailWithAugustField',
    value: {
      augustFieldType: augustField,
      description,
      group: detailGroup,
    },
    tempId: ADDED,
  }
}
