import localForage from 'localforage'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Person } from '@shared/types/person'
import { PersonStats } from '@shared/types/person_stats'
import { UserAccount } from '@shared/types/user'
import { Order } from '@shared/utils/common'
import { formatDate } from '@shared/utils/date'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { tw } from '@shared/utils/tailwind'
import CompletedTasksCounter from '@app/components/Residents/CompletedTasksCounter'
import {
  ColumnName,
  getProspectSortFunc,
  NameToSort,
  onNameSortingToggle,
  prospectHeaders,
  ProspectTableColumn,
  sortPeopleByName,
  STORAGE_NAME_TO_SORT,
  STORAGE_PREVIOUS_COLUMN,
} from '@app/components/Residents/List/helpers'
import { LastUpdated } from '@app/components/Residents/List/ResidentTableBody'
import {
  Table,
  TableContainer,
  TdWithExtraPadding,
  TrWithHoverEffect,
} from '@app/components/Table'
import ProfileImage from '@app/components/Table/ProfileImage'
import TableHeader, {
  getTableHeaderOnToggle,
} from '@app/components/Table/TableHeader'
import { getResidentPath } from '@app/routing/helpers'

const STORAGE_PROSPECT_ORDER = 'prospectListColumnOrder'
const STORAGE_PROSPECT_COLUMN = 'prospectListSelectedColumn'

export function ProspectsList({
  sortedProspects,
  user,
}: {
  sortedProspects: PersonStats[]
  user: UserAccount
}) {
  const history = useHistory()
  const [selectedColumn, setSelectedColumn] = useState<ProspectTableColumn>(
    ProspectTableColumn.NAME
  )
  const [sortingOrder, setSortingOrder] = useState<Order>(Order.ASC)
  const [nameToSort, setNameToSort] = useState<NameToSort>('LastName')
  const [previousColumn, setPreviousColumn] = useState<ColumnName | undefined>(
    undefined
  )

  const getDesiredMoveInDate = (person: Person) => {
    if (person?.admissionsInformation?.startRange) {
      return person.admissionsInformation.startRange === 'DESIRED_RANGE_ASAP'
        ? 'ASAP'
        : formatDate(person.admissionsInformation.startRange)
    } else if (person?.admissionsInformation?.startDate) {
      return formatDate(person.admissionsInformation.startDate)
    }

    return ''
  }

  const prospects =
    selectedColumn === 'Name'
      ? sortPeopleByName(sortedProspects, sortingOrder, nameToSort)
      : getProspectSortFunc(selectedColumn)(sortedProspects, sortingOrder)

  const handleProspectClick = (
    event: React.MouseEvent<HTMLTableRowElement>,
    url: string
  ) => {
    if (event.metaKey || event.ctrlKey) {
      window.open(url, '_blank')
    } else {
      history.push(url)
    }
  }

  useEffect(() => {
    void localForage.getItem(STORAGE_PROSPECT_COLUMN).then((column) => {
      setSelectedColumn(column as ProspectTableColumn)
    })
    void localForage.getItem(STORAGE_PROSPECT_ORDER).then((order) => {
      setSortingOrder(Number(order))
    })
    void localForage.getItem(STORAGE_NAME_TO_SORT).then((name) => {
      setNameToSort(name as NameToSort)
    })
    void localForage.getItem(STORAGE_PREVIOUS_COLUMN).then((previousCol) => {
      setPreviousColumn(previousCol as ColumnName)
    })
  }, [])

  return (
    <TableContainer withScrollBar>
      <Table>
        <TableHeader
          headers={prospectHeaders}
          sortable={{
            onToggle: (colName: string) => {
              if (colName === 'Name') {
                onNameSortingToggle({
                  nameToSort,
                  previousColumn,
                  sortingOrder,
                  setNameToSort,
                  setPreviousColumn,
                })
              }
              getTableHeaderOnToggle({
                selectedColumn,
                selectedOrder: sortingOrder,
                setColumn: setSelectedColumn as (c: string) => void,
                setOrder: setSortingOrder,
                storageColumnName: STORAGE_PROSPECT_COLUMN,
                storageOrderName: STORAGE_PROSPECT_ORDER,
              })(colName)
            },
            selectedColumn,
            sortingOrder,
          }}
        />
        <tbody>
          {prospects.map((prospect, index) => {
            const person = prospect.person!

            return (
              <TrWithHoverEffect
                key={person.id}
                id={`prospect-${index}`}
                onClick={(event) => {
                  handleProspectClick(
                    event,
                    getResidentPath({
                      user,
                      person,
                    })
                  )
                }}
              >
                <TdWithExtraPadding className={tw`font-semibold text-gray-01`}>
                  <div className={tw`flex items-center`}>
                    <ProfileImage
                      profilePhotoUrl={prospect.profilePhotoUrl}
                      person={person}
                    />
                    <span>{getFirstAndLastName(person.name)}</span>
                  </div>
                </TdWithExtraPadding>
                <TdWithExtraPadding className={tw`text-gray-07`}>
                  {getDesiredMoveInDate(person)}
                </TdWithExtraPadding>
                <TdWithExtraPadding>
                  <CompletedTasksCounter
                    numberCompleted={prospect.taskStats!.complete!}
                    numberTotal={prospect.taskStats!.total!}
                  />
                </TdWithExtraPadding>
                <LastUpdated resident={prospect} />
              </TrWithHoverEffect>
            )
          })}
        </tbody>
      </Table>
    </TableContainer>
  )
}
