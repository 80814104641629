import { useState } from 'react'
import useBillingLedger from '@app/hooks/useBillingLedger'
import { GenerateLedgerExportModal } from './GenerateLedgerExportModal'
import { LedgerExportsTable } from './LedgerExportsTable'

type Props = {
  orgId: string
  facilityId: string
}
export default function GeneralLedger(props: Props) {
  const { orgId, facilityId } = props
  const { ledgerExports, reloadExports } = useBillingLedger({
    orgId,
    facilityId,
  })
  const [isModalOpen, setIsModalOpen] = useState(false)

  return (
    <div>
      <LedgerExportsTable
        orgId={orgId}
        facilityId={facilityId}
        ledgerExports={ledgerExports}
        onCtaClick={() => setIsModalOpen(true)}
      />
      {isModalOpen && (
        <GenerateLedgerExportModal
          facilityId={facilityId}
          orgId={orgId}
          closeFn={async (downloaded?: boolean) => {
            if (downloaded) {
              await reloadExports()
            }
            setIsModalOpen(false)
          }}
        />
      )}
    </div>
  )
}
