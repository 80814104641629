import { ClassNameValue } from 'tailwind-merge'
import SearchBox from '@shared/components/SearchBox'
import { RoutineAdministrationProgressType } from '@shared/types/careapp'
import { tw, twx } from '@shared/utils/tailwind'

function RoutineFilterButton({
  buttonText,
  count,
  active,
  primaryBgClassName,
  onClick,
}: {
  buttonText: string
  count: number
  active: boolean
  primaryBgClassName: ClassNameValue
  onClick: () => void
}) {
  return (
    <button
      onClick={onClick}
      disabled={count === 0}
      className={twx(
        'flex flex-row items-center justify-center gap-layout-gap-xxs',
        'rounded-[1.25rem] px-2 py-1',
        'text-sm font-medium text-gray-700',
        'transition-colors duration-200',
        active ? primaryBgClassName : 'bg-none'
      )}
    >
      {buttonText}
      <span
        className={twx(
          'bg-white/60 px-2 py-[0.125rem]',
          'text-xs font-bold text-[#0C2836]',
          'rounded-full',
          'transition-colors duration-200',
          active ? 'bg-white/60' : primaryBgClassName
        )}
      >
        {count}
      </span>
    </button>
  )
}

export default function RoutineFilterAndSearch({
  exceptionsCount,
  overdueCount,
  doneCount,
  searchText,
  setSearchText,
  selectedProgressTypes,
  setSelectedProgressTypes,
}: {
  exceptionsCount: number
  overdueCount: number
  doneCount: number
  searchText: string
  setSearchText: (text: string) => void
  selectedProgressTypes: RoutineAdministrationProgressType[]
  setSelectedProgressTypes: (type: RoutineAdministrationProgressType) => void
}) {
  const exceptionsSelected =
    exceptionsCount > 0 && selectedProgressTypes.includes('Exceptions')
  const overdueSelected =
    overdueCount > 0 && selectedProgressTypes.includes('Overdue')
  const doneSelected = doneCount > 0 && selectedProgressTypes.includes('Done')

  return (
    <div className={tw`mb-[24px] flex flex-row items-center justify-between`}>
      <div className={tw`flex flex-row items-center gap-[12px]`}>
        <RoutineFilterButton
          count={exceptionsCount}
          active={exceptionsSelected}
          onClick={() => setSelectedProgressTypes('Exceptions')}
          primaryBgClassName="bg-yellow-12"
          buttonText="Exceptions"
        />
        <RoutineFilterButton
          count={overdueCount}
          active={overdueSelected}
          onClick={() => setSelectedProgressTypes('Overdue')}
          primaryBgClassName="bg-tags-error-fill"
          buttonText="Overdue"
        />
        <RoutineFilterButton
          count={doneCount}
          active={doneSelected}
          onClick={() => setSelectedProgressTypes('Done')}
          primaryBgClassName="bg-tags-happy-fill"
          buttonText="Completed"
        />
      </div>
      <SearchBox
        onChange={setSearchText}
        value={searchText}
        placeholder="Name or room..."
        holderClassName={tw`grow-0`}
      />
    </div>
  )
}
