import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useEffect, useState } from 'react'
import { Redirect, Switch, useLocation } from 'react-router-dom'
import { CompatRoute } from 'react-router-dom-v5-compat'
import urlcat from 'urlcat'
import { fetchFacilities } from '@shared/api/facility'
import { fetchPerson, fetchProfileImageURL } from '@shared/api/person'
import { fetchTasks } from '@shared/api/task'
import GatedRoute from '@shared/components/Auth/GatedRoute'
import { RequirePermissions } from '@shared/components/Auth/RequirePermissions'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { Person, PersonIds } from '@shared/types/person'
import { Task } from '@shared/types/task'
import { residentBillingPermissions } from '@shared/utils/billing'
import AddMedication from '@app/components/Residents/Medications/AddMedication/AddMedication'
import { EditMedication } from '@app/components/Residents/Medications/EditMedication/EditMedication'
import FacilitiesContext from '@app/contexts/FacilitiesContext'
import PersonContext from '@app/contexts/PersonContext'
import Contacts from '@app/pages/Contacts'
import Documents from '@app/pages/Documents'
import Notes from '@app/pages/Notes'
import EditIncident from '@app/pages/Notes/IncidentForm/EditIncident'
import { AddIncident } from '@app/pages/Notes/IncidentForm/IncidentForm'
import RoutinesWrapper from '@app/pages/Routines'
import EditCustomRoutineWrapper from '@app/pages/Routines/CustomRoutines/EditCustomRoutine'
import ScheduleCustomRoutine from '@app/pages/Routines/CustomRoutines/ScheduleCustomRoutine'
import styles from './list.module.css'
import Breadcrumbs from '../Breadcrumbs'
import PersonNavbarLayout from '../Layouts/PersonNavbarLayout'
import { tabFromUrl } from '../Layouts/PersonNavbarLayout/Tabs'
import Details from '../Prospects/Details'
import Tasks from '../Prospects/Tasks'
import SignInPersonPopup, {
  SignInPersonData,
} from '../Prospects/Tasks/popups/SignInPersonPopup'
import BillingDecider from './BillingDecider'
import PayerStatementViewer from './CommunityBilling/PayerStatementViewer'
import ResidentList from './List'
import Medications from './Medications'
import ReviewMedicationOrder from './Medications/Orders/ReviewMedicationOrder/ReviewMedicationOrder'
import { default as MovedOutResidentsList } from './MoveOutDetails/List'

export const RESIDENTS_LIST_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/residents',
]
export const RESIDENTS_DISCHARGED_LIST_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/residents/movedOut',
]

export const RESIDENT_DETAILS_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/residents/:id',
  '/orgs/:orgId/facilities/:facilityId/residents/:id/details',
]

export const RESIDENT_CONTACTS_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/residents/:id/contacts',
]

export const RESIDENT_TASKS_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/residents/:id/tasks',
  '/orgs/:orgId/facilities/:facilityId/residents/:id/admission_tasks',
]

export const RESIDENT_MEDICATIONS_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/residents/:id/medications',
]

export const RESIDENT_CARE_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/residents/:id/care',
]

export const RESIDENT_ADD_MEDICATION_PATH = [
  '/orgs/:orgId/facilities/:facilityId/residents/:id/medications/new',
]

export const RESIDENT_ADD_INCIDENT_PATH = [
  '/orgs/:orgId/facilities/:facilityId/residents/:id/incidents/new',
]

export const RESIDENT_EDIT_INCIDENT_PATH = [
  '/orgs/:orgId/facilities/:facilityId/residents/:id/incidents/:incidentId/edit',
]

export const RESIDENT_SCHEDULE_V1_ROUTINE_PATH = [
  '/orgs/:orgId/facilities/:facilityId/residents/:id/routines/:routineId/schedule',
]

export const RESIDENT_ADD_ROUTINE_PATH = [
  '/orgs/:orgId/facilities/:facilityId/residents/:id/routines/new',
]

export const RESIDENT_EDIT_ROUTINE_PATH = [
  '/orgs/:orgId/facilities/:facilityId/residents/:id/routines/:routineId/edit',
]
export function residentEditRoutinePath(
  person: Required<PersonIds>,
  routineId: string
) {
  const { orgId, facilityId, id } = person
  return urlcat(RESIDENT_EDIT_ROUTINE_PATH[0], {
    orgId,
    facilityId,
    id,
    routineId,
  })
}

export const RESIDENT_EDIT_MEDICATION_PATH = [
  '/orgs/:orgId/facilities/:facilityId/residents/:id/medications/:medicationId/edit',
]

export const RESIDENT_REVIEW_PHARMACY_MESSAGE_PATH = [
  '/orgs/:orgId/facilities/:facilityId/residents/:id/pharmacy-messages/:messageId/review',
  '/orgs/:orgId/facilities/:facilityId/residents/:id/pharmacy-messages/:messageId/order-groups/:orderGroupId/review',
]

export const RESIDENT_DOCUMENTS_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/residents/:id/documents/:documentId',
  '/orgs/:orgId/facilities/:facilityId/residents/:id/documents',
]

export const RESIDENT_BILLING_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/residents/:id/billing',
]
export const RESIDENT_BILLING_STATEMENT_PREVIEW_PATH = [
  '/orgs/:orgId/facilities/:facilityId/residents/:id/billing/statements/:statementId/preview',
]

export const RESIDENT_NOTES_PATHS = [
  '/orgs/:orgId/facilities/:facilityId/residents/:id/notes',
]

export const RESIDENT_PATHS = [
  ...RESIDENTS_LIST_PATHS,
  ...RESIDENTS_DISCHARGED_LIST_PATHS,
  ...RESIDENT_DETAILS_PATHS,
  ...RESIDENT_CONTACTS_PATHS,
  ...RESIDENT_TASKS_PATHS,
  ...RESIDENT_MEDICATIONS_PATHS,
  ...RESIDENT_CARE_PATHS,
  ...RESIDENT_ADD_MEDICATION_PATH,
  ...RESIDENT_ADD_INCIDENT_PATH,
  ...RESIDENT_BILLING_PATHS,
  ...RESIDENT_BILLING_STATEMENT_PREVIEW_PATH,
  ...RESIDENT_DOCUMENTS_PATHS,
  ...RESIDENT_EDIT_MEDICATION_PATH,
  ...RESIDENT_REVIEW_PHARMACY_MESSAGE_PATH,
  ...RESIDENT_NOTES_PATHS,
  ...RESIDENT_EDIT_INCIDENT_PATH,
  ...RESIDENT_SCHEDULE_V1_ROUTINE_PATH,
  ...RESIDENT_ADD_ROUTINE_PATH,
  ...RESIDENT_EDIT_ROUTINE_PATH,
]

interface Props {
  match: {
    params: {
      facilityId: string
      orgId: string
      id: string
    }
    path: string
  }
}

function Residents(props: Props) {
  const { user } = useUserContext()
  const { setError } = useContext(GlobalContext)
  const { orgId, id: personId, facilityId } = props.match.params
  const { facilities = [], setFacilities } = useContext(FacilitiesContext)
  const [person, setPerson] = useState<Person>()
  const [signInPersonPopupTask, setSignInPersonPopupTask] = useState<
    SignInPersonData | undefined
  >()
  const [isPersonLoading, setIsPersonLoading] = useState<boolean>(false)
  const [tasks, setTasks] = useState<Task[] | undefined>()
  const [profileImageURL, setProfileImageURL] = useState<string>('')
  const { pathname } = useLocation()

  useEffect(() => {
    fetchFacilities({ orgId })
      .then((res) => setFacilities(res && res.data))
      .catch(setError)
  }, [orgId])

  useEffect(() => {
    if (personId) {
      let localPerson: Person | undefined = undefined
      setIsPersonLoading(true)
      fetchPerson({ orgId, personId, facilityId })
        .then((res) => {
          localPerson = res
          setPerson(res)
          fetchProfileImageURL(res)
            .then((url) => {
              setProfileImageURL(url)
              setIsPersonLoading(false)
            })
            .catch(setError)
        })
        .then(() => {
          const canRequestTasks =
            localPerson &&
            hasPermissionForPerson({
              user,
              person: localPerson,
              permissions: [GroupPermission.GROUP_PERMISSION_TASK_READ],
            })

          if (canRequestTasks) {
            fetchTasks({ orgId, pId: personId })
              .then((res) => setTasks(res && res.data))
              .catch(setError)
          }
        })
        .catch(setError)
    } else {
      setPerson(undefined)
    }
  }, [personId])

  if (facilities.length === 0) {
    return null
  }

  const layoutProps = {
    activeTab: tabFromUrl(props.match.path),
  }

  const signInPersonPopup = signInPersonPopupTask && (
    <SignInPersonPopup
      facilityId={facilityId}
      taskToSign={signInPersonPopupTask}
      setTaskToSign={setSignInPersonPopupTask}
    />
  )

  const pageHasHeader = shouldRenderPerson(props.match.path)
  const pageHasBreadcrumbs = shouldRenderBreadcrumbs(props.match.path)

  return (
    <PersonContext.Provider
      value={{
        person,
        setPerson,
        tasks,
        setTasks,
        profileImageURL,
        setProfileImageURL,
        isPersonLoading,
        setIsPersonLoading,
        signInPersonPopupTask,
        setSignInPersonPopupTask,
      }}
    >
      <div className={styles.content}>
        {pageHasBreadcrumbs && <Breadcrumbs />}
        {pageHasHeader && <PersonNavbarLayout {...layoutProps} />}
        <Switch>
          <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
          <GatedRoute
            exact
            minimalPermissions={[
              GroupPermission.GROUP_PERMISSION_FACILITY_READ,
              GroupPermission.GROUP_PERMISSION_PERSON_READ,
            ]}
            path={RESIDENTS_DISCHARGED_LIST_PATHS}
            component={MovedOutResidentsList}
            title={'Moved Out Residents'}
          />
          <GatedRoute
            exact
            minimalPermissions={[
              GroupPermission.GROUP_PERMISSION_FACILITY_READ,
              GroupPermission.GROUP_PERMISSION_PERSON_READ,
            ]}
            component={ResidentList}
            path={RESIDENTS_LIST_PATHS}
            title={'Residents'}
          />
          <Redirect
            exact
            from={RESIDENT_DETAILS_PATHS[0]}
            to={RESIDENT_DETAILS_PATHS[1]}
          />
          <GatedRoute
            exact
            minimalPermissions={[
              GroupPermission.GROUP_PERMISSION_PERSON_READ,
              GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
              GroupPermission.GROUP_PERMISSION_SNAPSHOT_READ,
            ]}
            component={Details}
            path={RESIDENT_DETAILS_PATHS}
          />
          <GatedRoute
            exact
            minimalPermissions={[
              GroupPermission.GROUP_PERMISSION_PERSON_READ,
              GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
            ]}
            path={RESIDENT_CONTACTS_PATHS}
            component={Contacts}
          />
          <Redirect
            exact
            from={RESIDENT_TASKS_PATHS[0]}
            to={RESIDENT_TASKS_PATHS[1]}
          />
          <GatedRoute
            exact
            minimalPermissions={[
              GroupPermission.GROUP_PERMISSION_TASK_READ,
              GroupPermission.GROUP_PERMISSION_PERSON_READ,
            ]}
            path={RESIDENT_TASKS_PATHS}
            component={Tasks}
          />
          <GatedRoute
            exact
            path={RESIDENT_MEDICATIONS_PATHS}
            minimalPermissions={[
              GroupPermission.GROUP_PERMISSION_PERSON_READ,
              GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
              GroupPermission.GROUP_PERMISSION_MEDICATION_READ,
            ]}
            component={Medications}
          />
          <GatedRoute
            exact
            path={RESIDENT_CARE_PATHS}
            minimalPermissions={[GroupPermission.GROUP_PERMISSION_ROUTINE_READ]}
            component={RoutinesWrapper}
          />
          <GatedRoute
            exact
            minimalPermissions={[
              GroupPermission.GROUP_PERMISSION_PERSON_READ,
              GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
              GroupPermission.GROUP_PERMISSION_MEDICATION_READ,
              GroupPermission.GROUP_PERMISSION_MEDICATION_CREATE,
            ]}
            path={RESIDENT_ADD_MEDICATION_PATH}
            component={AddMedication}
          />
          <GatedRoute
            exact
            minimalPermissions={[
              GroupPermission.GROUP_PERMISSION_PERSON_READ,
              GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
              GroupPermission.GROUP_PERMISSION_MEDICATION_READ,
              GroupPermission.GROUP_PERMISSION_MED_PASSES_READ,
            ]}
            path={RESIDENT_EDIT_MEDICATION_PATH}
            component={EditMedication}
          />
          <GatedRoute
            exact
            minimalPermissions={[
              GroupPermission.GROUP_PERMISSION_PERSON_READ,
              GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
              GroupPermission.GROUP_PERMISSION_MEDICATION_READ,
              GroupPermission.GROUP_PERMISSION_MED_PASSES_READ,
            ]}
            path={RESIDENT_REVIEW_PHARMACY_MESSAGE_PATH}
            component={ReviewMedicationOrder}
          />
          <CompatRoute
            path={RESIDENT_ADD_ROUTINE_PATH}
            render={() => (
              <RequirePermissions
                minimalPermissions={[
                  GroupPermission.GROUP_PERMISSION_ROUTINE_UPDATE,
                ]}
              >
                <ScheduleCustomRoutine />
              </RequirePermissions>
            )}
          />
          <CompatRoute
            path={RESIDENT_EDIT_ROUTINE_PATH}
            render={() => (
              <RequirePermissions
                minimalPermissions={[
                  GroupPermission.GROUP_PERMISSION_ROUTINE_UPDATE,
                ]}
              >
                <EditCustomRoutineWrapper />
              </RequirePermissions>
            )}
          />
          <GatedRoute
            exact
            path={RESIDENT_DOCUMENTS_PATHS}
            component={Documents}
            minimalPermissions={[
              GroupPermission.GROUP_PERMISSION_PERSON_READ,
              GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
              GroupPermission.GROUP_PERMISSION_SNAPSHOT_READ,
            ]}
          />
          <GatedRoute
            exact
            path={RESIDENT_BILLING_PATHS}
            component={BillingDecider}
            minimalPermissions={[]}
          />
          <GatedRoute
            exact
            path={RESIDENT_BILLING_STATEMENT_PREVIEW_PATH}
            component={PayerStatementViewer}
            minimalPermissions={residentBillingPermissions}
          />
          <GatedRoute
            exact
            minimalPermissions={[
              GroupPermission.GROUP_PERMISSION_INCIDENT_READ,
              GroupPermission.GROUP_PERMISSION_PERSON_READ,
              GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
            ]}
            path={RESIDENT_NOTES_PATHS}
            component={Notes}
          />
          <GatedRoute
            exact
            minimalPermissions={[
              GroupPermission.GROUP_PERMISSION_INCIDENT_CREATE,
              GroupPermission.GROUP_PERMISSION_PERSON_READ,
              GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
            ]}
            path={RESIDENT_ADD_INCIDENT_PATH}
            component={AddIncident}
          />
          <GatedRoute
            exact
            minimalPermissions={[
              GroupPermission.GROUP_PERMISSION_PERSON_READ,
              GroupPermission.GROUP_PERMISSION_FACILITY_LIMITED_READ,
              GroupPermission.GROUP_PERMISSION_INCIDENT_UPDATE,
            ]}
            path={RESIDENT_EDIT_INCIDENT_PATH}
            component={EditIncident}
          />
        </Switch>
      </div>
      {signInPersonPopup}
    </PersonContext.Provider>
  )
}

function shouldRenderPerson(path: string) {
  switch (path) {
    case RESIDENT_ADD_MEDICATION_PATH[0]:
    case RESIDENT_EDIT_MEDICATION_PATH[0]:
    case RESIDENT_REVIEW_PHARMACY_MESSAGE_PATH[0]:
    case RESIDENT_REVIEW_PHARMACY_MESSAGE_PATH[1]:
    case RESIDENT_EDIT_INCIDENT_PATH[0]:
    case RESIDENT_ADD_INCIDENT_PATH[0]:
    case RESIDENT_SCHEDULE_V1_ROUTINE_PATH[0]:
    case RESIDENT_ADD_ROUTINE_PATH[0]:
    case RESIDENT_EDIT_ROUTINE_PATH[0]:
      return false
    default:
      return true
  }
}

function shouldRenderBreadcrumbs(path: string) {
  switch (path) {
    case RESIDENT_EDIT_MEDICATION_PATH[0]:
    case RESIDENT_REVIEW_PHARMACY_MESSAGE_PATH[0]:
    case RESIDENT_REVIEW_PHARMACY_MESSAGE_PATH[1]:
      return false
    default:
      return true
  }
}

export default Residents
