import classNames from 'classnames'
import { MedicationOrder } from '@shared/types/medication_order'
import { formatDateTime, monthDayYear } from '@shared/utils/date'
import { isCommunityManaged } from '@shared/utils/medicationStatement'
import { tw } from '@shared/utils/tailwind'
import MedMetadata from '@app/components/Residents/Medications/Orders/MedicationOrderDetails/MedMetadata'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import styles from './styles.module.css'
import { getStartAndEndOrderDates } from './helpers'

type Props = {
  order: MedicationOrder
  className?: string
  renderMode: 'list' | 'order'
}
export const OrderFooter = ({ order, className, renderMode }: Props) => {
  const {
    settings: { use24HourClock },
    convertToFacilityTimezone,
  } = useCurrentFacility()
  const containerClasses = classNames(styles.orderFooter, className)
  const medStatement = order.medicationStatement

  const { startingDate, endingDate } = getStartAndEndOrderDates(medStatement)

  const shownInList = renderMode === 'list'
  const fallbackValue = shownInList ? undefined : 'Not Specified'

  const profiled = order.sourceData?.profileOnly ?? false
  const communityManaged = isCommunityManaged(order)

  return (
    <div className={containerClasses}>
      <div className={styles.footerMetadata}>
        <div className={styles.footerDates}>
          <MedMetadata
            value={startingDate ? monthDayYear(startingDate) : undefined}
            iconClasses={'fa-arrow-right-from-line'}
            testId={'startOrderDate'}
          />
          <MedMetadata
            value={endingDate ? monthDayYear(endingDate) : undefined}
            iconClasses={'fa-arrow-right-to-line'}
            testId={'endOrderDate'}
          />
        </div>
        <div className={styles.footerMedInfo}>
          <MedMetadata
            value={medStatement.rxNumber ?? fallbackValue}
            iconClasses={'fa-prescription'}
            testId={'rxNumber'}
            underline={true}
          />
          <MedMetadata
            value={medStatement.pharmacy ?? fallbackValue}
            iconClasses={'fa-house-medical'}
            testId={'pharmacy'}
            underline={true}
            valueSuffix={profiled ? '(profiled)' : undefined}
            suffixClassName={tw`lowercase`}
          />
          <MedMetadata
            value={medStatement.prescriber?.toLowerCase() ?? fallbackValue}
            iconClasses={'fa-user-doctor-hair-long'}
            testId={'prescriber'}
            underline={true}
          />
          {communityManaged && (
            <MedMetadata
              value={'Community managed'}
              iconClasses={'fa-hands-holding-diamond'}
              testId={'community-managed'}
            />
          )}
        </div>
      </div>
      {order.sourceData?.receivedAt && (
        <div className={tw`mt-4`} data-testid={'received'}>
          Received on{' '}
          {formatDateTime(
            convertToFacilityTimezone(order.sourceData.receivedAt),
            {
              includeDate: true,
              use24HourClock,
            }
          )}
        </div>
      )}
    </div>
  )
}
