import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import classNames from 'classnames'
import { useContext } from 'react'
import { fetchProfileImageURL } from '@shared/api/person'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import PersonContext from '@app/contexts/PersonContext'
import styles from './styles.module.css'

export default function Avatar({
  setShowProfile,
  person,
}: {
  setShowProfile: React.Dispatch<React.SetStateAction<boolean>>
  person: Person
}) {
  return (
    <div>
      <div className={styles.avatarContainer}>
        <AvatarImage person={person} imageClass={styles.avatarImg} />
        <PersonPermissionGate
          person={person}
          permissions={[GroupPermission.GROUP_PERMISSION_PERSON_UPDATE]}
        >
          <button
            onClick={() => setShowProfile(true)}
            className={`${styles.editBtn} ${styles.hideOnMobile}`}
          >
            <span>
              <label className="visually-hidden">Edit Profile Details</label>
              <i className="fas fa-pencil fa-sm" />
            </span>
          </button>
        </PersonPermissionGate>
      </div>
    </div>
  )
}

export function AvatarImage({
  person,
  imageClass,
}: {
  person: Person
  imageClass?: string
}) {
  const {
    profileImageURL,
    setProfileImageURL,
    setIsPersonLoading,
    isPersonLoading,
  } = useContext(PersonContext)

  const { setError } = useContext(GlobalContext)

  const imageClasses = classNames(imageClass)
  return (
    <>
      {isPersonLoading && (
        <div style={{ width: '184px' }}>
          <SimpleSpinner />
        </div>
      )}
      {!isPersonLoading && (
        <>
          <img
            className={imageClasses}
            src={profileImageURL}
            alt={'Resident'}
            onError={() => {
              if (person) {
                setIsPersonLoading(true)
                fetchProfileImageURL(person as Required<Person>)
                  .then((url) => {
                    setProfileImageURL(url)
                  })
                  .catch((e) => setError(e))
                  .finally(() => setIsPersonLoading(false))
              }
            }}
          />
        </>
      )}
    </>
  )
}
