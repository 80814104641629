import { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { EmptyCard } from '@shared/components/EmptyCard'
import { useUserContext } from '@shared/contexts/UserContext'
import { getFullName } from '@shared/utils/humanName'
import { getOrElse } from '@shared/utils/loading'
import { twx } from '@shared/utils/tailwind'
import Content from '@app/components/generic/Content'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import { useFacilityIncidentsAndUsers } from '@app/hooks/useFacilityIncidentsAndUsers'
import usePeople from '@app/hooks/usePeople'
import useConfiguredTasks from '@app/pages/Documents/useConfiguredTasks'
import FilterIncidentsModal from '@app/pages/Notes/FilterIncidentsModal'
import { getBadges, getNewFilterValue } from '@app/pages/Notes/helpers'
import IncidentCard from '@app/pages/Notes/IncidentCard'
import { NoteCardSkeleton } from '@app/pages/Notes/NoteCardSkeleton'
import { InputWithIcon } from '@app/pages/Notes/types'
import { HeaderAndBadges } from './HeaderAndBadges'

export default function Incidents({
  match,
}: RouteComponentProps<{ orgId: string; facilityId: string }>) {
  const { user } = useUserContext()
  const { currentFacility } = useCurrentFacility()
  const { orgId, facilityId } = match.params
  const { people } = usePeople({
    facility: { orgId, id: facilityId },
  })

  const [showFilterIncidentsModal, setShowFilterIncidentsModal] =
    useState(false)
  const {
    filters,
    setFilters,
    incidents,
    numPages,
    currentPage,
    goToPage,
    goToNextPageOfIncidents,
    goToPreviousPageOfIncidents,
    hasNextPageOfIncidents,
    hasPreviousPageOfIncidents,
    communityUsers,
  } = useFacilityIncidentsAndUsers({ orgId, id: facilityId })
  const filteredIncidents = getOrElse(incidents, [])

  const loadedCommunityUsers = getOrElse(communityUsers, []).map((user) => {
    const fullName = getFullName(user.name)
    return {
      value: user.id!,
      name: fullName,
      label: fullName,
      isActive: user.isActive,
      icon: 'user',
    }
  })
  const creatableTasks = useConfiguredTasks({ orgId, facilityId })
  const badges: InputWithIcon[] = getBadges({
    filters,
    users: loadedCommunityUsers,
  })

  const findPersonById = (id: string) => {
    if (people.tag !== 'Complete') {
      return
    }
    return people.value.find((p) => p.id === id)
  }

  function clearFilterValue(name: string, value: string) {
    const newValue = getNewFilterValue(filters[name], value)

    void setFilters({
      ...filters,
      [name]: newValue,
    })
  }

  function resetCurrentPage() {
    return goToPage(currentPage)
  }

  function WithContent({ children }: { children: JSX.Element }) {
    return (
      <Content>
        <HeaderAndBadges
          showFilterButton={filteredIncidents.length >= 0 || badges.length > 0}
          badges={badges}
          onFilterButtonClick={() => {
            setShowFilterIncidentsModal(true)
          }}
          onFilterBadgeClick={clearFilterValue}
        />
        {children}
        {showFilterIncidentsModal && (
          <FilterIncidentsModal
            closeModal={() => setShowFilterIncidentsModal(false)}
            filters={filters}
            userOptions={loadedCommunityUsers}
            setFilters={setFilters}
          />
        )}
      </Content>
    )
  }

  function PaginationControls() {
    return (
      numPages > 1 && (
        <section className="flex items-center justify-center gap-4">
          <AsyncIconButton
            onClick={() => goToPreviousPageOfIncidents()}
            disabled={!hasPreviousPageOfIncidents()}
            buttonStyle="tertiary-outline"
            buttonSize="xsmall"
          >
            Previous page
          </AsyncIconButton>
          <span className={twx('text-sm font-semibold text-secondary-06')}>
            Page {currentPage} of {numPages}
          </span>
          <AsyncIconButton
            onClick={() => goToNextPageOfIncidents()}
            disabled={!hasNextPageOfIncidents()}
            buttonStyle="tertiary-outline"
            buttonSize="xsmall"
          >
            Next page
          </AsyncIconButton>
        </section>
      )
    )
  }

  if (
    currentFacility === undefined ||
    incidents.tag === 'Loading' ||
    people.tag === 'Loading' ||
    creatableTasks.tag === 'Loading' ||
    currentFacility.tag === 'Loading' ||
    communityUsers.tag === 'Loading'
  ) {
    return (
      <WithContent>
        <NoteCardSkeleton />
      </WithContent>
    )
  } else if (filteredIncidents.length === 0) {
    return (
      <WithContent>
        <EmptyCard
          title="No incidents found."
          version={{
            tag: 'Children',
            children: (
              <div className="mt-[4px]">
                Try removing some filters and try again.
              </div>
            ),
          }}
        />
      </WithContent>
    )
  } else {
    return (
      <WithContent>
        <>
          {filteredIncidents.map((incident) => {
            const { id, personId } = incident
            const person = findPersonById(personId!)
            if (person) {
              return (
                <div
                  key={`incident-${personId}-${id}`}
                  className={twx('print:block')}
                >
                  <IncidentCard
                    user={user}
                    incident={incident}
                    person={person}
                    onDelete={resetCurrentPage}
                    creatableTasks={creatableTasks.value}
                    includePersonLink
                  />
                </div>
              )
            }

            return null
          })}
          <PaginationControls />
        </>
      </WithContent>
    )
  }
}
