import {
  DateMessage,
  ValidDate,
} from '@augusthealth/models/com/august/protos/date'
import { DosageType } from '@augusthealth/models/com/august/protos/medication_statement'
import { differenceInCalendarDays } from 'date-fns'
import {
  FieldError,
  FieldPath,
  UseFormGetValues,
  UseFormReturn,
  UseFormSetValue,
} from 'react-hook-form'
import { MedOrderFormData } from '@shared/types/medication_order'
import { fromDateMessageToDate } from '@shared/utils/date'
import { isTaperDosageType } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/helpers'
import {
  addTimeToDateMessage,
  updateTaperDoseDurations,
} from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/TaperSchedule/helpers'

export const updateEndDate = ({
  originalDate,
  newDate,
  endDateName,
  getValues,
  setValue,
}: {
  originalDate?: Date
  newDate: Date
  endDateName: FieldPath<MedOrderFormData>
  getValues: UseFormGetValues<MedOrderFormData>
  setValue: UseFormSetValue<MedOrderFormData>
}) => {
  if (originalDate && newDate) {
    const differenceBetweenDates = differenceInCalendarDays(
      newDate,
      originalDate
    )
    const endDate = getValues(endDateName) as DateMessage

    const newEndDate = endDate
      ? addTimeToDateMessage({
          date: (getValues(endDateName) as DateMessage) ?? undefined,
          unitToAdd: 'days',
          amountToAdd: differenceBetweenDates,
        })
      : undefined

    if (newEndDate) {
      setValue(endDateName, newEndDate)
    }
  }
}

type nonTaperDosageTypes = Exclude<
  DosageType,
  | DosageType.DOSAGE_TYPE_TAPER
  | DosageType.DOSAGE_TYPE_UNSPECIFIED
  | DosageType.UNRECOGNIZED
>

export type PartialFooterMode =
  | {
      tag: `partial-${DosageType.DOSAGE_TYPE_TAPER}`
      doseIndex: number
      noteName: FieldPath<MedOrderFormData>
      requiredDocumentationName: FieldPath<MedOrderFormData>
      requiredDocumentationError: FieldError | null
      startDateName: FieldPath<MedOrderFormData>
      endDateName: FieldPath<MedOrderFormData>
    }
  | {
      tag: `partial-${nonTaperDosageTypes}`
      doseIndex: number
      noteName: FieldPath<MedOrderFormData> | null
      requiredDocumentationName: FieldPath<MedOrderFormData>
      requiredDocumentationError: FieldError | null
    }
export type FooterMode =
  | {
      tag: `full-${nonTaperDosageTypes}`
    }
  | {
      tag: `full-${DosageType.DOSAGE_TYPE_ROUTINE}-Vital`
      noteName: FieldPath<MedOrderFormData>
    }
  | {
      tag: `full-${DosageType.DOSAGE_TYPE_TAPER}`
      onStartDateChange: ({
        date,
        oldDate,
      }: {
        date: ValidDate | null
        oldDate: ValidDate | null
      }) => void
    }
  | PartialFooterMode

export const isPartialFooter = (
  mode: FooterMode
): mode is PartialFooterMode => {
  return mode.tag.startsWith('partial-')
}

export const getFooterMode = ({
  medDoseType,
  methods,
}: {
  medDoseType: DosageType
  methods: UseFormReturn<MedOrderFormData>
}): FooterMode => {
  return isTaperDosageType(medDoseType)
    ? {
        tag: `full-${DosageType.DOSAGE_TYPE_TAPER}`,
        onStartDateChange: ({
          date,
          oldDate,
        }: {
          oldDate: ValidDate
          date: ValidDate
        }) => {
          const differenceBetweenDates =
            date && oldDate
              ? differenceInCalendarDays(
                  fromDateMessageToDate(date) as Date,
                  fromDateMessageToDate(oldDate) as Date
                )
              : undefined

          updateTaperDoseDurations(methods, {
            differenceInDays: differenceBetweenDates,
            effectivePeriodStart: date,
          })
        },
      }
    : {
        tag: `full-${medDoseType as nonTaperDosageTypes}`,
      }
}
