import { useRouteMatch } from 'react-router-dom'
import ErrorMonitoring from '@shared/ErrorMonitoring'
import {
  admissionTasksPathForPerson,
  billingPathForPerson,
  carePathForPerson,
  contactsPathForPerson,
  detailsPathForPerson,
  documentPathForPerson,
  documentsPathForPerson,
  getAccountPreferencesUrl,
  getComplianceUrl,
  getFacilitiesUrl,
  getFacilityCareProgressUrl,
  getFacilityInboxUrl,
  getIncidentsUrl,
  getInsightsUrl,
  getOccupancyUrl,
  getOrgSettingsUrl,
  getProspectsUrl,
  getReportsUrl,
  getResidentsUrl,
  incidentPathForPerson,
  medicationsPathForPerson,
  notesPathForPerson,
  routinePathForPerson,
  schedulePathForPerson,
  taskPathForPerson,
} from '@shared/legacy_routes'
import { billingRoute as facilityBillingRoute } from '@shared/routes'
import { Person } from '@shared/types/person'
import { Snapshot } from '@shared/types/snapshot'

export const ARCHIVED_DOCUMENT_PATH =
  '/orgs/:orgId/facilities/:facilityId/people/:personId/documents/:documentId'

export const ARCHIVED_DOCUMENT_PATHS = [ARCHIVED_DOCUMENT_PATH]

export function archivedDocumentRoute({
  person,
  snapshot,
}: {
  person: Pick<Person, 'orgId' | 'facilityId' | 'id'>
  snapshot: Snapshot
}) {
  return ARCHIVED_DOCUMENT_PATH.replace(':orgId', person.orgId || '')
    .replace(':facilityId', person.facilityId || '')
    .replace(':personId', person.id || '')
    .replace(':documentId', snapshot.id || '')
}

export function extractIds(page: Page): {
  orgId: string | undefined
  facilityId: string | undefined
  personId: string | undefined
  taskId: string | undefined
  stepNumber: string | undefined
  routineId: string | undefined
  documentId: string | undefined
  medicationId: string | undefined
} {
  if (page.tag === 'Person') {
    const { orgId, facilityId, personId, tab } = page
    return {
      orgId,
      facilityId,
      personId,
      taskId: tab.tag === 'Task' ? tab.taskId : undefined,
      routineId: tab.tag === 'Routine' ? tab.routineId : undefined,
      stepNumber: tab.tag === 'Task' ? tab.stepNumber : undefined,
      documentId:
        tab.tag === 'Document' || tab.tag === 'ArchivedDocument'
          ? tab.documentId
          : undefined,
      medicationId: tab.tag === 'Medication' ? tab.medicationId : undefined,
    }
  }

  if (page.tag === 'Facility') {
    const { orgId, facilityId } = page
    return {
      orgId,
      facilityId,
      personId: undefined,
      taskId: undefined,
      routineId: undefined,
      stepNumber: undefined,
      documentId: undefined,
      medicationId: undefined,
    }
  }

  if (page.tag === 'Billing') {
    const { orgId, facilityId, personId } = page
    return {
      orgId,
      facilityId,
      personId,
      taskId: undefined,
      routineId: undefined,
      stepNumber: undefined,
      documentId: undefined,
      medicationId: undefined,
    }
  }

  if (page.tag === 'Organization') {
    const { orgId } = page
    return {
      orgId,
      facilityId: undefined,
      personId: undefined,
      taskId: undefined,
      routineId: undefined,
      stepNumber: undefined,
      documentId: undefined,
      medicationId: undefined,
    }
  }

  return {
    orgId: undefined,
    facilityId: undefined,
    personId: undefined,
    taskId: undefined,
    routineId: undefined,
    stepNumber: undefined,
    documentId: undefined,
    medicationId: undefined,
  }
}

/**
 * Convert a `Page` into a path. If forPerson is provided, the IDs of the page
 * will be swapped with the Person's IDs. If forPerson is provided, this
 * function will convert edit pages for resources into list pages for those
 * same resources. For example, a specific document will be converted into the
 * documents tab, a specific medication into the medications tab, etc.
 *
 * @param page
 * @param forPerson
 */
export function pageToPath({
  page,
  forPerson,
}: {
  page: Page
  forPerson?: Pick<Person, 'orgId' | 'facilityId' | 'id'>
}) {
  if (page.tag === 'Person') {
    const { orgId, facilityId, personId } = page
    const person = forPerson ?? { orgId, facilityId, id: personId }
    const preferParentPage = forPerson !== undefined

    switch (page.tab.tag) {
      case 'Tasks':
        return admissionTasksPathForPerson(person)
      case 'Task':
        return preferParentPage
          ? admissionTasksPathForPerson(person)
          : taskPathForPerson(person, page.tab.taskId)
      case 'Billing':
        return billingPathForPerson(person)
      case 'Care':
        return carePathForPerson(person)
      case 'Contacts':
        return contactsPathForPerson(person)
      case 'Details':
        return detailsPathForPerson(person)
      case 'Documents':
        return documentsPathForPerson(person)
      case 'Document':
        return preferParentPage
          ? documentsPathForPerson(person)
          : documentPathForPerson(person, { id: page.tab.documentId })
      case 'Medications':
        return medicationsPathForPerson(person)
      case 'Medication':
        return preferParentPage
          ? medicationsPathForPerson(person)
          : medicationsPathForPerson(person) + `/${page.tab.medicationId}`
      case 'Notes':
        return notesPathForPerson(person)
      case 'Incident':
        return preferParentPage
          ? notesPathForPerson(person)
          : incidentPathForPerson(person, { id: page.tab.incidentId })
      case 'Routine':
        return preferParentPage
          ? routinePathForPerson(person)
          : schedulePathForPerson(person, { id: page.tab.routineId })
      case 'ArchivedDocument':
        return archivedDocumentRoute({
          person,
          snapshot: { id: page.tab.documentId },
        })
      case 'SignDocuments':
        return `/orgs/${page.orgId}/facilities/${page.facilityId}/person/${person.id}/signDocuments`
    }
  } else if (page.tag === 'Facility') {
    const { orgId, facilityId } = forPerson ?? page

    switch (page.page) {
      case 'Compliance':
        return getComplianceUrl(orgId || '', facilityId || '')
      case 'Incidents':
        return getIncidentsUrl(orgId || '', facilityId || '')
      case 'Insights':
        return getInsightsUrl(orgId || '', facilityId || '')
      case 'Occupancy':
        return getOccupancyUrl(orgId || '', facilityId || '')
      case 'ProspectList':
        return getProspectsUrl(orgId || '', facilityId || '')
      case 'ResidentList':
        return getResidentsUrl(orgId || '', facilityId || '')
      case 'Reports':
        return getReportsUrl(orgId || '', facilityId || '')
      case 'AccountPreferences':
        return getAccountPreferencesUrl(orgId || '', facilityId || '')
      case 'Inbox':
        return getFacilityInboxUrl(orgId || '', facilityId || '')
      case 'Billing':
        return facilityBillingRoute(orgId || '', facilityId || '')
      case 'CareProgress':
        return getFacilityCareProgressUrl(orgId || '', facilityId || '')
    }
  } else if (page.tag === 'Organization') {
    const { orgId } = forPerson ?? page

    switch (page.page) {
      case 'FacilityList':
        return getFacilitiesUrl(orgId || '')
      case 'Settings':
        return getOrgSettingsUrl(orgId || '')
    }
  } else if (page.tag === 'OrganizationList') {
    return '/orgs'
  }

  return '/'
}

type RootSegment = 'orgs' | 'emar'
type OrgSegment = 'facilities' | 'settings' | 'dashboard' | undefined
type FacilitySegment =
  | 'occupancy'
  | 'prospects'
  | 'residents'
  | 'people'
  | 'reports'
  | 'compliance'
  | 'incidents'
  | 'insights'
  | 'account_preferences'
  | 'inbox'
  | 'passmeds'
  | 'orders'
  | 'care'
  | 'billing'
  | undefined
type ResourceSegment =
  | 'admission_tasks'
  | 'contacts'
  | 'details'
  | 'documents'
  | 'care'
  | 'medications'
  | 'pharmacy-messages'
  | 'billing'
  | 'tasks'
  | 'notes'
  | 'incidents'
  | 'routines'
  | 'insights'
  | undefined

interface AppRoutesMatch {
  0: RootSegment
  1: '/orgs' | undefined
  2: OrgSegment
  3: FacilitySegment
  4: ResourceSegment
  orgId?: string
  facilityId?: string
  personId?: string
  id?: string
  stepNumber?: string
}

export type Page =
  | {
      tag: 'Person'
      tab:
        | { tag: 'Tasks' }
        | { tag: 'Task'; taskId: string; stepNumber?: string }
        | { tag: 'Contacts' }
        | { tag: 'Details' }
        | { tag: 'Documents' }
        | { tag: 'Care' }
        | { tag: 'Document'; documentId: string }
        | { tag: 'Medications' }
        | { tag: 'Medication'; medicationId: string; action?: string }
        | { tag: 'Pharmacy Message'; messageId: string; action?: string }
        | { tag: 'Billing' }
        | { tag: 'Notes' }
        | { tag: 'Incident'; incidentId: string }
        | { tag: 'Routine'; routineId: string }
        | { tag: 'ArchivedDocument'; documentId: string }
        | { tag: 'SignDocuments' }
      orgId: string
      facilityId: string
      personId: string
    }
  | {
      tag: 'Facility'
      page: FacilityPage
      orgId: string
      facilityId: string
      tab?: string
    }
  | {
      tag: 'Organization'
      page: 'FacilityList' | 'Settings'
      orgId: string
    }
  | { tag: 'OrganizationList' }
  | {
      tag: 'Billing'
      tab?: { tag: 'Settings' | 'Residents' }
      orgId: string
      facilityId: string
      personId?: string
    }
  | { tag: 'Unknown' }

type FacilityPage =
  | 'Occupancy'
  | 'ProspectList'
  | 'ResidentList'
  | 'Reports'
  | 'Compliance'
  | 'Incidents'
  | 'Insights'
  | 'AccountPreferences'
  | 'Inbox'
  | 'PassMeds'
  | 'Orders'
  | 'CareProgress'
  | 'Progress'
  | 'Billing'

export const scheme =
  '/(orgs|emar)/(orgs/)?:orgId?/(facilities|settings)?/:facilityId?/(occupancy|prospects|residents|people|reports|compliance|incidents|insights|account_preferences|inbox|passmeds|orders|care|billing)?/:personId?/(admission_tasks|tasks|contacts|details|documents|care|medications|pharmacy-messages|billing|notes|incidents|routines)?/:id?/:stepNumber?'

interface MatchSegments {
  rootSegment: RootSegment
  orgSegment: OrgSegment
  facilitySegment: FacilitySegment
  resourceSegment: ResourceSegment
}

function extractMatchSegments(match: AppRoutesMatch): MatchSegments {
  return {
    rootSegment: match[0],
    orgSegment: match[2],
    facilitySegment: match[3],
    resourceSegment: match[4],
  }
}

function useInternalCurrentPage(): Page {
  const routeMatch = useRouteMatch<AppRoutesMatch>(scheme)?.params

  if (routeMatch === undefined) {
    return { tag: 'Unknown' }
  }

  const { orgId, facilityId, personId, id, stepNumber } = routeMatch
  const { orgSegment, facilitySegment, resourceSegment } =
    extractMatchSegments(routeMatch)

  // Trailing 'id' means we're on a specific task, document, incident, or medication page
  if (id && orgId && facilityId && personId) {
    const shareIds = {
      orgId,
      facilityId,
      personId,
    }

    if (facilitySegment === 'people') {
      return {
        tag: 'Person',
        tab: { tag: 'ArchivedDocument', documentId: id },
        ...shareIds,
      }
    }

    if (resourceSegment === 'tasks') {
      return {
        tag: 'Person',
        tab: { tag: 'Task', taskId: id, stepNumber },
        ...shareIds,
      }
    }

    if (resourceSegment === 'documents') {
      return {
        tag: 'Person',
        tab: { tag: 'Document', documentId: id },
        ...shareIds,
      }
    }

    if (resourceSegment === 'care') {
      return {
        tag: 'Person',
        tab: { tag: 'Care' },
        ...shareIds,
      }
    }

    if (resourceSegment === 'medications') {
      return {
        tag: 'Person',
        tab: {
          tag: 'Medication',
          medicationId: id,
          action: routeMatch.stepNumber,
        },
        ...shareIds,
      }
    }

    if (resourceSegment === 'pharmacy-messages') {
      return {
        tag: 'Person',
        tab: {
          tag: 'Pharmacy Message',
          messageId: id,
          action: routeMatch.stepNumber,
        },
        ...shareIds,
      }
    }

    if (resourceSegment === 'incidents') {
      return {
        tag: 'Person',
        tab: { tag: 'Incident', incidentId: id },
        ...shareIds,
      }
    }

    if (resourceSegment === 'routines') {
      return {
        tag: 'Person',
        tab: { tag: 'Routine', routineId: id },
        ...shareIds,
      }
    }

    if (resourceSegment === 'insights') {
      return {
        tag: 'Facility',
        page: 'Insights',
        tab: id,
        ...shareIds,
      }
    }
  }

  // No trailing 'id' means we're on a Person tab
  if (resourceSegment && orgId && facilityId && personId) {
    const sharedIds = {
      orgId,
      facilityId,
      personId,
    }

    switch (resourceSegment) {
      case 'admission_tasks':
        return {
          tag: 'Person',
          tab: { tag: 'Tasks' },
          ...sharedIds,
        }
      case 'contacts':
        return {
          tag: 'Person',
          tab: { tag: 'Contacts' },
          ...sharedIds,
        }
      case 'details':
        return {
          tag: 'Person',
          tab: { tag: 'Details' },
          ...sharedIds,
        }
      case 'documents':
        return {
          tag: 'Person',
          tab: { tag: 'Documents' },
          ...sharedIds,
        }
      case 'medications':
        return {
          tag: 'Person',
          tab: { tag: 'Medications' },
          ...sharedIds,
        }
      case 'billing':
        return {
          tag: 'Person',
          tab: { tag: 'Billing' },
          ...sharedIds,
        }
      case 'notes':
        return {
          tag: 'Person',
          tab: { tag: 'Notes' },
          ...sharedIds,
        }
      case 'care':
        return {
          tag: 'Person',
          tab: { tag: 'Care' },
          ...sharedIds,
        }
    }
  }

  // No 'personId' means we're on a facility level page
  if (facilitySegment && orgId && facilityId) {
    const sharedIds = {
      orgId,
      facilityId,
    }
    switch (facilitySegment) {
      case 'occupancy':
        return { tag: 'Facility', page: 'Occupancy', ...sharedIds }
      case 'prospects':
        return { tag: 'Facility', page: 'ProspectList', ...sharedIds }
      case 'residents':
        return { tag: 'Facility', page: 'ResidentList', ...sharedIds }
      case 'reports':
        return { tag: 'Facility', page: 'Reports', ...sharedIds }
      case 'compliance':
        return { tag: 'Facility', page: 'Compliance', ...sharedIds }
      case 'incidents':
        return { tag: 'Facility', page: 'Incidents', ...sharedIds }
      case 'insights':
        return { tag: 'Facility', page: 'Insights', ...sharedIds }
      case 'account_preferences':
        return { tag: 'Facility', page: 'AccountPreferences', ...sharedIds }
      case 'inbox':
        return { tag: 'Facility', page: 'Inbox', ...sharedIds }
      case 'passmeds':
        return { tag: 'Facility', page: 'PassMeds', ...sharedIds }
      case 'orders':
        return { tag: 'Facility', page: 'Orders', ...sharedIds }
      case 'care':
        return { tag: 'Facility', page: 'CareProgress', ...sharedIds }
      case 'billing':
        return { tag: 'Facility', page: 'Billing', ...sharedIds }
    }
  }

  // No 'facilityId' means we're on an organization level page
  if (orgSegment && orgId) {
    switch (orgSegment) {
      case 'facilities':
        return { tag: 'Organization', page: 'FacilityList', orgId }
      case 'settings':
        return { tag: 'Organization', page: 'Settings', orgId }
    }
  }

  // No 'orgId' means we're on the org list page
  if (orgSegment === undefined) {
    return { tag: 'OrganizationList' }
  }

  // Otherwise, unknown
  return { tag: 'Unknown' }
}

export default function useCurrentPage(): Page {
  const page = useInternalCurrentPage()
  ErrorMonitoring.setPage(page)
  return page
}
