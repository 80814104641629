import { useContext, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { patchRoutineAdministration } from '@shared/api/careapp'
import { getFacilityUsers } from '@shared/api/facility'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import Card from '@shared/components/Card'
import Icon from '@shared/components/Icon'
import { LabelAboveInput, requiredWhenError } from '@shared/components/Labels'
import { hasPermissionForFacility } from '@shared/components/PermissionGates/PermissionGates'
import { RoutineAdministrationBanner } from '@shared/components/RoutineAdministrationsStatusSummary/RoutineAdministrationsStatusSummary'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { CareAppPerson, RoutineAdministration } from '@shared/types/careapp'
import { DateAndTime } from '@shared/types/date'
import { GroupPermission } from '@shared/types/permission'
import { UserAccount } from '@shared/types/user'
import {
  fromDateTimeToDateInTimezone,
  fromDateToDateAndTimeInZone,
  toUTCIso8601DateTime,
} from '@shared/utils/date'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { getOrElse, Loading } from '@shared/utils/loading'
import {
  buildAdministeredEvent,
  getMostRecentEventId,
} from '@shared/utils/routineAdministration'
import { tw } from '@shared/utils/tailwind'
import RHFDateTimePicker from '@app/components/reactHookForm/DateTimePicker'
import RoutineModal from '@app/pages/Facilities/Progress/Routines/Modals/components/RoutineModal'

type MarkAsDoneFormData = {
  completedBy: UserAccount
  completedOn: DateAndTime // DateTimePicker supports DateAndTime only
}

export default function MarkAsDoneModal({
  careAppPerson,
  onClose,
  routineAdministration,
  facilityTimeZone,
  reload,
}: {
  careAppPerson: CareAppPerson
  onClose: () => void
  routineAdministration: RoutineAdministration
  facilityTimeZone: string
  reload: () => void
}) {
  const { setError } = useContext(GlobalContext)
  const { user: loginUser } = useUserContext()
  const [facilityUsers, setFacilityUsers] = useState<Loading<UserAccount[]>>({
    tag: 'Loading',
  })
  const facilityUserOptions = getOrElse(facilityUsers, []).map((user) => {
    return {
      label: getFirstAndLastName(user.name),
      value: user.id,
      data: user,
    }
  })
  const { id: adminId, events } = routineAdministration
  const { formState, control, handleSubmit } = useForm<MarkAsDoneFormData>({
    defaultValues: {
      completedBy: loginUser,
      completedOn: fromDateToDateAndTimeInZone(new Date(), facilityTimeZone),
    },
  })
  const onSave = async ({ completedBy, completedOn }: MarkAsDoneFormData) => {
    try {
      const occurredAt = fromDateTimeToDateInTimezone(
        completedOn,
        facilityTimeZone
      )
      const newEvent = buildAdministeredEvent({
        performer: {
          userId: completedBy.id,
          userName: completedBy.name,
        },
        occurredAt: toUTCIso8601DateTime(occurredAt),
        parentId: getMostRecentEventId(events),
      })

      await patchRoutineAdministration({
        adminId,
        careAppPerson,
        routineAdministration: { events: [newEvent] },
      })
      reload()
      onClose()
    } catch (err) {
      setError(err)
    }
  }
  const { orgId, facilityId } = careAppPerson

  useEffect(() => {
    getFacilityUsers({ orgId, facilityId })
      .then((facilityUsers) => {
        const containLoginUser = facilityUsers.some(
          ({ id }) => id === loginUser.id
        )

        setFacilityUsers({
          tag: 'Complete',
          value: (containLoginUser
            ? facilityUsers
            : [loginUser, ...facilityUsers]
          ).filter((user) => {
            return hasPermissionForFacility({
              user,
              permissions: [
                GroupPermission.GROUP_PERMISSION_ROUTINE_ADMINISTER,
              ],
              facility: { orgId, id: facilityId },
            })
          }),
        })
      })
      .catch(setError)
  }, [])

  return (
    <RoutineModal
      id="progress-routines-mark-as-done-modal"
      careAppPerson={careAppPerson}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSave)}>
        <Card className={tw`flex-col`}>
          <RoutineAdministrationBanner
            facilityTimeZone={facilityTimeZone}
            routineAdministration={routineAdministration}
          />
          <Card
            className={tw`mt-[16px] flex-col bg-gray-13 px-[8px] pb-[2px] pt-[16px]`}
          >
            <div
              className={tw`mb-[16px] text-[14px] font-semibold text-gray-04`}
            >
              <Icon name="circle-check" variant="regular" />
              <span className={tw`ml-[8px]`}>Mark as Done</span>
            </div>
            <div className={tw`mb-[16px] w-[384px]`}>
              <LabelAboveInput htmlFor="completedBy" uppercase={false}>
                Completed By
              </LabelAboveInput>
              <Controller
                control={control}
                name="completedBy"
                rules={{ required: true }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <StyledSelect
                      id="completedBy"
                      placeholder="Team Member"
                      onChange={(opt: OptionTypeBase<string, UserAccount>) =>
                        onChange(opt.data)
                      }
                      options={facilityUserOptions}
                      value={
                        facilityUserOptions.find(
                          (opt) => opt.value === value.id
                        ) || null
                      }
                      isDisabled={facilityUsers.tag === 'Loading'}
                    />
                  )
                }}
              />
            </div>
            <div>
              <RHFDateTimePicker
                control={control}
                id="completedOn"
                name="completedOn"
                title="Completed On"
                uppercase={false}
                subLabel={requiredWhenError(
                  Boolean(formState.errors.completedOn)
                )}
                required
                timeZone={facilityTimeZone}
              />
            </div>
          </Card>
        </Card>
        <div className={tw`mt-[24px]`}>
          <AnimatedPopupFormFooter
            yesBtn={{
              label: 'Mark as done',
              props: { width: 'calc(50% - 8px)' },
            }}
            noBtn={{
              action: onClose,
              props: { width: 'calc(50% - 8px)' },
            }}
            formState={formState}
          />
        </div>
      </form>
    </RoutineModal>
  )
}
