import { Array, Option, pipe, String } from 'effect'
import { sortBy } from 'lodash'
import { CareGroup } from '@shared/types/care_group'
import {
  CareAppPerson,
  PersonWithRoutineAdministrations,
  RoutineAdministration,
  RoutineAdministrationProgressType,
} from '@shared/types/careapp'
import {
  getAdministrationsForPerson,
  routineAdminIsDone,
  routineAdminIsException,
  routineAdminIsNotStarted,
  routineAdminIsOverDue,
} from '@shared/utils/careapp'
import { getFullName } from '@shared/utils/humanName'
import {
  getRoomNumber,
  isCurrentProspectOrResident,
  sortPeopleByRoomNumber,
} from '@shared/utils/person'

function hasAdministrations(
  personWithAdministrations: PersonWithRoutineAdministrations
) {
  return personWithAdministrations.administrations.length > 0
}

/**
 * We don't have to check the outOfFacilityDetail start and end date because
 * the backend does not return an outOfFacilityDetail if we have past the
 * outOfFacilityDetail end date.
 */
export function isAway(
  personWithAdministrations: PersonWithRoutineAdministrations
) {
  return personWithAdministrations.person.outOfFacilityDetail !== undefined
}

/**
 * Sorting function for lodash.sortBy
 *
 * Sort people by family name first.
 * Sort people by room number as alpha-numeric string.
 * Sort people who are away or not.
 * Finally sort people into two groups, those with administrations and those that have none.
 */

export function sortPeopleWithAdministrations(
  administrations: PersonWithRoutineAdministrations[]
) {
  const administrationsSortedByName = sortBy(
    administrations,
    (a) => a.person.name.family
  )
  // Sort Room as alpha-numeric string, so 7 < 10 (instead "7" > "10")
  const administrationsSortedByRoom = administrationsSortedByName.sort((a, b) =>
    sortPeopleByRoomNumber(a.person, b.person)
  )
  return sortBy(administrationsSortedByRoom, (a) => [
    !hasAdministrations(a),
    isAway(a),
  ])
}

const fromPerson =
  (shiftOccurrenceAdministrations: RoutineAdministration[]) =>
  (person: CareAppPerson): PersonWithRoutineAdministrations => {
    return {
      person,
      administrations: getAdministrationsForPerson({
        person,
        routineAdministrations: shiftOccurrenceAdministrations,
      }),
    }
  }

const personMatchesFilters =
  (careGroupFilter: Option.Option<CareGroup>, searchText: string) =>
  (person: CareAppPerson): boolean => {
    const personIsInCareGroup =
      Option.isNone(careGroupFilter) ||
      person.careGroupId === careGroupFilter.value.id

    const searchTextIsEmpty = searchText === ''

    const personNameMatchesSearchText = pipe(
      person.name,
      getFullName,
      String.toLowerCase,
      String.includes(searchText.toLowerCase())
    )

    const personRoomNumberMatchesSearchText = pipe(
      person,
      getRoomNumber,
      Option.fromNullable,
      Option.match({
        onSome: String.includes(searchText),
        onNone: () => false,
      })
    )

    return (
      personIsInCareGroup &&
      (searchTextIsEmpty ||
        personNameMatchesSearchText ||
        personRoomNumberMatchesSearchText)
    )
  }

const filterOutPeopleWithoutAdministrations =
  (noPeopleWithoutAdministrations: boolean) =>
  (person: PersonWithRoutineAdministrations) => {
    if (noPeopleWithoutAdministrations) {
      return person.administrations.length > 0
    } else {
      return true
    }
  }

const filterPeopleByProgressType =
  ({
    facilityTimeZone,
    selectedAdministrationProgressTypes,
  }: {
    facilityTimeZone: string
    selectedAdministrationProgressTypes: RoutineAdministrationProgressType[]
  }) =>
  (person: PersonWithRoutineAdministrations) => {
    if (Array.isEmptyArray(selectedAdministrationProgressTypes)) {
      return true
    } else {
      return person.administrations.some(
        administrationIsOneOfSelectedProgressTypes(
          selectedAdministrationProgressTypes,
          facilityTimeZone
        )
      )
    }
  }

const administrationIsOneOfSelectedProgressTypes =
  (
    selectedAdministrationProgressTypes: RoutineAdministrationProgressType[],
    facilityTimeZone: string
  ) =>
  (administration: RoutineAdministration) => {
    return selectedAdministrationProgressTypes.some((progressType) => {
      switch (progressType) {
        case 'Exceptions':
          return routineAdminIsException(administration)
        case 'Overdue':
          return routineAdminIsOverDue(administration, facilityTimeZone)
        case 'Not started yet':
          return routineAdminIsNotStarted(administration, facilityTimeZone)
        case 'Done':
          return routineAdminIsDone(administration)
      }
    })
  }

/**
 * noPeopleWithoutAdministrations: boolean
 * Similar to eMar, we should people sans administrations in CareAapp in FacilityAdministrationsPage
 * But hide people sans administrations in Main site's Facility Progress Routines page
 */

export function filterAndSortCarePeopleWithAdministrations({
  people,
  shiftOccurrenceAdministrations,
  careGroupFilter,
  searchText,
  noPeopleWithoutAdministrations = false,
  routineAdministrationProgressTypes = [],
  facilityTimeZone,
}: {
  people: CareAppPerson[]
  shiftOccurrenceAdministrations: RoutineAdministration[]
  careGroupFilter: Option.Option<CareGroup>
  searchText: string
  noPeopleWithoutAdministrations?: boolean
  routineAdministrationProgressTypes?: RoutineAdministrationProgressType[]
  facilityTimeZone: string
}): PersonWithRoutineAdministrations[] {
  return pipe(
    people,
    Array.filter(isCurrentProspectOrResident),
    Array.filter(personMatchesFilters(careGroupFilter, searchText)),
    Array.map(fromPerson(shiftOccurrenceAdministrations)),
    Array.filter(
      filterOutPeopleWithoutAdministrations(noPeopleWithoutAdministrations)
    ),
    Array.filter(
      filterPeopleByProgressType({
        facilityTimeZone,
        selectedAdministrationProgressTypes: routineAdministrationProgressTypes,
      })
    ),
    sortPeopleWithAdministrations
  )
}
