import { ContactPoint_ContactPointSystem } from '@augusthealth/models/com/august/protos/contact_point'
import { faker } from '@faker-js/faker'
import { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { createPerson } from '@shared/api/person'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { ModalTitle } from '@shared/components/baseMui/Modal/Layout'
import { Modal } from '@shared/components/baseMui/Modal/Modal'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import { LabelAboveInput } from '@shared/components/Labels'
import StyledSelect, {
  OptionTypeBase,
} from '@shared/components/Selects/StyledSelect'
import GlobalContext from '@shared/contexts/GlobalContext'
import useCurrentPage, { extractIds } from '@shared/hooks/useCurrentPage'
import { Contact, Contact_ContactRelationship } from '@shared/types/contact'
import { HumanName } from '@shared/types/human_name'
import { Organization } from '@shared/types/organization'
import { Person, ResidentStatus } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'
import { getOrElse } from '@shared/utils/loading'
import { createRPContact } from '@app/api/contacts'
import { useFacilities } from '@app/hooks/useFacilities'
import useOrganizations from '@app/hooks/useOrganizations'
import { humanNameFactory } from '@test/factories/humanNameFactory'
import { personFactory } from '@test/factories/personFactory'

type Props = {
  user: UserAccount
  onClose: () => void
  reload: () => Promise<void>
}

export default function CreatePersonModal(props: Props) {
  const { setError } = useContext(GlobalContext)
  const page = useCurrentPage()
  const { facilityId: defaultFacilityId, orgId: defaultOrgId } =
    extractIds(page)
  const defaultName = humanNameFactory.usual().buildList(1)
  const methods = useForm<Person>({
    mode: 'all',
    defaultValues: {
      facilityId: defaultFacilityId,
      orgId: defaultOrgId,
      name: defaultName,
      residentStatus: ResidentStatus.RESIDENT_STATUS_PROSPECT,
    },
  })

  const {
    formState: { isLoading },
    control,
    watch,
    handleSubmit,
    register,
  } = methods
  const selectedOrgId = watch('orgId')

  async function onSubmit(person: Person) {
    const isResident =
      person.residentStatus === ResidentStatus.RESIDENT_STATUS_CURRENT_RESIDENT
    try {
      const { id, contact, ...fullPerson } = isResident
        ? { ...personFactory.female().resident().build(), ...person }
        : {
            ...personFactory.female().prospect().build(),
            ...person,
          }

      const personResponse = await createPerson({ person: fullPerson })
      if (isResident) {
        const contact: Contact = {
          name: humanNameFactory.usual().build(),
          telecom: [
            {
              system:
                ContactPoint_ContactPointSystem.CONTACT_POINT_SYSTEM_EMAIL,
              value: props.user!.email!.replace(
                '@augusthealth.com',
                `+${faker.lorem.word()}+${Math.floor(Math.random() * 1000)}@augusthealth.com`
              ),
            },
          ],
          relationship: [
            Contact_ContactRelationship.CONTACT_RELATIONSHIP_PERSONAL,
            Contact_ContactRelationship.CONTACT_RELATIONSHIP_FAMMEMB,
            Contact_ContactRelationship.CONTACT_RELATIONSHIP_CHILD,
            Contact_ContactRelationship.CONTACT_RELATIONSHIP_FINANCIAL_PAYMENT_OF_CARE,
          ],
        }
        await createRPContact({
          contact,
          facilityId: fullPerson.facilityId!,
          orgId: fullPerson.orgId!,
          personId: `${personResponse.id}`,
        })
      }
      await props.reload()
      props.onClose()
    } catch (error) {
      setError(error)
    }
  }

  const { organizations } = useOrganizations()
  const { facilities } = useFacilities({ orgId: selectedOrgId || defaultOrgId })

  return (
    <Modal id="create-person" open={true} onClose={props.onClose}>
      <ModalTitle>Add a Move-in or a Resident</ModalTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-8">
          <div>
            <LabelAboveInput htmlFor="residentStatus">
              Resident Status
            </LabelAboveInput>
            <Controller
              name="residentStatus"
              control={control}
              render={({ field: { value, onChange } }) => (
                <StyledSelect
                  onChange={(e: OptionTypeBase) => onChange(e.value)}
                  options={[
                    {
                      label: 'Move-in',
                      value: ResidentStatus.RESIDENT_STATUS_PROSPECT,
                    },
                    {
                      label: 'Resident',
                      value: ResidentStatus.RESIDENT_STATUS_CURRENT_RESIDENT,
                    },
                  ]}
                  value={{
                    label:
                      value === ResidentStatus.RESIDENT_STATUS_PROSPECT
                        ? 'Move-in'
                        : 'Resident',
                    value,
                  }}
                />
              )}
            />
          </div>
          <div>
            <LabelAboveInput htmlFor="orgId">Organization</LabelAboveInput>
            <Controller
              name="orgId"
              control={control}
              defaultValue={defaultOrgId}
              render={({ field: { value, onChange } }) => {
                const options = getOrElse(organizations, []).map(
                  (org: Organization) => ({
                    label: org.name,
                    value: org.id,
                  })
                )
                return (
                  <StyledSelect
                    onChange={(e: OptionTypeBase) => onChange(e.value)}
                    options={options}
                    value={options.find((o) => o.value === value)}
                  />
                )
              }}
            />
          </div>
          <div>
            <LabelAboveInput htmlFor="facilityId">Facility</LabelAboveInput>
            <Controller
              name="facilityId"
              control={control}
              defaultValue={defaultFacilityId}
              render={({ field: { value, onChange } }) => {
                const options = getOrElse(facilities, []).map((facility) => ({
                  label: facility.name,
                  value: facility.id,
                }))
                return (
                  <StyledSelect
                    onChange={(e: OptionTypeBase) => onChange(e.value)}
                    options={options}
                    value={options.find((o) => o.value === value)}
                  />
                )
              }}
            />
          </div>
          <FullNameInputs
            defaultName={defaultName}
            name="name"
            register={register}
          />

          <div className="flex gap-2">
            <AsyncIconButton
              buttonStyle="secondary-outline"
              isLoading={isLoading}
              onClick={props.onClose}
            >
              Cancel
            </AsyncIconButton>
            <AsyncIconButton
              isLoading={isLoading}
              type="submit"
              buttonStyle="primary-fill"
              buttonSize="medium"
            >
              Save
            </AsyncIconButton>
          </div>
        </div>
      </form>
    </Modal>
  )
}

function FullNameInputs({
  defaultName,
  disabled = false,
  name = 'name',
  register,
}: {
  defaultName: HumanName[]
  disabled?: boolean
  name?: string
  register: any
}) {
  const firstName = `${name}.given[0]`
  const lastName = `${name}.family`

  const defaultFirstName = defaultName![0]!.given![0]!
  const defaultLastName = defaultName![0]!.family!

  return (
    <>
      <div>
        <LabelAboveInput htmlFor={firstName}>First Name</LabelAboveInput>
        <BasicInput
          disabled={disabled}
          id={firstName}
          defaultValue={defaultFirstName}
          {...register(firstName, { required: true })}
        />
      </div>
      <div>
        <LabelAboveInput htmlFor={lastName}>Last Name</LabelAboveInput>
        <BasicInput
          defaultValue={defaultLastName}
          disabled={disabled}
          id={lastName}
          {...register(lastName, { required: true })}
        />
      </div>
    </>
  )
}
