/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { DeepPartial } from 'react-hook-form'
import { getETag } from '@shared/api/etags'
import { download, getUrl } from '@shared/api/legacy'
import {
  fetchBlobUrlAndContentType,
  fetchJson,
  requestJson,
  requestString,
} from '@shared/api/request'
import { touchTask } from '@shared/api/task'
import * as fieldLists from '@shared/constants/fieldLists'
import environment from '@shared/environment'
import {
  apiPeopleStatsUrl,
  apiPeopleUrl,
  apiPersonUrl,
  apiTransferPersonUrl,
  emergencyPacketUrl,
  getFullSizeProfileImageUrl,
  getProfileImageUrl,
  personCompletionStatsUrl,
} from '@shared/legacy_routes'
import { ResidentCompletionStats } from '@shared/types/api/facility_stats'
import { Condition } from '@shared/types/condition'
import { ContactPoint } from '@shared/types/contact_point'
import { FunctionalStatus_Capability } from '@shared/types/functional_status'
import { PersonTransferRequest } from '@shared/types/jobs'
import { Person, ResidentStatus } from '@shared/types/person'
import { PersonStats } from '@shared/types/person_stats'
import { DeepNull, PickPartial } from '@shared/types/utilities'
import { filterOutEmptyTelecom } from '@shared/utils/contactPoint'
import { addCodingToDementia } from '@shared/utils/diagnosis'
import { fixCapabilities } from '@shared/utils/functionalStatus'
import { filterOutEmptyGivenName } from '@shared/utils/humanName'
import { getProfileSvgPath } from '@shared/utils/person'
import { queued } from './legacy'

interface CreatePersonArgs {
  person: PickPartial<Person, 'orgId' | 'facilityId'>
}

export async function createPerson({
  person,
}: CreatePersonArgs): Promise<{ id: number }> {
  const url = apiPeopleUrl(person.orgId || '', person.facilityId || '')
  const responseJson = (await requestJson({
    url,
    method: 'POST',
    body: JSON.stringify(person),
  })) as { data: Promise<{ id: number }> }

  return responseJson.data as Promise<{ id: number }>
}

interface FetchPersonParams {
  orgId: string
  facilityId: string
  personId: string
  fields?: string[]
}

interface FetchPeopleParams {
  orgId: string
  facilityId: string
  residentStatus?: ResidentStatus
  fields?: string[]
  limit?: number
}

export async function fetchPeople({
  orgId,
  facilityId,
  fields,
  residentStatus,
  limit = 9999,
}: FetchPeopleParams): Promise<Person[]> {
  let queryString = `limit=${limit}`
  if (fields) {
    queryString += `&fields=${fields as unknown as string}`
  }
  if (residentStatus) {
    queryString += `&residentStatus=${residentStatus}`
  }

  const url = apiPeopleUrl(orgId, facilityId)
  const fullUrl = `${url}?${queryString}`
  const responseJson = (await requestJson({ url: fullUrl })) as {
    data: Promise<Person[]>
  }
  return responseJson.data
}

export async function fetchPerson({
  orgId,
  facilityId,
  personId,
  fields,
}: FetchPersonParams): Promise<Person> {
  const url = apiPersonUrl(orgId, facilityId, personId)
  let queryString = ''

  if (fields) {
    queryString = `fields=${fields as unknown as string}`
  }

  const fullUrl = `${url}?${queryString}`

  const responseJson = (await requestJson({ url: fullUrl })) as {
    data: Promise<Person>
  }
  return responseJson.data
}

interface ResidentResponse {
  count: number
  residents: PersonStats[]
}

export const fetchAllPeopleStats = async ({
  orgId,
  facilityId,
  fields,
}: {
  orgId: string
  facilityId: string
  fields?: string[]
}): Promise<PersonStats[]> => {
  const url = apiPeopleStatsUrl(orgId, facilityId)
  let queryString = ''

  if (fields) {
    queryString = `&fields=${fields as unknown as string}`
  }

  const fullUrl = `${url}?limit=1000${queryString}`
  const responseJson = (await requestJson({ url: fullUrl })) as {
    data: Promise<PersonStats[]>
  }

  return responseJson.data
}

export async function fetchResidentsByStatus(
  orgId: string,
  fId: string,
  status: ResidentStatus,
  fields?: string[]
): Promise<ResidentResponse> {
  const url = apiPeopleStatsUrl(orgId, fId)
  let queryString = ''

  if (fields) {
    queryString = `&fields=${fields as unknown as string}`
  }

  const fullUrl = `${url}?residentStatus=${status}&limit=1000${queryString}`
  const responseJson = (await requestJson({ url: fullUrl })) as {
    meta: { count: number }
    data: PersonStats[]
  }

  return Promise.resolve({
    count: responseJson.meta.count,
    residents: responseJson.data,
  })
}

interface MergePatchParams {
  fId: string
  pId: string
  orgId: string
  patch: DeepNull<DeepPartial<Person>>
  fields?: string[]
  taskId?: string
  manualETag?: string
}

const BASE_URL = environment.baseUrl
const ORGANIZATIONS_URL = `${BASE_URL}/organizations`
const ORG_URL = `${ORGANIZATIONS_URL}/:orgId`
const FACILITY_URL = `${ORG_URL}/facilities/:facilityId`
const PERSON_URL = `${ORG_URL}/people/:personId`
const PERSON_WITH_FACILITY_URL = `${ORG_URL}/facilities/:facilityId/people/:personId`
export const SNAPSHOT_URL = `${PERSON_URL}/snapshots/:dataType/:snapshotId`
export const SNAPSHOT_BY_ID_URL = `${PERSON_URL}/snapshots/:snapshotId`
export const SNAPSHOT_FILE_URL = `${PERSON_URL}/snapshots/:dataType/:snapshotId/file`
export const MAR_V2_PDF_URL = `${PERSON_WITH_FACILITY_URL}/mar.pdf`
export const FACILITY_FACESHEET_URL = `${FACILITY_URL}/sendBulkFacesheet`
export const FACESHEET_PDF_URL = `${FACILITY_URL}/people/:personId/facesheet.pdf`
export const MEDLIST_PDF_URL = `${FACILITY_URL}/people/:personId/medlist.pdf`
export const MEDLOG_PDF_URL = `${FACILITY_URL}/people/:personId/centralMedicationLog.pdf`
export const PERSON_PDF_URL = `${PERSON_URL}/forms/:formCode.pdf`

export const mergePatchPerson: (
  params: Record<string, unknown>
) => Promise<any> = queued(rawMergePatchPerson)

function fixPersonEmptyAttributes(person: Person) {
  const fixedPerson = filterOutEmptyGivenName(person)
  const { telecom } = fixedPerson
  if (telecom) {
    const filteredTelecom = filterOutEmptyTelecom(telecom as ContactPoint[])

    return {
      ...fixedPerson,
      telecom: filteredTelecom.length ? filteredTelecom : null,
    }
  }

  return fixedPerson
}

function rawMergePatchPerson({
  fId,
  pId,
  orgId,
  patch,
  fields,
  taskId,
  manualETag,
}: {
  fId: string
  pId: string
  orgId: string
  patch: Person
  fields?: string[]
  taskId?: string
  manualETag: string
}) {
  const url = getUrl({
    fId,
    pId,
    orgId,
    baseUrl: PERSON_WITH_FACILITY_URL,
    params: fields ? { fields: fields } : {},
  })

  // Remove read-only fields from every person patch
  const {
    cprCode,
    isOnAlert,
    outOfFacilityHistory,
    isOutOfFacility,
    assessment,
    ...patchWithoutReadonlyFields
  } = patch
  const filteredPatch = fixPersonEmptyAttributes(patchWithoutReadonlyFields)

  return fetchJson(url, {
    method: 'PATCH',
    body: JSON.stringify(filteredPatch),
    headers: {
      'If-Match': manualETag || getETag(url) || '*',
      'Content-Type': 'application/merge-patch+json',
    },
  }).then((res) => {
    if (taskId !== undefined) {
      // Need to update tasks in PersonContext
      void touchTask({ pId, orgId, taskId })
    }
    return res as {
      id: number
    }
  })
}

export function fetchResidentDetails({
  facilityId: fId,
  pId,
  orgId,
}: {
  facilityId: string
  pId: string
  orgId: string
}) {
  return alternateFetchPerson({
    fId,
    pId,
    orgId,
    fields: fieldLists.RESIDENT_DETAILS_FIELDS,
  })
}

export function mergePatchResidentDetails({
  facilityId: fId,
  pId,
  orgId,
  patch,
  taskId,
}: {
  facilityId: string
  pId: string
  orgId: string
  patch: Record<string, unknown>
  taskId: string
}) {
  const {
    name,
    gender,
    birthDate,
    telecom,
    address,
    religiousPreference,
    ssn,
  } = patch

  return mergePatchPerson({
    fId,
    pId,
    orgId,
    patch: {
      name,
      gender,
      birthDate,
      telecom,
      address,
      religiousPreference,
      ssn,
    },
    fields: fieldLists.RESIDENT_DETAILS_FIELDS,
    taskId,
  })
}

export function fetchEmergencyInformation({
  facilityId: fId,
  pId,
  orgId,
}: {
  facilityId: string
  pId: string
  orgId: string
}) {
  return alternateFetchPerson({
    fId,
    pId,
    orgId,
    fields: fieldLists.EMERGENCY_INFO_FIELDS,
  })
}

export function mergePatchEmergencyInformation({
  facilityId: fId,
  pId,
  orgId,
  patch,
  taskId,
}: {
  facilityId: string
  pId: string
  orgId: string
  patch: Record<string, unknown>
  taskId: string
}) {
  const { hospital, coverage } = patch

  return mergePatchPerson({
    fId,
    pId,
    orgId,
    patch: { hospital, coverage },
    fields: fieldLists.EMERGENCY_INFO_FIELDS,
    taskId,
  })
}

export function fetchAmbulatoryStatus({
  facilityId: fId,
  pId,
  orgId,
}: {
  facilityId: string
  pId: string
  orgId: string
}) {
  return alternateFetchPerson({
    fId,
    pId,
    orgId,
    fields: fieldLists.AMBULATORY_STATUS_FIELDS,
  })
}

export function mergePatchAmbulatoryStatus({
  facilityId: fId,
  pId,
  orgId,
  patch,
  taskId,
}: {
  facilityId: string
  pId: string
  orgId: string
  patch: Record<string, unknown>
  taskId: string
}) {
  const { ambulatoryStatus } = patch

  return mergePatchPerson({
    fId,
    pId,
    orgId,
    patch: { ambulatoryStatus },
    fields: fieldLists.AMBULATORY_STATUS_FIELDS,
    taskId,
  })
}

export function fetchHealthHistoryPart1({
  facilityId: fId,
  pId,
  orgId,
}: {
  facilityId: string
  pId: string
  orgId: string
}) {
  return alternateFetchPerson({
    fId,
    pId,
    orgId,
    fields: fieldLists.HEALTH_HISTORY_PART_1_FIELDS,
  })
}

export function mergePatchHealthHistoryPart1({
  facilityId: fId,
  pId,
  orgId,
  patch,
  taskId,
}: {
  facilityId: string
  pId: string
  orgId: string
  patch: Record<string, unknown>
  taskId: string
}) {
  const {
    healthQuestionnaire: {
      overallConditionCode,
      overallConditionSummary,
      medications,
      hospitalizations,
    } = {} as never,
    socialHistory: {
      recentTbExposure,
      diet: { summary, dietType, dietTypeValue } = {} as never,
    } = {} as never,
    conditions,
    allergiesAndIntolerances: { allergies } = {} as never,
    ambulatoryStatus: { bedStatus } = {} as never,
    familyMemberHistory: { condition } = {} as never,
    diagnosticResults: { diagnosticReports } = {} as never,
  } = patch as Record<any, any>

  if (conditions && conditions.length > 0) {
    addCodingToDementia({ conditions }, conditions as Condition[])
  }

  const filteredPatch = {
    healthQuestionnaire: {
      overallConditionCode,
      overallConditionSummary,
      medications,
      hospitalizations,
    },
    socialHistory: {
      recentTbExposure,
      diet: { summary, dietType, dietTypeValue },
    },
    conditions,
    allergiesAndIntolerances: { allergies },
    ambulatoryStatus: { bedStatus },
    familyMemberHistory: { condition },
    diagnosticResults: { diagnosticReports },
  }

  return mergePatchPerson({
    fId,
    pId,
    orgId,
    patch: filteredPatch,
    fields: fieldLists.HEALTH_HISTORY_PART_1_FIELDS,
    taskId,
  })
}

export function fetchHealthHistoryPart2({ facilityId: fId, pId, orgId }) {
  return alternateFetchPerson({
    fId,
    pId,
    orgId,
    fields: fieldLists.HEALTH_HISTORY_PART_2_FIELDS,
  })
}

export function mergePatchHealthHistoryPart2({
  facilityId: fId,
  pId,
  orgId,
  patch,
  taskId,
}: {
  facilityId: string
  pId: string
  orgId: string
  patch: Record<string, unknown>
  taskId: string
}) {
  const {
    healthQuestionnaire: { physicalDisabilities, mentalHealth } = {} as never,
    socialHistory: {
      interestsAndActivitiesSummary,
      dislikesAndHardshipsSummary,
      educationSummary,
      occupationSummary,
      tobaccoUse,
      alcoholUse,
      other,
    } = {} as never,
    maritalStatus,
    communication,
  } = patch as any

  const filteredPatch = {
    healthQuestionnaire: {
      physicalDisabilities,
      mentalHealth,
    },
    socialHistory: {
      interestsAndActivitiesSummary,
      dislikesAndHardshipsSummary,
      educationSummary,
      occupationSummary,
      tobaccoUse,
      alcoholUse,
      other,
    },
    maritalStatus,
    communication,
  }

  return mergePatchPerson({
    fId,
    pId,
    orgId,
    patch: filteredPatch,
    fields: fieldLists.HEALTH_HISTORY_PART_2_FIELDS,
    taskId,
  })
}

export function fetchFunctionalCapabilities({ facilityId: fId, pId, orgId }) {
  return alternateFetchPerson({
    fId,
    pId,
    orgId,
    fields: fieldLists.FUNCTIONAL_CAPABILITIES_FIELDS,
  })
}

export function mergePatchFunctionalCapabilities({
  facilityId: fId,
  pId,
  orgId,
  patch,
  taskId,
}: {
  facilityId: string
  pId: string
  orgId: string
  patch: Record<string, any>
  taskId: string
}) {
  const { functionalStatus: { capabilities } = {} as any } = patch
  const fixedPatch = {
    functionalStatus: {
      capabilities: fixCapabilities(
        capabilities as FunctionalStatus_Capability[]
      ),
    },
  }
  return mergePatchPerson({
    fId,
    pId,
    orgId,
    patch: fixedPatch,
    fields: fieldLists.FUNCTIONAL_CAPABILITIES_FIELDS,
    taskId,
  })
}

export function fetchOtherServices({ facilityId: fId, pId, orgId }) {
  return alternateFetchPerson({
    fId,
    pId,
    orgId,
    fields: fieldLists.OTHER_SERVICES_FIELDS,
  })
}

export function mergePatchOtherServices({
  facilityId: fId,
  pId,
  orgId,
  patch,
  taskId,
}: {
  facilityId: string
  pId: string
  orgId: string
  patch: any
  taskId: string
}) {
  const {
    functionalStatus: { capabilities } = {} as any,
    healthQuestionnaire: {
      servicesNeeded,
      admissionSuitabilitySummary,
    } = {} as any,
  } = patch
  const fixedPatch = {
    functionalStatus: {
      capabilities: fixCapabilities(
        capabilities as FunctionalStatus_Capability[]
      ),
    },
    healthQuestionnaire: { servicesNeeded, admissionSuitabilitySummary },
  }
  return mergePatchPerson({
    fId,
    pId,
    orgId,
    patch: fixedPatch,
    fields: fieldLists.OTHER_SERVICES_FIELDS,
    taskId,
  })
}

export function fetchAdmissionsInformation({ facilityId: fId, pId, orgId }) {
  return alternateFetchPerson({
    fId,
    pId,
    orgId,
    fields: fieldLists.ADMISSIONS_INFORMATION,
  })
}

export function mergePatchAdmissionsInformation({
  facilityId: fId,
  pId,
  orgId,
  patch,
  taskId,
}) {
  const {
    admissionsInformation: {
      admissionType,
      startDate,
      financialStartDate,
      startRange,
      bedId,
      assignedRoomType,
      initialIntendedRate,
    } = {} as any,
  } = patch

  const filteredPatch = {
    admissionsInformation: {
      admissionType,
      startDate,
      financialStartDate,
      startRange,
      bedId,
      assignedRoomType,
      initialIntendedRate,
    },
  }

  return mergePatchPerson({
    fId,
    pId,
    orgId,
    patch: filteredPatch,
    fields: fieldLists.ADMISSIONS_INFORMATION,
    taskId,
  })
}

export function fetchConsentForms({ facilityId: fId, pId, orgId }) {
  return alternateFetchPerson({
    fId,
    pId,
    orgId,
    fields: fieldLists.CONSENT_FORMS_FIELDS,
  })
}

export function mergePatchConsentForms({
  facilityId: fId,
  pId,
  orgId,
  patch,
  taskId,
}) {
  const { contact, allergiesAndIntolerances: { allergies } = {} as any } = patch

  const filteredPatch = {
    contact,
    allergiesAndIntolerances: { allergies },
  }

  return mergePatchPerson({
    fId,
    pId,
    orgId,
    patch: filteredPatch,
    fields: fieldLists.CONSENT_FORMS_FIELDS,
    taskId,
  })
}

export async function typedMergePatchPerson(
  p: MergePatchParams
): Promise<{ data: DeepPartial<Person> }> {
  return (await mergePatchPerson(
    p as unknown as Record<string, unknown>
  )) as Promise<{
    data: DeepPartial<Person>
  }>
}

export const downloadEmergencyPacket = (
  person: Required<PickPartial<Person, 'orgId' | 'facilityId' | 'id'>>
) =>
  download({
    contentType: 'application/json',
    fileUrl: emergencyPacketUrl({
      orgId: person.orgId,
      facilityId: person.facilityId,
      id: person.id,
    }),
    target: '_blank',
    method: 'GET',
  })

export const getPersonCompletionStats = async (
  person: Required<PickPartial<Person, 'orgId' | 'facilityId' | 'id'>>
): Promise<ResidentCompletionStats> => {
  const response = await requestJson({
    url: personCompletionStatsUrl({
      orgId: person.orgId,
      facilityId: person.facilityId,
      id: person.id,
    }),
  })
  return response.data as Promise<ResidentCompletionStats>
}

export const fetchProfileImageURL = async (
  person: Required<PickPartial<Person, 'id' | 'orgId' | 'gender'>>
): Promise<string> => {
  try {
    const response = await requestString({
      url: getProfileImageUrl({ orgId: person.orgId, personId: person.id }),
    })
    if (response !== '') {
      return (JSON.parse(response) as { data: string }).data
    }
    // 204 "no content" response so return the default avatar
    return getProfileSvgPath(person)
  } catch (e) {
    // error retrieving pic so use default avatar
    return getProfileSvgPath(person)
  }
}

export const fetchSnapshotImageUrl = async ({
  person,
  snapshotId,
}: {
  person: Required<PickPartial<Person, 'id' | 'orgId' | 'gender'>>
  snapshotId: string
}) => {
  try {
    const blob = await fetchBlobUrlAndContentType({
      url: getFullSizeProfileImageUrl({ person, snapshotId }),
    })

    return blob.url
  } catch (e) {
    // error retrieving snapshot URL so use default avatar
    return getProfileSvgPath(person)
  }
}

export const transferPerson = async ({
  request,
}: {
  request: Required<PersonTransferRequest>
}): Promise<any> => {
  const { person } = request
  const { orgId, facilityId, personId } = person

  return (await requestJson({
    url: apiTransferPersonUrl(orgId || '', facilityId || '', personId || ''),
    body: JSON.stringify(request),
    method: 'POST',
  })) as Promise<any>
}

export const updatePersonToProspect = async ({
  person,
}: {
  person: PickPartial<Person, 'facilityId' | 'id' | 'orgId'>
}) =>
  await typedMergePatchPerson({
    fId: person.facilityId || '',
    orgId: person.orgId || '',
    pId: person.id || '',
    patch: {
      residentStatus: ResidentStatus.RESIDENT_STATUS_PROSPECT,
    },
  })

export function alternateFetchPerson({ fId, pId, orgId, fields }) {
  const url = getUrl({
    fId,
    pId,
    orgId,
    baseUrl: PERSON_WITH_FACILITY_URL,
    params: fields ? { fields } : {},
  })
  return fetchJson(url)
}
