import { Token } from '@augusthealth/models/com/august/protos/api/token'
import { OrgFacilitySettings } from '@augusthealth/models/com/august/protos/org_facility_settings'
import { cloneDeep } from 'lodash'
import { SetOptional } from 'type-fest'
import { requestJson } from '@shared/api/request'
import { billingDashboardsUrl } from '@shared/api/urls'
import {
  apiOrganizationDashboardsUrl,
  apiOrganizationDashboardTokenUrl,
  apiOrganizationsUrl,
  apiOrganizationUrl,
  orgFacilitySettingsUrl,
} from '@shared/legacy_routes'
import { Organization } from '@shared/types/organization'
import { DashboardType } from '@shared/types/superset'
import { UserAccount } from '@shared/types/user'
import { removeGroupsByOrgId } from '@shared/utils/permisson'
import { CreateUpdateResponse } from '@shared/utils/task'

export async function createOrganization(
  organization: SetOptional<Organization, 'id'>
): Promise<{ id: number }> {
  const responseJson = await requestJson({
    url: apiOrganizationsUrl(),
    method: 'POST',
    body: JSON.stringify(organization),
  })

  return responseJson.data
}

export async function deleteOrganization({
  organization,
}: {
  organization: Pick<Organization, 'id'>
}): Promise<{ id: number }> {
  const responseJson = await requestJson({
    url: apiOrganizationUrl(organization.id),
    method: 'DELETE',
  })

  return responseJson.data
}

export async function listOrganizations(): Promise<Organization[]> {
  const responseJson = await requestJson({
    url: apiOrganizationsUrl(),
    method: 'GET',
  })

  return responseJson.data
}

export async function updateOrganization(
  organization: Organization
): Promise<{ id: number }> {
  const responseJson = await requestJson({
    url: apiOrganizationUrl(organization.id),
    method: 'PUT',
    body: JSON.stringify(organization),
  })

  return responseJson.data
}

export async function getOrganization(orgId: string): Promise<Organization> {
  const responseJson = await requestJson({
    url: apiOrganizationUrl(orgId),
  })

  return responseJson.data
}

export async function getOrganizationUsers(
  orgId: string
): Promise<UserAccount[]> {
  const responseJson = await requestJson({
    url: `${apiOrganizationUrl(orgId)}/users?limit=9999`,
  })

  return responseJson.data
}

export async function createOrganizationUser(
  orgId: string,
  body: Omit<UserAccount, 'isActive'>
): Promise<{ id: string | number | undefined }> {
  const responseJson = await requestJson({
    url: `${apiOrganizationUrl(orgId)}/users`,
    method: 'POST',
    body: JSON.stringify(body),
  })

  return responseJson.data
}

export async function updateOrganizationUser(
  orgId: string,
  userId: string,
  body: Omit<UserAccount, 'isActive'>
): Promise<CreateUpdateResponse> {
  const withNulls = {
    name: body.name,
    email: body.email ?? null,
    preferredUsername: body.preferredUsername ?? null,
    phoneNumber: body.phoneNumber ?? null,
    groups: body.groups,
  }
  const responseJson = await requestJson({
    contentType: 'application/merge-patch+json',
    url: `${apiOrganizationUrl(orgId)}/users/${userId}`,
    method: 'PATCH',
    body: JSON.stringify(withNulls),
  })

  return responseJson.data
}

export async function removeGroupsFromOrganizationUser(
  orgId: string,
  user: UserAccount
): Promise<CreateUpdateResponse> {
  const { id: userId } = user
  const updatedUser = cloneDeep(user)
  if (updatedUser.groups) {
    updatedUser.groups = removeGroupsByOrgId({
      groups: updatedUser.groups,
      orgId,
    })
  }
  return updateOrganizationUser(orgId, userId!, updatedUser)
}

export const updateOrgFacilitySettings = async (
  body: OrgFacilitySettings
): Promise<OrgFacilitySettings> => {
  const responseJson = await requestJson({
    url: orgFacilitySettingsUrl(),
    method: 'POST',
    body: JSON.stringify(body),
  })

  return responseJson.data
}

export const fetchDashboardToken = async (
  orgId: string,
  dashboardId: string,
  dashboardType: DashboardType
): Promise<Token> => {
  const response = await requestJson({
    url: apiOrganizationDashboardTokenUrl(orgId, dashboardId, dashboardType),
    method: 'POST',
  })
  return response.data
}

export type DashboardRecord = {
  id: string
  label: string
}

export const fetchOrganizationDashboards = async (
  orgId: string
): Promise<DashboardRecord[]> => {
  const response = await requestJson({
    url: apiOrganizationDashboardsUrl(orgId),
    method: 'GET',
  })
  return response.data
}

export const fetchBillingDashboards = async (
  orgId: string
): Promise<DashboardRecord[]> => {
  const response = await requestJson({
    url: billingDashboardsUrl({ orgId }),
    method: 'GET',
  })
  return response.data
}
