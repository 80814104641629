import { twx } from '@shared/utils/tailwind'
import { CardRadioButton } from './BaseButtons'

export function MarkAsDoneCardRadioButton({
  onClick,
  radioInputClassName,
}: {
  onClick: () => void
  radioInputClassName?: string
}) {
  return (
    <CardRadioButton
      onClick={onClick}
      value="Mark as Done"
      iconName="circle-check"
      label="Mark as Done"
      className={twx(radioInputClassName)}
    />
  )
}

export function MarkAsExceptionCardRadioButton({
  onClick,
  radioInputClassName,
}: {
  onClick: () => void
  radioInputClassName?: string
}) {
  return (
    <CardRadioButton
      onClick={onClick}
      value="Mark as Exception"
      iconName="circle-x"
      label="Mark as Exception"
      className={twx(radioInputClassName)}
    />
  )
}
