import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { ReactNode } from 'react'
import ButtonLink from '@shared/components/ButtonLink'
import { DotDivider } from '@shared/components/DotDivider'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { Person } from '@shared/types/person'
import { FacilityGroupType } from '@shared/utils/careGroup'
import styles from './styles.module.css'
import CareRow from './CareRow'

export default function CareOrMedGroupRow({
  facilityGroupType,
  person,
  showEditGroup,
}: {
  facilityGroupType: FacilityGroupType
  person: Person
  showEditGroup: () => void
}) {
  const { careGroupDetails, medGroupDetails } = person
  const isMedGroup = facilityGroupType === 'med'
  let message: string | ReactNode
  if (isMedGroup && medGroupDetails?.name) {
    message = medGroupDetails.name
  } else if (!isMedGroup && careGroupDetails?.name) {
    message = careGroupDetails.name
  } else {
    message = <span className={styles.incomplete}>Unknown</span>
  }

  return (
    <CareRow label={isMedGroup ? 'Med Group' : 'Care Group'}>
      {message}
      <PersonPermissionGate
        person={person}
        permissions={[GroupPermission.GROUP_PERMISSION_PERSON_UPDATE]}
      >
        <DotDivider />
        <ButtonLink onClick={showEditGroup}>Edit</ButtonLink>
      </PersonPermissionGate>
    </CareRow>
  )
}
