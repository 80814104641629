import { useEffect, useState } from 'react'
import { fetchProfileImageURL } from '@shared/api/person'
import { Person } from '@shared/types/person'
import { loaded, Loading } from '@shared/utils/loading'

export default function useProfileImage({
  person,
}: {
  person: Loading<Person>
}): { imageURL: Loading<string> } {
  const [imageURL, setImageURL] = useState<Loading<string>>({ tag: 'Loading' })

  const refreshProfileImage = async () => {
    if (person.tag === 'Loading') {
      return
    }

    const url = await fetchProfileImageURL(person.value)
    setImageURL(loaded(url))
  }

  useEffect(() => {
    void refreshProfileImage()
  }, [person.tag, person.tag === 'Complete' ? person.value.id : ''])

  return { imageURL }
}
