import {
  Group,
  GroupType,
} from '@augusthealth/models/com/august/protos/permission'
import { Array as EffectArray, Option, pipe } from 'effect'
import {
  PersonData,
  UserFormData,
} from '@shared/components/Auth/LoginWithUsernameOrEmail/PasswordField/types'
import { UserAccount } from '@shared/types/user'
import {
  getGroupLabelAndType,
  removeGroupsByOrgId,
} from '@shared/utils/permisson'
import {
  isDirector,
  isFacilityUserManager,
  isOrgAdmin,
  isOrgUserManager,
  isSuperUser,
} from '@shared/utils/user'

export const ALL_USER_TYPE_OPTIONS = [
  GroupType.GROUP_TYPE_ORGANIZATION_ADMIN,
  GroupType.GROUP_TYPE_ORG_USER_MANAGEMENT,
  GroupType.GROUP_TYPE_SOCIAL_WORKER,
  GroupType.GROUP_TYPE_BILLING,
  GroupType.GROUP_TYPE_NURSE,
  GroupType.GROUP_TYPE_MED_TECH,
  GroupType.GROUP_TYPE_FRONT_DESK,
  GroupType.GROUP_TYPE_FACILITY_DIRECTOR,
  GroupType.GROUP_TYPE_PHARMACIST,
  GroupType.GROUP_TYPE_MED_TECH_SUPERVISOR,
  GroupType.GROUP_TYPE_FACILITY_STAFF,
  GroupType.GROUP_TYPE_FACILITY_SALES_AND_MARKETING,
  GroupType.GROUP_TYPE_SALES_AND_MARKETING_PLUS_BILLING,
  GroupType.GROUP_TYPE_CAREGIVER,
  GroupType.GROUP_TYPE_CAREGIVER_PLUS_EHR,
]

const USER_TYPE_OPTIONS_FOR_ORG_USER_MANAGER = ALL_USER_TYPE_OPTIONS

const USER_TYPE_OPTIONS_FOR_ORG_ADMIN =
  USER_TYPE_OPTIONS_FOR_ORG_USER_MANAGER.filter(
    (option) =>
      ![
        GroupType.GROUP_TYPE_ORGANIZATION_ADMIN,
        GroupType.GROUP_TYPE_ORG_USER_MANAGEMENT,
        GroupType.GROUP_TYPE_FACILITY_USER_MANAGEMENT,
      ].includes(option)
  )

const USER_TYPE_OPTIONS_FOR_DIRECTOR = USER_TYPE_OPTIONS_FOR_ORG_ADMIN.filter(
  (option) => option !== GroupType.GROUP_TYPE_FACILITY_DIRECTOR
)

const USER_TYPE_OPTIONS_FOR_FACILITY_USER_MANAGER =
  USER_TYPE_OPTIONS_FOR_DIRECTOR

type UserKind =
  | 'SuperUser'
  | 'OrgAdmin'
  | 'Director'
  | 'OrgUserManager'
  | 'FacilityUserManager'

const determineUserKind = (
  user: UserAccount,
  orgId: string
): Option.Option<UserKind> => {
  if (isSuperUser(user)) {
    return Option.some('SuperUser')
  } else if (isOrgAdmin({ user, orgId })) {
    return Option.some('OrgAdmin')
  } else if (isDirector({ user, orgId })) {
    return Option.some('Director')
  } else if (isOrgUserManager({ user, orgId })) {
    return Option.some('OrgUserManager')
  } else if (isFacilityUserManager({ user, orgId })) {
    return Option.some('FacilityUserManager')
  } else {
    return Option.none()
  }
}

const userKindToRoleOptions = (userKind: UserKind): GroupType[] => {
  switch (userKind) {
    case 'SuperUser':
      return ALL_USER_TYPE_OPTIONS
    case 'OrgAdmin':
      return USER_TYPE_OPTIONS_FOR_ORG_ADMIN
    case 'Director':
      return USER_TYPE_OPTIONS_FOR_DIRECTOR
    case 'OrgUserManager':
      return USER_TYPE_OPTIONS_FOR_ORG_USER_MANAGER
    case 'FacilityUserManager':
      return USER_TYPE_OPTIONS_FOR_FACILITY_USER_MANAGER
  }
}

const filterCaregiverOption =
  (hasAccessToCareTrack: boolean) => (option: GroupType) => {
    if (option === GroupType.GROUP_TYPE_CAREGIVER) {
      return hasAccessToCareTrack
    } else {
      return true
    }
  }

export function getRoleOptions(
  user: UserAccount,
  orgId: string,
  hasAccessToCareTrack: boolean
) {
  return pipe(
    determineUserKind(user, orgId),
    Option.map(userKindToRoleOptions),
    Option.map(EffectArray.filter(filterCaregiverOption(hasAccessToCareTrack))),
    Option.map(EffectArray.map(getGroupLabelAndType)),
    Option.getOrElse(() => [])
  )
}

export const GROUP_TYPE_NOT_REQUIRED_FACILITY_SELECT = [
  GroupType.GROUP_TYPE_ORGANIZATION_ADMIN,
  GroupType.GROUP_TYPE_ORG_USER_MANAGEMENT,
  GroupType.GROUP_TYPE_SUPER_USER,
]

export const GROUP_TYPE_REQUIRED_RESIDENT_DROPDOWN = [
  GroupType.GROUP_TYPE_SOCIAL_WORKER,
]

// Assume that you an have only one role for now
export function getRoleByGroup(groups: Group[]) {
  const g = groups.find((d) => d.groupType)
  return g?.groupType
}

function getFacilityIdsByRoleAndOrgId({
  groups,
  orgId,
  role,
}: {
  groups: Group[]
  orgId: string
  role: GroupType
}): string[] | undefined {
  const idsSet = groups.reduce((s, g) => {
    const { groupType, personMatcher } = g
    const { facilityId, organizationId } = personMatcher || {}
    if (facilityId && groupType === role && organizationId === orgId) {
      s.add(facilityId)
    }

    return s
  }, new Set<string>())

  return idsSet.size ? Array.from(idsSet) : undefined
}

function getResidentsByRoleAndOrgId({
  groups,
  orgId,
  role,
}: {
  groups: Group[]
  orgId: string
  role: GroupType
}): PersonData[] | undefined {
  const people = groups.reduce((list: PersonData[], g) => {
    const { groupType, personMatcher } = g
    const { facilityId, organizationId, personId } = personMatcher || {}
    if (
      facilityId &&
      personId &&
      groupType === role &&
      organizationId === orgId &&
      list.every(
        (data) => data.facilityId !== facilityId || data.id !== personId
      )
    ) {
      list.push({ facilityId, id: personId })
    }

    return list
  }, [])

  return people?.length ? people : undefined
}

export function getFormDataFromUser({
  orgId,
  user,
}: {
  orgId: string
  user: UserAccount | null
}): UserFormData {
  const { email, groups = [], name, preferredUsername } = user ?? {}
  const role = getRoleByGroup(groups)

  return {
    preferredUsername,
    email,
    facilityIds: role
      ? getFacilityIdsByRoleAndOrgId({ groups, orgId, role })
      : undefined,
    name,
    residents: role
      ? getResidentsByRoleAndOrgId({ groups, orgId, role })
      : undefined,
    role,
  }
}

export function convertUserFormDataToUser({
  orgId,
  originalUser,
  userFormData,
}: {
  orgId: string
  originalUser: UserAccount | null
  userFormData: UserFormData
}) {
  const { facilityIds, residents, role, ...rest } = userFormData
  const groups: Group[] = removeGroupsByOrgId({
    groups: originalUser?.groups || [],
    orgId,
  })

  if (role && GROUP_TYPE_NOT_REQUIRED_FACILITY_SELECT.includes(role)) {
    groups.push({
      groupType: role,
      personMatcher: { organizationId: orgId },
    })
  } else if (residents?.length) {
    // Expect resident from one facility for the moment
    groups.push(
      ...residents.map(({ facilityId, id }) => ({
        groupType: role,
        personMatcher: {
          facilityId,
          organizationId: orgId,
          personId: id,
        },
      }))
    )
  } else if (facilityIds?.length) {
    groups.push(
      ...facilityIds.map((facilityId) => ({
        groupType: role,
        personMatcher: {
          facilityId,
          organizationId: orgId,
        },
      }))
    )
  }

  return {
    ...originalUser,
    ...rest,
    groups,
  }
}

export function setPasswordValue(
  userFormData: UserFormData,
  includePassword: boolean
) {
  if (includePassword) {
    return { ...userFormData, password: userFormData.password }
  }

  const { password, ...rest } = userFormData

  return rest
}

export function setEmailValue(userFormData: UserFormData) {
  const { email, ...rest } = userFormData
  if (email === '') {
    return rest
  }

  return userFormData
}

/**
 * Builds a "clean" version of the payload that excludes:
 * - empty email (e.g. `{email: ''}`)
 * - "untouched" password field
 * @param {UserFormData} userFormData
 * @param {boolean} includePassword
 * @returns {UserFormData}
 */
export function setUserFormData(
  userFormData: UserFormData,
  includePassword: boolean
): UserFormData {
  const valuesWithPassword = setPasswordValue(userFormData, includePassword)

  return setEmailValue(valuesWithPassword)
}

export type FieldName = keyof UserFormData | 'saveBtn'
export type FieldState = {
  hidden: boolean
  disabled: boolean
}

export const HIDDEN = { disabled: true, hidden: true }
export const DISABLED = { disabled: true, hidden: false }
export const ENABLED = { disabled: false, hidden: false }

export const fullAccessFieldState: Record<FieldName, FieldState> = {
  name: ENABLED,
  email: ENABLED,
  preferredUsername: ENABLED,
  password: ENABLED,
  oneTimeUse: ENABLED,
  role: ENABLED,
  facilityIds: ENABLED,
  residents: ENABLED,
  saveBtn: ENABLED,
}

export const readOnlyFieldState: Record<FieldName, FieldState> = {
  name: DISABLED,
  email: DISABLED,
  preferredUsername: DISABLED,
  password: DISABLED,
  oneTimeUse: HIDDEN,
  role: DISABLED,
  facilityIds: DISABLED,
  residents: DISABLED,
  saveBtn: HIDDEN,
}

export function assignFieldStateBasedOnRole({
  currentUser,
  targetUser,
  orgId,
}: {
  currentUser: UserAccount
  targetUser: UserAccount | null
  orgId: string
}): Record<FieldName, FieldState> {
  if (!targetUser) {
    return fullAccessFieldState
  }

  if (isSuperUser(currentUser)) {
    return fullAccessFieldState
  }

  const isSelf = targetUser.id === currentUser.id

  if (isOrgUserManager({ user: currentUser, orgId })) {
    if (isSelf) {
      return {
        ...fullAccessFieldState,
        role: HIDDEN,
      }
    }

    return fullAccessFieldState
  }

  if (isOrgAdmin({ user: currentUser, orgId })) {
    if (isSelf) {
      return {
        ...fullAccessFieldState,
        name: DISABLED,
        email: DISABLED,
        role: DISABLED,
      }
    }

    if (isOrgAdmin({ user: targetUser, orgId })) {
      return {
        ...readOnlyFieldState,
        password: HIDDEN,
        preferredUsername: HIDDEN,
        facilityIds: DISABLED,
      }
    }

    if (isOrgUserManager({ user: targetUser, orgId })) {
      return {
        ...readOnlyFieldState,
        password: HIDDEN,
        preferredUsername: HIDDEN,
        facilityIds: DISABLED,
      }
    }

    return fullAccessFieldState
  }

  if (isDirector({ user: currentUser, orgId })) {
    if (isSelf) {
      return {
        ...fullAccessFieldState,
        name: DISABLED,
        email: DISABLED,
        role: DISABLED,
        facilityIds: DISABLED,
      }
    }

    if (
      isDirector({ user: targetUser, orgId }) ||
      isOrgAdmin({ user: targetUser, orgId }) ||
      isOrgUserManager({ user: targetUser, orgId })
    ) {
      return {
        ...readOnlyFieldState,
        password: HIDDEN,
        preferredUsername: HIDDEN,
      }
    }

    return fullAccessFieldState
  }

  if (isFacilityUserManager({ user: currentUser, orgId })) {
    if (isSelf) {
      return {
        ...fullAccessFieldState,
        facilityIds: DISABLED,
        role: HIDDEN,
      }
    }

    if (isDirector({ user: targetUser, orgId })) {
      return {
        ...fullAccessFieldState,
        facilityIds: DISABLED,
        role: DISABLED,
        password: HIDDEN,
      }
    }

    if (
      isOrgAdmin({ user: targetUser, orgId }) ||
      isOrgUserManager({ user: targetUser, orgId }) ||
      isFacilityUserManager({ user: targetUser, orgId })
    ) {
      return {
        ...readOnlyFieldState,
        facilityIds: DISABLED,
        password: HIDDEN,
        preferredUsername: HIDDEN,
      }
    }

    return {
      ...fullAccessFieldState,
      facilityIds: DISABLED,
    }
  }

  return readOnlyFieldState
}
