import {
  ContactPoint,
  ContactPoint_ContactPointUse,
} from '@augusthealth/models/com/august/protos/contact_point'
import { ChangeEvent, useContext, useRef, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { createFacility, mergePatchFacility } from '@shared/api/facility'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { BasicInput } from '@shared/components/BasicInput/BasicInput'
import {
  LabelAboveInput,
  requiredLabel,
  requiredWhenError,
} from '@shared/components/Labels'
import { CreatableStyledSelect } from '@shared/components/Selects/StyledSelect'
import {
  EmailInput,
  FaxInput,
  PhoneInput,
} from '@shared/components/TextInputWithIcon/TextInputWithIcon'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { Facility } from '@shared/types/facility'
import { Organization } from '@shared/types/organization'
import {
  formatSingleLineAddress,
  getAddressFromGeocoder,
  getAddressPartFromGeocoder,
} from '@shared/utils/address'
import {
  getEmailValue,
  getFaxValue,
  getPhoneValue,
} from '@shared/utils/contactPoint'
import { removeEmptyPaymentSubscription } from '@shared/utils/payment'
import { EMAIL_REGX } from '@shared/utils/regex'
import {
  getCountryCode,
  getCountryName,
  getTimeZoneOptions,
} from '@shared/utils/Timezones/utils'
import { isSuperUser as getIsSuperUser } from '@shared/utils/user'
import usePlacesAutocomplete from '@app/hooks/usePlacesAutocomplete'
import SuperUserSettings from '@app/pages/Organizations/Settings/CommunitiesPage/FacilityFormPopup/SuperUserSettings'
import { FacilityForm } from '@app/pages/Organizations/Settings/CommunitiesPage/FacilityFormPopup/types'
import {
  getOnEmailChange,
  getOnFaxChange,
  getOnOmbudsmanPhoneChange,
  getOnPhoneChange,
} from './helpers'

type Props = {
  facility: Facility | undefined
  organization: Organization
  onClose: () => void
  onSave: () => Promise<void>
}

export default function FacilityFormPopup(props: Props) {
  const { facility: defaultF, organization, onClose, onSave } = props
  const { setError } = useContext(GlobalContext)
  const { user } = useUserContext()
  const isSuperUser = getIsSuperUser(user)
  const methods = useForm<FacilityForm>({
    defaultValues: defaultF,
  })
  const {
    control,
    handleSubmit,
    getValues,
    register,
    setError: setFormError,
    setValue,
    formState,
    formState: { errors },
  } = methods
  const isEditMode = defaultF !== undefined
  const [addressPlainText, setAddressPlainText] = useState<string | null>(null)
  const [country, setCountry] = useState<string>(
    getCountryName(defaultF?.timeZone)
  )

  const inputRef = useRef<HTMLInputElement>(null)
  usePlacesAutocomplete({
    inputRef,
    callback: (place) => {
      const addressComponents = place?.address_components || []
      const address = getAddressFromGeocoder(addressComponents)
      const newCountry = getAddressPartFromGeocoder(
        addressComponents,
        'country'
      )

      setValue('address', address)
      setAddressPlainText(null)
      if (newCountry !== country) {
        setCountry(newCountry)
        setValue('timeZone', '')
      }
    },
  })

  const highlightErrorInput = (fieldName: string) => {
    const emailCollection = document.getElementsByName(fieldName)
    if (emailCollection.length) {
      emailCollection[0].focus()
    }
  }

  const onSubmit = async (formData: FacilityForm) => {
    const email = getEmailValue(formData.telecom)

    if (addressPlainText?.trim().length === 0) {
      setFormError('address', { message: 'Required Field' })
      highlightErrorInput('address')
    } else if (email && !EMAIL_REGX.test(email)) {
      setFormError('telecom', { message: 'Invalid Email' })
      highlightErrorInput('email')
    }

    try {
      const facility = removeEmptyPaymentSubscription({
        id: defaultF?.id,
        orgId: organization.id,
        ...formData,
      })

      if (addressPlainText && addressPlainText.trim().length > 0) {
        facility.address = {
          text: addressPlainText,
          // @ts-ignore
          line: null,
          // @ts-ignore
          city: null,
          // @ts-ignore
          state: null,
          // @ts-ignore
          postalCode: null,
        }
      }

      facility.emarSettings = {
        isActive: formData.emarSettings?.isActive ?? false,
        goLiveMedPassDate: formData.emarSettings?.isActive
          ? formData.emarSettings.goLiveMedPassDate
          : undefined,
        usesStrictTiming: formData.emarSettings?.usesStrictTiming ?? false,
      }

      facility.billingSettings = {
        isActive: formData.billingSettings?.isActive ?? false,
        goLiveDate: formData.billingSettings?.isActive
          ? formData.billingSettings.goLiveDate
          : undefined,
      }

      facility.careAppSettings = {
        chartingType: formData.careAppSettings?.chartingType,
        isActive: formData.careAppSettings?.isActive ?? false,
        goLiveDate: formData.careAppSettings?.isActive
          ? formData.careAppSettings.goLiveDate
          : undefined,
      }

      const {
        emarSettings,
        billingSettings,
        careAppSettings,
        ...primaryFacilityData
      } = formData

      const writeableFacilityData = isSuperUser ? facility : primaryFacilityData

      if (defaultF) {
        await mergePatchFacility({
          facility: defaultF,
          patch: writeableFacilityData,
        })
      } else {
        await createFacility({
          facility,
          orgId: organization.id,
        })
      }
      await onSave()
    } catch (e) {
      setError(e)
    }
  }

  const onErrors = (errors: object) => {
    // UI Controller may not be focused automatically
    // So need to focus here
    const keys = Object.keys(errors)
    if (keys.length) {
      highlightErrorInput(keys[0])
    }
  }

  const title = isEditMode ? 'Edit Community' : 'Add Community'
  const timeZoneOptions = getTimeZoneOptions(
    country || getCountryCode(getValues('timeZone'))
  )

  return (
    <AnimatedPopup title={title}>
      <form className="mt-[32px]">
        <div className="mt-6">
          <LabelAboveInput
            htmlFor="name"
            subLabel={requiredLabel(Boolean(errors.name))}
          >
            Name
          </LabelAboveInput>
          <BasicInput {...register('name', { required: true })} />
        </div>
        {isSuperUser && (
          <>
            <div className="mt-6">
              <LabelAboveInput htmlFor="legalName">Legal Name</LabelAboveInput>
              <BasicInput {...register('legalName')} />
            </div>
            <div className="mt-6">
              <LabelAboveInput htmlFor="longName">Long Name</LabelAboveInput>
              <BasicInput {...register('longName')} />
            </div>
          </>
        )}
        <div className="mt-6">
          <LabelAboveInput
            htmlFor="address"
            subLabel={requiredLabel(Boolean(errors.address))}
          >
            Address
          </LabelAboveInput>
          <Controller
            name="address"
            control={control}
            render={({ field: { value } }) => (
              <BasicInput
                name="address"
                defaultValue={formatSingleLineAddress(value)}
                placeholder="Enter Street, City, Zip"
                ref={inputRef}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value?.trim()
                  setAddressPlainText(value)
                  setValue('address.text', value)
                }}
              />
            )}
            rules={{ required: true }}
          />
        </div>
        <div className="mt-6">
          <LabelAboveInput
            htmlFor="input-timeZone"
            subLabel={requiredLabel(Boolean(errors.timeZone))}
          >
            Timezone
          </LabelAboveInput>
          <Controller
            control={control}
            name="timeZone"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <CreatableStyledSelect
                id="timeZone"
                instanceId="timeZone"
                name="timeZone"
                onChange={(opt?: { value: any }) =>
                  onChange(opt?.value || null)
                }
                options={timeZoneOptions}
                placeholder="Select a timezone..."
                value={
                  timeZoneOptions.find((opt) => opt.value === value) || null
                }
                isClearable={false}
              />
            )}
          />
        </div>
        <div className="mt-6">
          <LabelAboveInput htmlFor="licenseNumber">
            License Number
          </LabelAboveInput>
          <BasicInput {...register('stateLicenseNumber')} />
        </div>
        <Controller
          name="telecom"
          control={control}
          render={({ field: { onChange, value: telecom = [] } }) => {
            const onTelecomChange = (t?: ContactPoint[]) => {
              // Convert empty array to undefined
              return onChange(t && t.length ? t : undefined)
            }
            return (
              <>
                <div className="mt-6">
                  <LabelAboveInput
                    htmlFor="email"
                    subLabel={requiredWhenError(Boolean(errors.telecom))}
                    errorMessage="Invalid format"
                  >
                    Email
                  </LabelAboveInput>
                  <EmailInput
                    inputProps={{
                      name: 'email',
                      onChange: getOnEmailChange({ telecom, onTelecomChange }),
                      defaultValue: getEmailValue(telecom),
                    }}
                  />
                </div>
                <div className="mt-6">
                  <LabelAboveInput htmlFor="phone">Phone</LabelAboveInput>
                  <PhoneInput
                    inputProps={{
                      name: 'phone',
                      onChange: getOnPhoneChange({
                        onTelecomChange,
                        telecom,
                        use: ContactPoint_ContactPointUse.CONTACT_POINT_USE_WORK,
                      }),
                      value: getPhoneValue(telecom),
                    }}
                  />
                </div>
                <div className="mt-6">
                  <LabelAboveInput htmlFor="fax">Fax</LabelAboveInput>
                  <FaxInput
                    inputProps={{
                      name: 'fax',
                      onChange: getOnFaxChange({ telecom, onTelecomChange }),
                      value: getFaxValue(telecom),
                    }}
                  />
                </div>
              </>
            )
          }}
        />
        <div className="mt-6">
          <LabelAboveInput
            htmlFor="ombudsman"
            subLabel={requiredLabel(Boolean(errors.ombudsman))}
          >
            Ombudsman
          </LabelAboveInput>
          <Controller
            name="ombudsman"
            control={control}
            render={({ field: { onChange, value: ombudsman = {} } }) => {
              return (
                <PhoneInput
                  inputProps={{
                    name: 'ombudsman',
                    onChange: getOnOmbudsmanPhoneChange({
                      ombudsman,
                      onChange,
                      use: ContactPoint_ContactPointUse.CONTACT_POINT_USE_WORK,
                    }),
                    placeholder: 'Phone Number',
                    value: getPhoneValue(ombudsman?.telecom),
                  }}
                />
              )
            }}
            rules={{ required: true }}
          />
        </div>
        {isSuperUser && (
          <FormProvider {...methods}>
            <SuperUserSettings />
          </FormProvider>
        )}
        <div className={'mt-6'}>
          <AnimatedPopupFormFooter
            yesBtn={{
              props: {
                onClick: handleSubmit(onSubmit, onErrors),
                id: 'saveFacilityButton',
              },
            }}
            noBtn={{
              label: 'Cancel',
              action: onClose,
              props: { id: 'cancelFacilityButton' },
            }}
            formState={formState}
          />
        </div>
      </form>
    </AnimatedPopup>
  )
}
