import classNames from 'classnames'
import { HTMLAttributes, useRef } from 'react'
import {
  BasicInput,
  BasicInputProps,
} from '@shared/components/BasicInput/BasicInput'
import { Switch, SwitchProps } from '@app/components/Switch'
import styles from './styles.module.css'

type Props = {
  inputProps: BasicInputProps
  switchProps?: SwitchProps
  trashIconProps?: HTMLAttributes<HTMLButtonElement>
  holderClassName?: string
}

export default function DeletableInput(props: Props) {
  const inputRef = useRef(null)
  const { holderClassName, inputProps, switchProps, trashIconProps } = props
  let switchHolder
  if (switchProps) {
    switchHolder = <Switch holderClassName="floating-switch" {...switchProps} />
  }
  let trashIcon
  if (trashIconProps) {
    trashIcon = (
      <button
        data-testid={`trash-icon-${inputProps.name}`}
        className={styles.iconContainer}
        type="button"
        {...trashIconProps}
      >
        <i className="fas fa-fw fa-trash-can" />
      </button>
    )
  }

  const classNameList = classNames(styles.inputContainer, holderClassName)

  const { className, ...restInputProps } = inputProps
  const inputClasses = classNames(className, {
    [styles.inputWithSwitch]: !!switchProps,
    [styles.inputWithDelete]: !switchProps && trashIconProps,
  })

  return (
    <div className={classNameList}>
      <BasicInput ref={inputRef} {...restInputProps} className={inputClasses} />
      {switchHolder}
      {trashIcon}
    </div>
  )
}
