import { UserReference } from '@augusthealth/models/com/august/protos/annotation'
import { add, isAfter, isSameMinute, isToday, sub } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { groupBy, maxBy } from 'lodash'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import ErrorMonitoring from '@shared/ErrorMonitoring'
import { DateAndTime } from '@shared/types/date'
import { DosageV2 } from '@shared/types/dosage'
import { DeviationAdministration } from '@shared/types/dosage_deviation'
import {
  AdministrationStatus,
  MedAdministrationEvent,
  MedicationAdministration,
  ProgressAdministration,
  ReasonForException,
  ReasonForOutOfSchedule,
} from '@shared/types/medication_administration'
import { MedicationOrder } from '@shared/types/medication_order'
import { UserAccount } from '@shared/types/user'
import {
  formatDateTimeInZone,
  fromDateTimeToDate,
  fromDateTimeToDateInTimezone,
} from '@shared/utils/date'
import { getCurrentZonedTimeAtFacility } from '@shared/utils/facilities'
import { getFullName } from '@shared/utils/humanName'
import {
  orderIsMealDependent,
  orderSpecifiesEmptyStomach,
} from '@shared/utils/medicationOrder'
import { getDosageUnitDisplay } from '@shared/utils/medicationStatement'
import {
  validStringOrNull,
  validStringOrUndefined,
} from '@shared/utils/parsing'
import pluralize from '@shared/utils/pluralize'
import { addEvent, updateMedicationAdministration } from '@emar/db/emar'
import { AdministerablePrn } from '@emar/hooks/useDeferredAdministerablePrns'
import { AdministrationWithOrder } from '@emar/types/administration_with_order'
import { MedicationAdministrationRow } from '@emar/types/db'

export const unadministerMedAdministration = async ({
  user,
  administration,
  note,
}: {
  user: UserAccount
  administration: MedicationAdministrationRow
  note: string | null
}): Promise<void> => {
  const unadministeredStatus =
    administration.administrationType === 'prn'
      ? AdministrationStatus.ADMINISTRATION_STATUS_ENTERED_IN_ERROR
      : AdministrationStatus.ADMINISTRATION_STATUS_UNSPECIFIED
  return updateMedicationAdministration({
    id: administration.id,
    status: unadministeredStatus,
    reasonForException: ReasonForException.REASON_FOR_EXCEPTION_UNSPECIFIED,
    occurrence: null,
    performer: null,
    note: validStringOrNull(note),
    injectionOrApplicationSiteNote: null,
    events: addEvent({
      user,
      currentEvents: administration.events,
      eventDetails: {
        unadministered: { note: validStringOrUndefined(note) },
      },
    }),
  })
}
export const getLatestAdministrationEvent = (
  administration: Pick<
    MedicationAdministrationRow,
    'administrationType' | 'events'
  >
): MedAdministrationEvent | undefined => {
  const administrationKey =
    administration.administrationType === 'prn'
      ? 'prnAdministered'
      : 'administered'
  const administeredEvents = administration.events.filter(
    (event) => event[administrationKey]
  )
  const latestAdministration = administeredEvents.length
    ? administeredEvents[administeredEvents.length - 1]
    : null

  if (latestAdministration) {
    const unadministeredEvents = administration.events.filter(
      (event) => event.unadministered
    )

    const unadministeredAfterLatest = unadministeredEvents.find((event) =>
      isAfter(
        new Date(event.occurredAt),
        new Date(latestAdministration.occurredAt)
      )
    )

    return unadministeredAfterLatest ? undefined : latestAdministration
  }

  return undefined
}

export const getAdministeredBy = (
  administration: Pick<
    MedicationAdministrationRow,
    'administrationType' | 'events' | 'performer'
  >
): string | null => {
  const event = getLatestAdministrationEvent(administration)

  let administeredBy: UserReference | null = null

  if (event) {
    const administrationKey =
      administration.administrationType === 'prn'
        ? 'prnAdministered'
        : 'administered'
    administeredBy = event[administrationKey]?.administeredBy ?? null

    if (administeredBy && administration.performer) {
      return getFullName(administration.performer.userName)
    } else if (administeredBy) {
      ErrorMonitoring.capture({
        level: 'error',
        error: `Performer not set on administration when event notes retroactive administration by userID ${administeredBy.userId}`,
      })
      return null
    }
  }

  return null
}

export const getAdministeredAt = ({
  administration,
  use24HourClock,
  facilityTimeZone,
}: {
  administration: Pick<
    MedicationAdministrationRow,
    'administrationType' | 'events'
  >
  use24HourClock: boolean
  facilityTimeZone: string
}): string | null => {
  const event = getLatestAdministrationEvent(administration)

  let administeredAt: string | null = null

  if (event) {
    const administrationKey =
      administration.administrationType === 'prn'
        ? 'prnAdministered'
        : 'administered'
    administeredAt = event[administrationKey]?.administeredAt ?? null

    if (administeredAt) {
      const format = `${use24HourClock ? 'HH:mm' : 'h:mm aa'} 'on' MMM dd, yyyy`
      return formatInTimeZone(administeredAt, facilityTimeZone, format)
    }
  }

  return null
}
export const getLatestMarkedAsExceptionEvent = (
  administration: MedicationAdministrationRow
): MedAdministrationEvent | undefined => {
  const latestMarkedAsExceptionEvent = maxBy(
    administration.events.filter((event) => event.markedAsException),
    (event: MedAdministrationEvent) => event.occurredAt
  )
  const latestUnmarkedAsExceptionEvent = maxBy(
    administration.events.filter((event) => event.unmarkedAsException),
    (event: MedAdministrationEvent) => event.occurredAt
  )

  if (latestUnmarkedAsExceptionEvent && latestMarkedAsExceptionEvent) {
    // only return the event if it has not been unmarked as an exception
    return isAfter(
      new Date(latestMarkedAsExceptionEvent.occurredAt),
      new Date(latestUnmarkedAsExceptionEvent.occurredAt)
    )
      ? latestMarkedAsExceptionEvent
      : undefined
  }

  return latestMarkedAsExceptionEvent
}

export const isPrn = ({
  administration,
}: {
  administration: Pick<MedicationAdministrationRow, 'administrationType'>
}) => administration.administrationType === 'prn'

export const isVitalAdministration = (
  administration: MedicationAdministrationRow | AdministerablePrn
): administration is MedicationAdministrationRow => {
  return (
    'administrationType' in administration &&
    administration.administrationType === 'scheduled-vital'
  )
}

export const isOnHold = ({
  administration,
}: {
  administration: MedicationAdministrationRow | MedicationAdministration
}) =>
  administration.status === AdministrationStatus.ADMINISTRATION_STATUS_ON_HOLD

export const administrationIsAdministered = (
  administration: Pick<MedicationAdministrationRow, 'status'>
) => {
  return (
    administration.status ===
    AdministrationStatus.ADMINISTRATION_STATUS_ADMINISTERED
  )
}

export const administrationIsTimeDependent = (
  administration: MedicationAdministrationRow | DeviationAdministration
): boolean => {
  if (
    administration.scheduled?.beginScheduledTime &&
    administration.scheduled.endScheduledTime
  ) {
    return isSameMinute(
      fromDateTimeToDate(administration.scheduled.beginScheduledTime) as Date,
      fromDateTimeToDate(administration.scheduled.endScheduledTime) as Date
    )
  }

  return false
}

export const administrationIsMarkedAsException = (
  administration: Pick<MedicationAdministrationRow, 'status'>
): boolean => {
  return (
    administration.status ===
    AdministrationStatus.ADMINISTRATION_STATUS_NOT_ADMINISTERED
  )
}

export const administrationIsOnHold = (
  administration: Pick<MedicationAdministrationRow, 'status'>
): boolean => {
  return (
    administration.status === AdministrationStatus.ADMINISTRATION_STATUS_ON_HOLD
  )
}

export const administrationIsOutsideAcceptableWindow = (
  administration: MedicationAdministrationRow | ProgressAdministration,
  facilityTz: string
): boolean => {
  return (
    administrationIsEarly(administration, facilityTz) ||
    administrationIsOverdue(administration, facilityTz)
  )
}
export const administrationIsEarly = (
  administration: MedicationAdministrationRow | ProgressAdministration,
  facilityTz: string
): boolean => {
  const nowAtFacility = getCurrentZonedTimeAtFacility(facilityTz)
  const dueBy = administrationStartByDate(administration, facilityTz)
  const dueByWithWindow = dueBy ? sub(dueBy, { hours: 1 }) : undefined

  return (
    Boolean(dueByWithWindow && nowAtFacility <= dueByWithWindow) &&
    !administrationIsAdministered(administration) &&
    !administrationIsMarkedAsException(administration) &&
    !administrationIsOnHold(administration)
  )
}

export const administrationIsOverdue = (
  administration: MedicationAdministrationRow | ProgressAdministration,
  facilityTz: string
): boolean => {
  const nowAtFacility = getCurrentZonedTimeAtFacility(facilityTz)
  const dueBy = administrationDueByDate(administration, facilityTz)
  const dueByWithWindow = dueBy ? add(dueBy, { hours: 1 }) : undefined

  return (
    Boolean(dueByWithWindow && dueByWithWindow <= nowAtFacility) &&
    !administrationIsAdministered(administration) &&
    !administrationIsMarkedAsException(administration) &&
    !administrationIsOnHold(administration)
  )
}

export const administrationNeedsAction = (
  administration: MedicationAdministrationRow
) =>
  administration.status ===
  AdministrationStatus.ADMINISTRATION_STATUS_UNSPECIFIED

export const administrationStartByDate = (
  administration: MedicationAdministrationRow | ProgressAdministration,
  timeZone: string
): Date | undefined => {
  return administration.scheduled?.beginScheduledTime?.date &&
    administration.scheduled.beginScheduledTime.time
    ? fromDateTimeToDateInTimezone(
        administration.scheduled.beginScheduledTime,
        timeZone
      )
    : undefined
}

export const administrationDueByDate = (
  administration: MedicationAdministrationRow | ProgressAdministration,
  timeZone: string
): Date | undefined => {
  return administration.scheduled?.endScheduledTime?.date &&
    administration.scheduled.endScheduledTime.time
    ? fromDateTimeToDateInTimezone(
        administration.scheduled.endScheduledTime,
        timeZone
      )
    : undefined
}

const genericDoseDescriptor = ({
  value = 1,
  unit = 'Dose',
}: {
  value?: number
  unit?: string
}) => {
  return pluralize(getDosageUnitDisplay(unit), value, true)
}
export const administrationDoseDescriptor = (
  administration: Pick<MedicationAdministrationRow, 'dosage'>
): string => {
  const value = administration.dosage.dose?.value
  const unit = administration.dosage.dose?.unit

  return genericDoseDescriptor({ value, unit })
}

export const prnMedicationDoseDescriptor = (dose: DosageV2) => {
  const value = dose.doseAndRate?.doseQuantity?.value
  const unit = dose.doseAndRate?.doseQuantity?.unit

  return genericDoseDescriptor({ value, unit })
}
export const medicationAdministrationExceptionOptions = (
  { isVital }: { isVital?: boolean } = { isVital: false }
): Array<OptionTypeBase<ReasonForException>> => [
  {
    value: ReasonForException.REASON_FOR_EXCEPTION_OUT_OF_FACILITY,
    label: 'Out of facility',
  },
  {
    value: ReasonForException.REASON_FOR_EXCEPTION_RESIDENT_REFUSED,
    label: 'Resident refused',
  },
  {
    value: ReasonForException.REASON_FOR_EXCEPTION_GIVEN_BY_HOME_HEALTH,
    label: 'Given by Home Health',
  },
  {
    value:
      ReasonForException.REASON_FOR_EXCEPTION_GIVEN_TO_FAMILY_TO_GIVE_LATER,
    label: 'Given to family to give later',
  },
  {
    value: ReasonForException.REASON_FOR_EXCEPTION_MEDICATION_NOT_AVAILABLE,
    label: 'Medication not available',
    isDisabled: isVital,
  },
  {
    value: ReasonForException.REASON_FOR_EXCEPTION_WITHHELD_PER_DOCTORS_REQUEST,
    label: "Withheld per RN/Doctor's request",
  },
  {
    value: ReasonForException.REASON_FOR_EXCEPTION_CHANGING_SCHEDULE,
    label: `Changing ${isVital ? 'vital' : 'medication'} schedule`,
  },
  {
    value:
      ReasonForException.REASON_FOR_EXCEPTION_HELD_FOR_CONDITIONAL_PARAMETERS,
    label: 'Medication held due to conditional parameters',
    isDisabled: true,
  },
  {
    value: ReasonForException.REASON_FOR_EXCEPTION_OTHER,
    label: 'Other',
  },
]

export const outOfWindowAdministrationReasonOptions: Array<
  OptionTypeBase<ReasonForOutOfSchedule>
> = [
  {
    label: 'Early or late entry',
    value: ReasonForOutOfSchedule.REASON_FOR_OUT_OF_SCHEDULE_LATE_ENTRY,
  },
  {
    label: 'Resident appointment',
    value:
      ReasonForOutOfSchedule.REASON_FOR_OUT_OF_SCHEDULE_RESIDENT_APPOINTMENT,
  },
  {
    label: 'Wifi not working',
    value: ReasonForOutOfSchedule.REASON_FOR_OUT_OF_SCHEDULE_WIFI_NOT_WORKING,
  },
  {
    label: 'Charting system not working',
    value:
      ReasonForOutOfSchedule.REASON_FOR_OUT_OF_SCHEDULE_CHARTING_SYSTEM_NOT_WORKING,
  },
]

export const labelForExceptionReason = (
  reason: ReasonForException | undefined
): string => {
  const option = medicationAdministrationExceptionOptions().find(
    (option) => option.value === reason
  )
  return option ? option.label : 'No reason specified'
}

type NoteworthyGrouping =
  | 'markedAsException'
  | 'timeDependent'
  | 'mealDependentEmptyStomach'
  | 'mealDependentWithFood'
  | 'overdue'
  | 'notNoteworthy'

export const sortUnadministeredMedsIntoSummaryGroups = (
  administrationsWithOrders: AdministrationWithOrder[],
  timeZone: string
) => {
  const groupedBySection = groupBy(
    administrationsWithOrders,
    (administrationAndOrder): NoteworthyGrouping => {
      if (
        administrationIsMarkedAsException(administrationAndOrder.administration)
      ) {
        return 'markedAsException'
      } else if (
        administrationIsOverdue(administrationAndOrder.administration, timeZone)
      ) {
        return 'overdue'
      } else if (orderIsMealDependent(administrationAndOrder.order)) {
        return orderSpecifiesEmptyStomach(administrationAndOrder.order)
          ? 'mealDependentEmptyStomach'
          : 'mealDependentWithFood'
      } else if (
        administrationIsTimeDependent(administrationAndOrder.administration)
      ) {
        return 'timeDependent'
      } else {
        return 'notNoteworthy'
      }
    }
  ) as Record<NoteworthyGrouping, AdministrationWithOrder[] | undefined>

  const {
    markedAsException,
    timeDependent,
    mealDependentEmptyStomach,
    mealDependentWithFood,
    overdue,
    notNoteworthy,
  } = groupedBySection

  return {
    markedAsException: markedAsException?.sort(
      sortMedicationAdministrationScheduledStartThenNameAlphabetical
    ),
    timeDependent: timeDependent?.sort(
      sortMedicationAdministrationScheduledStartThenNameAlphabetical
    ),
    mealDependentEmptyStomach: mealDependentEmptyStomach?.sort(
      sortMedicationAdministrationScheduledStartThenNameAlphabetical
    ),
    mealDependentWithFood: mealDependentWithFood?.sort(
      sortMedicationAdministrationScheduledStartThenNameAlphabetical
    ),
    overdue: overdue?.sort(
      sortMedicationAdministrationScheduledStartThenNameAlphabetical
    ),
    notNoteworthy: notNoteworthy?.sort(
      sortMedicationAdministrationScheduledStartThenNameAlphabetical
    ),
  }
}

/**
 * Sorts medication administrations for list and person views
 * Priority order for scheduled medications: Time Dependent Meds > Start of scheduled time/window > Medication Name
 * Priority order for PRN follow-ups: Occurrence time of med > Medication Name
 * @param medA
 * @param medB
 */
export const sortMedicationAdministrationScheduledStartThenNameAlphabetical = (
  medA: AdministrationWithOrder,
  medB: AdministrationWithOrder
) => {
  // prioritize meds that are time dependent first
  const medAIsTimeDependent = administrationIsTimeDependent(medA.administration)
  const medBIsTimeDependent = administrationIsTimeDependent(medB.administration)

  if (medAIsTimeDependent && !medBIsTimeDependent) {
    return -1
  } else if (medBIsTimeDependent && !medAIsTimeDependent) {
    return 1
  }

  let medATime
  let medBTime

  if (
    medA.administration.administrationType === 'prn' &&
    medB.administration.administrationType === 'prn'
  ) {
    // comparing PRN follow-ups; look at occurrence time to determine order
    medATime = new Date(medA.administration.occurrence as string)
    medBTime = new Date(medB.administration.occurrence as string)
  } else {
    // comparing scheduled administrations; look at the beginScheduledTime to determine order
    medATime = fromDateTimeToDate(
      medA.administration.scheduled?.beginScheduledTime as DateAndTime
    ) as Date
    medBTime = fromDateTimeToDate(
      medB.administration.scheduled?.beginScheduledTime as DateAndTime
    ) as Date
  }

  // if meds are both time dependent, compare relevant times
  if (medATime < medBTime) {
    return -1
  } else if (medATime > medBTime) {
    return 1
  }

  // if meds are identical in time/scheduling, sort by the med name
  return sortMedicationOrderByNameAlphabetical(medA.order, medB.order)
}

export const sortMedicationOrderByNameAlphabetical = (
  medA: MedicationOrder,
  medB: MedicationOrder
) => {
  const nameA =
    medA.medicationStatement.medication?.drugName ?? ''.toUpperCase()
  const nameB =
    medB.medicationStatement.medication?.drugName ?? ''.toUpperCase()
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }

  return 0
}

const canBeAdministered = ({
  administration,
}: {
  administration: MedicationAdministrationRow
}): boolean =>
  administration.status ===
  AdministrationStatus.ADMINISTRATION_STATUS_UNSPECIFIED

export const isUnAdministeredScheduledMedication = (
  administration: MedicationAdministrationRow
) => {
  return !isOnHold({ administration }) && canBeAdministered({ administration })
}

export const getFormattedOccurrenceTime = ({
  administration,
  use24HourClock,
  timeZone,
}: {
  administration: MedicationAdministrationRow
  use24HourClock: boolean
  timeZone: string
}) => {
  if (administration.occurrence) {
    return formatDateTimeInZone(administration.occurrence, timeZone, {
      use24HourClock,
      includeDate: !isToday(new Date(administration.occurrence)),
      dateFormat: 'PPP ',
    })
  }

  return 'Unknown'
}
