import { Option } from 'effect'
import {
  CareAppPerson,
  RoutineAdministration,
  RoutineAdministrationProgressType,
} from '@shared/types/careapp'
import { filterAndSortCarePeopleWithAdministrations } from '@shared/utils/personWithAdministrations'
import { tw } from '@shared/utils/tailwind'
import ResidentRoutinesCard from './ResidentRoutinesCard'

export default function RoutineResidentCardList({
  careAppPeople,
  routineAdministrations,
  facilityTimeZone,
  searchText,
  selectedProgressTypes,
  reload,
}: {
  careAppPeople: CareAppPerson[]
  routineAdministrations: RoutineAdministration[]
  facilityTimeZone: string
  searchText: string
  selectedProgressTypes: RoutineAdministrationProgressType[]
  reload: () => void
}) {
  const filteredPeople = filterAndSortCarePeopleWithAdministrations({
    people: careAppPeople,
    shiftOccurrenceAdministrations: routineAdministrations,
    careGroupFilter: Option.none(), // Care Group will be filtered by API instead from Frontend
    searchText,
    noPeopleWithoutAdministrations: true,
    routineAdministrationProgressTypes: selectedProgressTypes,
    facilityTimeZone,
  })

  if (filteredPeople.length === 0) {
    return (
      <div className={tw`mt-1 text-center text-[14px] text-secondary-07`}>
        {searchText
          ? 'No people or room matching the search filter: '
          : 'Resident not found'}
        {searchText && (
          <code
            className={tw`font-semibold leading-[16px] text-august-primary`}
          >
            {searchText}
          </code>
        )}
      </div>
    )
  }

  return (
    <>
      {filteredPeople.map(({ person, administrations }) => {
        return (
          <ResidentRoutinesCard
            key={`person-card-${person.id}`}
            careAppPerson={person}
            routineAdministrations={administrations}
            facilityTimeZone={facilityTimeZone}
            reload={reload}
          />
        )
      })}
    </>
  )
}
