import { useContext, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import GlobalContext from '@shared/contexts/GlobalContext'
import { InternalErrorCode } from '@shared/utils/error'
import styles from './styles.module.css'

interface Props {
  onFileChange: (files: File[]) => void
  uploadInProgress: boolean
  defaultButtonText: string
}

function filesHaveInternalErrorCode({
  fileRejections,
  internalErrorCode,
}: {
  fileRejections: FileRejection[]
  internalErrorCode: InternalErrorCode
}) {
  return fileRejections.some((file) =>
    file.errors.some((err) => err.code === internalErrorCode)
  )
}

export default function DocumentUploadZone({
  onFileChange,
  uploadInProgress,
  defaultButtonText,
}: Props) {
  const { setError } = useContext(GlobalContext)
  const [files, setFiles] = useState<File[]>()

  const onDeleteFile = (index: number) => {
    const newFiles = files!.filter((_f, i) => i !== index)
    setFiles(newFiles)
    onFileChange(newFiles)
  }

  const onDrop = (newFiles: File[], fileRejections: FileRejection[]) => {
    if (
      filesHaveInternalErrorCode({
        fileRejections,
        internalErrorCode: InternalErrorCode.FILE_TOO_LARGE,
      })
    ) {
      setError({ internalCode: InternalErrorCode.FILE_TOO_LARGE })
    } else if (
      filesHaveInternalErrorCode({
        fileRejections,
        internalErrorCode: InternalErrorCode.FILE_TOO_SMALL,
      })
    ) {
      setError({ internalCode: InternalErrorCode.FILE_TOO_SMALL })
    }

    if (newFiles.length > 0) {
      if (files) {
        setFiles([...files, ...newFiles])
        onFileChange([...files, ...newFiles])
      } else {
        setFiles(newFiles)
        onFileChange(newFiles)
      }
    }
  }

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
      'image/*': ['.gif', '.jpeg', '.jpg', '.png'],
    },
    onDrop,
    minSize: 1,
    maxSize: 31457280, // 30 MB
  })

  const buttonTextColor = files
    ? { color: 'var(--primary-light)' }
    : { color: 'white' }

  return (
    <div
      className={`${styles.uploadContainer} ${
        isDragActive ? styles.activeDrag : styles.inactiveDrag
      }`}
    >
      <div {...getRootProps()} className={styles.dropzone}>
        {uploadInProgress && <SimpleSpinner className={styles.uploadSpinner} />}
        {!uploadInProgress && (
          <>
            <input
              data-cy="polstFileInput"
              {...getInputProps()}
              data-testid="uploader-input"
            />
            <div className={styles.dropzoneContent}>
              <div className={styles.instructions}>
                Drag a file here or select a file from your computer
              </div>
              {(!files || files.length === 0) && (
                <div className={styles.uploadButton} style={buttonTextColor}>
                  <i className="far fa-cloud-upload mr-[8px]" />
                  <span className={styles.buttonText}>{defaultButtonText}</span>
                </div>
              )}
              {files?.map((file, index) => (
                <div
                  key={`${file.name + index}`}
                  className={styles.uploadButton}
                  style={buttonTextColor}
                >
                  <i className="far fa-cloud-upload mr-[8px]" />
                  <span className={styles.buttonText}>{file.name}</span>
                  <button
                    className={styles.deleteButton}
                    onClick={(e) => {
                      e.stopPropagation()
                      onDeleteFile(index)
                    }}
                  >
                    <i className="fa-solid fa-xmark ml-[8px]" />
                  </button>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
      <div className={styles.actionButtonHolder}>
        {!uploadInProgress && (
          <button className={styles.actionButton} onClick={open} type="button">
            <i className="far fa-cloud-upload mr-[8px]" />
            <span>Add New</span>
          </button>
        )}
      </div>
    </div>
  )
}
