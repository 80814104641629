/* eslint react-hooks/exhaustive-deps: 2 */

import { parseISO } from 'date-fns'
import { DateTime, Option, pipe } from 'effect'
import { useCallback, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom-v5-compat'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import useFacilityCareProgress from '@shared/hooks/useFacilityCareProgress'
import { CareGroup } from '@shared/types/care_group'
import { Facility } from '@shared/types/facility'
import { formatIsoDate } from '@shared/utils/date'
import { tw } from '@shared/utils/tailwind'
import Content from '@app/components/generic/Content'
import PersonPageTitle from '@app/components/PersonPageTitle'
import Warning from '@app/components/Warning'
import PageLoaded from './PageLoaded'

const ISO_DATE_SEARCH_PARAM_KEY = 'date'
const CARE_GROUP_ID_SEARCH_PARAM_KEY = 'careGroupId'
const SHIFT_ID_SEARCH_PARAM_KEY = 'shiftId'

export default function Routines({ facility }: { facility: Facility }) {
  const { timeZone } = facility
  const [searchParams, setSearchParams] = useSearchParams()

  const isoDate = searchParams.get(ISO_DATE_SEARCH_PARAM_KEY)
  const setIsoDate = (next: Date) => {
    setSearchParams((prev) => {
      prev.set(ISO_DATE_SEARCH_PARAM_KEY, formatIsoDate(next))
      return prev
    })
  }

  const careGroupId = searchParams.get(CARE_GROUP_ID_SEARCH_PARAM_KEY)
  const setCareGroupId = (optionNext: Option.Option<CareGroup>) => {
    setSearchParams((prev) => {
      Option.match(optionNext, {
        onNone: () => prev.delete(CARE_GROUP_ID_SEARCH_PARAM_KEY),
        onSome: ({ id }) => prev.set(CARE_GROUP_ID_SEARCH_PARAM_KEY, id!),
      })
      return prev
    })
  }

  const shiftId = searchParams.get(SHIFT_ID_SEARCH_PARAM_KEY)
  const setShiftId = useCallback(
    (next: string) => {
      setSearchParams((prev) => {
        prev.set(SHIFT_ID_SEARCH_PARAM_KEY, next)
        return prev
      })
    },
    [setSearchParams]
  )

  useEffect(() => {
    const setIsoDateToCurrentDateAtFacility = () => {
      if (!isoDate) {
        const dateAtFacility = pipe(
          DateTime.makeZoned(new Date(), {
            timeZone,
          }),
          Option.map(DateTime.formatIsoDate),
          Option.getOrThrow
        )

        setSearchParams((prev) => {
          prev.set(ISO_DATE_SEARCH_PARAM_KEY, dateAtFacility)
          return prev
        })
      }
    }

    setIsoDateToCurrentDateAtFacility()
  }, [isoDate, setSearchParams, timeZone])

  if (!isoDate) {
    return <SimpleSpinner />
  }

  return (
    <Wrapper
      isoDate={isoDate}
      facility={facility}
      careGroupId={careGroupId}
      shiftId={shiftId}
      setIsoDate={setIsoDate}
      setCareGroupId={setCareGroupId}
      setShiftId={setShiftId}
    />
  )
}

function Wrapper({
  isoDate,
  facility,
  careGroupId,
  shiftId,
  setIsoDate,
  setCareGroupId,
  setShiftId,
}: {
  isoDate: string
  facility: Facility
  careGroupId: string | null
  shiftId: string | null
  setIsoDate: (date: Date) => void
  setCareGroupId: (optionCareGroupId: Option.Option<CareGroup>) => void
  setShiftId: (shiftId: string) => void
}) {
  const { facilityCareProgress, reloadFacilityCareProgress } =
    useFacilityCareProgress({
      facility,
      date: isoDate,
      careGroupId: careGroupId ?? undefined,
    })

  if (facilityCareProgress.tag === 'Loading') {
    return <SimpleSpinner />
  }

  if (facilityCareProgress.tag === 'Error') {
    const errorMsg =
      facilityCareProgress.value.json?.errors?.[0].message ?? 'Unknown error!'

    return (
      <Content className={tw`mt-[32px]`}>
        <PersonPageTitle title="Care Progress" />
        <Warning>{errorMsg}</Warning>
      </Content>
    )
  }

  const dateInLocalTZ = parseISO(isoDate)

  return (
    <PageLoaded
      facility={facility}
      facilityCareProgress={facilityCareProgress.value}
      reloadFacilityCareProgress={reloadFacilityCareProgress}
      selectedDate={dateInLocalTZ}
      careGroupId={careGroupId}
      shiftId={shiftId}
      setIsoDate={setIsoDate}
      setCareGroupId={setCareGroupId}
      setShiftId={setShiftId}
    />
  )
}
