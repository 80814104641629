import { identity } from 'lodash'
import { useContext, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import { BadgeInput } from '@shared/components/BadgeInputs/BadgeInput'
import { ModalButtons } from '@shared/components/baseMui/Modal/Layout'
import SearchBox from '@shared/components/SearchBox'
import GlobalContext from '@shared/contexts/GlobalContext'
import { PaymentMethod, ResidentListEntry } from '@shared/types/billing'
import { formatCurrencyForBilling } from '@shared/utils/billing'
import { getFullName } from '@shared/utils/humanName'
import { escapeStringRegexp } from '@shared/utils/regex'
import { recordManualPayments } from '@app/api/payments'
import RhfDatePicker from '@app/components/reactHookForm/RhfDateTimePicker'
import {
  BulkPaymentsForm,
  formToRequest,
  getDefaultValues,
  isValidPaymentRow,
} from './helpers'
import { RecordPaymentCard } from './RecordPaymentCard'

export default function RecordPayments({
  onClose,
  residents,
  onFinishPayment,
  paymentMethod,
}: {
  residents: ResidentListEntry[]
  onFinishPayment: () => Promise<void>
  onClose: () => void
  paymentMethod: PaymentMethod
}) {
  const { setError } = useContext(GlobalContext)
  const { orgId, facilityId } = useParams<{
    orgId: string
    facilityId: string
  }>()
  const [search, setSearch] = useState<string>('')
  const methods = useForm<BulkPaymentsForm>({
    defaultValues: getDefaultValues(residents),
    mode: 'onSubmit',
  })
  const { fields } = useFieldArray({
    ...methods,
    name: 'residents',
  })

  async function onSubmit(data: BulkPaymentsForm) {
    try {
      await recordManualPayments({
        facility: {
          id: facilityId,
          orgId,
        },
        data: formToRequest(data, paymentMethod),
      })

      await onFinishPayment()
      onClose()
    } catch (e) {
      setError(e)
    }
  }

  const totalAmount = methods
    .watch('residents')
    .flatMap((r) => r.payments)
    .filter((r) => isValidPaymentRow(r, paymentMethod))
    .reduce((acc, p) => acc + p.amountInCents!, 0)

  const totalPaymentsLabel = match(paymentMethod)
    .with(PaymentMethod.CHECK, () => 'Total Check Payments')
    .with(PaymentMethod.ACH, () => 'Total ACH Payments')
    .with(PaymentMethod.CREDIT_CARD, () => 'Total Credit Card Payments')
    .with(PaymentMethod.DEBIT_CARD, () => 'Total Debit Card Payments')
    .with(PaymentMethod.CASH, () => 'Total Cash Payments')
    .with(PaymentMethod.WIRE, () => 'Total Wire Payments')
    .otherwise(() => 'Unsupported')

  return (
    <form className="flex flex-grow" onSubmit={methods.handleSubmit(onSubmit)}>
      <div className="flex-grow flex-col overflow-y-scroll">
        <FormProvider {...methods}>
          <section className="flex-grow px-[32px]">
            {fields.map((r, i) => {
              /**
               We need the indices to be preserved across renders due to `useFieldArray`
               Hence the `return null` on residents that don't match the search, rather
               than filtering them out
               */
              const name = getFullName(r.resident.name)
              if (
                search &&
                !name.match(new RegExp(escapeStringRegexp(search), 'i'))
              ) {
                return null
              }

              return (
                <RecordPaymentCard
                  paymentMethod={paymentMethod}
                  key={i}
                  index={i}
                  field={r}
                />
              )
            })}
          </section>
        </FormProvider>
      </div>
      <div className="flex flex-col justify-between">
        <div>
          <h1 className="mb-8 text-[24px] font-medium leading-[36px] ">
            Record Payments
          </h1>
          <div className="mb-6 flex flex-col items-center rounded-2xl bg-white p-6 shadow">
            <div className="mb-4 text-[12px] font-semibold uppercase text-gray-07">
              {totalPaymentsLabel}
            </div>
            <h4 className="mb-0 font-semibold">
              {formatCurrencyForBilling(totalAmount)}
            </h4>
          </div>
          {paymentMethod !== PaymentMethod.WIRE && (
            <>
              <BadgeInput
                badgeLabel="Deposit ID"
                placeholder="123456"
                {...methods.register('externalId')}
                className="mb-6"
                showErrorBorder={!!methods.formState.errors.externalId}
                autoComplete="off"
              />
              <RhfDatePicker
                style={{ tag: 'Badge Text', value: 'Deposit Date' }}
                required={true}
                control={methods.control}
                name={'depositDate'}
                labelProps={{ a11yOnly: true }}
                datePickerProps={{
                  convertToDate: identity,
                  convertToSaveValue: identity,
                  wrapperClassName: 'w-full',
                }}
                showErrorBorder={!!methods.formState.errors.depositDate}
              />
            </>
          )}
        </div>
        <div>
          <SearchBox
            onChange={setSearch}
            value={search}
            placeholder="Resident name..."
            holderClassName="w-full mb-6"
          />
          <ModalButtons
            containerClassName="m-0 gap-3"
            confirmProps={{
              disabled: !methods.formState.isDirty,
              isLoading: methods.formState.isSubmitting,
              label: 'Submit',
              id: 'record-payments-submit',
            }}
            cancelProps={{
              onClick: onClose,
              id: 'record-payments-cancel',
            }}
          />
        </div>
      </div>
    </form>
  )
}
