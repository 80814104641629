import Allergies from 'app/components/AugustFields/Allergies'
import Diagnoses from 'app/components/AugustFields/Diagnoses'
import Diet from 'app/components/AugustFields/Diet'
import Badge from '@shared/components/Badge'
import { AugustInitialAppraisal } from '@shared/types/august_initial_appraisal'
import { AugustFieldType } from '@shared/types/custom_field'
import { Person } from '@shared/types/person'
import {
  getAllergies,
  getDiagnoses,
  getDietaryRestrictions,
  getMaritalStatus,
} from '@shared/utils/residentAssessment'
import { tw } from '@shared/utils/tailwind'
import MaritalStatus from '@app/components/AugustFields/MaritalStatus'
import { usePersonInContext } from '@app/hooks/usePerson'

function ReadOnlyBadge({
  badgeLabel,
  textContent,
}: {
  badgeLabel: string
  textContent?: string
}) {
  if (textContent) {
    return (
      <div className={tw`my-[8px] flex items-center`}>
        <Badge
          badgeSize="medium"
          color="primary"
          shape="square"
          className={tw`mr-[8px] uppercase`}
        >
          {badgeLabel}
        </Badge>
        <span className={tw`font-medium`}>{textContent}</span>
      </div>
    )
  }

  return null
}

export default function AugustFieldDetail({
  initialPerson,
  augustField,
  mode,
  assessment,
}: {
  initialPerson: Person
  augustField: AugustFieldType
  mode: 'edit' | 'edit-sans-august-fields' | 'view'
  assessment?: AugustInitialAppraisal
}) {
  const { refreshPerson } = usePersonInContext({
    initialData: initialPerson,
    skipFirstFetch: true,
  })

  if (mode === 'edit-sans-august-fields') {
    return null
  }

  switch (augustField) {
    case AugustFieldType.AUGUST_FIELD_TYPE_ALLERGIES:
      if (mode === 'edit') {
        return <Allergies person={initialPerson} updatePerson={refreshPerson} />
      } else {
        const allergiesData = assessment ? getAllergies(assessment) : undefined

        return (
          <ReadOnlyBadge badgeLabel="Allergies" textContent={allergiesData} />
        )
      }
    case AugustFieldType.AUGUST_FIELD_TYPE_CONDITION:
      if (mode === 'edit') {
        return <Diagnoses person={initialPerson} updatePerson={refreshPerson} />
      } else {
        const diagnosesData = assessment ? getDiagnoses(assessment) : undefined

        return (
          <ReadOnlyBadge badgeLabel="Diagnoses" textContent={diagnosesData} />
        )
      }
    case AugustFieldType.AUGUST_FIELD_TYPE_DIETARY_RESTRICTIONS:
      if (mode === 'edit') {
        return <Diet person={initialPerson} updatePerson={refreshPerson} />
      } else {
        const dietData = assessment
          ? getDietaryRestrictions(assessment)
          : undefined

        return <ReadOnlyBadge badgeLabel="Diet" textContent={dietData} />
      }
    case AugustFieldType.AUGUST_FIELD_TYPE_MARITAL_STATUS:
      if (mode === 'edit') {
        return (
          <MaritalStatus person={initialPerson} updatePerson={refreshPerson} />
        )
      } else {
        const martialStatusData = assessment
          ? getMaritalStatus(assessment)
          : undefined

        return (
          <ReadOnlyBadge
            badgeLabel="Martial Status"
            textContent={martialStatusData}
          />
        )
      }
    default:
      return null
  }
}
