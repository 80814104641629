import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { sortBy } from 'lodash'
import { useContext, useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { AnimatedPopup } from '@shared/components/AnimatedPopup/AnimatedPopup'
import AnimatedPopupFormFooter from '@shared/components/AnimatedPopup/AnimatedPopupFormFooter'
import { AsyncIconButton as Button } from '@shared/components/AsyncButton'
import PasswordField from '@shared/components/Auth/LoginWithUsernameOrEmail/PasswordField/PasswordField'
import { UserFormData } from '@shared/components/Auth/LoginWithUsernameOrEmail/PasswordField/types'
import { LabelAboveInput, requiredLabel } from '@shared/components/Labels'
import { hasPermissionForOrg } from '@shared/components/PermissionGates/PermissionGates'
import StyledSelect, {
  SelectComponent,
} from '@shared/components/Selects/StyledSelect'
import {
  PasswordPolicyRequirement,
  passwordPolicyRequirements,
} from '@shared/constants/passwordPolicy'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { UserAccount } from '@shared/types/user'
import {
  getFacilityIdsFromGroups,
  getGroupLabelAndType,
} from '@shared/utils/permisson'
import { isDirector } from '@shared/utils/user'
import EmailInputWithValidation from '@app/components/reactHookForm/EmailInputWithValidation'
import FullNameInputs from '@app/components/reactHookForm/FullNameInputs'
import SwitchGroup from '@app/components/reactHookForm/SwitchGroup'
import DropdownOption from '@app/components/RPView/ResidentSwitcher/DropdownOption'
import FacilitiesContext from '@app/contexts/FacilitiesContext'
import { fetchOrgFacilitySecuritySettings } from '@app/pages/Tools/SecuritySettings/helpers'
import {
  assignFieldStateBasedOnRole,
  getFormDataFromUser,
  getRoleOptions,
  GROUP_TYPE_NOT_REQUIRED_FACILITY_SELECT,
  GROUP_TYPE_REQUIRED_RESIDENT_DROPDOWN,
  setUserFormData,
} from './helpers'
import PersonDropdown from './PersonDropdown'
import PreferredUsernameInput from './PreferredUsernameInput'
import { save } from './saveUser'

type Props = {
  disabled?: boolean
  onClose: (didUpdate: boolean) => Promise<void>
  orgId: string
  user: UserAccount | null
  users: UserAccount[]
  setUserPopupData: (data: UserPopupData) => void
}

export interface UserPopupData {
  user: UserAccount | null
  disabled: boolean
  users: UserAccount[]
}

export default function UserFormPopup({
  disabled = false,
  onClose,
  orgId,
  user,
  users,
  setUserPopupData,
}: Props) {
  const { facilities = [] } = useContext(FacilitiesContext)
  const { setError } = useContext(GlobalContext)
  const { user: loggedInUser } = useUserContext()
  const loggedInAsDirector = isDirector({ user: loggedInUser, orgId })
  const isAddUser = user === null
  const formData = getFormDataFromUser({ orgId, user })
  const hasAccessToCareTrack = facilities.some(
    ({ careAppSettings }) => careAppSettings?.isActive
  )

  const [passwordRequirements, setPasswordRequirements] = useState<
    PasswordPolicyRequirement | undefined
  >(undefined)

  useEffect(() => {
    void fetchOrgFacilitySecuritySettings(orgId).then((orgSettings) => {
      const passwordPolicy =
        orgSettings?.settings?.securityPolicy?.passwordPolicy

      if (passwordPolicy) {
        setPasswordRequirements(passwordPolicyRequirements[passwordPolicy])
      }
    })
  }, [orgId])

  const methods = useForm<UserFormData>({
    defaultValues: formData,
    mode: 'all',
    criteriaMode: 'all',
  })

  const {
    control,
    getValues,
    formState,
    handleSubmit,
    register,
    setValue,
    watch,
    unregister,
  } = methods
  const { errors, isDirty, isValid } = formState
  const [isEmailAuth, setIsEmailAuth] = useState<boolean>(
    !user?.preferredUsername
  )
  const saveUser = async (userFormData: UserFormData) => {
    try {
      await save({
        orgId,
        originalUser: user,
        userFormData: setUserFormData(userFormData, !!getValues().password),
        loginUser: loggedInUser,
        users,
        setUserPopupData,
      })
      await onClose(true)
    } catch (e) {
      setError(e)
    }
  }

  let title: string
  if (disabled) {
    title = 'User Details'
  } else if (isAddUser) {
    title = 'Add User'
  } else {
    title = 'Edit User'
  }

  const fieldState = assignFieldStateBasedOnRole({
    currentUser: loggedInUser,
    targetUser: user,
    orgId,
  })

  const allowedFacilityIds = getFacilityIdsFromGroups({
    groups: loggedInUser.groups || [],
    orgId,
    permission: GroupPermission.GROUP_PERMISSION_FACILITY_USER_CREATE,
  })
  const facilityOptions = facilities.map((f) => ({
    label: f.name!,
    value: f.id!,
    disabled:
      disabled ||
      (loggedInAsDirector && !allowedFacilityIds.includes(f.id!)) ||
      fieldState.facilityIds.disabled,
  }))

  const facilityIds = watch('facilityIds')
  const role = watch('role')

  const showFacilitySwitchGroup =
    role &&
    !GROUP_TYPE_NOT_REQUIRED_FACILITY_SELECT.includes(role) &&
    (!fieldState.facilityIds.hidden || !fieldState.facilityIds.disabled)
  const showResidentDropdown =
    role &&
    GROUP_TYPE_REQUIRED_RESIDENT_DROPDOWN.includes(role) &&
    Boolean(facilityIds?.length)

  useEffect(() => {
    const residents = watch('residents') || []
    setValue(
      'residents',
      residents?.filter((r) => facilityIds?.some((fId) => fId === r.facilityId))
    )
  }, [facilityIds?.join(',')])

  function toggleIsEmailAuth(isEmailAuth: boolean) {
    setIsEmailAuth(isEmailAuth)

    if (isEmailAuth) {
      unregister('preferredUsername')
    } else {
      unregister('email')
    }
  }

  const canCreateUserWithPassword = hasPermissionForOrg({
    user: loggedInUser,
    orgId,
    permissions: [GroupPermission.GROUP_PERMISSION_USER_PASSWORD_UPDATE],
  })

  const ctaProps =
    canCreateUserWithPassword && !fieldState.preferredUsername.disabled
      ? {
          text: "This user doesn't have an email",
          onClick: () => toggleIsEmailAuth(false),
        }
      : undefined

  return (
    <AnimatedPopup title={title}>
      <form
        data-testid="user-form-popup"
        className="mt-[32px]"
        onSubmit={handleSubmit(saveUser)}
      >
        {!fieldState.name.hidden && (
          <FullNameInputs
            disabled={fieldState.name.disabled}
            errors={errors}
            register={register}
          />
        )}

        {!fieldState.email.hidden && isEmailAuth && (
          <EmailInputWithValidation
            required={getValues().preferredUsername ? false : true}
            disabled={fieldState.email.disabled}
            errors={errors}
            register={register}
            ctaProps={ctaProps}
          />
        )}
        {!fieldState.preferredUsername?.hidden &&
          !isEmailAuth &&
          canCreateUserWithPassword && (
            <FormProvider {...methods}>
              <PreferredUsernameInput
                disabled={fieldState.preferredUsername.disabled}
                setIsEmailAuth={toggleIsEmailAuth}
                register={register}
              />
            </FormProvider>
          )}
        {!fieldState.password.hidden &&
          canCreateUserWithPassword &&
          passwordRequirements && (
            <FormProvider {...methods}>
              <PasswordField
                disabled={fieldState.password.disabled}
                isEmailAuth={isEmailAuth}
                isAddUser={isAddUser}
                requirements={passwordRequirements}
              />
            </FormProvider>
          )}
        {!fieldState.role.hidden && (
          <div className="mb-[32px]">
            <LabelAboveInput
              htmlFor="role-dropdown"
              subLabel={requiredLabel(Boolean(errors.role))}
            >
              Role
            </LabelAboveInput>
            <Controller
              control={control}
              name="role"
              rules={{ required: true }}
              render={({ field: { onChange, value } }) => {
                return (
                  <StyledSelect
                    inputId="role-dropdown"
                    components={{ Option: DropdownOption as SelectComponent }}
                    onChange={(opt: { value: string }) => {
                      onChange(opt.value)
                      setValue('residents', undefined)
                    }}
                    options={sortBy(
                      getRoleOptions(loggedInUser, orgId, hasAccessToCareTrack),
                      'label'
                    )}
                    isDisabled={fieldState.role.disabled}
                    value={value && getGroupLabelAndType(value)}
                    menuPlacement="top"
                  />
                )
              }}
            />
          </div>
        )}
        {!fieldState.facilityIds.hidden && showFacilitySwitchGroup && (
          <SwitchGroup
            control={control}
            errors={errors}
            name="facilityIds"
            onClick={() => {
              if (!showResidentDropdown) {
                setValue('residents', undefined)
              }
            }}
            options={facilityOptions}
            title="Access"
            isMulti
            required
          />
        )}
        {showResidentDropdown && (
          <PersonDropdown
            control={control}
            errors={errors}
            facilityIds={facilityIds}
            orgId={orgId}
            loginUser={loggedInUser}
          />
        )}
        {fieldState.saveBtn.disabled && (
          <Button
            buttonStyle="secondary-outline"
            onClick={() => onClose(false)}
            width={'138px'}
            type="button"
          >
            Close
          </Button>
        )}
        {!fieldState.saveBtn.disabled && (
          <AnimatedPopupFormFooter
            formState={formState}
            noBtn={{ action: () => void onClose(false) }}
            yesBtn={{
              props: {
                disabled: !isDirty || !isValid || fieldState.saveBtn.disabled,
                id: 'user-save-btn',
              },
            }}
          />
        )}
      </form>
    </AnimatedPopup>
  )
}
