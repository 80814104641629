import { PharmacyPartner } from '@augusthealth/models/com/august/protos/pharmacy_partner'
import { useFormContext } from 'react-hook-form'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { PharmacyFacility } from '@shared/types/pharmacy_facility'
import { getReadablePharmacyPartnerName } from '@shared/utils/pharmacyPartner'
import { tw, twx } from '@shared/utils/tailwind'
import {
  IntegrationsForm,
  mapPharmacyFacilityToIntegrationsForm,
} from '@app/pages/Tools/PharmacyTools/PharmacyIntegration/helpers'
import { LabelWithInput } from '@app/pages/Tools/PharmacyTools/PharmacyIntegration/Layout'
import {
  getFormConfigurationForPharmacyPartner,
  partnerConfigHasNoEditableFields,
  PartnerFormConfig,
} from '@app/pages/Tools/PharmacyTools/PharmacyIntegration/PartnerConfigurationForm/helpers'

type Props = {
  editingIntegration: PharmacyFacility | undefined
}
export const PartnerConfigurationForm = ({ editingIntegration }: Props) => {
  const methods = useFormContext<IntegrationsForm>()
  const selectedPharmacyPartner = methods.watch('partner')

  const partnerConfig =
    selectedPharmacyPartner &&
    getFormConfigurationForPharmacyPartner(
      methods,
      selectedPharmacyPartner,
      editingIntegration
        ? {
            tag: 'edit',
            formData: mapPharmacyFacilityToIntegrationsForm(editingIntegration),
          }
        : { tag: 'create' }
    )

  const helperTextClasses = tw`mt-4 text-center text-[14px] leading-[20px]`

  const providedByClasses = twx(
    helperTextClasses,
    'tracking-wider text-gray-07'
  )

  if (!partnerConfig) {
    const message = selectedPharmacyPartner
      ? 'Pharmacy Partner not supported in Tools'
      : 'Select a facility and pharmacy partner to begin'

    return <div className={helperTextClasses}>{message}</div>
  }

  const noFieldsToEdit =
    editingIntegration && partnerConfigHasNoEditableFields(partnerConfig)

  return (
    <>
      <ValuesProvidedByPartner
        partnerConfig={partnerConfig}
        selectedPharmacyPartner={selectedPharmacyPartner}
        providedByClasses={providedByClasses}
      />
      <ValuesProvidedByAugust
        partnerConfig={partnerConfig}
        providedByClasses={providedByClasses}
      />
      <AsyncIconButton
        buttonStyle={'primary-fill'}
        type={'submit'}
        isLoading={false}
        disabled={!selectedPharmacyPartner || noFieldsToEdit}
        className={tw`mt-8`}
      >
        {editingIntegration ? 'Update' : 'Save'} Integration
      </AsyncIconButton>
    </>
  )
}

const ValuesProvidedByPartner = ({
  partnerConfig,
  selectedPharmacyPartner,
  providedByClasses,
}: {
  partnerConfig: PartnerFormConfig
  selectedPharmacyPartner: PharmacyPartner
  providedByClasses: string
}) => {
  if (partnerConfig.them.length === 0) {
    return null
  }
  return (
    <>
      <div className={providedByClasses}>
        Values provided by{' '}
        {getReadablePharmacyPartnerName(selectedPharmacyPartner)}
      </div>

      {partnerConfig.them.map((field) => (
        <LabelWithInput field={field} key={field.name} />
      ))}
    </>
  )
}
const ValuesProvidedByAugust = ({
  partnerConfig,
  providedByClasses,
}: {
  partnerConfig: PartnerFormConfig
  providedByClasses: string
}) => {
  if (partnerConfig.us.length === 0) {
    return null
  }

  return (
    <>
      <div className={providedByClasses}>Values provided by August Health</div>
      {partnerConfig.us.map((field) => (
        <LabelWithInput field={field} key={field.name} />
      ))}
    </>
  )
}
