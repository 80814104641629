import { Option } from 'effect'
import { isEqual } from 'lodash'
import { useEffect, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import Icon from '@shared/components/Icon'
import {
  BillingResidentSummary,
  PayerSettingsData,
  PayerWithScheduledPayment,
} from '@shared/types/billing'
import { RequiredPersonIds } from '@shared/types/person'
import { toOrdinal } from '@shared/utils/date'
import { tw } from '@shared/utils/tailwind'
import { RHFSwitch } from '@app/components/Switch'
import { PaymentDetailsModal } from '@app/pages/Facilities/Billing/Residents/AddPaymentMethod'
import BankInfo from '../../BankInfo'
import DayForAutoPayModal from './DayForAutoPayModal'
import DueNowBox from './DueNowBox'

export type AutoPayFormProps = {
  payer: PayerWithScheduledPayment
  billingSummary: BillingResidentSummary
  onSubmit: (updatedSettings: PayerSettingsData) => Promise<void>
}

enum ModalTypes {
  DayForAutoPay = 'dayForAutoPay',
  PaymentMethod = 'paymentMethod',
}
type ModalType = ModalTypes.DayForAutoPay | ModalTypes.PaymentMethod

enum AutoPayFormState {
  DEFAULT = 'DEFAULT',
  SHOW_FORM = 'SHOW_FORM',
  SHOW_MODAL = 'SHOW_MODAL',
}

type State =
  | { tag: AutoPayFormState.DEFAULT }
  | { tag: AutoPayFormState.SHOW_FORM }
  | { tag: AutoPayFormState.SHOW_MODAL; value: ModalType }

export default function AutoPayForm(props: AutoPayFormProps) {
  const { id, facilityId, orgId } = useParams<{
    id: string
    facilityId: string
    orgId: string
  }>()

  const { payer, billingSummary, onSubmit } = props
  const billingPayerUserSettings = payer?.paymentSettings
  const [state, setState] = useState<State>(() => {
    if (!billingPayerUserSettings?.paymentMethod) {
      return { tag: AutoPayFormState.DEFAULT }
    }

    return { tag: AutoPayFormState.SHOW_FORM }
  })

  const methods = useForm<PayerSettingsData>({
    defaultValues: billingPayerUserSettings,
  })
  const { handleSubmit, watch, control } = methods

  const paymentDayOfMonth =
    billingPayerUserSettings?.paymentDayOfMonth ||
    billingSummary.statementDueDay

  useEffect(() => {
    const subscription = watch(() => void handleSubmit(onSubmit)())

    return () => subscription.unsubscribe()
  }, [handleSubmit, watch])

  if (state.tag === AutoPayFormState.DEFAULT) {
    return (
      <div
        className={tw`flex h-[130px] min-h-0 animate-fade-in rounded-lg bg-white p-2 shadow-card`}
      >
        {payer?.scheduledPayment && (
          <DueNowBox amountCents={payer?.scheduledPayment?.amountCents} />
        )}
        <div
          className={tw`flex h-full w-full flex-col items-center justify-center bg-gray-13`}
        >
          <Icon
            className={tw`mt-[13px] text-2xl font-semibold text-primary`}
            variant="solid"
            name="bank"
          />

          <AsyncIconButton
            buttonStyle="link"
            isLoading={false}
            onClick={() =>
              setState({
                tag: AutoPayFormState.SHOW_MODAL,
                value: ModalTypes.PaymentMethod,
              })
            }
            className={tw`text-sm font-medium normal-case underline`}
            data-testid="add-payment-method-btn"
          >
            Add payment method
          </AsyncIconButton>
        </div>
      </div>
    )
  }

  return (
    <form data-testid="auto-pay-form" onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <DayForAutoPayModal
          onClose={async () => {
            setState({ tag: AutoPayFormState.SHOW_FORM })
          }}
          isOpen={isEqual(state, {
            tag: AutoPayFormState.SHOW_MODAL,
            value: ModalTypes.DayForAutoPay,
          })}
          paymentDayOfMonth={paymentDayOfMonth!}
        />
      </FormProvider>
      <PaymentDetailsModal
        contactId={Option.none()}
        personIds={{ orgId, facilityId, id } as RequiredPersonIds}
        payerSettings={billingPayerUserSettings}
        onClose={() => setState({ tag: AutoPayFormState.SHOW_FORM })}
        submitFn={async (updatedSettings) => {
          await onSubmit(updatedSettings)

          setState({ tag: AutoPayFormState.SHOW_FORM })

          return Promise.resolve(true)
        }}
        isOpen={isEqual(state, {
          tag: AutoPayFormState.SHOW_MODAL,
          value: ModalTypes.PaymentMethod,
        })}
      />
      <div
        className={tw`flex min-h-0 animate-fade-in rounded-lg bg-white p-4 shadow-card`}
      >
        {payer.scheduledPayment && (
          <div className={tw`mr-4`}>
            <DueNowBox amountCents={payer.scheduledPayment.amountCents} />
          </div>
        )}
        <div>
          {billingPayerUserSettings.paymentMethod && (
            <div className={tw`flex flex-col divide-y-2 pb-4`}>
              <div className={tw`flex items-start justify-start gap-4`}>
                <BankInfo billingPayerUserSettings={billingPayerUserSettings} />
              </div>
            </div>
          )}

          <hr className="border-1 border-gray-10" />

          <div className={tw`flex items-center justify-start gap-4 py-4`}>
            <Controller
              control={control}
              defaultValue={billingPayerUserSettings.autoPay}
              name="autoPay"
              render={({ field: { onChange, value } }) => (
                <RHFSwitch
                  aria-label="Auto pay"
                  id="auto-pay"
                  checked={value}
                  onChange={async () => {
                    onChange(!value)
                  }}
                  switchSize="small"
                />
              )}
            />

            <span
              data-testid="auto-pay-day"
              className={tw`text-sm font-medium text-gray-04`}
            >
              Auto pay on the{' '}
              <AsyncIconButton
                data-testid="auto-pay-day-btn"
                buttonStyle="link"
                isLoading={false}
                onClick={() =>
                  setState({
                    tag: AutoPayFormState.SHOW_MODAL,
                    value: ModalTypes.DayForAutoPay,
                  })
                }
                className={tw`text-sm font-medium normal-case underline`}
              >
                {toOrdinal(paymentDayOfMonth!)} of the month
              </AsyncIconButton>
            </span>
          </div>

          <hr className="border-1 border-gray-10" />

          <div className={tw`flex items-center justify-start gap-6 pt-4`}>
            <AsyncIconButton
              data-testid="update-payment-info"
              initialIcon="pencil"
              onClick={() =>
                setState({
                  tag: AutoPayFormState.SHOW_MODAL,
                  value: ModalTypes.PaymentMethod,
                })
              }
              buttonStyle="link"
              className={tw`h-6 text-sm font-medium capitalize text-gray-07`}
            >
              Update payment info
            </AsyncIconButton>
            {billingPayerUserSettings.paymentMethod && (
              <AsyncIconButton
                data-testid="remove-payment-method"
                initialIcon="trash"
                onClick={async () => {
                  await onSubmit({
                    ...billingPayerUserSettings,
                    paymentMethod: undefined,
                    autoPay: false,
                  })
                  setState({ tag: AutoPayFormState.DEFAULT })
                }}
                buttonStyle="link"
                className={tw`h-6 text-sm font-medium capitalize text-gray-07`}
              >
                Remove payment method
              </AsyncIconButton>
            )}
          </div>
        </div>
      </div>
    </form>
  )
}
