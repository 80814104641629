import { tw, twx } from '@shared/utils/tailwind'

export default function Button({
  text,
  className,
  icon,
  state,
  disabled,
}: {
  text: string
  className?: string
  icon?: string
  state: 'engaged' | 'disengaged' | 'default'
  disabled: boolean
}) {
  if (state === 'disengaged') {
    return null
  }

  return (
    <button
      disabled={disabled}
      className={twx(
        className,
        'disabled:cursor-initial h-[48px] w-[360px] rounded-[8px] font-inter text-[14px] font-semibold uppercase leading-[16px] transition-all disabled:opacity-50 [&:not(:disabled)]:hover:brightness-90',
        {
          'border-0 bg-rebrand-primary text-white': true,
        }
      )}
      value="Login to August Health"
      type="submit"
    >
      {icon && <i className={tw`fa fa-fw fa-${icon} mr-[8px]`} />}
      {text}
    </button>
  )
}
