import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { UserAccount } from '@shared/types/user'
import { tw } from '@shared/utils/tailwind'
import ConfirmModal, {
  Props as ConfirmModalProps,
} from '@app/components/ConfirmModal'
import { EditableDetailsCard } from '../DetailsCard'
import { CompleteCard, IncompleteCard } from './Card'
import EditInsurance from './EditInsurance'
import {
  COVERAGE_TYPE_OPTIONS,
  deleteCoverage,
  getAllCoveragesByType,
  IndexedCoverage,
} from './helpers'

interface Props {
  person: Person
  onInsuranceChange: () => void
  user: UserAccount
}

export type Prefix = 'Primary' | 'Secondary' | undefined

export default function Insurance({ person, onInsuranceChange, user }: Props) {
  const { setError } = useContext(GlobalContext)
  const [showEdit, setShowEdit] = useState<
    { coverage: IndexedCoverage; prefix: Prefix } | undefined
  >(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [confirmationModalProps, setConfirmationModalProps] =
    useState<ConfirmModalProps>()

  const canEdit = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_PERSON_UPDATE],
  })

  const [medicalInsurances, dentalInsurances, pharmacyInsurances] =
    getAllCoveragesByType(person)

  function handleCardClick({
    coverage,
    prefix,
  }: {
    coverage: IndexedCoverage
    prefix: Prefix
  }): (() => void) | undefined {
    if (canEdit) {
      return () => setShowEdit({ coverage, prefix })
    }
    return undefined
  }

  async function handleDeleteClick(index: number) {
    const hideConfirmation = () => setConfirmationModalProps(undefined)

    setConfirmationModalProps((existing) => ({
      ...existing,
      title: 'Delete insurance information?',
      content: '',
      confirmButtonConfig: {
        ...existing?.confirmButtonConfig,
        children: 'Delete',
        buttonStyle: 'danger-fill',
        onClick: async () => {
          try {
            setIsLoading(true)
            await deleteCoverage({ index, person })
            onInsuranceChange()
          } catch (error) {
            setError(error)
          } finally {
            setIsLoading(false)
            hideConfirmation()
          }
        },
      },
      denyButtonConfig: {
        ...existing?.denyButtonConfig,
        onClick: hideConfirmation,
      },
    }))
  }

  function Insurances({
    title,
    insurances,
  }: {
    title: 'Dental' | 'Medical' | 'Pharmacy'
    insurances: IndexedCoverage[]
  }) {
    if (!insurances?.length) {
      const coverage = {
        index: -1,
        type: COVERAGE_TYPE_OPTIONS.find((o) => o.label === title)?.value,
      }
      return (
        <IncompleteCard
          coverage={coverage}
          prefix="Primary"
          onClick={handleCardClick({
            coverage,
            prefix: 'Primary',
          })}
        />
      )
    }

    return insurances.map((coverage, keyIndex) => {
      const prefixDict = {
        [0]: 'Primary',
        [1]: 'Secondary',
      }

      const prefix = prefixDict[keyIndex]

      return (
        <CompleteCard
          onDelete={(selectedIndex: number) => handleDeleteClick(selectedIndex)}
          key={keyIndex}
          coverage={coverage}
          prefix={prefix}
          onClick={handleCardClick({ coverage, prefix })}
        />
      )
    })
  }

  function RenderInsurances() {
    if (isLoading) {
      return (
        <Skeleton
          count={3}
          height={100}
          className={tw`mb-[16px] rounded-[8px] p-[24px] last-of-type:mb-0`}
        />
      )
    }

    return (
      <div>
        <Insurances title="Medical" insurances={medicalInsurances} />
        <Insurances title="Dental" insurances={dentalInsurances} />
        <Insurances title="Pharmacy" insurances={pharmacyInsurances} />
      </div>
    )
  }

  return (
    <>
      <EditableDetailsCard
        titleUnderline={false}
        icon="fas fa-fw fa-shield-alt"
        title="Insurance"
        editPermissions={{
          person,
          permissions: [GroupPermission.GROUP_PERMISSION_PERSON_UPDATE],
        }}
        editButtonId="edit-insurance"
        onEditClick={() =>
          setShowEdit({
            coverage: { index: -1 },
            prefix: 'Primary',
          })
        }
        buttonTitle="Add"
        className={tw`pb-[10px]`}
      >
        <RenderInsurances />
      </EditableDetailsCard>
      {!!showEdit?.coverage && (
        <EditInsurance
          person={person}
          onClose={(updated) => {
            setShowEdit(undefined)
            if (updated) {
              onInsuranceChange()
            }
          }}
          coverage={showEdit.coverage}
        />
      )}
      {confirmationModalProps && <ConfirmModal {...confirmationModalProps} />}
    </>
  )
}
