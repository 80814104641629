import {
  AppraisalSettings_AppraisalCategory,
  AppraisalSettings_CustomDetails,
  AppraisalSettings_AppraisalCategoryDetail as Detail,
} from '@augusthealth/models/com/august/protos/settings/appraisal_settings'
import { camelCase, flatten, intersection, minBy, sortBy } from 'lodash'
import {
  Assessment,
  DetailWithAugustField,
  DetailWithCheckbox,
  DetailWithDropdown,
  DetailWithTextarea,
  DetailWithTextbox,
  FrontendDetail,
} from '@shared/types/assessment'
import {
  AssessmentCategory,
  CategoryKeyIdentifier,
} from '@shared/types/assessment_configuration'
import {
  AppraisalStatus,
  AugustField,
  AugustInitialAppraisal,
  CustomDetailsWrapper,
} from '@shared/types/august_initial_appraisal'
import { AugustFieldType } from '@shared/types/custom_field'
import { ServicePlan_PlanCategoryKey as CatKey } from '@shared/types/service_plan'
import {
  AppraisalSettings_AppraisalCategory as AppraisalCategory,
  AppraisalSettings_AssessmentReason,
  AppraisalSettings_Level as Level,
} from '@shared/types/settings/appraisal_settings'
import { Snapshot } from '@shared/types/snapshot'
import { isCustomTextDetail } from '@shared/utils/assessmentConfiguration'
import notEmpty from '@shared/utils/notEmpty'

/**
 * Assessments with 'realignment' as their reason are required to provided a
 * manually entered completion date via the UI.
 * @param assessment
 */
export function isRealignment(assessment: AugustInitialAppraisal) {
  return (
    assessment.assessmentReason?.assessmentReason ===
    AppraisalSettings_AssessmentReason.ASSESSMENT_REASON_ALIGNMENT
  )
}

export function getMatchingAssessmentCategory({
  categoryKey,
  categories,
}: {
  categoryKey: CategoryKeyIdentifier | CatKey
  categories?: AppraisalCategory[]
}): AppraisalCategory | undefined {
  const { categoryKey: parsedCategoryKey, customKey } =
    getCategoryAndCustomKeyFromCategoryKeyIdentifier(categoryKey)
  return categories?.find(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
    (c) => c.categoryKey === parsedCategoryKey && c.customKey === customKey
  )
}

export function getCategoryAndCustomKeyFromCategoryKeyIdentifier(
  categoryKey: CategoryKeyIdentifier | CatKey
) {
  const [parsedCategoryKey, customKey] = categoryKey.split('-')

  return {
    categoryKey: parsedCategoryKey,
    customKey: customKey,
  }
}

export function buildCategoryKeyIdentifier({
  categoryKey,
  customKey,
}: {
  categoryKey?: CatKey | CategoryKeyIdentifier
  customKey?: string
}): CategoryKeyIdentifier {
  if (customKey) {
    return `${CatKey.PLAN_CATEGORY_KEY_CUSTOM}-${customKey}`
  } else {
    return categoryKey as CategoryKeyIdentifier
  }
}

export function getLevelLabel(level: Level) {
  return level
    .replace(/^LEVEL_/, '')
    .toLowerCase()
    .replace(/^(\b.)/, (c) => c.toUpperCase())
}

export function getCategoryTitle(category: AppraisalCategory) {
  const {
    categoryKey = CatKey.PLAN_CATEGORY_KEY_UNSPECIFIED,
    categoryOptions,
  } = category
  if (categoryOptions?.title) {
    return categoryOptions.title
  }

  return getCategoryKeyTitle(categoryKey)
}

export function getCategoryKeyTitle(categoryKey: CatKey) {
  if (categoryKey.startsWith(CatKey.PLAN_CATEGORY_KEY_CUSTOM)) {
    return getCategoryAndCustomKeyFromCategoryKeyIdentifier(categoryKey)
      .customKey
  }
  return categoryKey.replace(/^PLAN_CATEGORY_KEY_/, '').replace(/_/g, ' ')
}

export function getCategoryQuestion(category: AppraisalCategory) {
  const { categoryOptions } = category
  if (categoryOptions?.question) {
    return categoryOptions.question
  }

  const title = getCategoryTitle(category)
  return `What is the level of assistance needed with ${title.toLowerCase()}?`
}

export function getCategoryPrefix(category: AppraisalCategory) {
  const { categoryKey = '' } = category
  return camelCase(categoryKey.replace(/^PLAN_CATEGORY_KEY_/, ''))
}

export const matchOnCategoryAndCustomKey = (
  firstCategory: Pick<CustomDetailsWrapper, 'categoryKey' | 'customKey'>,
  secondCategory: Pick<CustomDetailsWrapper, 'categoryKey' | 'customKey'>
) => {
  const { categoryKey, customKey } = firstCategory
  const { categoryKey: categoryKey2, customKey: customKey2 } = secondCategory

  return categoryKey === categoryKey2 && customKey === customKey2
}

export function getLevelValue({
  category,
  assessment,
}: {
  category: AppraisalCategory
  assessment: AugustInitialAppraisal
}): Level {
  const { customDetails = [] } = assessment
  const { categoryKey, customKey } = category
  const customDetailsByCategory = customDetails.find((cd) =>
    matchOnCategoryAndCustomKey(cd, { categoryKey, customKey })
  )

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return customKey
    ? customDetailsByCategory?.level
    : assessment[getLevelPath(category)]
}

function getNoteValue({
  category,
  assessment,
}: {
  category: AppraisalCategory
  assessment: AugustInitialAppraisal
}): string {
  const { customDetails = [] } = assessment
  const { categoryKey, customKey } = category
  const customDetailsByCategory = customDetails.find((cd) =>
    matchOnCategoryAndCustomKey(cd, { categoryKey, customKey })
  )

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return customKey
    ? customDetailsByCategory?.notes
    : assessment[getNotesPath(category)]
}

export function getLevelPath(category: AppraisalCategory) {
  return getCategoryPrefix(category)
}

export function getDetailsPath(category: AppraisalCategory) {
  return `${getCategoryPrefix(category)}Details`
}

export function getNotesPath(category: AppraisalCategory) {
  return `${getCategoryPrefix(category)}Notes`
}

export function getValuesByCategory({
  assessment,
  category,
}: {
  assessment: AugustInitialAppraisal
  category: AppraisalCategory
}): {
  level?: Level
  details?: string[]
  notes?: string
  customDetailsIds?: string[]
  customDetails?: CustomDetailsWrapper
} {
  const { customDetails = [] } = assessment
  const { categoryKey, customKey } = category
  const customDetailsByCategory = customDetails.find((cd) =>
    matchOnCategoryAndCustomKey(cd, { categoryKey, customKey })
  )

  const level = getLevelValue({ category, assessment })

  const notes = getNoteValue({ category, assessment })

  return {
    level,
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    details: assessment[getDetailsPath(category)],
    notes,
    customDetailsIds: customDetailsByCategory?.fields
      ?.map((f) => f.id)
      .filter(notEmpty),
    customDetails: customDetailsByCategory,
  }
}

type SectionName = string

export function makeCheckboxSections(
  category: AppraisalSettings_AppraisalCategory | AssessmentCategory
) {
  const { details = [], customDetails = [], answerGroups = [] } = category
  const orderedGroups = new Map<SectionName, FrontendDetail[]>()

  const dropdownDetails: DetailWithDropdown[] = answerGroups.map(
    (answerGroup) => {
      const optionsForDropdown = [...details, ...customDetails].filter(
        (d) => d.answerGroup === answerGroup.name
      )

      return {
        tag: 'DetailWithDropdown',
        value: {
          detailMetadata: answerGroup,
          options: sortBy(optionsForDropdown, (d) => d.displayOrder),
        },
        displayOrder:
          minBy(optionsForDropdown, (gd) => gd.displayOrder)?.displayOrder ?? 0,
        groupName: optionsForDropdown[0].group ?? 'Unmatched',
      }
    }
  )

  const augustFieldDetails: DetailWithAugustField[] = [
    ...details,
    ...customDetails,
  ].reduce((results: DetailWithAugustField[], d) => {
    if (d.augustFieldType !== undefined) {
      results.push({
        tag: 'DetailWithAugustField',
        value: d,
        groupName: d.group ?? '',
        displayOrder: d.displayOrder ?? 0,
      })
    }

    return results
  }, [])
  const checkboxDetails: DetailWithCheckbox[] = [
    ...details,
    ...customDetails,
  ].reduce((results: DetailWithCheckbox[], d) => {
    if (
      d.answerGroup === undefined &&
      !isCustomTextDetail(d.customDetails) &&
      d.augustFieldType === undefined
    ) {
      results.push({
        tag: 'DetailWithCheckbox',
        value: d,
        displayOrder: d.displayOrder ?? 0,
        groupName: d.group ?? '',
      })
    }

    return results
  }, [])

  const textboxDetails: DetailWithTextbox[] = [
    ...details,
    ...customDetails,
  ].reduce((results: DetailWithTextbox[], d) => {
    if (
      d.answerGroup === undefined &&
      d.customDetails === AppraisalSettings_CustomDetails.CUSTOM_DETAILS_TEXTBOX
    ) {
      results.push({
        tag: 'DetailWithTextbox',
        value: d,
        displayOrder: d.displayOrder ?? 0,
        groupName: d.group ?? '',
      })
    }

    return results
  }, [])

  const textareaDetails: DetailWithTextarea[] = [
    ...details,
    ...customDetails,
  ].reduce((results: DetailWithTextarea[], d) => {
    if (
      d.answerGroup === undefined &&
      d.customDetails ===
        AppraisalSettings_CustomDetails.CUSTOM_DETAILS_TEXTAREA
    ) {
      results.push({
        tag: 'DetailWithTextarea',
        value: d,
        displayOrder: d.displayOrder ?? 0,
        groupName: d.group ?? '',
      })
    }

    return results
  }, [])

  const sorted = sortBy(
    [
      ...augustFieldDetails,
      ...dropdownDetails,
      ...checkboxDetails,
      ...textboxDetails,
      ...textareaDetails,
    ],
    (d) => d.displayOrder
  )

  sorted.forEach((detailData) => {
    const existingDetailsForGroup =
      orderedGroups.get(detailData.groupName) ?? []
    orderedGroups.set(
      detailData.groupName,
      sortBy([...existingDetailsForGroup, detailData], (d) => d.displayOrder)
    )
  })

  return orderedGroups
}

export function formatScore(backendDetail: Detail) {
  const score = backendDetail.score

  if (score && score > 0) {
    return `(+${score})`
  }
  if (score && score < 0) {
    return `(${score})`
  }

  return ''
}

function assessmentAnswersToFrontendDetails({
  assessment,
  category,
}: {
  assessment: AugustInitialAppraisal
  category: AppraisalCategory
}) {
  const { details = [], customDetailsIds = [] } = getValuesByCategory({
    assessment,
    category,
  })
  const frontendDetailsByGroup = makeCheckboxSections(category).values()
  const allDetails = flatten([...frontendDetailsByGroup])
  const detailsPath = getDetailsPath(category)

  return allDetails.filter((detailData) => {
    switch (detailData.tag) {
      case 'DetailWithAugustField':
        return true
      case 'DetailWithCheckbox': {
        const customDetailsId = detailData.value.id
        return (
          details.includes(detailData.value[detailsPath] as string) ||
          (customDetailsId && customDetailsIds.includes(customDetailsId))
        )
      }
      case 'DetailWithTextbox':
      case 'DetailWithTextarea':
        // eslint-disable-next-line no-case-declarations
        const customDetailsId = detailData.value.id
        return customDetailsId && customDetailsIds.includes(customDetailsId)
      case 'DetailWithDropdown':
        // eslint-disable-next-line no-case-declarations
        const containsCustomOptions = detailData.value.options.some(
          (o) => o.customDetails !== undefined
        )
        if (containsCustomOptions) {
          return (
            intersection(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              detailData.value.options.map((o) => o[detailsPath]),
              details
            ).length > 0 ||
            intersection(
              detailData.value.options.map((o) => o.id),
              customDetailsIds
            ).length > 0
          )
        } else {
          return (
            intersection(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-return
              detailData.value.options.map((o) => o[detailsPath]),
              details
            ).length > 0
          )
        }
    }
  })
}

function getAugustFieldValueFromAssessment({
  augustFieldType,
  assessment,
  defaultLabel,
}: {
  augustFieldType: AugustFieldType
  assessment: AugustInitialAppraisal
  defaultLabel: string
}) {
  const dataList: (AugustField | undefined)[] = (assessment.customDetails ?? [])
    .flatMap((cd) => {
      return cd.augustFields?.find((af) => af.type === augustFieldType)
    })
    .filter(notEmpty)

  return dataList[0]?.value ?? defaultLabel
}

export function getAllergies(assessment: AugustInitialAppraisal) {
  return getAugustFieldValueFromAssessment({
    augustFieldType: AugustFieldType.AUGUST_FIELD_TYPE_ALLERGIES,
    assessment,
    defaultLabel: 'No Allergies Specified',
  })
}

export function getDiagnoses(assessment: AugustInitialAppraisal) {
  return getAugustFieldValueFromAssessment({
    augustFieldType: AugustFieldType.AUGUST_FIELD_TYPE_CONDITION,
    assessment,
    defaultLabel: 'No Diagnoses Specified',
  })
}

export function getDietaryRestrictions(assessment: AugustInitialAppraisal) {
  return getAugustFieldValueFromAssessment({
    augustFieldType: AugustFieldType.AUGUST_FIELD_TYPE_DIETARY_RESTRICTIONS,
    assessment,
    defaultLabel: 'No Diet Specified',
  })
}

export function getMaritalStatus(assessment: AugustInitialAppraisal) {
  return getAugustFieldValueFromAssessment({
    augustFieldType: AugustFieldType.AUGUST_FIELD_TYPE_MARITAL_STATUS,
    assessment,
    defaultLabel: 'No Marital Status Specified',
  })
}

function getDisplayLabelForDetailWithAugustField({
  augustFieldType,
  assessment,
}: {
  augustFieldType: AugustFieldType | undefined
  assessment: AugustInitialAppraisal
}) {
  switch (augustFieldType) {
    case AugustFieldType.AUGUST_FIELD_TYPE_ALLERGIES:
      return `Allergies: ${getAllergies(assessment)}`
    case AugustFieldType.AUGUST_FIELD_TYPE_CONDITION:
      return `Diagnoses: ${getDiagnoses(assessment)}`
    case AugustFieldType.AUGUST_FIELD_TYPE_DIETARY_RESTRICTIONS:
      return `Diet: ${getDietaryRestrictions(assessment)}`
    case AugustFieldType.AUGUST_FIELD_TYPE_MARITAL_STATUS:
      return `Marital Status: ${getMaritalStatus(assessment)}`
    default:
      return null
  }
}

function frontendDetailToDescription({
  detailData,
  category,
  assessment,
}: {
  detailData: FrontendDetail
  category: AppraisalCategory
  assessment: AugustInitialAppraisal
}) {
  const {
    details = [],
    customDetails,
    customDetailsIds = [],
  } = getValuesByCategory({
    assessment,
    category,
  })

  switch (detailData.tag) {
    case 'DetailWithAugustField':
      return getDisplayLabelForDetailWithAugustField({
        assessment,
        augustFieldType: detailData.value.augustFieldType,
      })
    case 'DetailWithDropdown': {
      const prefix = detailData.value.detailMetadata.description

      const customAnswer = detailData.value.options.find(
        (o) => o.id && customDetailsIds.includes(o.id)
      )?.description
      const answer = detailData.value.options.find((o) =>
        details.includes(o[getDetailsPath(category)] as string)
      )?.description
      return `${prefix} ${answer || customAnswer}`
    }
    case 'DetailWithTextbox':
    case 'DetailWithTextarea':
      // eslint-disable-next-line no-case-declarations
      const prefix = detailData.value.description
      // eslint-disable-next-line no-case-declarations
      const helpText = detailData.value.helpText
      // assumes all textboxes are custom - could this change in the future?
      // eslint-disable-next-line no-case-declarations
      const matchingDetail = customDetails?.fields?.find(
        (ca) => ca.id === detailData.value.id
      )
      // eslint-disable-next-line no-case-declarations
      const answer = matchingDetail?.textField?.value

      if (helpText) {
        return `${prefix} (${helpText}): ${answer}`
      } else {
        return `${prefix}: ${answer}`
      }
    case 'DetailWithCheckbox':
      return detailData.value.description
  }
}

/**
 * Used to display assessment results in the Service Plan > Assessment Needs sections
 */
export function getDisplayLabelsByCategory({
  assessment,
  category,
}: {
  assessment: AugustInitialAppraisal
  category: AppraisalCategory
}) {
  const { level, notes } = getValuesByCategory({
    assessment,
    category,
  })

  const descriptions = assessmentAnswersToFrontendDetails({
    assessment,
    category,
  }).map((detailData) =>
    frontendDetailToDescription({ detailData, category, assessment })
  )

  const { levels: levelsConfig } = category
  const lv = levelsConfig?.find((l) => l.level === level) || {}

  return {
    level: level ? `${getLevelLabel(level)} - ${lv.description}` : undefined,
    details: descriptions,
    notes,
  }
}

export function getIncompleteCount({
  assessment,
  categories,
}: {
  assessment: AugustInitialAppraisal
  categories: AppraisalCategory[]
}) {
  return categories.reduce((count, c) => {
    // Some categories have no levels. These shouldn't be counted as incomplete.
    if (c.levels === undefined) {
      return count
    }

    // custom categories need to look for level in custom details
    if (c.customKey) {
      const matchingDetail = assessment.customDetails?.find(
        (cd) => cd.customKey === c.customKey
      )

      return matchingDetail?.level ? count : count + 1
    }

    if (assessment[getLevelPath(c)]) {
      return count
    }

    return count + 1
  }, 0)
}

export function sortCategories(c1: AppraisalCategory, c2: AppraisalCategory) {
  const displayOrder1 = c1.categoryOptions?.displayOrder
  const displayOrder2 = c2.categoryOptions?.displayOrder
  if (displayOrder1 === undefined && displayOrder2 === undefined) {
    return 0
  } else if (displayOrder1 === undefined) {
    return 1
  } else if (displayOrder2 === undefined) {
    return -1
  } else if (displayOrder1 > displayOrder2) {
    return 1
  } else if (displayOrder1 < displayOrder2) {
    return -1
  }

  return 0
}

export function hasAssessmentLevelValues(snapshot: Snapshot) {
  return !!snapshot.data?.augustInitialAppraisal?.levelOfCareSettings
    ?.levelValues?.length
}

export function getAssessmentFromSnapshot(snapshot: Snapshot) {
  return snapshot.data?.augustInitialAppraisal
}

export function getCategoriesFromSnapshot(snapshot: Snapshot) {
  return snapshot.data?.augustInitialAppraisal?.settings?.categories
}

export function getCategoriesFromAppraisal(appStatus: AppraisalStatus) {
  return appStatus.appraisal?.settings?.categories
}

/**
 * Get all saved custom details for a given category
 */
export function selectedCustomDetailForCategory(
  assessment: AugustInitialAppraisal,
  category: AppraisalCategory
): Required<Pick<CustomDetailsWrapper, 'categoryKey' | 'fields'>> {
  const currentCustomDetails = assessment.customDetails ?? []

  return {
    categoryKey: category.categoryKey as CatKey,
    customKey: category.customKey,
    fields: [],
    ...(currentCustomDetails.find((cd) =>
      matchOnCategoryAndCustomKey(cd, category)
    ) ?? {}),
  }
}

/**
 * Get all unanswered required text fields for a given assessment.
 * @param assessment - A snapshot containing an assessment
 */
export function getUnansweredRequiredTextFields(assessment: Snapshot) {
  const requiredTextFields = getRequiredTextFields(assessment)
  const textFieldsWithAnswers = getTextFieldsWithAnswers(assessment)

  return requiredTextFields.filter((rtf) => {
    return !textFieldsWithAnswers.map((tfwa) => tfwa.id).includes(rtf?.id)
  })
}

function getRequiredTextFields(assessment: Snapshot) {
  return (
    assessment.data?.augustInitialAppraisal?.settings?.categories
      ?.map((c) => c.customDetails)
      .filter((c) => notEmpty(c) && c.length > 0)
      .flat()
      .filter((c) => c?.isRequired) ?? []
  )
}

function getTextFieldsWithAnswers(assessment: Snapshot) {
  return (
    assessment.data?.augustInitialAppraisal?.customDetails
      ?.map((cd) => cd.fields)
      .filter(notEmpty)
      .flat()
      .filter((f) => f.textField && f.textField.value) ?? []
  )
}

export type LevelDisplay =
  | { tag: 'Level and Score'; value: { level: number; score: number } }
  | { tag: 'Level Name'; value: string }

export function getLevelDisplay(assessment: Assessment): LevelDisplay {
  const { data } = assessment
  const { augustInitialAppraisal } = data
  const {
    value = 0,
    score = 0,
    levelName,
  } = augustInitialAppraisal.levelOfCare ?? { value: 0, score: 0 }

  if (levelName) {
    return { tag: 'Level Name', value: levelName }
  }

  return { tag: 'Level and Score', value: { level: value, score } }
}
