// https://convertlive.com/u/convert/megabytes/to/bytes#10
export const MAX_UPLOAD_SIZE = 31457280 // 30 mb

export const selectorOptions = [
  {
    label: 'Incident Form',
    value: 'Incident Form',
  },
  {
    label: 'Witness Statement',
    value: 'Witness Statement',
  },
  {
    label: 'Police Report',
    value: 'Police Report',
  },
]
