import { useContext, useEffect, useState } from 'react'
import { getLedgerExports } from '@shared/api/billing'
import GlobalContext from '@shared/contexts/GlobalContext'
import { LedgerExport } from '@shared/types/billing'
import { loaded, LOADING, Loading } from '@shared/utils/loading'

export default function useBillingLedger({
  orgId,
  facilityId,
}: {
  orgId: string
  facilityId: string
}) {
  const { setError } = useContext(GlobalContext)
  const [ledgerExports, setLedgerExports] =
    useState<Loading<LedgerExport[]>>(LOADING)

  async function reloadExports() {
    setLedgerExports(LOADING)

    try {
      const exports = await getLedgerExports({ orgId, facilityId })
      setLedgerExports(loaded(exports))
    } catch (e) {
      setError(e)
      setLedgerExports({ tag: 'Complete', value: [] })
    }
  }

  useEffect(() => {
    void reloadExports()
  }, [orgId, facilityId])

  return {
    ledgerExports,
    reloadExports,
  }
}
