import { RoutineStatus } from '@augusthealth/models/com/august/protos/routine'
import { match } from 'ts-pattern'
import { RoutineOrder } from '@shared/types/routine_order'

function routineStatusSortKey(status: RoutineStatus): number {
  return match(status)
    .with(RoutineStatus.ROUTINE_STATUS_ACTIVE, () => 1)
    .with(RoutineStatus.ROUTINE_STATUS_DRAFT, () => 2)
    .with(RoutineStatus.ROUTINE_STATUS_DISCARDED, () => 3)
    .with(RoutineStatus.ROUTINE_STATUS_DISCONTINUED, () => 4)
    .with(RoutineStatus.ROUTINE_STATUS_UNSPECIFIED, () => 5)
    .with(RoutineStatus.UNRECOGNIZED, () => 6)
    .exhaustive()
}

function routineOrderMorningBedtimeKey(routineOrder: RoutineOrder): number {
  if (routineOrder.routineType.assessment) {
    if (routineOrder.name === 'Morning') {
      return 1
    }
    if (routineOrder.name === 'Bedtime') {
      return 2
    }
  }
  return 3
}

/*
 * Routine orders are sorted by the following:
 * 1. Status (active first, then drafts, etc.)
 * 2. Morning, then Bedtime assessment routines.
 * 3. All others (both assessment and custom), alphabetical by name.
 */
export function routineOrderSortKey(routineOrder: RoutineOrder) {
  return [
    routineStatusSortKey(routineOrder.status),
    routineOrderMorningBedtimeKey(routineOrder),
    routineOrder.name.toLowerCase(),
  ]
}
