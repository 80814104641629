import { Array, Data, DateTime, Effect, Option, pipe } from 'effect'
import { match, P } from 'ts-pattern'
import { UserReference } from '@shared/types/annotation'
import {
  isAdministeredEvent,
  isDeactivatedEvent,
  isExceptionEvent,
  isUndoAdministeredEvent,
  isUndoExceptionEvent,
  RoutineAdministration,
  RoutineAdministrationEventAdministered,
  RoutineAdministrationEventException,
  RoutineAdministrationEventUndoAdministered,
  RoutineAdministrationEventUndoException,
  RoutineAdministrationExceptionReason,
  RoutineAdministrationStatus,
  ShiftOccurrence,
  UUID,
} from '@shared/types/careapp'
import { toUTCIso8601DateTime, UTCIsoDateTime } from '@shared/utils/date'
import { toShiftOccurrenceRef } from '@shared/utils/shiftOccurrence'
import { generateUUID } from '@shared/utils/uuid'

/**
 * RoutineAdministrationEvents that can be added through care app actions
 */
type CareAppRoutineAdministrationEvent =
  | RoutineAdministrationEventException
  | RoutineAdministrationEventAdministered
  | RoutineAdministrationEventUndoException
  | RoutineAdministrationEventUndoAdministered

/**
 * Determines a routine status given a new event.
 */
function updatedStatus(
  event: CareAppRoutineAdministrationEvent
): RoutineAdministrationStatus {
  return match(event)
    .with(
      isExceptionEvent,
      () => RoutineAdministrationStatus.ROUTINE_ADMINISTRATION_STATUS_EXCEPTION
    )
    .with(
      isAdministeredEvent,
      () =>
        RoutineAdministrationStatus.ROUTINE_ADMINISTRATION_STATUS_ADMINISTERED
    )
    .with(
      P.union(isUndoAdministeredEvent, isUndoExceptionEvent),
      () =>
        RoutineAdministrationStatus.ROUTINE_ADMINISTRATION_STATUS_UNSPECIFIED
    )
    .exhaustive()
}

class DeactivatedAdministrationError extends Data.TaggedError('DEACTIVATED') {}
class InvalidUndoError extends Data.TaggedError('INVALID_UNDO') {}
export type AddEventError = DeactivatedAdministrationError | InvalidUndoError

export function sortEvents(events: RoutineAdministration['events']) {
  return events.sort((a, b) => b.occurredAt.localeCompare(a.occurredAt))
}

function mostRecentEvent(events: RoutineAdministration['events']) {
  return sortEvents(events).at(0)
}

export function getMostRecentEventId(events: RoutineAdministration['events']) {
  return mostRecentEvent(events)?.id
}

/**
 * Add and event to the given routine administration.
 *
 * @returns the updated RoutineAdministration or an error
 * if the routine administration is deactivated or an undo was attempted for a
 * mis-matching parent event.
 */
export function addEvent(
  event: CareAppRoutineAdministrationEvent,
  administration: RoutineAdministration
): Effect.Effect<RoutineAdministration, AddEventError> {
  const previousEvents = sortEvents(administration.events)
  const mostRecentEvent = previousEvents.at(0)

  return match([event, mostRecentEvent])
    .with(
      P.union(
        [isUndoExceptionEvent, isAdministeredEvent],
        [isUndoAdministeredEvent, isExceptionEvent]
      ),
      () => Effect.fail(new InvalidUndoError())
    )
    .with([P.any, isDeactivatedEvent], () =>
      Effect.fail(new DeactivatedAdministrationError())
    )
    .otherwise(([event, _]) => {
      const updatedEvents = [
        {
          ...event,
          parentId: mostRecentEvent?.id,
        },
        ...previousEvents,
      ]

      return Effect.succeed({
        ...administration,
        status: updatedStatus(event),
        events: updatedEvents,
      })
    })
}

export function canUndo(administration: RoutineAdministration) {
  return match(mostRecentEvent(administration.events))
    .with(P.union(isAdministeredEvent, isExceptionEvent), () => true)
    .otherwise(() => false)
}

export function getUndoEvent({
  performer,
  administration,
}: {
  performer: UserReference
  administration: RoutineAdministration
}): Option.Option<
  | RoutineAdministrationEventUndoException
  | RoutineAdministrationEventUndoAdministered
> {
  return match(mostRecentEvent(administration.events))
    .with(isExceptionEvent, () =>
      Option.some(buildUndoExceptionEvent({ performer }))
    )
    .with(isAdministeredEvent, () =>
      Option.some(buildUndoAdministeredEvent({ performer }))
    )
    .otherwise(() => Option.none())
}

function buildUndoExceptionEvent({
  performer,
}: {
  performer: UserReference
}): RoutineAdministrationEventUndoException {
  return {
    id: generateUUID(),
    occurredAt:
      new Date().toISOString() as RoutineAdministrationEventException['occurredAt'],
    performer,
    undoException: {},
  }
}

function buildUndoAdministeredEvent({
  performer,
}: {
  performer: UserReference
}): RoutineAdministrationEventUndoAdministered {
  return {
    id: generateUUID(),
    occurredAt:
      new Date().toISOString() as RoutineAdministrationEventException['occurredAt'],
    performer,
    undoAdministered: {},
  }
}

export function buildAdministeredEvent({
  performer,
  effortLevel,
  note,
  occurredAt,
  parentId,
}: {
  performer: UserReference
  effortLevel?: number
  note?: string
  occurredAt?: UTCIsoDateTime
  parentId?: UUID
}): RoutineAdministrationEventAdministered {
  return {
    id: generateUUID(),
    occurredAt: occurredAt ?? toUTCIso8601DateTime(new Date()),
    performer,
    note,
    administered: { effortLevel },
    parentId,
  }
}

export function buildExceptionEvent({
  performer,
  exceptionReason,
  note,
  parentId,
}: {
  performer: UserReference
  exceptionReason: RoutineAdministrationExceptionReason | null
  note: string | null
  parentId?: UUID
}): RoutineAdministrationEventException {
  return {
    id: generateUUID(),
    occurredAt: toUTCIso8601DateTime(new Date()),
    performer,
    note: note ?? undefined,
    exception: {
      reason: exceptionReason ?? undefined,
    },
    parentId,
  }
}

export function isCharted({ status }: { status: RoutineAdministrationStatus }) {
  return (
    status ===
      RoutineAdministrationStatus.ROUTINE_ADMINISTRATION_STATUS_EXCEPTION ||
    status ===
      RoutineAdministrationStatus.ROUTINE_ADMINISTRATION_STATUS_ADMINISTERED
  )
}

export const determineIsForFutureShiftOccurrence =
  (shiftOccurrences: ShiftOccurrence[], facilityTimezone: string, now: Date) =>
  (routineAdministration: RoutineAdministration) =>
    Effect.gen(function* () {
      const shiftOccurrence = yield* pipe(
        shiftOccurrences,
        Array.findFirst(
          (shiftOccurrence) =>
            toShiftOccurrenceRef(shiftOccurrence) ===
            routineAdministration.shiftOccurrence
        )
      )

      const dateTimeForStartOfShiftOccurrence = yield* pipe(
        shiftOccurrence.date,
        DateTime.make,
        Option.map(
          DateTime.setParts({
            hours: shiftOccurrence.shift.period.startTime.hour,
            minutes: shiftOccurrence.shift.period.startTime.minute,
          })
        ),
        Option.flatMap(
          DateTime.setZoneNamed(facilityTimezone, { adjustForTimeZone: true })
        )
      )

      const dateTimeNow = yield* DateTime.make(now)

      return pipe(
        dateTimeNow,
        DateTime.lessThan(dateTimeForStartOfShiftOccurrence)
      )
    }).pipe(Effect.runSyncExit)
