import { FieldPath, UseFormReturn } from 'react-hook-form'
import { BasicInputProps } from '@shared/components/BasicInput/BasicInput'
import { PharmacyPartner } from '@shared/types/pharmacy_partner'
import notEmpty from '@shared/utils/notEmpty'
import { IntegrationsForm } from '@app/pages/Tools/PharmacyTools/PharmacyIntegration/helpers'

export type PartnerFormField = Omit<BasicInputProps, 'name'> & {
  name: FieldPath<IntegrationsForm>
}
export type PartnerFormConfig = {
  us: PartnerFormField[]
  them: PartnerFormField[]
}
type PartnerToFieldsMode =
  | {
      tag: 'edit'
      formData: IntegrationsForm
    }
  | {
      tag: 'create'
    }
export const getFormConfigurationForPharmacyPartner = (
  methods: UseFormReturn<IntegrationsForm>,
  partner: PharmacyPartner,
  mode: PartnerToFieldsMode
): PartnerFormConfig | null => {
  const isEditing = mode.tag === 'edit'
  const EXTERNAL_IDENTIFIER: PartnerFormField = {
    label: 'External Identifier',
    name: 'externalIdentifier',
    readOnly: isEditing,
    required: true,
  }
  switch (partner) {
    case PharmacyPartner.PHARMACY_PARTNER_OMNICARE: {
      return {
        us: [],
        them: [
          {
            ...EXTERNAL_IDENTIFIER,
            value: isEditing
              ? mode.formData.externalIdentifier
              : [
                  methods.watch('pharmacyMailboxId') ?? null,
                  methods.watch('facilityId') ?? null,
                ]
                  .filter(notEmpty)
                  .join(':'),
            readOnly: true,
          },
          {
            label: 'Facility Password',
            name: 'facilityPassword',
            required: true,
          },
          {
            label: 'Pharmacy Mailbox ID',
            name: 'pharmacyMailboxId',
            required: true,
            readOnly: isEditing,
          },
          {
            label: 'Pharmacy ID',
            name: 'pharmacyId',
            required: true,
          },
          {
            label: 'Facility Mailbox ID',
            name: 'facilityMailboxId',
            required: true,
          },
          {
            label: 'Facility ID',
            name: 'facilityId',
            required: true,
            readOnly: isEditing,
          },
        ],
      }
    }
    case PharmacyPartner.PHARMACY_PARTNER_FRAMEWORK_LTC: {
      return {
        us: [
          {
            label: 'Facility Password',
            name: 'facilityPassword',
            required: true,
          },
          {
            label: 'Auth User ID',
            name: 'authUserId',
            required: true,
          },
        ],
        them: [EXTERNAL_IDENTIFIER],
      }
    }
    case PharmacyPartner.PHARMACY_PARTNER_REDSAIL_QS1:
    case PharmacyPartner.PHARMACY_PARTNER_SUITE_RX:
    case PharmacyPartner.PHARMACY_PARTNER_DIGITAL_RX:
    case PharmacyPartner.PHARMACY_PARTNER_PHARMERICA: {
      return {
        us: [],
        them: [EXTERNAL_IDENTIFIER],
      }
    }
    default: {
      return null
    }
  }
}

export const partnerConfigHasNoEditableFields = (config: PartnerFormConfig) => {
  return (
    config.us.length === 0 &&
    config.them.length === 1 &&
    config.them[0].name === 'externalIdentifier'
  )
}
