import { BadgeColor } from '@shared/components/Badge'
import { PdfProj, SignerRequest } from '@shared/types/signable_form'
import { SignerRole } from '@shared/types/snapshot'
import { appendExternalRoles } from '@shared/utils/signerRole'
import {
  ADMIN,
  CreatePdfTemplateForm,
  EXTERNAL,
  RoleDescription,
} from './CreatePdfTemplate'

export enum PageTab {
  RECENT_TEMPLATES = 'recentTemplates',
  PDF_TEMPLATE_LIST = 'pdfTemplateList',
  CREATE_PDF_TEMPLATE = 'createPdfTemplate',
  ASSIGN_TEMPLATE = 'assignTemplates',
  NOTIFY_STATE = 'notifyState',
  STATE_ASSIGNMENT = 'assign',
}

export const DRAFT_STATUS_DISPLAY_DATA_MAP: Record<
  string,
  {
    badgeColor: BadgeColor
    label: string
  }
> = {
  DRAFT_STATUS_UNSPECIFIED: {
    badgeColor: 'green',
    label: 'OK',
  },
  DRAFT_STATUS_PENDING: {
    badgeColor: 'gray',
    label: 'PROCESSING',
  },
  DRAFT_STATUS_ERROR: {
    badgeColor: 'darkOrange',
    label: 'ERROR',
  },
  DRAFT_STATUS_READY: {
    badgeColor: 'darkGray',
    label: 'UNPUBLISHED',
  },
}

type SignersPayload = {
  roles: string[]
  numberOfExternalSigners: number
}

// reversedRoles maps SIGNER_ROLE_ADMIN to 'admin 2'
export function reversedRoles(roleMap) {
  return Object.fromEntries(
    Object.entries(roleMap).map(([key, val]) => [val, key])
  )
}

export function assignSignersFromRoles(
  signers: SignerRole[],
  roleMap
): SignersPayload {
  const reversedRoleMap = reversedRoles(roleMap)
  const numberOfExternalSigners = signers.filter((s) =>
    s.includes('EXTERNAL')
  ).length

  const roleValues = signers.map((s, index) => {
    if (s.includes('ADMIN') && signers.indexOf(s) === index) {
      return ADMIN
    } else {
      return reversedRoleMap[s]
    }
  })
  const uniqueRoles = roleValues.filter(
    (s, index) => roleValues.indexOf(s) === index
  )

  return { numberOfExternalSigners, roles: uniqueRoles }
}

export function assignSignerDescriptionsFromHelloSignAndRoles(
  roles: string[],
  numberOfExternalSigners: number,
  signers: SignerRequest[] | undefined,
  roleMap
): RoleDescription[] {
  if (signers) {
    const reversedRoleMap = reversedRoles(roleMap)
    const rolesWithDescription: RoleDescription[] = signers.reduce(
      (arr: RoleDescription[], s) => {
        let role: string
        if (s.role?.includes('ADMIN') && !arr.find((e) => e.role === ADMIN)) {
          role = ADMIN
        } else {
          role = reversedRoleMap[s.role!]
        }
        arr.push({ role: role, description: s.description || '' })
        return arr
      },
      []
    )
    return rolesWithDescription
  } else {
    let descriptions: RoleDescription[] = []
    roles.forEach((o) => {
      if (o === EXTERNAL) {
        descriptions = descriptions.concat(
          Array(numberOfExternalSigners).fill({
            role: EXTERNAL,
            description: '',
          })
        )
      } else {
        descriptions.push({
          role: o,
          description: '',
        })
      }
    })
    return descriptions
  }
}

export function createFormData(data: CreatePdfTemplateForm, roleMap) {
  const {
    name,
    description,
    file,
    template,
    roles,
    roleDescriptions,
    numberOfExternalSigners,
    projectionType,
  } = data
  // convert fields
  const signerRoles: SignerRole[] = roles.map((r) => roleMap[r.value])
  const descriptions: string[] = roleDescriptions.map((d) => d.description)
  const projection: PdfProj = projectionType.value

  const formData = {
    file: file as File,
    name,
    description,
    copyFromSignableFormId: template?.value || undefined,
    roles: appendExternalRoles({
      roles: signerRoles,
      numberOfExternalSigners: numberOfExternalSigners,
    }),
    roleDescriptions: descriptions,
    projectionType: projection,
  }

  return formData
}

export type TemplateLimit = 'All Templates' | 'Recent Templates'
