import { Array, pipe, String } from 'effect'
import {
  SummarySectionList,
  SummarySectionListItem,
} from '@shared/components/LinkCard/SummarySection'
import { NEWLINE } from '@shared/constants/parsing'
import {
  RoutineAdministration,
  RoutineAdministrationProgressType,
} from '@shared/types/careapp'
import {
  routineAdminIsDone,
  routineAdminIsException,
  routineAdminIsOverDue,
} from '@shared/utils/careapp'
import { tw, twx } from '@shared/utils/tailwind'
import { getIconNameAndClassByStatus, getLabels } from './helpers'

type SummaryTitle = 'Completed' | 'Exceptions' | 'Overdue'

function getSummaryTitle(
  progressType: RoutineAdministrationProgressType
): SummaryTitle | undefined {
  switch (progressType) {
    case 'Done':
      return 'Completed'
    case 'Exceptions':
      return 'Exceptions'
    case 'Overdue':
      return 'Overdue'
    case 'Not started yet':
      return undefined
  }
}

export default function RoutineAdministrationsStatusSummary({
  onAdministrationClick,
  routineAdministrationMap,
  hideTitle,
  liClass,
  showBgColor,
  facilityTimeZone,
}: {
  routineAdministrationMap: Partial<
    Record<RoutineAdministrationProgressType, RoutineAdministration[]>
  >
  onAdministrationClick?: (admin: RoutineAdministration) => void
  hideTitle?: boolean
  liClass?: string
  showBgColor?: boolean
  facilityTimeZone: string
}) {
  return (
    <div className={tw`flex w-full flex-col gap-layout-gap`}>
      {Object.entries(routineAdministrationMap).map(
        ([key, administrations]) => {
          const progressType = key as RoutineAdministrationProgressType
          const { barColorClass, bgColor, iconName, iconClass } =
            getIconNameAndClassByStatus(progressType)
          const title =
            `${getSummaryTitle(progressType)} (${administrations.length})`.toUpperCase()

          if (administrations.length) {
            return (
              <SummarySectionList
                key={title}
                title={hideTitle ? '' : title}
                barColor={barColorClass}
                bgColor={showBgColor ? bgColor : undefined}
              >
                {administrations.map((administration) => {
                  const { id } = administration
                  const { label, subLabel, note } = getLabels({
                    routineAdministration: administration,
                    type: key as RoutineAdministrationProgressType,
                    facilityTimeZone,
                  })

                  return (
                    <SummarySectionListItem
                      key={`summary-item-${key}-${id}`}
                      iconName={iconName}
                      iconClass={iconClass}
                      liClass={twx('font-medium text-gray-07', liClass)}
                      onLiClick={
                        onAdministrationClick
                          ? () => onAdministrationClick(administration)
                          : undefined
                      }
                    >
                      <div>
                        <span className={tw`font-semibold text-secondary-04`}>
                          {label}
                        </span>
                        <div
                          className={tw`flex flex-col flex-wrap gap-layout-gap-xxs`}
                        >
                          {subLabel && <span>{subLabel}</span>}
                          {note &&
                            pipe(
                              note,
                              String.split(NEWLINE),
                              Array.map((line, i) => (
                                <span key={i}>{line}</span>
                              ))
                            )}
                        </div>
                      </div>
                    </SummarySectionListItem>
                  )
                })}
              </SummarySectionList>
            )
          } else return null
        }
      )}
    </div>
  )
}

export function RoutineAdministrationBanner({
  facilityTimeZone,
  routineAdministration,
}: {
  facilityTimeZone: string
  routineAdministration: RoutineAdministration
}) {
  const isException = routineAdminIsException(routineAdministration)
  const isOverdue = routineAdminIsOverDue(
    routineAdministration,
    facilityTimeZone
  )
  const isDone = routineAdminIsDone(routineAdministration)

  return (
    <RoutineAdministrationsStatusSummary
      routineAdministrationMap={{
        Exceptions: isException ? [routineAdministration] : [],
        Overdue: isOverdue ? [routineAdministration] : [],
        Done: isDone ? [routineAdministration] : [],
      }}
      hideTitle
      liClass={tw`py-[8px]`}
      showBgColor
      facilityTimeZone={facilityTimeZone}
    />
  )
}
