import { sortBy } from 'lodash'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { useUserContext } from '@shared/contexts/UserContext'
import { UserAccount } from '@shared/types/user'
import { getPhone } from '@shared/utils/contactPoint'
import { isSuperUser } from '@shared/utils/user'
import HUD from '@app/components/HUD'
import PersonPageTitle from '@app/components/PersonPageTitle'
import {
  NameTd,
  Table,
  TableContainer,
  TdWithExtraPadding,
  TrWithHoverEffect,
} from '@app/components/Table'
import TableHeader from '@app/components/Table/TableHeader'
import useOrganizations from '@app/hooks/useOrganizations'
import rootRouteForUser from '@app/routing/groupBasedRouting'
import OrgEditPopup from './OrgEditPopup'

const TABLE_COLUMN_LABELS = ['Organization', 'Phone']

function getOrgPath(user: UserAccount) {
  return (orgId: string) => {
    return rootRouteForUser(user, orgId)
  }
}

type Props = {
  getPath?: (orgId: string) => string
  showInactiveOrganizations?: boolean
  containerClassNames?: string
}

export default function List(props: Props) {
  const { user } = useUserContext()
  const {
    getPath = getOrgPath(user),
    showInactiveOrganizations = false,
    containerClassNames = '',
  } = props

  const { organizations, inactiveOrganizations, reloadOrgs } =
    useOrganizations()

  const [openOrgPopup, setOpenOrgPopup] = useState(false)
  const [showNotice, setShowNotice] = useState(false)

  const onExpire = () => {
    setShowNotice(false)
  }

  const orgsToDisplay = sortBy(
    organizations.tag !== 'Loading'
      ? showInactiveOrganizations
        ? [...organizations.value, ...inactiveOrganizations]
        : [...organizations.value]
      : [],
    (o) => o.name.toLocaleLowerCase()
  )
  const isEmpty = orgsToDisplay.length === 0

  return (
    <div className={containerClassNames}>
      <PersonPageTitle subtitle title="Organizations">
        {isSuperUser(user) && (
          <AsyncIconButton
            buttonStyle="primary-fill"
            initialIcon="fa-plus"
            buttonSize="small"
            onClick={() => setOpenOrgPopup(true)}
          >
            Add Organization
          </AsyncIconButton>
        )}
      </PersonPageTitle>
      <TableContainer isEmpty={isEmpty}>
        <Table>
          <TableHeader headers={TABLE_COLUMN_LABELS} />
          <tbody>
            {isEmpty && (
              <TrWithHoverEffect>
                <TdWithExtraPadding colSpan={TABLE_COLUMN_LABELS.length}>
                  Organization not found
                </TdWithExtraPadding>
              </TrWithHoverEffect>
            )}
            {!isEmpty &&
              orgsToDisplay.map((org) => {
                const { id: orgId, name, telecom } = org
                const phone = getPhone(telecom)

                return (
                  <TrWithHoverEffect key={`org-row-${orgId}`}>
                    <NameTd>
                      <Link to={getPath(orgId)}>{name}</Link>
                    </NameTd>
                    <TdWithExtraPadding>
                      {phone?.value || ''}
                    </TdWithExtraPadding>
                  </TrWithHoverEffect>
                )
              })}
          </tbody>
        </Table>
      </TableContainer>
      {openOrgPopup && (
        <OrgEditPopup
          organization={undefined}
          onClose={() => setOpenOrgPopup(false)}
          onSave={async () => {
            setOpenOrgPopup(false)
            await reloadOrgs()
            setShowNotice(true)
          }}
        />
      )}
      {showNotice && <HUD onExpire={onExpire}>New organization created!</HUD>}
    </div>
  )
}
