import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { login } from '@shared/api/login'
import {
  loginWithMagicLink,
  userHasAccessToOrg,
} from '@shared/components/Auth/helpers'
import LoginWithUsernameOrEmail from '@shared/components/Auth/LoginWithUsernameOrEmail/LoginWithUsernameOrEmail'
import { LoaderSvg } from '@shared/components/LoadingPopup'
import useQuery from '@shared/hooks/useQuery'
import { tw } from '@shared/utils/tailwind'

function Login({ applicationBasePath }: { applicationBasePath: string }) {
  const [email, setEmail] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const { e: loginEmail, c: loginCode } = useParams<{
    e: string | undefined
    c: string | undefined
  }>()
  const [expired, setExpired] = useState(false)
  const queryString = useQuery()
  const redirectPath = queryString.get('path') || applicationBasePath

  useEffect(() => {
    if (loginEmail && loginCode) {
      setIsLoggingIn(true)

      handleMagicLogin({
        redirectPath,
        email: loginEmail,
        code: loginCode,
        applicationBasePath,
      }).catch(() => {
        setExpired(true)
        setEmail(loginEmail)
      })
    }
  }, [loginCode, loginEmail, redirectPath])

  const onSubmit = async (
    e: React.FormEvent<HTMLFormElement>,
    path: string
  ) => {
    e.preventDefault()
    // clear state of login component for submit
    setExpired(false)
    setIsLoggingIn(false)

    setSubmitted(true)
    await login(email, path)
  }

  return (
    <div
      className={tw`flex h-screen flex-col items-center justify-center text-secondary-03 `}
      slot="sign-in"
    >
      <div
        className={tw`flex flex-col items-center rounded-xl bg-white px-[60px] pb-[16px] pt-[60px]`}
      >
        <img src="/svg/rebrand_logotype.svg" alt={'August Health wordmark'} />
        {expired && <Expired onSubmit={(e) => onSubmit(e, redirectPath)} />}

        {isLoggingIn && !expired && <LoggingYouIn />}

        {!isLoggingIn && submitted && <CheckEmail email={email} />}

        {!isLoggingIn && !submitted && (
          <LoginWithUsernameOrEmail applicationBasePath={applicationBasePath} />
        )}

        <Footer />
      </div>
    </div>
  )
}

const LoggingYouIn = () => (
  <>
    <div className={tw`mt-[40px]`}>
      <LoaderSvg />
    </div>
    <div
      className={tw`mt-[28px] font-inter text-[20px] font-semibold not-italic leading-[24px]`}
    >
      Logging you in
    </div>
  </>
)

function Expired({
  onSubmit,
}: {
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
}) {
  return (
    <div className={tw`flex w-[360px] flex-col items-center`}>
      <div
        className={tw`mt-[40px] font-inter text-[20px] font-semibold not-italic leading-[24px]`}
      >
        Your login link has expired
      </div>
      <form onSubmit={onSubmit} className={tw`w-full`}>
        <input
          className={tw`no-italic disabled:cursor-initial mt-[16px] h-[48px] w-full cursor-pointer rounded-[4px] border-0 bg-rebrand-primary text-center font-inter text-[14px] font-semibold uppercase leading-[16px] text-white hover:brightness-90 disabled:opacity-50`}
          type="submit"
          value="Send me a new link"
        />
      </form>
    </div>
  )
}

function CheckEmail({ email }: { email: string }) {
  return (
    <>
      <div
        className={tw`mt-[40px] font-inter text-[20px] font-semibold not-italic leading-[24px]`}
      >
        Check your email
      </div>
      <div className={tw`mt-[16px]`}>
        <div className={tw`text-center`}>
          We've sent a temporary login link to{' '}
          <span className={tw`font-bold`}>{email}</span>
        </div>
      </div>
    </>
  )
}

export function Footer() {
  return (
    <div
      className={tw`mt-[40px] flex flex-col items-center text-[12px] font-medium leading-[16px] text-secondary-07`}
    >
      <div>
        Or,{' '}
        <a
          href="mailto:support@augusthealth.com"
          className={tw`font-semibold text-rebrand-primary-light hover:underline`}
        >
          contact support
        </a>{' '}
        if you need help to log in.
      </div>
      <div className={tw`mt-[4px]`}>
        By logging in, you accept our{' '}
        <a
          href="/terms.html"
          className={tw`font-semibold text-rebrand-primary-light hover:underline`}
        >
          Terms
        </a>{' '}
        and{' '}
        <a
          href="/privacy.html"
          className={tw`font-semibold text-rebrand-primary-light hover:underline`}
        >
          Privacy Policy
        </a>
      </div>
    </div>
  )
}

async function handleMagicLogin({
  redirectPath,
  email,
  code,
  applicationBasePath,
}: {
  redirectPath: string
  email: string
  code: string
  applicationBasePath: string
}) {
  await loginWithMagicLink(email, code)

  try {
    const isValidRedirect = await userHasAccessToOrg(redirectPath)

    if (isValidRedirect) {
      window.location.href = redirectPath
    } else {
      window.location.href = applicationBasePath
    }
  } catch (e) {
    window.location.href = applicationBasePath
  }
}

export default Login
