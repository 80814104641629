import { formatInTimeZone, zonedTimeToUtc } from 'date-fns-tz'
import { match } from 'ts-pattern'
import { VerticalBarColors } from '@shared/components/VerticalRoundedBar'
import { CHART_COLORS } from '@shared/constants/victory'
import {
  RoutineAdministration,
  RoutineAdministrationProgressType,
} from '@shared/types/careapp'
import {
  getReasonForExceptionDisplay,
  routineAdminIsDone,
  routineAdminIsException,
  routineAdminIsNotStarted,
  routineAdminIsOverDue,
} from '@shared/utils/careapp'

export function getRoutineAdministrationMap(
  routineAdministrations: RoutineAdministration[],
  facilityTimeZone: string
) {
  return routineAdministrations.reduce(
    (hash, administration) => {
      return match(administration)
        .when(routineAdminIsDone, (administration) => ({
          ...hash,
          Done: [...hash.Done, administration],
        }))
        .when(routineAdminIsException, (administration) => ({
          ...hash,
          Exceptions: [...hash.Exceptions, administration],
        }))
        .when(
          (administration) =>
            routineAdminIsOverDue(administration, facilityTimeZone),
          (administration) => ({
            ...hash,
            Overdue: [...hash.Overdue, administration],
          })
        )
        .when(
          (administration) =>
            routineAdminIsNotStarted(administration, facilityTimeZone),
          (administration) => ({
            ...hash,
            'Not started yet': [...hash['Not started yet'], administration],
          })
        )
        .otherwise(() => hash)
    },
    {
      Done: [] as RoutineAdministration[],
      Exceptions: [] as RoutineAdministration[],
      Overdue: [] as RoutineAdministration[],
      'Not started yet': [] as RoutineAdministration[],
    }
  )
}

export function getIconNameAndClassByStatus(
  type: RoutineAdministrationProgressType
): {
  barColorClass: VerticalBarColors
  bgColor: string
  iconName: string
  iconClass: string
} {
  const { BAR_FILL } = CHART_COLORS

  switch (type) {
    case 'Done':
      return {
        barColorClass: 'tags-happy',
        bgColor: BAR_FILL.SUCCESS,
        iconName: 'circle-check',
        iconClass: 'text-tags-happy',
      }
    case 'Overdue':
      return {
        barColorClass: 'tags-red',
        bgColor: BAR_FILL.DANGER,
        iconName: 'siren-on',
        iconClass: 'text-tags-red',
      }
    case 'Exceptions':
      return {
        barColorClass: 'pill-warning',
        bgColor: BAR_FILL.WARNING,
        iconName: 'circle-xmark',
        iconClass: 'text-pill-warning',
      }
    case 'Not started yet':
      return {
        barColorClass: 'mute',
        bgColor: BAR_FILL.MUTED,
        iconName: 'hourglass-start',
        iconClass: 'mute-text',
      }
  }
}

export function getLabels({
  routineAdministration,
  type,
  facilityTimeZone,
}: {
  routineAdministration: RoutineAdministration
  type: RoutineAdministrationProgressType
  facilityTimeZone: string
}): {
  label: string
  subLabel: string | undefined
  note: string | undefined
} {
  const { endAdministrationWindow, orderName, note, reasonForException } =
    routineAdministration

  if (type === 'Overdue') {
    return {
      label: formatInTimeZone(
        zonedTimeToUtc(endAdministrationWindow, facilityTimeZone),
        facilityTimeZone,
        'h:mm a'
      ),
      subLabel: orderName,
      note,
    }
  } else {
    return {
      label: orderName,
      subLabel: reasonForException
        ? getReasonForExceptionDisplay(reasonForException)
        : undefined,
      note,
    }
  }
}
