import { Coverage_CoverageType } from '@augusthealth/models/com/august/protos/coverage'
import Card from '@shared/components/Card'
import { tw, twx } from '@shared/utils/tailwind'
import { Prefix } from '.'
import { createTitle, IndexedCoverage } from './helpers'

const CARD_INCOMPLETE = 'Not Complete'

type CardProps = {
  prefix: Prefix
  onClick: (() => void) | undefined
  coverage: IndexedCoverage
  'data-testid'?: string
}

type CompleteCardProps = CardProps & {
  onDelete?: (selectedIndex: number) => void
}

const cardClassNames = tw`mb-[16px] w-full`

export function CompleteCard({
  prefix,
  onClick,
  coverage,
  onDelete,
  ...rest
}: CompleteCardProps) {
  const title = createTitle({ prefix, type: coverage.type })
  return (
    <div className="flex items-center">
      <Card
        id={coverage.type}
        data-testid={rest['data-testid']}
        className={twx(cardClassNames)}
        cardStyle="focal"
        onClick={onClick}
      >
        <div className="flex flex-col">
          <span
            className={tw`text-[14px] font-bold capitalize leading-[20px] text-gray-03`}
          >
            {title} Insurance
          </span>

          <div className={tw`flex justify-between`}>
            <div className={tw`mt-[8px]`}>
              <CardDetails
                title="Insurance Provider"
                description={coverage?.name}
              />
              <CardDetails
                title="Member ID"
                description={coverage?.subscriberId}
              />
              <CardDetails
                title="Group Number"
                description={coverage?.plan?.value}
              />
              {coverage?.type ===
                Coverage_CoverageType.COVERAGE_TYPE_PHARMACY && (
                <>
                  <CardDetails
                    title="BIN"
                    description={coverage?.rxbin?.value}
                  />
                  <CardDetails
                    title="PCN"
                    description={coverage?.rxpcn?.value}
                  />
                </>
              )}
            </div>
          </div>
        </div>

        <button
          onClick={() => {
            if (onDelete) {
              onDelete(coverage.index)
            }
          }}
          data-testid={`delete-coverage-${coverage.index}`}
        >
          <i className={tw`fa fa-trash text-gray-07`} />
        </button>
      </Card>
    </div>
  )
}

export function IncompleteCard({ coverage, prefix, onClick }: CardProps) {
  const title = createTitle({ prefix, type: coverage.type })
  return (
    <Card
      id={coverage.type}
      data-testid={title}
      className={twx(cardClassNames)}
      cardStyle="focal"
      onClick={onClick}
    >
      <div className="flex flex-col">
        <span
          className={tw`text-[14px] font-bold capitalize leading-[20px] text-gray-03`}
        >
          {title} Insurance
        </span>

        <div className={tw`mt-[8px]`}>
          <CardDetails
            title="Insurance Provider"
            description={CARD_INCOMPLETE}
          />
        </div>
      </div>
    </Card>
  )
}

function CardDescription({ description }: { description: string | undefined }) {
  const isIncomplete = description === CARD_INCOMPLETE
  return (
    <span
      className={twx(
        'inline-block w-[160px] text-[14px] font-medium leading-[20px] text-gray-06',
        {
          'text-gray-08': isIncomplete,
          'text-gray-03': !isIncomplete,
        }
      )}
    >
      {description}
    </span>
  )
}

function CardDetails({
  title,
  description,
}: {
  title: string
  description: string | undefined
}) {
  return (
    <>
      <span
        className={tw`inline-block w-[160px] text-[14px] font-[600] leading-[20px] text-gray-06`}
      >
        {title}
      </span>{' '}
      <CardDescription description={description} />
    </>
  )
}
