// eslint-disable-next-line check-file/filename-naming-convention
import React, { ForwardedRef, forwardRef } from 'react'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import Icon from '@shared/components/Icon'
import { tw, twx } from '@shared/utils/tailwind'

export interface BasicInputProps extends React.HTMLProps<HTMLInputElement> {
  ref?: ForwardedRef<HTMLInputElement>
  valueSuffix?: string
  iconName?: string
  iconClassNames?: string
  showErrorBorder?: boolean
}

export const BasicInput = forwardRef<
  HTMLInputElement,
  BasicInputProps & {
    ref?: ForwardedRef<HTMLInputElement>
    type?: string
    name?: string
    valueSuffix?: string
    className?: string
    containerClassName?: string
    disabled?: boolean
    readOnly?: boolean
    inputSize?: 'large' | 'default' | 'inline'
    showErrorBorder?: boolean
  }
>((props, ref) => {
  const {
    className,
    containerClassName,
    inputSize = 'default',
    showErrorBorder,
    type = 'text',
    valueSuffix,
    iconName,
    iconClassNames,
    ...rest
  } = props
  if (type === 'time') {
    const timeIconClassNames = twx(
      'fa fa-clock cursor-pointer',
      'text-primary-light absolute top-[12px] left-[12px] z-0',
      {
        ['text-form-input-disabled-text-color cursor-default']: props.disabled,
      },
      iconClassNames
    )
    const inputClassNames = twx(
      'cursor-pointer w-full h-input bg-white border border-form-input-border',
      'rounded-input p-inputPadding text-formInput leading-[16px] font-medium placeholder:text-form-input-placeholder-color',
      'hover:enabled:border-form-input-border-hover focus:enabled:border-form-input-border-hover focus-visible:placeholder:text-form-input-placeholder-color',
      'active:placeholder:text-form-input-placeholder-color focus-visible:outline focus-visible:outline-double focus-visible:outline-form-input-border-hover',
      'disabled:bg-form-input-disabled-background-color disabled:text-form-input-disabled-text-color disabled:cursor-default disabled:resize-none',
      'pl-inputIconLeftPadding',
      '[&::-webkit-calendar-picker-indicator]:cursor-pointer [&::-webkit-calendar-picker-indicator]:opacity-0 [&::-webkit-calendar-picker-indicator]:absolute [&::-webkit-calendar-picker-indicator]:left-[4px] [&::-webkit-calendar-picker-indicator]:top-[14px] [&::-webkit-calendar-picker-indicator]:z-[1] [&::-webkit-calendar-picker-indicator]:appearance-none',
      {
        ['border-form-input-error-border focus-visible:outline-form-input-error-border focus-visible:border:form-input-error-border focus:enabled:border-form-input-error-border focus-within:border-form-input-error-border focus-within:outline-double focus-within:outline-form-input-error-border']:
          showErrorBorder,
      },
      props.className
    )
    return (
      <div className={twx('relative', containerClassName)}>
        <input
          ref={ref}
          data-testid={`text-input-${props.name}`}
          type={type}
          id={props.name}
          {...rest}
          className={twx(inputClassNames)}
        />
        <i className={twx(timeIconClassNames)} />
      </div>
    )
  }

  const inputClassNames = twx(
    'cursor-pointer w-full bg-white border border-form-input-border',
    'rounded-input p-inputPadding text-formInput leading-[16px] font-medium placeholder:text-form-input-placeholder-color',
    'hover:enabled:border-form-input-border-hover focus:enabled:border-form-input-border-hover focus-visible:placeholder:text-form-input-placeholder-color',
    'active:placeholder:text-form-input-placeholder-color focus-visible:outline focus-visible:outline-double focus-visible:outline-form-input-border-hover',
    'disabled:bg-form-input-disabled-background-color disabled:text-form-input-disabled-text-color disabled:cursor-default disabled:resize-none',
    'read-only:bg-form-input-disabled-background-color read-only:text-form-input-disabled-text-color read-only:cursor-default read-only:resize-none',
    {
      ['h-input']: inputSize === 'default',
      ['h-largeInput']: inputSize === 'large',
      ['h-inlineInput']: inputSize === 'inline',
      ['border-form-input-error-border hover:enabled:border-form-input-error-border focus:enabled:border-form-input-error-border focus-visible:enabled:outline-form-input-error-border']:
        showErrorBorder,
    },
    props.className
  )

  return (
    <div className={twx('relative', containerClassName)}>
      <input
        ref={ref}
        data-testid={`text-input-${props.name}`}
        type={type}
        id={props.name}
        {...rest}
        className={twx(inputClassNames, {
          'pl-7': iconName !== undefined,
          'pr-16': valueSuffix !== undefined,
        })}
        onWheel={(e: React.WheelEvent<HTMLInputElement>) => {
          if (type === 'number') {
            e.currentTarget.blur()
          }
        }}
      />
      {iconName && (
        <Icon
          name={iconName}
          className={twx(
            'pointer-events-none absolute bottom-0 left-2 top-[50%] mt-[-0.5em] block h-4',
            iconClassNames
          )}
        />
      )}
      {valueSuffix && (
        <div
          data-testid={`text-input-${props.name}-suffix`}
          className={tw`pointer-events-none absolute bottom-0 right-2 top-0 my-auto flex h-4 items-center justify-center text-sm font-medium leading-none text-gray-07`}
        >
          <div>{valueSuffix}</div>
        </div>
      )}
    </div>
  )
})
BasicInput.displayName = 'BasicInput'

export type BasicTextAreaProps = React.HTMLProps<HTMLTextAreaElement>

export const BasicTextarea = forwardRef<
  HTMLTextAreaElement,
  BasicTextAreaProps & {
    className?: string
    name?: string
    onBlur?: React.FocusEventHandler<HTMLTextAreaElement>
    disabled?: boolean
    showErrorBorder?: boolean
    ignoreMaxWidth?: boolean
  }
>((props, ref) => {
  const { className, showErrorBorder, ignoreMaxWidth, ...rest } = props
  const classes = twx(
    'placeholder:text-form-input-placeholder-color',
    'hover:enabled:border-form-input-border-hover, focus:enabled:border-form-input-border-hover',
    'focus-visible:enabled:outline focus-visible:enabled:outline-double focus-visible:enabled:outline-form-input-border-hover',
    'disabled:bg-form-input-disabled-background-color disabled:text-form-input-disabled-text-color disabled:cursor-default disabled:resize-none',
    'cursor-pointer w-full bg-white border border-form-input-border rounded-input p-inputPadding text-formInput leading-[20px] font-medium',
    {
      ['relative max-w-[600px']: Boolean(props.onBlur && !ignoreMaxWidth),
      ['border-form-input-error-border hover:enabled:border-form-input-error-border focus:enabled:border-form-input-error-border focus-visible:enabled:outline-form-input-error-border']:
        showErrorBorder,
    },
    className
  )
  return (
    <textarea
      data-testid={`textarea-${props.name}`}
      id={rest.name}
      {...rest}
      ref={ref}
      className={twx(classes)}
    />
  )
})
BasicTextarea.displayName = 'BasicTextarea'

export const ResizingTextarea = forwardRef<
  HTMLSpanElement,
  BasicTextAreaProps & {
    className?: string
    name?: string
    onChange?: React.ChangeEventHandler<HTMLTextAreaElement>
    placeholder?: string
    formControls?: {
      onClickCancel: () => void
      onClickSubmit: () => void
      isSubmitting: boolean
    }
  }
>((props, ref) => {
  const parentClasses = twx(
    'placeholder:text-form-input-placeholder-color',
    'hover:border-form-input-border-hover focus:border-form-input-border-hover focus-within:border-form-input-border-hover active:border-form-input-border-hover',
    'has-[:focus-visible]:outline-1 has-[:focus-visible]:outline-double has-[:focus-visible]:outline-form-input-border-hover',
    'disabled:bg-form-input-disabled-background-color disabled:text-form-input-disabled-text-color disabled:cursor-default disabled:resize-none',
    'cursor-pointer w-full bg-white border border-form-input-border rounded-input p-inputPadding text-formInput leading-[20px] font-medium',
    'flex flex-col',
    props.className
  )
  return (
    <div className={twx(parentClasses)}>
      <span
        placeholder={props.placeholder}
        data-testid={`textarea-${props.name}`}
        role="textbox"
        className={twx(
          'block flex-1 outline-none',
          'before:[&[contenteditable]]:empty:pointer-events-none before:[&[contenteditable]]:empty:block',
          'before:[&[contenteditable]]:empty:text-form-input-placeholder-color before:[&[contenteditable]]:empty:content-[attr(placeholder)]'
        )}
        onInput={props.onChange}
        ref={ref}
        contentEditable
      />
      {props.formControls && (
        <div
          className={twx(
            'flex items-center px-[4px] pb-0 pt-[16px] print:hidden'
          )}
        >
          <AsyncIconButton
            isLoading={false}
            buttonStyle="tertiary-fill"
            buttonSize="small"
            className={twx('mr-[8px]')}
            onClick={props.formControls.onClickCancel}
          >
            Cancel
          </AsyncIconButton>
          <AsyncIconButton
            buttonStyle="primary-fill"
            buttonSize="small"
            disabled={props.formControls.isSubmitting}
            isLoading={props.formControls.isSubmitting}
            onClick={props.formControls.onClickSubmit}
          >
            Submit
          </AsyncIconButton>
        </div>
      )}
    </div>
  )
})
ResizingTextarea.displayName = 'ResizingTextarea'

interface BasicCheckboxProps extends React.PropsWithChildren<BasicInputProps> {
  labelClassName?: string
}

export const BasicCheckbox = forwardRef<
  HTMLInputElement,
  BasicCheckboxProps & {
    labelClassName?: string
    name?: string
    className?: string
    children?: React.ReactNode
  }
>((props, ref) => {
  const { children, labelClassName, ...remainingProps } = props
  const labelClassNames = twx(
    'cursor-pointer flex items-center relative leading-[20px] mb-[16px]',
    { 'cursor-default': props.disabled },
    labelClassName
  )
  const inputClassNames = twx(
    'appearance-none border border-duotone-level3 rounded-[3px] mr-[8px]',
    'absolute top-0 left-0 h-[16px] w-[16px] bg-white transition-all duration-75 ease-linear',
    'hover:enabled:not:checked:bg-gray-11 hover:enabled:cursor-pointer',
    'focus:enabled:outline-primary-light focus:enabled:outline-2',
    'checked:bg-august-primary checked:border-august-primary',
    'print:checked:border-8',
    'disabled:opacity-50 disabled:cursor-default',
    props.className
  )

  return (
    <label className={twx(labelClassNames)}>
      <input
        data-testid={`checkbox-${props.name}`}
        type="checkbox"
        {...remainingProps}
        className={twx(inputClassNames)}
        ref={ref}
      />
      <span
        className={twx('ml-[24px] font-medium leading-[16px]', {
          'cursor-default text-duotone-level3': props.disabled,
        })}
      >
        {children}
      </span>
      <span
        className={twx(
          "after:content-[' '] after:absolute after:left-[5px] after:top-[2px] after:h-[10px] after:w-[6px]",
          'after:rotate-45 after:border-b-[2px] after:border-l-0  after:border-r-[2px] after:border-t-0 after:border-white',
          'after:not:peer-checked:hidden after:peer-checked:block',
          'print:opacity-100',
          { 'cursor-default opacity-50': props.disabled }
        )}
      />
    </label>
  )
})
BasicCheckbox.displayName = 'BasicCheckbox'

type BasicRadioProps = React.PropsWithChildren<BasicInputProps>

export type BasicRadioButtonProps = BasicRadioProps & {
  className?: string
  holderClassName?: string
  radioLabelClassName?: string
}

export const BasicRadioButton = forwardRef<
  HTMLInputElement,
  BasicRadioButtonProps
>((props, ref) => {
  const {
    children,
    className,
    holderClassName,
    radioLabelClassName,
    ...remainingProps
  } = props
  const holderClassNames = twx(
    'flex items-start cursor-pointer',
    holderClassName
  )
  const inputClassNames = twx(
    'appearance-none bg-white border border-secondary-07 rounded-[8px] enabled:cursor-pointer',
    'min-w-[16px] w-[16px] h-[16px] mr-[5px] mt-[4px] mb-[4px] transition-all ease-linear duration-75',
    'checked:border-[5px] checked:border-button-primary-color',
    'focus:enabled:outline-primary-light focus:enabled:outline-2',
    'disabled:opacity-50 disabled:cursor-default',
    className
  )
  const radioLabelClassNames = twx(
    'font-medium ml-[2px] leading-[24px]',
    { 'text-duotone-level3 cursor-default': props.disabled },
    radioLabelClassName
  )

  return (
    <label className={twx(holderClassNames)}>
      <input
        type="radio"
        {...remainingProps}
        className={twx(inputClassNames)}
        ref={ref}
      />
      <span className={twx(radioLabelClassNames)}>{children}</span>
    </label>
  )
})
BasicRadioButton.displayName = 'BasicRadioButton'
