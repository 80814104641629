import { flatten, groupBy, isMatchWith, mapValues, uniq } from 'lodash'
import {
  FeatureFlagNames,
  isFeatureFlagAllowedForEnv,
} from '@shared/constants/feature_flags'
import { Facility } from '@shared/types/facility'
import { GroupType, PersonMatcher } from '@shared/types/permission'
import { Signer } from '@shared/types/snapshot'
import { UserAccount } from '@shared/types/user'
import notEmpty from '@shared/utils/notEmpty'

type GetFacilities = {
  facilities?: Facility[]
  user?: UserAccount
}

function isSpecificUser({
  user,
  groupType,
  matcher,
}: {
  user: UserAccount
  groupType: GroupType
  matcher?: PersonMatcher
}) {
  return Boolean(
    user.groups?.some((g) => {
      const { groupType: userGroupType, personMatcher } = g
      const {
        organizationId: userOrgId,
        facilityId: userFacilityId,
        personId: userPersonId,
      } = personMatcher || {}
      const { personId, facilityId, organizationId } = matcher || {}

      return (
        userGroupType === groupType &&
        (!matcher ||
          ((!personId || personId === userPersonId) &&
            (!facilityId || facilityId === userFacilityId) &&
            (!organizationId || organizationId === userOrgId)))
      )
    })
  )
}

type UserRequiredOrgId = {
  user: UserAccount
  orgId: string
}

function isSpecificUserRequiredOrgId(
  params: UserRequiredOrgId & { groupType: GroupType }
) {
  const { user, groupType, orgId } = params
  return isSpecificUser({ user, groupType, matcher: { organizationId: orgId } })
}

type UserRequiredFacilityId = {
  user: UserAccount
  facilityId: string
  orgId: string
}

function isSpecificUserRequiredFacilityId(
  params: UserRequiredFacilityId & { groupType: GroupType }
) {
  const { user, groupType, facilityId, orgId } = params
  return isSpecificUser({
    user,
    groupType,
    matcher: { organizationId: orgId, facilityId },
  })
}

type UserRequiredPersonId = {
  user: UserAccount
  facilityId: string
  orgId: string
  personId: string
}

function isSpecificUserRequiredPersonId(
  params: UserRequiredPersonId & { groupType: GroupType }
) {
  const { user, groupType, facilityId, orgId, personId } = params
  return isSpecificUser({
    user,
    groupType,
    matcher: { organizationId: orgId, facilityId, personId },
  })
}

export const isSuperUser = (user: UserAccount) =>
  isSpecificUser({ user, groupType: GroupType.GROUP_TYPE_SUPER_USER })

export const isOrgAdmin = (params: UserRequiredOrgId) =>
  isSpecificUserRequiredOrgId({
    ...params,
    groupType: GroupType.GROUP_TYPE_ORGANIZATION_ADMIN,
  })

export const hasFullOrgAccess = ({ orgId, user }: UserRequiredOrgId) =>
  isSuperUser(user) ||
  Boolean(
    user.groups?.some(({ personMatcher }) => {
      const { organizationId, facilityId } = personMatcher || {}
      return organizationId === orgId && !facilityId
    })
  )

export const isPharmacist = (params: UserRequiredFacilityId) =>
  isSpecificUserRequiredFacilityId({
    ...params,
    groupType: GroupType.GROUP_TYPE_PHARMACIST,
  })

export const isMedTechSupervisor = (params: UserRequiredOrgId) =>
  isSpecificUserRequiredOrgId({
    ...params,
    groupType: GroupType.GROUP_TYPE_MED_TECH_SUPERVISOR,
  })

/**
 * facilityId is optional when viewing Settings page for Director
 * orgId is always required
 */
export const isDirector = (
  params: UserRequiredOrgId & { facilityId?: string }
) =>
  isRoleForOrgOrFacility({
    ...params,
    role: GroupType.GROUP_TYPE_FACILITY_DIRECTOR,
  })

export const isFacilityStaff = (
  params: UserRequiredOrgId & { facilityId?: string }
) =>
  isRoleForOrgOrFacility({
    ...params,
    role: GroupType.GROUP_TYPE_FACILITY_STAFF,
  })

export const isFacilitySalesAndMarketing = (
  params: UserRequiredOrgId & { facilityId?: string }
) =>
  isRoleForOrgOrFacility({
    ...params,
    role: GroupType.GROUP_TYPE_FACILITY_SALES_AND_MARKETING,
  })

export const isOrgUserManager = (params: UserRequiredOrgId) =>
  isRoleForOrgOrFacility({
    ...params,
    role: GroupType.GROUP_TYPE_ORG_USER_MANAGEMENT,
  })

export const isFacilityUserManager = (
  params: UserRequiredOrgId & { facilityId?: string }
) =>
  isRoleForOrgOrFacility({
    ...params,
    role: GroupType.GROUP_TYPE_FACILITY_USER_MANAGEMENT,
  })

const isRoleForOrgOrFacility = (
  params: UserRequiredOrgId & { facilityId?: string; role: GroupType }
) => {
  const { facilityId, role, user, orgId } = params
  if (facilityId) {
    return isSpecificUserRequiredFacilityId({
      user,
      orgId,
      facilityId,
      groupType: role,
    })
  }

  return isSpecificUserRequiredOrgId({ user, orgId, groupType: role })
}

export const isAdmin = (user: UserAccount, matcher: PersonMatcher) => {
  const { organizationId: orgId, facilityId } = matcher

  if (isSuperUser(user)) return true
  if (orgId && isOrgAdmin({ user, orgId })) return true
  if (orgId && facilityId) {
    if (isFacilitySalesAndMarketing({ user, orgId, facilityId })) return true
    if (isFacilityStaff({ user, orgId, facilityId })) return true
    if (isDirector({ user, orgId, facilityId })) return true
  }

  return false
}

const hasRole = (user: UserAccount, type: GroupType) =>
  Boolean(user.groups?.some(({ groupType }) => type === groupType))

export const hasOrgAdminRole = (user: UserAccount) =>
  hasRole(user, GroupType.GROUP_TYPE_ORGANIZATION_ADMIN)
export const hasPharmacistRole = (user: UserAccount) =>
  hasRole(user, GroupType.GROUP_TYPE_PHARMACIST)
export const hasDirectorRole = (user: UserAccount) =>
  hasRole(user, GroupType.GROUP_TYPE_FACILITY_DIRECTOR)
export const hasFacilitySalesAndMarketingRole = (user: UserAccount) =>
  hasRole(user, GroupType.GROUP_TYPE_FACILITY_SALES_AND_MARKETING)
export const hasSalesAndMarketingPlusBillingRole = (user: UserAccount) =>
  hasRole(user, GroupType.GROUP_TYPE_SALES_AND_MARKETING_PLUS_BILLING)
export const hasFacilityStaffRole = (user: UserAccount) =>
  hasRole(user, GroupType.GROUP_TYPE_FACILITY_STAFF)
export const hasSocialWorkerRole = (user: UserAccount) =>
  hasRole(user, GroupType.GROUP_TYPE_SOCIAL_WORKER)
export const hasBillingRole = (user: UserAccount) =>
  hasRole(user, GroupType.GROUP_TYPE_BILLING)
export const hasNurseRole = (user: UserAccount) =>
  hasRole(user, GroupType.GROUP_TYPE_NURSE)
export const hasMedTechRole = (user: UserAccount) =>
  hasRole(user, GroupType.GROUP_TYPE_MED_TECH)
export const hasMedTechSupervisorRole = (user: UserAccount) =>
  hasRole(user, GroupType.GROUP_TYPE_MED_TECH_SUPERVISOR)
export const hasMedicalRole = (user: UserAccount) =>
  hasRole(user, GroupType.GROUP_TYPE_MEDICAL)
export const hasFrontDeskRole = (user: UserAccount) =>
  hasRole(user, GroupType.GROUP_TYPE_FRONT_DESK)
export const hasResponsiblePersonRole = (user: UserAccount) =>
  hasRole(user, GroupType.GROUP_TYPE_RESPONSIBLE_PARTY)
export const hasExternalRole = (user: UserAccount) =>
  hasRole(user, GroupType.GROUP_TYPE_EXTERNAL_CONTACT)
export const hasOrganizationUserManagementRole = (user: UserAccount) =>
  hasRole(user, GroupType.GROUP_TYPE_ORG_USER_MANAGEMENT)
export const hasFacilityUserManagementRole = (user: UserAccount) =>
  hasRole(user, GroupType.GROUP_TYPE_FACILITY_USER_MANAGEMENT)
export const hasCaregiverRole = (user: UserAccount) =>
  hasRole(user, GroupType.GROUP_TYPE_CAREGIVER)
export const hasCaregiverPlusEhrRole = (user: UserAccount) =>
  hasRole(user, GroupType.GROUP_TYPE_CAREGIVER_PLUS_EHR)

export const hasAdminRole = (user: UserAccount) =>
  Boolean(
    user.groups?.some(
      ({ groupType }) =>
        groupType === GroupType.GROUP_TYPE_SUPER_USER ||
        groupType === GroupType.GROUP_TYPE_ORGANIZATION_ADMIN ||
        groupType === GroupType.GROUP_TYPE_FACILITY_DIRECTOR
    )
  )

export const hasRoleWithoutSidebar = (user: UserAccount) =>
  Boolean(
    user.groups?.some(
      ({ groupType }) =>
        groupType === GroupType.GROUP_TYPE_RESPONSIBLE_PARTY ||
        groupType === GroupType.GROUP_TYPE_SOCIAL_WORKER ||
        groupType === GroupType.GROUP_TYPE_EXTERNAL_CONTACT ||
        groupType === GroupType.GROUP_TYPE_PAYER
    )
  )

export const isToolsUser = (user: UserAccount) =>
  isSpecificUser({ user, groupType: GroupType.GROUP_TYPE_TOOL_USER })

export const isAnalyst = (user: UserAccount) =>
  isSpecificUser({ user, groupType: GroupType.GROUP_TYPE_ANALYST })

export const isResponsiblePerson = (params: UserRequiredPersonId) =>
  isSpecificUserRequiredPersonId({
    ...params,
    groupType: GroupType.GROUP_TYPE_RESPONSIBLE_PARTY,
  })

export const isExternalSigner = (params: UserRequiredPersonId) =>
  isSpecificUserRequiredPersonId({
    ...params,
    groupType: GroupType.GROUP_TYPE_EXTERNAL_CONTACT,
  })

export const isMedicalUser = (params: UserRequiredPersonId) =>
  isSpecificUserRequiredPersonId({
    ...params,
    groupType: GroupType.GROUP_TYPE_MEDICAL,
  })

export const isSocialWorker = (params: UserRequiredPersonId) =>
  isSpecificUserRequiredPersonId({
    ...params,
    groupType: GroupType.GROUP_TYPE_SOCIAL_WORKER,
  })

export const isBillingUser = (params: UserRequiredFacilityId) =>
  isSpecificUserRequiredFacilityId({
    ...params,
    groupType: GroupType.GROUP_TYPE_BILLING,
  })

export const isMedtechUser = (params: UserRequiredFacilityId) =>
  isSpecificUserRequiredFacilityId({
    ...params,
    groupType: GroupType.GROUP_TYPE_MED_TECH,
  })

export const isFrontDeskUser = (params: UserRequiredFacilityId) =>
  isSpecificUserRequiredFacilityId({
    ...params,
    groupType: GroupType.GROUP_TYPE_FRONT_DESK,
  })

export const isNurseUser = (params: UserRequiredFacilityId) =>
  isSpecificUserRequiredFacilityId({
    ...params,
    groupType: GroupType.GROUP_TYPE_NURSE,
  })

export function getRelevantFacilities(props: GetFacilities): Facility[] {
  const { facilities, user } = props

  if (!facilities || !user || !user.groups) {
    return []
  }

  if (isSuperUser(user)) {
    return facilities
  }

  return facilities.filter((f) =>
    (user.groups ?? []).some((g) =>
      isMatchWith(
        {
          organizationId: f.orgId,
          facilityId: f.id,
          personId: undefined,
        },
        {
          organizationId: undefined,
          facilityId: undefined,
          ...g.personMatcher,
          personId: undefined,
        },
        (personValue, matcherValue) =>
          personValue === matcherValue || matcherValue === undefined
      )
    )
  )
}

export function isFeatureAllowed(
  user: UserAccount | undefined,
  featureName: FeatureFlagNames
): boolean {
  const isCypress = window.Cypress
  if (isCypress) {
    return (
      window.sessionStorage
        .getItem('cypress_feature_flags')
        ?.includes(featureName) || false
    )
  }

  if (!isFeatureFlagAllowedForEnv(featureName)) {
    return false
  }

  return user?.featureFlags?.includes(featureName) || false
}

export function canEditOwnPermissions(user: UserAccount | undefined) {
  return (
    user &&
    (process.env.REACT_APP_ENV === 'local' ||
      process.env.REACT_APP_ENV === 'staging') &&
    isSuperUser(user)
  )
}

export function belongsToMultipleOrgs(user: UserAccount) {
  return (
    uniq(
      (user.groups ?? []).map((g) => g.personMatcher?.organizationId)
    ).filter(notEmpty).length > 1
  )
}

export function userMatchesSigner(user: UserAccount, signer?: Signer) {
  const idMatches = user.id === signer?.userAccountId
  const emailMatches = user.email !== undefined && user.email === signer?.email

  return idMatches || emailMatches
}

export function hasEmail(user: UserAccount) {
  return Boolean(user.email)
}

export function canReceiveEmail({
  checkPermissionFunc,
  user,
}: {
  checkPermissionFunc: (user: UserAccount) => boolean
  user: UserAccount
}) {
  return checkPermissionFunc(user) && hasEmail(user)
}

/**
 * Given a list of a list of user accounts, find all matching accounts (by id)
 * and merge their groups into a single UserAccount.
 * @param userListInList
 */
export function mergeFacilityUsers(userListInList: UserAccount[][]) {
  const groupedById = groupBy(flatten(userListInList), 'id')
  const result = mapValues(groupedById, (u) =>
    u.reduce((accum, el) => ({
      ...accum,
      groups: [...(accum.groups ?? []), ...(el.groups ?? [])],
    }))
  )

  return Object.values(result)
}
