import { useContext, useEffect, useState } from 'react'
import GlobalContext from '@shared/contexts/GlobalContext'
import { MedicationOrder, OrderAndDiff } from '@shared/types/medication_order'
import { Loading } from '@shared/utils/loading'
import { getMedicationOrderDiffs } from '../api/medicationOrders'
import PersonContext from '../contexts/PersonContext'

export function usePharmacyMessageDiffs(
  medicationOrder?: MedicationOrder,
  orderGroupId?: string
): {
  messageDiffs: Loading<OrderAndDiff | undefined>
} {
  const { setError } = useContext(GlobalContext)
  const { person } = useContext(PersonContext)
  const [messageDiffs, setMessageDiffs] = useState<
    Loading<OrderAndDiff | undefined>
  >({
    tag: 'Loading',
  })

  const reloadPharmacyMessageDiffs = async () => {
    if (person && medicationOrder) {
      try {
        if (orderGroupId && medicationOrder?.sourceData?.messageId) {
          const diffs = await getMedicationOrderDiffs({
            person,
            orderGroupId,
            pharmacyMessageId: medicationOrder.sourceData!.messageId!,
          })

          if (
            diffs.withDiffs.diffs.length > 0 ||
            diffs.withDiffs.dosageDiffs.some((dd) => dd.length > 0)
          ) {
            setMessageDiffs({
              tag: 'Complete',
              value: diffs,
            })
          } else {
            setMessageDiffs({
              tag: 'Complete',
              value: undefined,
            })
          }
        } else {
          setMessageDiffs({
            tag: 'Complete',
            value: undefined,
          })
        }
      } catch (e) {
        setMessageDiffs({
          tag: 'Complete',
          value: undefined,
        })
        setError(e)
      }
    }
  }

  useEffect(() => {
    setMessageDiffs({ tag: 'Loading' })
    void reloadPharmacyMessageDiffs()
  }, [medicationOrder?.sourceData?.messageId, orderGroupId])

  return {
    messageDiffs,
  }
}
