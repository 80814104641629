import { useState } from 'react'
import { Link } from 'react-router-dom'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { Menu } from '@shared/components/baseMui/Menu/Menu'
import Card from '@shared/components/Card'
import Icon from '@shared/components/Icon'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import { Facility } from '@shared/types/facility'
import { MedicationOrder } from '@shared/types/medication_order'
import { GroupPermission } from '@shared/types/permission'
import { Person } from '@shared/types/person'
import { RefillEligibility } from '@shared/types/pharmacy_refill'
import { UserAccount } from '@shared/types/user'
import {
  maximumDosesPerDayDescriptor,
  minimumTimeBetweenDosesDescriptor,
} from '@shared/utils/dosage'
import {
  getAllAdditionalInstructions,
  getDoseAndRate,
  getMedicationOrderInstructions,
} from '@shared/utils/medicationOrder'
import {
  canHaveMessageDiffs,
  isExternal,
  isPending,
} from '@shared/utils/medicationStatement'
import { tw, twx } from '@shared/utils/tailwind'
import { RefillModal } from '@app/components/Residents/Medications/Orders/Refills/RefillModal'
import styles from './styles.module.css'
import { OrderFooter } from './OrderFooter'
import { OrderHeader } from './OrderHeader'

type Props = {
  person: Person
  user: UserAccount
  facility: Facility
  order: MedicationOrder
  canInteractWithMedication: boolean
  refillEligibility: RefillEligibility | null
  discontinued?: boolean
  highlight?: boolean
  pending?: boolean
}

const OrderCard = ({
  order,
  user,
  person,
  facility,
  canInteractWithMedication,
  refillEligibility,
  discontinued = false,
  highlight = false,
}: Props) => {
  const [showRefillModal, setShowRefillModal] = useState(false)
  const instructions = getMedicationOrderInstructions(order)

  const hasReviewAction = isPending(order)

  const reviewOrderUrl = () => {
    let url = `pharmacy-messages/${order.sourceData?.messageId}/review`
    if (order.orderGroupId && canHaveMessageDiffs(order)) {
      url = `pharmacy-messages/${order.sourceData?.messageId}/order-groups/${order.orderGroupId}/review`
    }
    return url
  }

  const editOrderUrl = () => {
    return `medications/${order.id}/edit`
  }

  function cardUrl() {
    if (canInteractWithMedication) {
      if (hasReviewAction) {
        return reviewOrderUrl()
      } else {
        return editOrderUrl()
      }
    } else {
      return ''
    }
  }

  const allAdditionalInstructions = getAllAdditionalInstructions(order)

  const hasOrderRefillPotential =
    isExternal(order) && !discontinued && refillEligibility

  return (
    <>
      <RefillModal
        user={user}
        person={person}
        open={showRefillModal}
        order={order}
        onClose={() => setShowRefillModal(false)}
      />
      <span className={tw`relative`}>
        <Link
          to={cardUrl()}
          className={tw`hover:no-underline`}
          data-testid={order.id ? `anchor-${order.id}` : undefined}
        >
          <Card
            id={order.id ? `order-${order.id}` : undefined}
            className={twx(
              `my-0 flex cursor-default flex-row items-center justify-between border-[1px] border-transparent`,
              {
                'cursor-pointer hover:border-primary-light':
                  canInteractWithMedication,
                'rounded-l-none border-l-[2px] border-l-primary-light':
                  highlight,
                'border-secondary-10 bg-secondary-11': discontinued,
              }
            )}
            onClick={cardUrl}
          >
            <div className={tw`w-full`}>
              <OrderHeader
                order={order}
                renderMode={'list'}
                discontinued={discontinued}
              />
              <div className={styles.medInstructions}>
                {instructions.map((inst, index) => (
                  <span
                    key={`${order.id}-instruction-${index}`}
                    className={tw`block`}
                  >
                    {inst}
                  </span>
                ))}
                {allAdditionalInstructions.map((inst, index) => (
                  <span
                    key={`${order.id}-additional-instruction-${index}`}
                    className={tw`block`}
                  >
                    {inst}
                  </span>
                ))}
              </div>
              <div
                className={tw`h-fit text-[14px] font-medium text-gray-07 [&>*:first-of-type]:mt-4 [&>*]:mb-1`}
              >
                {getDoseAndRate(order)?.maxDosePerDay && (
                  <div>
                    <span className={tw`inline-block w-20`}>Maximum:</span>
                    {maximumDosesPerDayDescriptor(
                      order.medicationStatement!.dosageInstruction![0],
                      { excludeMaximumDescriptor: true }
                    )}
                  </div>
                )}
                {getDoseAndRate(order)?.minTimeBetweenDose && (
                  <div>
                    <span className={tw`inline-block w-20`}>Interval:</span>
                    {minimumTimeBetweenDosesDescriptor(
                      order.medicationStatement!.dosageInstruction![0]
                    )}
                  </div>
                )}
                {order.medicationStatement!.reasonCode?.text && (
                  <div>
                    <span className={tw`inline-block w-20`}>Condition:</span>
                    {order.medicationStatement!.reasonCode.text}
                  </div>
                )}
              </div>
              <OrderFooter order={order} renderMode={'list'} />
            </div>
            <div
              className={twx('h-full min-h-[20px] w-fit min-w-[80px] shrink-0')}
            >
              {/* 
                This div is intentionally left blank to keep spacing for buttons that visually live within these Link Cards.
                `min-w-[80px]` is arbitrary but gives some spacing for the `Review` button below;
                keep that spacing in mind if additional actions are added to this card.
              */}
            </div>
          </Card>
        </Link>
        <div
          className={tw`absolute right-[24px] top-0 flex h-full w-[0px] flex-col items-end justify-center`}
        >
          {hasReviewAction && canInteractWithMedication && (
            <Link
              to={cardUrl()}
              className={tw`hover:no-underline`}
              data-testid={order.id ? `review-anchor-${order.id}` : undefined}
            >
              <AsyncIconButton
                buttonStyle={'tertiary-outline'}
                buttonSize={'small'}
              >
                Review
              </AsyncIconButton>
            </Link>
          )}
          {!hasReviewAction &&
            hasOrderRefillPotential &&
            facility.hasActiveRefillEnabledPharmacyIntegration && (
              <PersonPermissionGate
                person={person}
                permissions={[GroupPermission.GROUP_PERMISSION_MEDICATION_READ]}
              >
                <Menu
                  id={'refill-menu'}
                  anchor={'bottom-right'}
                  options={[
                    {
                      label: (
                        <div className={tw`flex flex-row items-center gap-2`}>
                          <Icon
                            name={'prescription-bottle-medical'}
                            className={tw`text-gray-09`}
                          />
                          Manage refills
                        </div>
                      ),
                      onClick: () => setShowRefillModal(true),
                    },
                  ]}
                >
                  <Icon name={'ellipsis-vertical'} />
                </Menu>
              </PersonPermissionGate>
            )}
        </div>
      </span>
    </>
  )
}

export default OrderCard
