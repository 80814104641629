import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { download } from '@shared/api/legacy'
import { AsyncIconButton } from '@shared/components/AsyncButton'
import { PersonPermissionGate } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { Person } from '@shared/types/person'
import { tw } from '@shared/utils/tailwind'
import { careSummaryPdfUrl } from '@app/api/routines'
import PersonPageTitle from '@app/components/PersonPageTitle'

export const routineButtonText = 'Add Routine'

export default function RoutinePageTitle({ person }: { person: Person }) {
  const { setError } = useContext(GlobalContext)

  const history = useHistory()
  const [isLoadingPDF, setIsLoadingPDF] = useState(false)

  return (
    <PersonPageTitle title={'Routines'} withBorder={false}>
      <PersonPermissionGate
        person={person}
        permissions={[GroupPermission.GROUP_PERMISSION_ROUTINE_CREATE]}
      >
        <AsyncIconButton
          className={tw`mr-[12px]`}
          initialIcon={'fa-list-check'}
          buttonStyle={'secondary-fill'}
          onClick={() => history.push('routines/new')}
        >
          {routineButtonText}
        </AsyncIconButton>
      </PersonPermissionGate>
      <PersonPermissionGate
        person={person}
        permissions={[GroupPermission.GROUP_PERMISSION_ROUTINE_READ]}
      >
        <AsyncIconButton
          initialIcon={'fa-download'}
          buttonStyle={'secondary-fill'}
          isLoading={isLoadingPDF}
          disabled={isLoadingPDF}
          onClick={async () => {
            try {
              setIsLoadingPDF(true)
              const fileUrl = careSummaryPdfUrl(person)
              await download({
                fileUrl,
                target: '_blank',
              })
            } catch (e) {
              setError(e)
            } finally {
              setIsLoadingPDF(false)
            }
          }}
        >
          Care Summary
        </AsyncIconButton>
      </PersonPermissionGate>
    </PersonPageTitle>
  )
}
