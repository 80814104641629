import { useState } from 'react'
import { PersonCard } from '@shared/components/LinkCard/PersonCard'
import { getRoutineAdministrationMap } from '@shared/components/RoutineAdministrationsStatusSummary/helpers'
import RoutineAdministrationsStatusSummary from '@shared/components/RoutineAdministrationsStatusSummary/RoutineAdministrationsStatusSummary'
import { CareAppPerson, RoutineAdministration } from '@shared/types/careapp'
import {
  routineAdminIsDone,
  routineAdminIsException,
  routineAdminIsOverDue,
} from '@shared/utils/careapp'
import { tw } from '@shared/utils/tailwind'
import MarkAsDoneModal from '@app/pages/Facilities/Progress/Routines/Modals/MarkAsDoneModal'
import ModalToHandleOverdue from '@app/pages/Facilities/Progress/Routines/Modals/ModalToHandleOverdue'
import MarkAsExceptionModal from '../../Modals/MarkAsExceptionModal'

export default function ResidentRoutinesCard({
  careAppPerson,
  routineAdministrations,
  facilityTimeZone,
  reload,
}: {
  careAppPerson: CareAppPerson
  routineAdministrations: RoutineAdministration[]
  facilityTimeZone: string
  reload: () => void
}) {
  const [selectedAdministration, setSelectedAdministration] = useState<
    RoutineAdministration | undefined
  >()
  const {
    admissionsInformation: { bedDetails, roomDetails },
  } = careAppPerson
  const { Done, Exceptions, Overdue } = getRoutineAdministrationMap(
    routineAdministrations,
    facilityTimeZone
  )

  const hasAdministrationsToDisplay = Boolean(
    Exceptions.length || Overdue.length || Done.length
  )

  return (
    <PersonCard
      cardClassName={tw`mb-[24px]`}
      anchorClassName={tw`text-gray-01`}
      href=""
      person={careAppPerson}
      roomInfo={{ bedDetails, roomDetails }}
    >
      {hasAdministrationsToDisplay && (
        <RoutineAdministrationsStatusSummary
          routineAdministrationMap={{ Done, Exceptions, Overdue }}
          onAdministrationClick={setSelectedAdministration}
          facilityTimeZone={facilityTimeZone}
        />
      )}
      {selectedAdministration && routineAdminIsDone(selectedAdministration) && (
        <MarkAsExceptionModal
          onClose={() => setSelectedAdministration(undefined)}
          careAppPerson={careAppPerson}
          routineAdministration={selectedAdministration}
          facilityTimeZone={facilityTimeZone}
          reload={reload}
        />
      )}
      {selectedAdministration &&
        routineAdminIsException(selectedAdministration) && (
          <MarkAsDoneModal
            onClose={() => setSelectedAdministration(undefined)}
            careAppPerson={careAppPerson}
            routineAdministration={selectedAdministration}
            facilityTimeZone={facilityTimeZone}
            reload={reload}
          />
        )}
      {selectedAdministration &&
        routineAdminIsOverDue(selectedAdministration, facilityTimeZone) && (
          <ModalToHandleOverdue
            onClose={() => setSelectedAdministration(undefined)}
            careAppPerson={careAppPerson}
            routineAdministration={selectedAdministration}
            facilityTimeZone={facilityTimeZone}
            reload={reload}
          />
        )}
    </PersonCard>
  )
}
