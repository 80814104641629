import { SystemUrl } from '@augusthealth/models/com/august/protos/codeable_concept'
import {
  EventTiming,
  UnitOfTime,
} from '@augusthealth/models/com/august/protos/timing'
import { cloneDeep } from 'lodash'
import { FormState, UseFormReturn } from 'react-hook-form'
import { v4 as uuid } from 'uuid'
import { OptionTypeBase } from '@shared/components/Selects/StyledSelect'
import { Time } from '@shared/types/date'
import { DosageV2 } from '@shared/types/dosage'
import {
  MedicationOrder,
  MedOrderFormData,
  MedOrderFormDose,
  RxMedicationOrder,
  SlidingScaleEntryWithId,
  VitalMedicationOrder,
} from '@shared/types/medication_order'
import {
  DosageType,
  MedicationStatement,
} from '@shared/types/medication_statement'
import { DeepNull } from '@shared/types/utilities'
import { VitalInstruction, VitalType } from '@shared/types/vital'
import { formatTime, fromDateToDateMessage } from '@shared/utils/date'
import {
  CustomPeriodUnits,
  isDiscontinued,
  isExternal,
  isVitalOrder,
  splitSupportedDosageTypes,
} from '@shared/utils/medicationStatement'
import notEmpty from '@shared/utils/notEmpty'
import {
  hasValidNumberValue,
  validDateMessageOrNull,
  validNumberOrNull,
  validStringOrNull,
} from '@shared/utils/parsing'
import { medPassEventTimings } from '@shared/utils/time'
import {
  EVENT_TIMING_TO_MED_PASS_STRING,
  getTimesOfDay,
} from '@shared/utils/timing'
import {
  isPrnDosageType,
  isRoutineDosageType,
  isSlidingScaleDosageType,
  isSplitDosageType,
  isTaperDosageType,
  isVitalDosageType,
} from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/helpers'
import { updateTaperDoseDurations } from '@app/components/Residents/Medications/Orders/ReviewMedicationOrder/ReviewOrderScheduleCard/TaperSchedule/helpers'

export const getMedOrderDosageType = (
  order: MedicationStatement
): DosageType => {
  return <DosageType>order.dosageType!
}

export const buildNewDose = ({
  formData,
  userSetDosageType,
}: {
  formData: MedOrderFormData
  userSetDosageType: DosageType
}) => {
  return {
    tempId: uuid(),
    quantity: {
      value: undefined,
      unit: formData.doses?.[0]?.quantity?.unit,
    },
    oneTimeOnly: false,
    frequency: formData.doses?.[0]?.frequency ?? 1,
    period: 1,
    periodUnit: UnitOfTime.UNIT_OF_TIME_DAY,
    userSetDosageType,
    parameters: [],
  }
}

export const handleChangingDoseType = ({
  newDoseType,
  index,
  methods,
  isNewOrder = false,
}: {
  newDoseType: DosageType
  index: number
  methods: UseFormReturn<MedOrderFormData>
  isNewOrder?: boolean
}) => {
  const { setValue, clearErrors, getValues } = methods

  const medOrder = getValues()

  clearErrors()
  if (isTaperDosageType(newDoseType)) {
    const updatedDoses = getValues('doses')
    updatedDoses.forEach((dose, index) => {
      if (dose) {
        dose.userSetDosageType = DosageType.DOSAGE_TYPE_TAPER
        if (dose.periodUnit === CustomPeriodUnits.SPECIFIC_DAYS) {
          dose.periodUnit = undefined
        }

        if (index === 0) {
          dose.boundsPeriod = {
            startDate: getValues('effectivePeriod.startDate'),
            endDate: undefined,
          }
        }
      }
    })

    if (isNewOrder && updatedDoses.length === 1) {
      updatedDoses.push(
        buildNewDose({
          formData: medOrder,
          userSetDosageType: DosageType.DOSAGE_TYPE_TAPER,
        })
      )
    }

    setValue('vitalType', null)
    setValue('doses', updatedDoses)
    updateTaperDoseDurations(methods)
  } else if (isSlidingScaleDosageType(newDoseType)) {
    const updatedDoses = [...getValues('doses')]
    updatedDoses.forEach((dose: MedOrderFormDose, doseIndex) => {
      if (dose) {
        if (index === doseIndex) {
          dose.slidingScale = {
            entries: emptySlidingScaleEntries({
              doseUnit: dose?.quantity.unit,
            }),
          }
        } else if (
          !splitSupportedDosageTypes.includes(dose.userSetDosageType!)
        ) {
          dose.userSetDosageType = DosageType.DOSAGE_TYPE_ROUTINE
        }
      }
    })

    setValue('vitalType', null)
    setValue('doses', updatedDoses)
  } else if (isRoutineDosageType(newDoseType) || isPrnDosageType(newDoseType)) {
    const updatedDoses = [...getValues('doses')]
    updatedDoses.forEach((dose: MedOrderFormDose, doseIndex) => {
      if (dose) {
        if (!splitSupportedDosageTypes.includes(dose.userSetDosageType!)) {
          dose.userSetDosageType = DosageType.DOSAGE_TYPE_ROUTINE
        }

        if (isPrnDosageType(dose.userSetDosageType)) {
          dose.maxDosage = {
            value: dose.maxDosage?.value ?? undefined,
            unit: dose.maxDosage?.unit ?? dose.quantity.unit,
          }
        }

        if (
          isSlidingScaleDosageType(dose.userSetDosageType) &&
          index === doseIndex
        ) {
          const unitToUse =
            dose.slidingScale?.entries?.find(
              (entry) => entry?.doseQuantity?.unit
            )?.doseQuantity?.unit ?? dose.quantity?.unit
          if (isRoutineDosageType(newDoseType)) {
            dose.quantity.unit = unitToUse
          } else if (isPrnDosageType(newDoseType)) {
            dose.maxDosage = {
              value: dose.maxDosage?.value ?? undefined,
              unit: unitToUse ?? dose.maxDosage?.unit ?? dose.quantity.unit,
            }
          }
        }
      }
    })

    setValue('vitalType', null)
    setValue('doses', updatedDoses)
  } else if (isVitalDosageType(newDoseType)) {
    // only take the first dose because vital orders are currently one dose only
    const updatedDoses = [getValues('doses.0')]

    updatedDoses.forEach((dose: MedOrderFormDose) => {
      if (dose) {
        dose.userSetDosageType = DosageType.DOSAGE_TYPE_VITAL
      }
    })
    setValue('vitalType', VitalType.VITAL_TYPE_UNSPECIFIED)
    setValue('doses', updatedDoses)
  }
}

export const canAddOrRemoveDoseParts = (
  order: MedicationOrder,
  formData: MedOrderFormData
) => {
  const userSetDosageType = formData.doses?.[0]?.userSetDosageType
  const numberOfDoses = formData.doses?.length ?? 1

  return isExternal(order)
    ? false
    : (isPrnDosageType(userSetDosageType) ||
        isRoutineDosageType(userSetDosageType)) &&
        !isDiscontinued(order.medicationStatement!) &&
        numberOfDoses > 1
}

export const doesFormHaveSchedulingErrors = (
  formState: FormState<MedOrderFormData>
): boolean => {
  const errors = formState.errors
  if (Object.keys(errors).length === 0) {
    return false
  }

  const ignoredErrorProperties = [
    'onHold',
    'strengthAndForm',
    'displayName',
    'sig',
  ]

  return Object.keys(errors).some((key) => {
    if (ignoredErrorProperties.includes(key)) {
      return false
    }

    return !!errors[key]
  })
}

export const doesFormHaveMissingMedicationInformation = (
  formState: FormState<MedOrderFormData>
): boolean => {
  return (
    !!formState.errors.sig ||
    !!formState.errors.displayName ||
    !!formState.errors.strengthAndForm ||
    !!formState.errors.vitalType
  )
}

export const hasTouchedSlidingScaleEntries = (
  formState: FormState<MedOrderFormData>
): boolean => {
  // @ts-ignore TODO: why doesn't this work for TS
  const entries = formState.dirtyFields.doses?.[0]?.slidingScale?.entries

  return entries?.some(
    (entry) => entry?.bounds?.low?.value || entry?.bounds?.high?.value
  )
}

export const emptySlidingScaleEntries = ({
  measuredUnit = 'mg/dL',
  doseUnit = 'unit',
}: {
  measuredUnit?: string
  doseUnit?: string
}): SlidingScaleEntryWithId[] => [
  {
    tempId: uuid(),
    bounds: {
      low: {
        value: 0,
        unit: measuredUnit,
      },
      high: {
        value: undefined,
        unit: measuredUnit,
      },
    },
    doseQuantity: {
      value: undefined,
      unit: doseUnit,
    },
    note: undefined,
  },
  {
    tempId: uuid(),
    bounds: {
      low: {
        value: undefined,
        unit: measuredUnit,
      },
      high: {
        value: undefined,
        unit: measuredUnit,
      },
    },
    doseQuantity: {
      value: undefined,
      unit: doseUnit,
    },
    note: undefined,
  },
]

export const newMedicationOrderDefaultValues: MedOrderFormData = {
  vitalType: null,
  hasCustomPrnMaxDosage: false,
  rxNumber: undefined,
  pharmacy: undefined,
  prescriber: undefined,
  rxCui: undefined,
  sig: undefined,
  selfAdministered: false,
  isTreatment: false,
  medicationClass: [],
  medAdditionalInstructions: [],
  strengthAndForm: undefined,
  codeName: undefined,
  displayName: undefined,
  additionalInstructions: undefined,
  condition: undefined,
  effectivePeriod: {
    startDate: fromDateToDateMessage(new Date()),
    endDate: undefined,
  },
  onHold: {
    start: {
      date: undefined,
      time: undefined,
    },
    end: {
      date: undefined,
      time: undefined,
    },
    reason: undefined,
  },
  doses: [
    {
      tempId: uuid(),
      oneTimeOnly: false,
      userSetDosageType: DosageType.DOSAGE_TYPE_ROUTINE,
      quantity: {
        value: undefined,
        unit: undefined,
      },
      frequency: 1,
      period: 1,
      slidingScale: {
        entries: emptySlidingScaleEntries({}),
      },
      parameters: [],
      vitals: {
        bloodSugarTiming: null,
        supplementalOxygen: null,
        bloodPressurePosition: null,
        temperatureType: null,
        observationActionNote: null,
      },
    },
  ],
}

export const mapMedOrderToFormData = (
  order: MedicationOrder
): MedOrderFormData => {
  const doses = isVitalOrder(order)
    ? (order.medicationStatement.vitalInstructions ?? [{}])
    : (order.medicationStatement?.dosageInstruction ?? [{}])

  const doseType = getMedOrderDosageType(order.medicationStatement!)
  const requiredVitals = order.medicationStatement?.requiredVitals ?? []

  return {
    vitalType: order.medicationStatement.vitalType ?? null,
    hasCustomPrnMaxDosage: false,
    prescriber: order.medicationStatement!.prescriber,
    rxNumber: order.medicationStatement!.rxNumber,
    pharmacy: order.medicationStatement!.pharmacy,
    selfAdministered: order.medicationStatement?.selfAdministered ?? false,
    isTreatment: order.medicationStatement?.isTreatment ?? false,
    medicationClass:
      order.medicationStatement?.medication?.medicationClass ?? [],
    medAdditionalInstructions:
      order.medicationStatement?.additionalInstructions ?? [],
    additionalInstructions: order.medicationStatement!.note,
    condition: order.medicationStatement!.reasonCode?.text ?? undefined,
    effectivePeriod: {
      startDate: order.medicationStatement!.effectivePeriod?.startDate,
      endDate: order.medicationStatement!.effectivePeriod?.endDate,
    },
    sig: order.medicationStatement!.providerDosageInstructions,
    // specifically looking for RXNorm because that is the code
    // we populate when meds are manually entered by the community
    rxCui: order.medicationStatement.medication?.code?.coding?.find(
      (code) => code.systemEnum === SystemUrl.SYSTEM_URL_RXNORM
    )?.code,
    codeName: order.medicationStatement.medication?.alternateName ?? undefined,
    displayName: order.medicationStatement.medication?.drugName ?? undefined,
    strengthAndForm:
      order.medicationStatement.medication?.strengthAndForm ?? undefined,
    onHold: {
      start: {
        date:
          order.medicationStatement!.onHold?.onHoldDetail?.start?.date ??
          undefined,
        time: order.medicationStatement!.onHold?.onHoldDetail?.start?.time
          ? {
              label: formatTime(
                order.medicationStatement!.onHold?.onHoldDetail?.start?.time,
                { use24HourClock: true }
              )!,
              value:
                order.medicationStatement.onHold?.onHoldDetail?.start?.time,
            }
          : undefined,
      },
      end: {
        date:
          order.medicationStatement!.onHold?.onHoldDetail?.end?.date ??
          undefined,
        time: order.medicationStatement!.onHold?.onHoldDetail?.end?.time
          ? {
              label: formatTime(
                order.medicationStatement!.onHold?.onHoldDetail?.end?.time,
                { use24HourClock: true }
              )!,
              value: order.medicationStatement.onHold?.onHoldDetail?.end.time,
            }
          : undefined,
      },
      reason: order.medicationStatement!.onHold?.onHoldDetail?.reason,
    },
    doses:
      doses.map((dose, index) => {
        const dosageUnit =
          dose.doseAndRate?.doseQuantity?.unit ??
          dose.doseAndRate?.slidingScale?.entries?.find(
            (entry) => entry?.doseQuantity?.unit
          )?.doseQuantity?.unit
        const specifiedPeriod = dose.timing?.period ?? undefined
        let periodUnit: UnitOfTime | CustomPeriodUnits | undefined =
          dose.timing?.periodUnit
        let variablePeriodUnit: UnitOfTime | undefined

        const daysOfMonth = dose.timing?.dayOfMonth
        const daysOfWeek = dose.timing?.dayOfWeek
        if (!dose.asNeededBoolean) {
          if (daysOfMonth?.length) {
            periodUnit = UnitOfTime.UNIT_OF_TIME_MONTH
          } else if (daysOfWeek?.length) {
            periodUnit = UnitOfTime.UNIT_OF_TIME_WEEK
          } else if (
            (specifiedPeriod && specifiedPeriod > 1) ||
            periodUnit === UnitOfTime.UNIT_OF_TIME_HOUR
          ) {
            periodUnit = CustomPeriodUnits.EVERY
            variablePeriodUnit = dose.timing?.periodUnit
          }
        }

        if (dose.timing?.oneTimeOnly) {
          return {
            tempId: uuid(),
            quantity: {
              value: dose.doseAndRate?.doseQuantity?.value,
              unit: dose.doseAndRate?.doseQuantity?.unit,
            },
            frequency: 1,
            period: undefined,
            periodUnit: CustomPeriodUnits.SPECIFIC_DAYS,
            duration: dose.timing?.duration,
            durationUnit: dose.timing?.durationUnit,
            variablePeriodUnit: undefined,
            dayOfWeek: undefined,
            dayOfMonth: undefined,
            boundsPeriod: {
              startDate: undefined,
              endDate: undefined,
            },
            maxDosage: {
              value: undefined,
              unit: undefined,
            },
            minTime: undefined,
            timeOfDay: medTimeToFormTimeOfDay(dose),
            oneTimeOnly: true,
            onThisDate: order.medicationStatement?.effectivePeriod?.startDate,
            originalDoseType: getDoseSchedulingTypeForIndividualDose({
              order,
              overallDosageType: doseType,
              doseIndex: index,
            }),
            originalDose: dose,
            userSetDosageType: getDoseSchedulingTypeForIndividualDose({
              order,
              overallDosageType: doseType,
              doseIndex: index,
            }),
            note: dose.note,
            text: dose.text,
            parameters: dose.parameters ?? [],
            requiredInjectionOrApplicationSitePrompt:
              dose.requiredInjectionOrApplicationSitePrompt,
            requiredVitals: requiredVitals,
            slidingScale: undefined,
            vitals: {
              ...dose,
            },
          }
        }

        return {
          tempId: uuid(),
          quantity: {
            value: dose.doseAndRate?.doseQuantity?.value,
            unit: dosageUnit,
          },
          frequency: dose.timing?.frequency as number,
          /**
           * Default to one if a period is not set
           * See AH-6124 for more details
           */
          period: specifiedPeriod ?? 1,
          periodUnit: periodUnit,
          duration: dose.timing?.duration,
          durationUnit: dose.timing?.durationUnit,
          variablePeriodUnit: variablePeriodUnit,
          dayOfWeek: dose.timing?.dayOfWeek,
          dayOfMonth: dose.timing?.dayOfMonth,
          boundsPeriod: {
            startDate: dose.timing?.boundsPeriod?.startDate,
            endDate: dose.timing?.boundsPeriod?.endDate,
          },
          timeOfDay: medTimeToFormTimeOfDay(dose),
          maxDosage: {
            value: dose.doseAndRate?.maxDosePerDay?.value ?? undefined,
            unit: dose.doseAndRate?.maxDosePerDay?.unit ?? undefined,
          },
          minTime: dose.doseAndRate?.minTimeBetweenDose?.value ?? undefined,
          oneTimeOnly: false,
          onThisDate: undefined,
          originalDoseType: getDoseSchedulingTypeForIndividualDose({
            order,
            overallDosageType: doseType,
            doseIndex: index,
          }),
          userSetDosageType: getDoseSchedulingTypeForIndividualDose({
            order,
            overallDosageType: doseType,
            doseIndex: index,
          }),
          originalDose: dose,
          note: dose.note,
          text: dose.text,
          requiredVitals: requiredVitals,
          parameters: dose.parameters ?? [],
          requiredInjectionOrApplicationSitePrompt:
            dose.requiredInjectionOrApplicationSitePrompt,
          slidingScale: {
            entries:
              dose.doseAndRate?.slidingScale?.entries?.map((en) => ({
                ...en,
                tempId: uuid(),
              })) ??
              emptySlidingScaleEntries({
                doseUnit: dosageUnit,
              }),
          },
          vitals: {
            ...dose,
          },
        }
      }) ?? [],
  }
}

const getDoseSchedulingTypeForIndividualDose = ({
  order,
  overallDosageType,
  doseIndex,
}: {
  order: MedicationOrder
  overallDosageType: DosageType
  doseIndex: number
}) => {
  if (isVitalOrder(order)) {
    return DosageType.DOSAGE_TYPE_VITAL
  }

  if (isSplitDosageType(overallDosageType)) {
    const currentDose =
      order.medicationStatement!.dosageInstruction?.[doseIndex]
    if (currentDose?.asNeededBoolean) {
      return DosageType.DOSAGE_TYPE_PRN
    } else if (currentDose?.doseAndRate?.slidingScale?.entries) {
      return DosageType.DOSAGE_TYPE_SLIDING_SCALE
    }

    return DosageType.DOSAGE_TYPE_ROUTINE
  }

  return overallDosageType
}

export const mapFormMedToMedOrder = ({
  formData,
  medOrder,
}: {
  formData: MedOrderFormData
  medOrder: Partial<MedicationOrder>
}): DeepNull<MedicationOrder> => {
  const isVitalOrder = !!formData.vitalType

  const updatedMedOrder: DeepNull<Partial<MedicationOrder>> =
    cloneDeep(medOrder)

  const numberOfDoses = formData.doses?.length ?? 0
  const firstDoseType =
    formData.doses?.[0]?.userSetDosageType ??
    (formData.doses?.[0]?.originalDoseType as DosageType)

  updatedMedOrder.medicationStatement!.providerDosageInstructions =
    validStringOrNull(formData.sig)

  updatedMedOrder.medicationStatement!.dosageType =
    numberOfDoses > 1 && splitSupportedDosageTypes.includes(firstDoseType)
      ? DosageType.DOSAGE_TYPE_SPLIT
      : firstDoseType

  updatedMedOrder.medicationStatement!.note =
    formData.additionalInstructions ?? null
  updatedMedOrder.medicationStatement!.effectivePeriod = {
    startDate: validDateMessageOrNull(formData.effectivePeriod?.startDate),
    endDate: validDateMessageOrNull(formData.effectivePeriod?.endDate),
  }

  const condition = validStringOrNull(formData.condition)

  if (condition) {
    const originalCondition =
      updatedMedOrder.medicationStatement!.reasonCode?.text
    const conditionIsSame =
      originalCondition &&
      originalCondition.toLowerCase() === condition.toLowerCase()

    updatedMedOrder.medicationStatement!.reasonCode = {
      ...(conditionIsSame
        ? (updatedMedOrder.medicationStatement!.reasonCode ?? {})
        : {}),
      text: condition,
    }
  } else {
    updatedMedOrder.medicationStatement!.reasonCode = null
  }

  if (formData.onHold?.start?.date) {
    const holdEnd = formData.onHold?.end?.date
      ? {
          date: formData.onHold?.end?.date,
          time: formData.onHold?.end?.time?.value ?? null,
        }
      : null
    updatedMedOrder.medicationStatement!.onHold = {
      onHoldDetail: {
        ...(updatedMedOrder.medicationStatement!.onHold?.onHoldDetail ?? {}),
        start: {
          date: formData.onHold?.start?.date ?? null,
          time: formData.onHold?.start?.time?.value ?? null,
        },
        end: holdEnd,
        reason:
          (formData.onHold?.reason?.length ?? 0 > 0)
            ? formData.onHold.reason
            : null,
      },
      onHoldHistory: updatedMedOrder.medicationStatement!.onHold?.onHoldHistory,
    }
  } else {
    updatedMedOrder.medicationStatement!.onHold = {
      onHoldDetail: null,
      onHoldHistory: updatedMedOrder.medicationStatement!.onHold?.onHoldHistory,
    }
  }

  updatedMedOrder.medicationStatement!.rxNumber = validStringOrNull(
    formData.rxNumber
  )
  updatedMedOrder.medicationStatement!.pharmacy = validStringOrNull(
    formData.pharmacy
  )
  updatedMedOrder.medicationStatement!.prescriber = validStringOrNull(
    formData.prescriber
  )

  updatedMedOrder.medicationStatement!.additionalInstructions =
    formData.medAdditionalInstructions ?? []

  if (isVitalOrder) {
    return mapToVitalOrder({ medicationOrder: updatedMedOrder, formData })
  } else {
    return mapToMedicationOrder({ medicationOrder: updatedMedOrder, formData })
  }
}

const mapToVitalOrder = ({
  medicationOrder,
  formData,
}: {
  medicationOrder: DeepNull<Partial<MedicationOrder>>
  formData: MedOrderFormData
}): DeepNull<VitalMedicationOrder> => {
  medicationOrder.medicationStatement!.dosageType = DosageType.DOSAGE_TYPE_VITAL
  medicationOrder.medicationStatement!.dosageInstruction = null
  medicationOrder.medicationStatement!.medication = null
  medicationOrder.medicationStatement!.selfAdministered = false
  medicationOrder.medicationStatement!.isTreatment = false
  medicationOrder.medicationStatement!.vitalType = formData.vitalType
  medicationOrder.medicationStatement!.vitalInstructions = formData.doses
    ?.map((dose) => {
      if (dose) {
        return mapVitalDose(dose, formData.vitalType!)
      }

      return null
    })
    .filter(notEmpty)

  return medicationOrder as DeepNull<VitalMedicationOrder>
}

const mapToMedicationOrder = ({
  medicationOrder,
  formData,
}: {
  medicationOrder: DeepNull<Partial<MedicationOrder>>
  formData: MedOrderFormData
}): DeepNull<RxMedicationOrder> => {
  medicationOrder.medicationStatement!.vitalType = null
  medicationOrder.medicationStatement!.vitalInstructions = null
  medicationOrder.medicationStatement!.medication = {
    ...(medicationOrder.medicationStatement!.medication ?? {}),
    medicationClass: formData.medicationClass ?? [],
    drugName: formData.displayName,
    strengthAndForm: formData.strengthAndForm,
    alternateName: formData.codeName ?? formData.displayName,
    code: {
      ...(medicationOrder.medicationStatement!.medication?.code ?? {}),
      text: formData.codeName ?? formData.displayName,
    },
  }

  medicationOrder.medicationStatement!.selfAdministered =
    formData.selfAdministered ?? false
  medicationOrder.medicationStatement!.isTreatment =
    formData.isTreatment ?? false

  const matchingCoding =
    medicationOrder.medicationStatement!.medication?.code?.coding?.find(
      (code) => code?.code === formData.rxCui
    )

  if (matchingCoding) {
    // coding already exists in med so no updates needed
  } else if (formData.rxCui) {
    // set the code for new meds/meds without any coding to start
    medicationOrder.medicationStatement!.medication.code!.coding = [
      {
        code: formData.rxCui,
        display: formData.displayName,
        systemEnum: SystemUrl.SYSTEM_URL_RXNORM,
      },
    ]
  } else {
    // no rxCui was set in the form so send empty coding
    medicationOrder.medicationStatement!.medication.code!.coding = []
  }

  medicationOrder.medicationStatement!.dosageInstruction = formData.doses
    ?.map((dose) => {
      if (dose) {
        const doseType = dose.userSetDosageType ?? dose.originalDoseType

        let mappingFunction
        switch (doseType) {
          case DosageType.DOSAGE_TYPE_PRN:
            mappingFunction = mapPRN
            break
          case DosageType.DOSAGE_TYPE_TAPER:
            mappingFunction = mapTaper
            break
          case DosageType.DOSAGE_TYPE_SLIDING_SCALE:
            mappingFunction = mapSlidingScale
            break
          default:
            mappingFunction = mapRoutine
        }

        return mappingFunction(dose)
      }

      return null
    })
    .filter(notEmpty)

  return medicationOrder as DeepNull<RxMedicationOrder>
}

const mapTaper = (dose: MedOrderFormDose): DeepNull<DosageV2> => {
  if (dose === null) {
    throw new Error('Dose is null')
  }

  const periodUnit = getPeriodUnit(dose)

  const hasBoundsPeriod =
    dose.boundsPeriod?.startDate || dose.boundsPeriod?.endDate
  const originalDose = dose.originalDose
  const duration = validNumberOrNull(dose.duration)

  const newDoseInfo: DeepNull<DosageV2> = {
    asNeededBoolean: false,
    doseAndRate: {
      ...(originalDose?.doseAndRate ?? {}),
      doseQuantity: getDoseQuantityObject(dose),
      minTimeBetweenDose: null,
      maxDosePerDay: null,
      slidingScale: null,
    },
    timing: {
      frequency: dose.frequency,
      period: validNumberOrNull(dose.period),
      periodUnit: periodUnit ?? null,
      duration,
      durationUnit: duration ? dose.durationUnit : null,
      dayOfWeek: dose.dayOfWeek ?? null,
      dayOfMonth: dose.dayOfMonth ?? null,
      boundsPeriod: null,
      timeOfDay: getTimeOfDay(dose),
      oneTimeOnly: false,
    },
    note: validStringOrNull(dose.note),
    requiredInjectionOrApplicationSitePrompt: validStringOrNull(
      dose.requiredInjectionOrApplicationSitePrompt
    ),
    parameters: dose.parameters,
  }

  if (hasBoundsPeriod) {
    newDoseInfo.timing!.boundsPeriod = {
      startDate: validDateMessageOrNull(dose.boundsPeriod?.startDate),
      endDate: validDateMessageOrNull(dose.boundsPeriod?.endDate),
    }
  }

  return {
    ...(originalDose ?? {}),
    ...newDoseInfo,
  }
}

const mapPRN = (dose: MedOrderFormDose): DeepNull<DosageV2> => {
  if (dose === null) {
    throw new Error('Dose is null')
  }

  const originalDose = dose.originalDose
  const validMaxDose = hasValidNumberValue(dose.maxDosage?.value)
  const hasSetMaxDose = !!(validMaxDose && dose.maxDosage?.unit)

  const newDoseInfo: DeepNull<DosageV2> = {
    asNeededBoolean: true,
    doseAndRate: {
      ...(originalDose?.doseAndRate ?? {}),
      doseQuantity: getDoseQuantityObject(dose),
      minTimeBetweenDose: hasValidNumberValue(dose.minTime)
        ? {
            value: dose.minTime,
            unit: UnitOfTime.UNIT_OF_TIME_HOUR,
          }
        : null,
      maxDosePerDay: hasSetMaxDose
        ? {
            value: dose.maxDosage!.value,
            unit: dose.maxDosage!.unit,
          }
        : null,
      slidingScale: null,
    },
    timing: {
      frequency: null,
      period: null,
      periodUnit: null,
      duration: null,
      durationUnit: null,
      dayOfWeek: null,
      dayOfMonth: null,
      boundsPeriod: null,
      timeOfDay: getTimeOfDay(dose),
      oneTimeOnly: false,
    },
    note: validStringOrNull(dose.note),
    requiredInjectionOrApplicationSitePrompt: validStringOrNull(
      dose.requiredInjectionOrApplicationSitePrompt
    ),
    parameters: dose.parameters,
  }

  return {
    ...(originalDose ?? {}),
    ...newDoseInfo,
  }
}

const mapRoutine = (dose: MedOrderFormDose): DeepNull<DosageV2> => {
  if (dose === null) {
    throw new Error('Dose is null')
  }

  const periodUnit = getPeriodUnit(dose)

  const originalDose = dose.originalDose

  const newDoseInfo: DeepNull<DosageV2> = {
    asNeededBoolean: false,
    doseAndRate: {
      ...(originalDose?.doseAndRate ?? {}),
      doseQuantity: getDoseQuantityObject(dose),
      minTimeBetweenDose: null,
      maxDosePerDay: null,
      slidingScale: null,
    },
    timing: {
      frequency: dose.frequency,
      period: validNumberOrNull(dose.period),
      periodUnit: periodUnit ?? null,
      duration: null,
      durationUnit: null,
      dayOfWeek: dose.dayOfWeek ?? null,
      dayOfMonth: dose.dayOfMonth ?? null,
      boundsPeriod: null,
      timeOfDay: getTimeOfDay(dose),
      oneTimeOnly: dose.oneTimeOnly,
    },
    note: validStringOrNull(dose.note),
    requiredInjectionOrApplicationSitePrompt: validStringOrNull(
      dose.requiredInjectionOrApplicationSitePrompt
    ),
    parameters: dose.parameters,
  }

  return {
    ...(originalDose ?? {}),
    ...newDoseInfo,
  }
}

const mapVitalDose = (
  dose: MedOrderFormDose,
  vitalType: VitalType
): DeepNull<VitalInstruction> => {
  if (dose === null || !dose.vitals) {
    throw new Error('Dose is null')
  }

  return {
    timing: {
      frequency: dose.frequency,
      period: validNumberOrNull(dose.period),
      periodUnit: getPeriodUnit(dose) ?? null,
      duration: null,
      durationUnit: null,
      dayOfWeek: dose.dayOfWeek ?? null,
      dayOfMonth: dose.dayOfMonth ?? null,
      boundsPeriod: null,
      timeOfDay: getTimeOfDay(dose),
      oneTimeOnly: dose.oneTimeOnly,
    },
    vitalType: vitalType,
    bloodSugarTiming: dose.vitals.bloodSugarTiming ?? null,
    bloodPressurePosition: dose.vitals.bloodPressurePosition ?? null,
    supplementalOxygen: dose.vitals.supplementalOxygen ?? null,
    temperatureType: dose.vitals.temperatureType ?? null,
    observationActionNote: validStringOrNull(dose.vitals.observationActionNote),
  }
}

const mapSlidingScale = (dose: MedOrderFormDose): DeepNull<DosageV2> => {
  if (dose === null) {
    throw new Error('Dose is null')
  }

  const periodUnit = getPeriodUnit(dose)
  const hasSlidingScale =
    dose.slidingScale?.entries?.[0]?.bounds?.low?.value !== undefined &&
    dose.slidingScale?.entries?.[0]?.bounds?.high?.value !== undefined

  const originalDose = dose.originalDose

  const newDoseInfo: DeepNull<DosageV2> = {
    asNeededBoolean: false,
    doseAndRate: {
      ...(originalDose?.doseAndRate ?? {}),
      doseQuantity: null,
      minTimeBetweenDose: null,
      maxDosePerDay: null,
      slidingScale: hasSlidingScale
        ? {
            entries: dose.slidingScale!.entries!.map((en, index) => {
              const isFinalEntry =
                dose.slidingScale!.entries!.length === index + 1

              const bounds = isFinalEntry
                ? {
                    low: en.bounds?.low,
                    high: null,
                  }
                : en.bounds

              const doseQuantity = hasValidNumberValue(en.doseQuantity?.value)
                ? {
                    value: en.doseQuantity!.value,
                    unit: en.doseQuantity?.unit ?? dose.quantity.unit ?? 'unit',
                  }
                : null
              return {
                ...en,
                bounds,
                doseQuantity,
                tempId: undefined,
              }
            }),
          }
        : null,
    },
    timing: {
      frequency: dose.frequency,
      period: validNumberOrNull(dose.period),
      periodUnit: periodUnit ?? null,
      duration: null,
      durationUnit: null,
      dayOfWeek: dose.dayOfWeek ?? null,
      dayOfMonth: dose.dayOfMonth ?? null,
      boundsPeriod: null,
      timeOfDay: getTimeOfDay(dose),
      oneTimeOnly: false,
    },
    note: validStringOrNull(dose.note),
    requiredInjectionOrApplicationSitePrompt: validStringOrNull(
      dose.requiredInjectionOrApplicationSitePrompt
    ),
    parameters: dose.parameters,
  }

  return {
    ...(originalDose ?? {}),
    ...newDoseInfo,
  }
}

const getTimeOfDay = (dose: MedOrderFormDose) => {
  return (
    dose?.timeOfDay
      ?.map((t) => {
        if (!t || !t.value || (t.value as string) === '') {
          return null
        }
        if (isEventTiming(t.value)) {
          return {
            time: null,
            when: t.value,
          }
        } else {
          return {
            time: t.value,
            when: undefined,
          }
        }
      })
      .filter(notEmpty) ?? null
  )
}

const isEventTiming = (time: Time | EventTiming): time is EventTiming => {
  return medPassEventTimings
    .map(({ value }) => value)
    .includes(time as EventTiming)
}

const getPeriodUnit = (dose: MedOrderFormDose): UnitOfTime => {
  if (dose?.oneTimeOnly) {
    return UnitOfTime.UNIT_OF_TIME_DAY
  }

  if (dose?.variablePeriodUnit) {
    return dose.variablePeriodUnit
  }

  if (dose?.periodUnit === CustomPeriodUnits.SPECIFIC_DAYS) {
    return UnitOfTime.UNIT_OF_TIME_DAY
  }

  return dose!.periodUnit as UnitOfTime
}

const getDoseQuantityObject = (dose: MedOrderFormDose) => {
  const result = {
    value: validNumberOrNull(dose!.quantity?.value),
    unit: dose!.quantity.unit ?? null,
  }

  if (result.unit === null && result.value === null) {
    return null
  }

  return result
}

const medTimeToFormTimeOfDay = (
  dose: DosageV2
): OptionTypeBase<Time | EventTiming>[] => {
  const timesOfDay = getTimesOfDay(dose.timing)
  if (timesOfDay.length > 0) {
    return timesOfDay
      .map((t) => {
        if (t.time?.hour !== undefined) {
          return {
            label: formatTime(t.time, { use24HourClock: true })!,
            value: t.time,
          }
        } else if (t.when) {
          return {
            label: EVENT_TIMING_TO_MED_PASS_STRING[t.when!],
            value: t.when,
          }
        }

        return null
      })
      .filter(notEmpty)
  }

  return []
}

export const isFormVitalOrder = (formData: MedOrderFormData): boolean => {
  return !!formData.vitalType
}
