import {
  differenceInCalendarDays,
  differenceInHours,
  isAfter,
  isBefore,
  parseISO,
} from 'date-fns'
import { sortBy } from 'lodash'
import * as timeago from 'timeago.js'
import { match, P } from 'ts-pattern'
import { TimeZoneConvertFunc } from '@shared/contexts/CurrentFacilityContext'
import {
  Incident,
  IncidentAction,
  IncidentActionStatus,
  IncidentActionType,
  IncidentLocation,
  IncidentStatus,
  IncidentType,
  NoteType,
} from '@shared/types/incidents'
import { Modification } from '@shared/types/modification'
import { formatDateToDateAtTimeLabel } from '@shared/utils/date'
import { getFullName } from '@shared/utils/humanName'
import notEmpty from './notEmpty'

export const noteTypes = sortBy(
  [
    {
      name: 'Agency Visit',
      icon: 'house-medical',
      value: NoteType.NOTE_TYPE_AGENCY_VISIT,
    },
    {
      name: 'Care Conference',
      icon: 'chalkboard-user',
      value: NoteType.NOTE_TYPE_CARE_CONFERENCE,
    },
    {
      name: 'Clinician Visit',
      icon: 'user-doctor',
      value: NoteType.NOTE_TYPE_CLINICIAN_VISIT,
    },
    {
      name: 'Room Change',
      icon: 'door-open',
      value: NoteType.NOTE_TYPE_ROOM_CHANGE,
    },
    {
      name: 'Room Rate Change',
      icon: 'money-check-dollar-pen',
      value: NoteType.NOTE_TYPE_ROOM_RATE_CHANGE,
    },
    {
      name: 'Observation',
      icon: 'calendar-lines-pen',
      value: NoteType.NOTE_TYPE_OBSERVATION,
    },
    {
      name: 'Progress Note',
      icon: 'calendar-lines-pen',
      value: NoteType.NOTE_TYPE_PROGRESS,
    },
    {
      name: 'Shift Change',
      icon: 'calendar-lines-pen',
      value: NoteType.NOTE_TYPE_SHIFT_CHANGE,
    },
    { name: 'Covid', icon: 'virus-covid', value: NoteType.NOTE_TYPE_COVID },
    { name: 'Vitals', icon: 'table', value: NoteType.NOTE_TYPE_VITAL },
    {
      name: 'Family Outreach',
      icon: 'family',
      value: NoteType.NOTE_TYPE_FAMILY_OUTREACH,
    },
  ],
  'name'
).concat([
  {
    name: 'No Tags',
    icon: 'ban',
    value: NoteType.NOTE_TYPE_UNSPECIFIED,
  },
])

export const tags: { name: string; icon: string; value: IncidentType }[] =
  sortBy(
    [
      {
        name: 'Behavioral',
        icon: 'face-angry',
        value: IncidentType.INCIDENT_TYPE_BEHAVIORAL,
      },
      {
        name: 'Abuse (Suspected)',
        icon: 'face-sad-tear',
        value: IncidentType.INCIDENT_TYPE_ABUSE,
      },
      {
        name: 'Illness',
        icon: 'face-thermometer',
        value: IncidentType.INCIDENT_TYPE_ILLNESS,
      },
      {
        name: 'Injury',
        icon: 'face-head-bandage',
        value: IncidentType.INCIDENT_TYPE_INJURY,
      },
      {
        name: 'Medical Emergency / 911',
        icon: 'stretcher',
        value: IncidentType.INCIDENT_TYPE_MEDICAL_EMERGENCY,
      },
      { name: 'Skin', icon: 'bandage', value: IncidentType.INCIDENT_TYPE_SKIN },
      {
        name: 'Hospitalization',
        icon: 'hospital',
        value: IncidentType.INCIDENT_TYPE_HOSPITALIZATION,
      },
      {
        name: 'Elopement',
        icon: 'person-running',
        value: IncidentType.INCIDENT_TYPE_ELOPEMENT,
      },
      {
        name: 'Medication Error',
        icon: 'pills',
        value: IncidentType.INCIDENT_TYPE_MED_ERROR,
      },
      {
        name: 'Fall',
        icon: 'face-head-bandage',
        value: IncidentType.INCIDENT_TYPE_FALL,
      },
      {
        name: 'On Floor',
        icon: 'person-falling',
        value: IncidentType.INCIDENT_TYPE_ON_FLOOR,
      },
      {
        name: 'Covid',
        icon: 'virus-covid',
        value: IncidentType.INCIDENT_TYPE_COVID,
      },
      {
        name: 'ER Visit',
        icon: 'truck-medical',
        value: IncidentType.INCIDENT_TYPE_ER_VISIT,
      },
    ],
    'name'
  ).concat([
    {
      name: 'Other',
      icon: 'note',
      value: IncidentType.INCIDENT_TYPE_OTHER,
    },
    {
      name: 'No Tags',
      icon: 'ban',
      value: IncidentType.INCIDENT_TYPE_UNSPECIFIED,
    },
  ])

export const followUpActions: { name: string; value: IncidentActionType }[] = [
  {
    name: 'Notify Responsible Person',
    value: IncidentActionType.INCIDENT_ACTION_TYPE_NOTIFY_RP,
  },
  {
    name: 'Notify Primary Physician',
    value: IncidentActionType.INCIDENT_ACTION_TYPE_NOTIFY_GP,
  },
  {
    name: 'Notify State',
    value: IncidentActionType.INCIDENT_ACTION_TYPE_NOTIFY_CA_LIC_624,
  },
  {
    name: 'Update the Service Plan',
    value: IncidentActionType.INCIDENT_ACTION_TYPE_UPDATE_SERVICE_PLAN,
  },
  {
    name: 'Notify LTC Ombudsman',
    value: IncidentActionType.INCIDENT_ACTION_TYPE_NOTIFY_LTC_OMBUDSMAN,
  },
  {
    name: 'Notify Law Enforcement',
    value: IncidentActionType.INCIDENT_ACTION_TYPE_NOTIFY_LAW_ENFORCEMENT,
  },
  {
    name: 'Notify Placement Agency',
    value: IncidentActionType.INCIDENT_ACTION_TYPE_NOTIFY_PLACEMENT_AGENCY,
  },
]

export const incidentStatus = [
  {
    name: 'Open',
    icon: 'circle-dashed',
    value: IncidentStatus.INCIDENT_STATUS_OPEN,
  },
  {
    name: 'Closed',
    icon: 'circle-check',
    regularIconType: true,
    value: IncidentStatus.INCIDENT_STATUS_COMPLETED,
  },
  {
    name: 'Reportable to state',
    icon: 'landmark-flag',
    regularIconType: true,
    value: IncidentActionType.INCIDENT_ACTION_TYPE_NOTIFY_CA_LIC_624,
  },
]

export const time = [
  {
    name: 'Last 24 hours',
    icon: 'calendar-days',
    value: 'lastTwentyFourHours',
  },
  {
    name: 'Last 7 days',
    icon: 'calendar-days',
    value: 'lastSevenDays',
  },

  {
    name: 'Last 30 days',
    icon: 'calendar-days',
    value: 'lastThirtyDays',
  },
  {
    name: 'Last 60 days',
    icon: 'calendar-days',
    value: 'lastSixtyDays',
  },

  {
    name: 'Last 120 days',
    icon: 'calendar-days',
    value: 'lastHundredAndTwentyDays',
  },
  {
    name: 'Last 12 Months',
    icon: 'calendar-days',
    value: 'lastTwelveMonths',
  },
  {
    name: 'Year to Date',
    icon: 'calendar-days',
    value: 'yearToDate',
  },
]

const onAlert = [
  {
    name: 'On Alert',
    icon: 'triangle-exclamation',
    value: true,
  },
]

export const inputWithIconGroups = {
  incidentStatus,
  time,
  noteTypes,
  incidentTypes: tags,
  onAlert,
}

export function title(incident: Incident) {
  return match(incident.detail)
    .with({ incidentDetail: P.select() }, (incidentDetail) => {
      const representation = (incidentDetail?.types ?? [])
        .map((incidentType) => tags.find((tag) => tag.value === incidentType))
        .filter(notEmpty)

      if (representation.length > 0) {
        return representation.map((r) => r.name).join(', ')
      } else {
        return 'Incident'
      }
    })
    .with({ noteDetail: P.select() }, (noteDetail) => {
      const representation = (noteDetail?.types ?? [])
        .map((noteType) => noteTypes.find((tag) => tag.value === noteType))
        .filter(notEmpty)

      if (representation.length > 0) {
        return representation.map((r) => r.name).join(', ')
      } else {
        return 'Note'
      }
    })
    .otherwise(() => 'Note')
}

export function icon(incident: Incident) {
  return match(incident.detail)
    .with({ incidentDetail: P.select() }, (incidentDetail) => {
      const representation = (incidentDetail?.types ?? [])
        .map((incidentType) => tags.find((tag) => tag.value === incidentType))
        .filter(notEmpty)

      if (representation.length === 1) {
        return representation[0].icon
      } else {
        return 'hexagon-exclamation'
      }
    })
    .with({ noteDetail: P.select() }, (noteDetail) => {
      const representation = (noteDetail?.types ?? [])
        .map((noteType) => noteTypes.find((tag) => tag.value === noteType))
        .filter(notEmpty)

      if (representation.length > 0) {
        return representation[0].icon
      } else {
        return 'note'
      }
    })
    .otherwise(() => 'note')
}

export function modifiedByString(
  incident: Incident,
  timezoneConverter?: TimeZoneConvertFunc
) {
  const { modifiedByUserName: userName, modificationTime } =
    incident.updatedBy || {}
  const dateTime =
    modificationTime && timezoneConverter
      ? timezoneConverter(modificationTime)
      : modificationTime

  if (userName && dateTime) {
    return `Last edited by ${getFullName(userName)} on ${formatDateToDateAtTimeLabel(dateTime)}`
  }

  return undefined
}

export const createdByString = (
  incident: Incident,
  timezoneConverter?: TimeZoneConvertFunc
) => {
  const { modifiedByUserName: userName, modificationTime } =
    incident.createdBy || {}
  const creationDateTime =
    modificationTime && timezoneConverter
      ? timezoneConverter(modificationTime)
      : modificationTime

  if (userName && creationDateTime) {
    return `Created by ${getFullName(userName)} on ${formatDateToDateAtTimeLabel(creationDateTime)}`
  }

  return undefined
}

export function occurredAtString(incident: Incident) {
  return incident.occurredAt
    ? formatDateToDateAtTimeLabel(incident.occurredAt)
    : undefined
}

export function locationString(incident: Incident) {
  if (incident.detail?.incidentDetail?.location) {
    const location = incident.detail.incidentDetail.location

    if (location === IncidentLocation.INCIDENT_LOCATION_OTHER) {
      return incident.detail.incidentDetail.otherLocation
    }

    switch (location) {
      case IncidentLocation.INCIDENT_LOCATION_ACTIVITY_ROOM:
        return 'Activity Room'
      case IncidentLocation.INCIDENT_LOCATION_BATHROOM:
        return 'Bathroom'
      case IncidentLocation.INCIDENT_LOCATION_BEAUTY_SALON:
        return 'Beauty Salon'
      case IncidentLocation.INCIDENT_LOCATION_COMMON_AREA:
        return 'Common Area'
      case IncidentLocation.INCIDENT_LOCATION_DINING_ROOM:
        return 'Dining Room'
      case IncidentLocation.INCIDENT_LOCATION_HALLWAY:
        return 'Hallway'
      case IncidentLocation.INCIDENT_LOCATION_LOBBY:
        return 'Lobby'
      case IncidentLocation.INCIDENT_LOCATION_ROOM:
        return 'Room'
      case IncidentLocation.INCIDENT_LOCATION_SHOWER_BATHTUB:
        return 'Shower or Bathtub'
    }
  }

  return undefined
}

export function injuriesString(incident: Incident) {
  return incident.detail?.incidentDetail?.injuries?.note
}

export function hasActiveAlert(incident: Incident) {
  if (incident.alert === undefined) {
    return false
  }

  if (incident.alert.endedAt && incident.alert.startedAt) {
    const now = Date.now()

    return (
      isBefore(parseISO(incident.alert.startedAt), now) &&
      isAfter(parseISO(incident.alert.endedAt), now)
    )
  }

  return false
}

export const commentsUpdatedByText = (updatedBy: Modification): string =>
  getFullName(updatedBy.modifiedByUserName)

export const commentsUpdatedTimeText = (updatedBy: Modification): string =>
  timeago.format(new Date(updatedBy.modificationTime || ''))

export function isIncident(i: Incident) {
  return !!i.detail?.incidentDetail
}

export function frequencyText(incident: Incident) {
  if (incident.alert) {
    return incident.alert.frequency
      ? `Check every ${incident.alert.frequency / 60} hours.`
      : 'Check as needed'
  }

  return undefined
}

function getIncidentActions(incident: Incident) {
  return incident.detail?.incidentDetail?.incidentActions
}

export function getIncidentSatus(incident: Incident) {
  return incident.detail?.incidentDetail?.status
}

export function hasIncompleteAction(incident: Incident) {
  const incidentActions = getIncidentActions(incident)
  return incidentActions?.some(
    (action) =>
      action.status !== IncidentActionStatus.INCIDENT_ACTION_STATUS_COMPLETED
  )
}

/**
 * Returns the number of days or hours remaining in the alert
 * If the alert has GT 2 days remaining, it will return the number of days
 * If the alert has LEQ 2 days remaining, it will return the number of hours
 * @param incident
 */
export function remainingAlertTime(incident: Incident) {
  if (incident.alert?.endedAt === undefined) {
    return undefined
  }
  const remainingTimeInDays = differenceInCalendarDays(
    new Date(incident.alert.endedAt),
    new Date()
  )

  if (remainingTimeInDays <= 2) {
    const remainingTimeInHours = differenceInHours(
      new Date(incident.alert.endedAt),
      new Date()
    )

    return `${remainingTimeInHours} hours`
  } else {
    return `${remainingTimeInDays} days`
  }
}

export function customIncidentActionIdentifier(action: IncidentAction) {
  return action.sideEffect?.fillablePdf?.customType
}

export function isServicePlanAction(action: IncidentAction) {
  return (
    action.type === IncidentActionType.INCIDENT_ACTION_TYPE_UPDATE_SERVICE_PLAN
  )
}

export function isResidentIncidentReportAction(action: IncidentAction) {
  return (
    action.type ===
    IncidentActionType.INCIDENT_ACTION_TYPE_RESIDENT_INCIDENT_REPORT
  )
}

export function isInsuranceIncidentReportAction(action: IncidentAction) {
  return (
    action.type ===
    IncidentActionType.INCIDENT_ACTION_TYPE_INSURANCE_INCIDENT_REPORT
  )
}
