import { LatestVital } from '@augusthealth/models/com/august/protos/api/latest_vital'
import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useEffect, useState } from 'react'
import { hasPermissionForPerson } from '@shared/components/PermissionGates/PermissionGates'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { Person } from '@shared/types/person'
import { Loading } from '@shared/utils/loading'
import { getLatestVitals } from '../api/vitals'

export default function useLatestVitals(person: Person | undefined) {
  const { user } = useUserContext()
  const { setError } = useContext(GlobalContext)
  const [latestVitals, setLatestVitals] = useState<Loading<LatestVital>>({
    tag: 'Loading',
  })

  const { id } = person || {}

  const refreshLatestVitals = async () => {
    if (person === undefined) {
      return Promise.resolve()
    }

    if (
      !hasPermissionForPerson({
        user,
        person,
        permissions: [GroupPermission.GROUP_PERMISSION_VITAL_READ],
      })
    ) {
      return Promise.resolve()
    }

    setLatestVitals({ tag: 'Loading' })

    return getLatestVitals(person)
      .then((res) => {
        setLatestVitals({ tag: 'Complete', value: res })
      })
      .catch(setError)
  }

  useEffect(() => {
    void refreshLatestVitals()
  }, [id])

  return { latestVitals, refreshLatestVitals }
}
