/* eslint react-hooks/exhaustive-deps: 2 */

import { Option } from 'effect'
import { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { CalendarInput } from '@shared/components/CalendarInput/CalendarInput'
import { SimpleSpinner } from '@shared/components/LoadingPopup'
import MissedAdministrationsBanner from '@shared/components/MissedAdministrationsBanner'
import { CareGroupsSelect } from '@shared/components/Selects/CareGroupsSelect'
import ShiftSelect from '@shared/components/Selects/ShiftSelect'
import { CareGroup } from '@shared/types/care_group'
import {
  FacilityProgressRoutines,
  RoutineAdministrationProgressType,
} from '@shared/types/careapp'
import { Facility } from '@shared/types/facility'
import {
  routineAdminIsDone,
  routineAdminIsException,
  routineAdminIsOverDue,
} from '@shared/utils/careapp'
import { tw } from '@shared/utils/tailwind'
import Content from '@app/components/generic/Content'
import PersonPageTitle from '@app/components/PersonPageTitle'
import { getProgressRoutinesPageUrl } from '../paths'
import ResidentRoutines from './ResidentRoutines'
import RoutineCharts from './RoutineCharts'
import RoutineFilterAndSearch from './RoutineFilterAndSearch'

export default function PageLoaded({
  facility,
  facilityCareProgress,
  reloadFacilityCareProgress,
  selectedDate,
  careGroupId,
  shiftId,
  setIsoDate,
  setCareGroupId,
  setShiftId,
}: {
  facility: Facility
  facilityCareProgress: FacilityProgressRoutines
  reloadFacilityCareProgress: () => Promise<void>
  selectedDate: Date
  careGroupId: string | null
  shiftId: string | null
  setIsoDate: (date: Date) => void
  setCareGroupId: (optionCareGroupId: Option.Option<CareGroup>) => void
  setShiftId: (shiftId: string) => void
}) {
  const { timeZone, orgId, id: facilityId } = facility
  const [searchText, setSearchText] = useState('')
  const [selectedProgressTypes, setSelectedProgressTypes] = useState<
    RoutineAdministrationProgressType[]
  >([])

  const {
    careGroups,
    people,
    shifts,
    routineAdministrations: routineAdministrationsForDate,
    missedAdministrationsHistory = [],
  } = facilityCareProgress

  const selectedCareGroup = careGroups.find(({ id }) => id === careGroupId)
  const routineAdministrationsForSelectedShift = shiftId
    ? routineAdministrationsForDate.filter(
        (administration) => shiftId === administration.shiftId
      )
    : routineAdministrationsForDate
  const overdueRoutines = routineAdministrationsForSelectedShift.filter(
    (administration) => routineAdminIsOverDue(administration, timeZone)
  )
  const exceptionRoutines = routineAdministrationsForSelectedShift.filter(
    (administration) => routineAdminIsException(administration)
  )
  const doneRoutines = routineAdministrationsForSelectedShift.filter((r) =>
    routineAdminIsDone(r)
  )

  useEffect(() => {
    if (!shiftId) {
      const { shifts } = facilityCareProgress
      const firstShift = shifts[0]!
      setShiftId(firstShift.id)
    }
  }, [facilityCareProgress, setShiftId, shiftId])

  if (!shiftId) {
    return <SimpleSpinner />
  }

  return (
    <Content className={tw`mt-[32px]`}>
      <PersonPageTitle title="Care Progress" withBorder={false}>
        <div className={tw`flex gap-3`}>
          <DatePicker
            wrapperClassName={tw`w-[174px]`}
            selected={selectedDate}
            onChange={setIsoDate}
            customInput={<CalendarInput className={tw`w-[174px]`} />}
            maxDate={new Date()}
          />
          <ShiftSelect
            className={tw`min-w-[174px]`}
            selectedShiftId={shiftId}
            shifts={shifts}
            onChange={setShiftId}
          />
          <CareGroupsSelect
            className={tw`min-w-[174px]`}
            selectedCareGroup={Option.fromNullable(selectedCareGroup)}
            careGroups={careGroups}
            onChangeSelectedCareGroup={setCareGroupId}
          />
        </div>
      </PersonPageTitle>
      {missedAdministrationsHistory.length > 0 && (
        <MissedAdministrationsBanner
          title="You have missed routines"
          orgId={orgId}
          facilityId={facilityId}
          activeDate={selectedDate}
          missedAdministrationsHistory={missedAdministrationsHistory}
          className={tw`mb-[16px]`}
          linkFunc={(oId, fId, { date }) =>
            getProgressRoutinesPageUrl(oId, fId, {
              date,
              careGroupId,
              shiftId,
            })
          }
        />
      )}
      <RoutineCharts
        allRoutineAdministrations={routineAdministrationsForDate}
        shiftId={shiftId}
        shifts={shifts}
        setShiftId={setShiftId}
        facilityTimeZone={timeZone}
      />
      <RoutineFilterAndSearch
        doneCount={doneRoutines.length}
        exceptionsCount={exceptionRoutines.length}
        overdueCount={overdueRoutines.length}
        selectedProgressTypes={selectedProgressTypes}
        setSelectedProgressTypes={(type: RoutineAdministrationProgressType) => {
          setSelectedProgressTypes((prev) => {
            if (prev.includes(type)) {
              return prev.filter((t) => t !== type)
            } else {
              return [...prev, type]
            }
          })
        }}
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <ResidentRoutines
        careAppPeople={people}
        routineAdministrations={routineAdministrationsForSelectedShift}
        selectedProgressTypes={selectedProgressTypes}
        facilityTimeZone={timeZone}
        searchText={searchText}
        reload={reloadFacilityCareProgress}
      />
    </Content>
  )
}
