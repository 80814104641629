import { billingRoute } from '@shared/routes'
import { PersonParams } from '@shared/types/person'
import { getFirstAndLastName } from '@shared/utils/humanName'
import { getOrElse } from '@shared/utils/loading'
import BreadcrumbEntry from '@app/components/Breadcrumbs/BreadcrumbEntry'
import {
  BreadcrumbContainer,
  BreadcrumbLinksContainer,
} from '@app/components/Breadcrumbs/layout'
import { useCurrentFacility } from '@app/hooks/useFacilities'
import { Tab } from '../../paths'

type Props = PersonParams & {
  activeTab: Tab
}

export default function BillingBreadcrumbs({
  orgId,
  facilityId,
  personId,
  activeTab,
}: Props) {
  const { residents } = useCurrentFacility()
  const resident = getOrElse(residents, []).find(
    (r) => r.person.id === personId
  )

  return (
    <BreadcrumbContainer className="pb-0">
      <BreadcrumbLinksContainer>
        <BreadcrumbEntry
          breadcrumb={{
            text: 'Billing',
            url: billingRoute(orgId, facilityId),
          }}
          index={0}
        />
        <BreadcrumbEntry
          breadcrumb={{
            text: activeTab,
            url: `${billingRoute(orgId, facilityId)}/${activeTab.toLowerCase()}`,
          }}
          index={1}
        />
        {resident && (
          <BreadcrumbEntry
            breadcrumb={{
              text: getFirstAndLastName(resident.person.name),
              url: `${billingRoute(orgId, facilityId)}/people/${resident.person.id}}`,
            }}
            index={2}
          />
        )}
      </BreadcrumbLinksContainer>
    </BreadcrumbContainer>
  )
}
