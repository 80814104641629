import { GroupPermission } from '@augusthealth/models/com/august/protos/permission'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { BasicTextarea } from '@shared/components/BasicInput/BasicInput'
import ButtonLink from '@shared/components/ButtonLink'
import { DotDivider } from '@shared/components/DotDivider'
import {
  hasPermissionForPerson,
  PersonPermissionGate,
} from '@shared/components/PermissionGates/PermissionGates'
import CurrentFacilityContext from '@shared/contexts/CurrentFacilityContext'
import GlobalContext from '@shared/contexts/GlobalContext'
import { useUserContext } from '@shared/contexts/UserContext'
import { getTaskUrl } from '@shared/legacy_routes'
import { Person } from '@shared/types/person'
import { DataType, Snapshot } from '@shared/types/snapshot'
import { Task } from '@shared/types/task'
import { FacilityGroupType } from '@shared/utils/careGroup'
import { Loading } from '@shared/utils/loading'
import { displayCPRCode, hasDementia } from '@shared/utils/person'
import PdfPopup from '@app/components/Prospects/Forms/sharable/PdfPopup'
import useAmbulatoryStatus from '@app/hooks/useAmbulatoryStatus'
import usePerson from '@app/hooks/usePerson'
import GeneralUploader from '@app/pages/Documents/Uploader'
import POLSTViewer from '@app/pages/Documents/Viewer/POLSTViewer'
import styles from './styles.module.css'
import { PlainDetailsCard } from '../DetailsCard'
import CareOrMedGroupRow from './CareOrMedGroupRow'
import CareRow from './CareRow'
import EditAmbulatoryStatus from './EditCareHighlights/EditAmbulatoryStatus'
import EditCareOrMedGroup from './EditCareHighlights/EditCareOrMedGroup'
import EditDementia from './EditCareHighlights/EditDementia'
import { updateNotes } from './helpers'
import LevelOfCareRow from './LevelOfCareRow'

interface Props {
  person: Person
  incompletePolstTask: Task | undefined
  levelOfCareTask: Task | undefined
  serviceAppraisals: Snapshot[]
  onUpdate: (toUpdate: 'Person' | 'Tasks' | 'Both' | 'None') => Promise<void>
  currentPOLST: Loading<Snapshot | undefined>
}

export default function CareHighlights({
  person,
  onUpdate,
  incompletePolstTask,
  levelOfCareTask,
  serviceAppraisals,
  currentPOLST,
}: Props) {
  const { currentFacility } = useContext(CurrentFacilityContext)
  const { setError } = useContext(GlobalContext)
  const { user } = useUserContext()
  const history = useHistory()
  const { ambulatoryStatus } = useAmbulatoryStatus(person)
  const [notes, setNotes] = useState(person.prospectInfo?.details)

  const [showEditAmbulatory, setShowEditAmbulatory] = useState(false)
  const [showPolst, setShowPolst] = useState(false)
  const [showLevelOfCare, setShowLevelOfCare] = useState(false)
  const [showUploadPolst, setShowUploadPolst] = useState(false)
  const [showEditDementia, setShowEditDementia] = useState(false)
  const [showEditCareOrMedGroup, setShowEditCareOrMedGroup] = useState<
    FacilityGroupType | undefined
  >(undefined)

  const { person: eTagPerson } = usePerson({
    initialData: person,
    fields: ['prospectInfo/details'],
    dependencies: [person.id],
    showWhenLoading: true,
  })

  useEffect(() => {
    if (eTagPerson.tag === 'Complete') {
      setNotes(eTagPerson.value.prospectInfo?.details)
    } else {
      setNotes(person.prospectInfo?.details)
    }
  }, [eTagPerson.tag, person.id])

  async function updateResidentSnapshot(newNote: string) {
    try {
      await updateNotes(newNote, person)
      await onUpdate('Person')
    } catch (e) {
      setError(e)
    }
  }

  const dementia = hasDementia(person)
  const cprCode = displayCPRCode(person, 'Unknown')
  const canUpdateNotes = hasPermissionForPerson({
    user,
    person,
    permissions: [GroupPermission.GROUP_PERMISSION_PERSON_UPDATE],
  })

  return (
    <PlainDetailsCard icon="fas fa-info-square fa-fw" title="Care Highlights">
      <div>
        <POLSTRow
          cprCode={cprCode}
          currentPOLST={currentPOLST}
          showPOLST={() => setShowPolst(true)}
          showUploadPOLST={() => setShowUploadPolst(true)}
          person={person}
        />
        <LevelOfCareRow
          serviceAppraisals={serviceAppraisals}
          levelOfCareTask={levelOfCareTask}
          person={person}
          user={user}
          showLoC={() => setShowLevelOfCare(true)}
          goToLoC={(loc: Task) =>
            history.push(
              getTaskUrl({
                id: loc.id!,
                orgId: loc.orgId!,
                personId: loc.personId!,
                facilityId: person.facilityId!,
              })
            )
          }
        />
        <CareOrMedGroupRow
          facilityGroupType="care"
          person={person}
          showEditGroup={() => setShowEditCareOrMedGroup('care')}
        />
        <CareOrMedGroupRow
          facilityGroupType="med"
          person={person}
          showEditGroup={() => setShowEditCareOrMedGroup('med')}
        />
        <CareRow label="Ambulatory">
          {ambulatoryStatus}
          <PersonPermissionGate
            person={person}
            permissions={[GroupPermission.GROUP_PERMISSION_PERSON_UPDATE]}
          >
            <DotDivider />
            <ButtonLink onClick={() => setShowEditAmbulatory(true)}>
              Edit
            </ButtonLink>
          </PersonPermissionGate>
        </CareRow>
        <CareRow label="Dementia">
          {dementia ? 'Yes' : 'No'}
          <PersonPermissionGate
            person={person}
            permissions={[GroupPermission.GROUP_PERMISSION_PERSON_UPDATE]}
          >
            <DotDivider />
            <ButtonLink onClick={() => setShowEditDementia(true)}>
              Edit
            </ButtonLink>
          </PersonPermissionGate>
        </CareRow>
      </div>
      <div
        className={styles.residentSnapshot}
        key={`${person.id}-resident-notes`}
      >
        <BasicTextarea
          name={`${person.id}-resident-notes`}
          data-testid={'resident-notes'}
          disabled={!canUpdateNotes || eTagPerson.tag === 'Loading'}
          placeholder="Include high-level information to be visible at a glance"
          value={notes}
          className={styles.noteContent}
          style={{ cursor: canUpdateNotes ? 'pointer' : 'default' }}
          onChange={(e) => setNotes(e.currentTarget.value)}
          onBlur={(e) => updateResidentSnapshot(e.target.value)}
        />
      </div>
      {showEditAmbulatory && (
        <EditAmbulatoryStatus
          person={person}
          onClose={async (updated) => {
            if (updated) {
              await onUpdate('Person')
            }
            setShowEditAmbulatory(false)
          }}
        />
      )}
      {currentPOLST.tag === 'Complete' && currentPOLST.value && showPolst && (
        <POLSTViewer
          person={person}
          onComplete={async (updated) => {
            if (updated) {
              await onUpdate('Both')
            }
            setShowPolst(false)
          }}
          currentPOLST={currentPOLST.value}
        />
      )}
      {levelOfCareTask && showLevelOfCare && (
        <LevelofCareViewer
          person={person}
          task={levelOfCareTask}
          onClose={() => setShowLevelOfCare(false)}
        />
      )}
      {showUploadPolst && (
        <GeneralUploader
          initialType={DataType.DATA_TYPE_POLST}
          person={person}
          tasks={incompletePolstTask ? [incompletePolstTask] : []}
          onUpdate={async () => await onUpdate('Both')}
          onClose={() => setShowUploadPolst(false)}
        />
      )}
      {showEditDementia && (
        <EditDementia
          onClose={async (updated) => {
            if (updated) {
              await onUpdate('Person')
            }
            setShowEditDementia(false)
          }}
          person={person}
        />
      )}
      {showEditCareOrMedGroup && currentFacility?.tag === 'Complete' && (
        <EditCareOrMedGroup
          facility={currentFacility.value}
          facilityGroupType={showEditCareOrMedGroup}
          onClose={async (updated) => {
            if (updated) {
              await onUpdate('Person')
            }
            setShowEditCareOrMedGroup(undefined)
          }}
          person={person}
        />
      )}
    </PlainDetailsCard>
  )
}

function LevelofCareViewer({
  person,
  task,
  onClose,
}: {
  person: Person
  task: Task
  onClose: () => void
}) {
  return (
    <PdfPopup
      params={{
        id: person.id!,
        orgId: person.orgId!,
        taskId: task.id!,
        facilityId: person.facilityId!,
      }}
      onClose={onClose}
    />
  )
}

function POLSTRow({
  cprCode,
  currentPOLST,
  showPOLST,
  showUploadPOLST,
  person,
}: {
  cprCode: string | undefined
  currentPOLST: Loading<Snapshot | undefined>
  showPOLST: () => void
  showUploadPOLST: () => void
  person: Person
}) {
  if (currentPOLST.tag === 'Loading') {
    return (
      <CareRow label="Code Status">
        <span className={styles.incomplete}>Loading...</span>
      </CareRow>
    )
  }

  if (currentPOLST.tag === 'Complete' && currentPOLST.value) {
    // Person has a POLST
    return (
      <CareRow label="Code Status">
        {cprCode}
        <DotDivider />
        <ButtonLink onClick={showPOLST}>View</ButtonLink>
      </CareRow>
    )
  } else {
    // No active POLST
    return (
      <CareRow label="Code Status">
        {cprCode}
        <PersonPermissionGate
          person={person}
          permissions={[GroupPermission.GROUP_PERMISSION_SNAPSHOT_CREATE]}
        >
          <DotDivider />
          <ButtonLink onClick={showUploadPOLST}>Upload</ButtonLink>
        </PersonPermissionGate>
      </CareRow>
    )
  }
}
