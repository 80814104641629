import { ReactNode } from 'react'
import { ClassNameValue } from 'tailwind-merge'
import Icon from '@shared/components/Icon'
import {
  VerticalBarColors,
  VerticalRoundedBar,
} from '@shared/components/VerticalRoundedBar'
import { tw, twx } from '@shared/utils/tailwind'

export function SummarySectionTitle({ title }: { title: string }) {
  return (
    title && (
      <div
        className={tw`text-xs font-semibold uppercase leading-3 text-gray-07`}
      >
        {title}
      </div>
    )
  )
}

export function SummarySectionDetails({
  barColor,
  bgColor,
  children,
  className,
}: {
  children: ReactNode
  barColor: VerticalBarColors
  bgColor?: string
  className?: ClassNameValue
}) {
  return (
    <div
      className={tw`flex flex-row justify-start gap-layout-gap-xs`}
      // TO-DO covert color code to class
      // eslint-disable-next-line no-restricted-syntax
      style={bgColor ? { backgroundColor: bgColor } : undefined}
    >
      <VerticalRoundedBar color={barColor} />
      <div
        className={twx('px-layout-gap-xs py-layout-gap-xxs text-sm', className)}
      >
        {children}
      </div>
    </div>
  )
}

export function SummarySection({
  title,
  children,
}: {
  title: string
  children: ReactNode
}) {
  return (
    <div className={twx('flex flex-col gap-layout-gap-sm')}>
      <SummarySectionTitle title={title} />
      {children}
    </div>
  )
}

export function MultiDetailsSummarySection({
  title,
  children,
  className,
}: {
  title: string
  children: ReactNode
  className?: ClassNameValue
}) {
  return (
    <div className={twx('flex flex-col gap-layout-gap-sm')}>
      <SummarySectionTitle title={title} />
      <ul className={twx('flex flex-col flex-wrap gap-layout-gap', className)}>
        {children}
      </ul>
    </div>
  )
}

export function SummarySectionList({
  barColor,
  title,
  children,
  bgColor,
}: {
  title: string
  barColor: VerticalBarColors
  children: React.ReactNode
  bgColor?: string
}) {
  return (
    <div className={twx('flex flex-col gap-layout-gap-sm')}>
      <SummarySectionTitle title={title} />
      <SummarySectionDetails bgColor={bgColor} barColor={barColor}>
        <ul className={tw`flex flex-col gap-layout-gap-xxs`}>{children}</ul>
      </SummarySectionDetails>
    </div>
  )
}

export function SummarySectionListItem({
  iconName,
  iconClass,
  liClass,
  children,
  onLiClick,
}: {
  iconName: string
  iconClass: string
  liClass?: string
  children: React.ReactNode
  onLiClick?: () => void
}) {
  return (
    <li
      className={twx(
        'flex flex-row items-start gap-layout-gap-xs leading-relaxed',
        { 'cursor-pointer': onLiClick },
        liClass
      )}
      onClick={onLiClick}
    >
      <Icon
        name={iconName}
        className={twx('fa-fw leading-relaxed', iconClass)}
      />
      {children}
    </li>
  )
}
