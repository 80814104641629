import { isMatchWith } from 'lodash'
import { Redirect, useParams } from 'react-router-dom'
import { useUserContext } from '@shared/contexts/UserContext'
import { GroupPermission } from '@shared/types/permission'
import { isSuperUser } from '@shared/utils/user'

/**
 * A component similar to <GatedRoute />, but used in our react-router v6 upgrade project.
 *
 * Currently, this handles super-users and community users. Feature flags & internal user
 * handling will need to be added as required.
 */
export function RequirePermissions({
  children,
  minimalPermissions,
}: {
  children: React.ReactNode
  minimalPermissions: GroupPermission[]
}) {
  const { user } = useUserContext()
  const {
    orgId: routeOrgId,
    facilityId: routeFacilityId,
    personId: routePersonId,
  } = useParams<{
    orgId?: string
    facilityId?: string
    personId?: string
  }>()

  const hasGroupPermission = (user.groups || []).find((g) => {
    const { organizationId, facilityId, personId } = g.personMatcher || {}
    return (
      minimalPermissions.every((p) => g.groupPermissions?.includes(p)) &&
      isMatchWith(
        {
          organizationId: routeOrgId,
          facilityId: routeFacilityId,
          personId: routePersonId,
        },
        {
          organizationId,
          facilityId,
          personId,
        },
        (pathValue, matcherValue) =>
          pathValue === matcherValue || matcherValue === undefined
      )
    )
  })

  return hasGroupPermission || isSuperUser(user) ? (
    children
  ) : (
    <Redirect to={'/'} />
  )
}
