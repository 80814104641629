import { fetchJson, request } from '@shared/api/request'
import { apiUrl, facilityUrl, personUrl } from '@shared/api/urls'
import {
  CareAppPerson,
  FacilityProgressRoutines,
  RoutineAdministration,
} from '@shared/types/careapp'
import { FacilityIds } from '@shared/types/facility'
import { DeepNull } from '@shared/types/utilities'

type FacilityCareProgress = { data: FacilityProgressRoutines }

export type FetchFacilityCareProgress = {
  facility: FacilityIds
  careGroupId?: string
  date?: string
  shiftId?: string
}

export async function fetchFacilityCareProgress({
  facility: { orgId, id: facilityId },
  careGroupId,
  date,
  shiftId,
}: Pick<
  FetchFacilityCareProgress,
  'facility' | 'careGroupId' | 'date' | 'shiftId'
>) {
  return fetchJson<FacilityCareProgress>(
    apiUrl(facilityUrl(orgId, facilityId), '/care/progress', {
      careGroupId,
      date,
      shiftId,
    }),
    { method: 'GET' }
  ).then((res: FacilityCareProgress) => res.data)
}

export async function patchRoutineAdministration({
  adminId,
  careAppPerson,
  routineAdministration,
}: {
  adminId: string
  careAppPerson: Pick<CareAppPerson, 'id' | 'facilityId' | 'orgId'>
  routineAdministration: Partial<DeepNull<RoutineAdministration>>
}) {
  const { orgId, facilityId, id: personId } = careAppPerson
  return (await request({
    url: `${personUrl(orgId, facilityId, personId)}/routineAdministrations/${adminId}`,
    body: JSON.stringify(routineAdministration),
    contentType: 'application/merge-patch+json',
    method: 'PATCH',
  })) as Promise<{ data: RoutineAdministration }>
}
