import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { changeTemporaryPassword } from '@shared/api/user'
import Footer from '@shared/components/Auth/LoginWithUsernameOrEmail/Footer'
import LogoHeader from '@shared/components/LogoHeader'
import { PasswordPolicy } from '@shared/types/settings/security_policy'
import { tw } from '@shared/utils/tailwind'
import ChangePasswordForm from './ChangePasswordForm'
import { ForgotPasswordFormProps } from './type'

export default function ResetPassword({
  userAlias,
  currentPassword,
  afterReset,
}: {
  userAlias: string
  currentPassword: string
  afterReset: ({
    preferredUsername,
    password,
  }: {
    preferredUsername: string
    password: string
  }) => void
}) {
  const [hasError, setHasError] = useState<boolean>(false)
  const [resetting, setResetting] = useState<boolean>(false)
  const methods = useForm<ForgotPasswordFormProps>({
    defaultValues: {
      code: '',
      password: '',
      passwordConfirmation: '',
    },
    mode: 'all',
    criteriaMode: 'all',
  })

  if (hasError) {
    return (
      <div className={tw`align-items-center flex flex-col text-center`}>
        <div
          className={tw`font-inter font-[20px] font-semibold leading-[24px] text-secondary-04`}
        >
          We weren't able to update your password.
        </div>
        <div
          className={tw`mt-[16px] font-inter font-[16px] font-medium leading-[24px] text-secondary-07`}
        >
          Please try again, or contact an administrator to reset your password
        </div>
        <button
          className={tw`mt-[40px] h-[48px] w-[360px] rounded-[8px] border-2 border-august-primary bg-background-login text-[14px] font-semibold uppercase leading-[16px] text-august-primary transition-colors duration-[350ms]`}
          onClick={() => setHasError(false)}
          type="button"
        >
          Try again
        </button>
      </div>
    )
  }

  async function resetPassword({ password }: { password: string }) {
    try {
      setResetting(true)

      await changeTemporaryPassword({
        userAlias,
        currentPassword,
        newPassword: password,
      })

      afterReset({
        preferredUsername: userAlias,
        password,
      })
    } catch (err) {
      setHasError(true)
    } finally {
      setResetting(false)
    }
  }

  return (
    <div className={tw`flex flex-col items-center`}>
      <LogoHeader title="Change temporary password" />
      <div className={tw`mt-[24px] w-[360px] text-center`}>
        Your temporary password has expired, please set a new password to
        continue.
      </div>
      <FormProvider {...methods}>
        <ChangePasswordForm
          passwordPolicy={PasswordPolicy.PASSWORD_POLICY_STRICT}
          onSubmit={resetPassword}
          hideCodeInput
          disabled={resetting}
        />
      </FormProvider>
      <Footer />
    </div>
  )
}
